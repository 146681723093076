import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowImageModule } from "./../../directives/show-image/show-image.module";
import { PipesModule } from "./../../pipes/pipes.module";
import { FormsModule } from "@angular/forms";
import { AmlSearchRoutingModule } from './aml-search-routing.module';
import {
  AmlSearchComponent,
  DetailsComponent,
  RelationComponent,
  VisComponent,
  BlackListComponent,
  RelationGraphComponent
} from './index';
import { GaugeChartModule } from 'angular-gauge-chart';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgxOrgChartModule } from 'ngx-org-chart';
import { NgxPaginationModule } from 'ngx-pagination';
import { HighchartsChartComponent } from 'highcharts-angular'
import { CorporateCheckModule } from '../corporate-check/corporate-check.module';
import { NgxGraphModule } from "@swimlane/ngx-graph";
import { ReportComponent } from './profile-search/report/report.component';


@NgModule({
  declarations: [
    AmlSearchComponent,
    DetailsComponent,
    RelationComponent,
    VisComponent,
    BlackListComponent,
    HighchartsChartComponent,
    RelationGraphComponent,
    ReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ShowImageModule,
    PipesModule, GaugeChartModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    AmlSearchRoutingModule,
    NgxOrgChartModule,
    NgxPaginationModule,
    CorporateCheckModule,
    NgxGraphModule,
  ],
  exports: [DetailsComponent, RelationGraphComponent, ReportComponent]
})
export class AmlSearchModule { }


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxStripeModule } from 'ngx-stripe';
import { ArchwizardModule } from "angular-archwizard";

// import { CouponsModule } from "../../pages/coupons/coupons.module" ;

import { CheckoutComponent } from './checkout.component';

@NgModule({
  declarations: [ CheckoutComponent ],
  imports: [
  	FormsModule,
  	ReactiveFormsModule,
  	ArchwizardModule,
    // CouponsModule,
  	// KycModalModule,
    // NgxStripeModule.forRoot("pk_test_YgJt7PMEdzsHAl07H4GWs68R"),
    NgxStripeModule.forRoot(),
    CommonModule
  ],
  exports: [ CheckoutComponent ]
})
export class CheckoutModule { }

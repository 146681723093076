import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: 'app-family-tree',
  templateUrl: './app-family-tree.component.html',
  styleUrls: ['./app-family-tree.component.scss']
})

export class FamilyTree implements OnInit {
  @Input() nodes;
  constructor() {
  }
  ngOnInit() {
  }

}


import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services';
import { RiskScreeningService } from '../risk-screening.service';

@Component({
  selector: 'app-recent-searches',
  templateUrl: './recent-searches.component.html',
  styleUrls: ['./recent-searches.component.scss', '../risk-screening.component.scss']
})
export class RecentSearchesComponent implements OnInit {
  page: number = 1;
  size: number = 10;
  tableList: any[] = [];
  totalItems: number = 0;
  itemPerPage: any[] = [
    { value : 10 },
    { value : 25 },
    { value : 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  searchKey: any;
  order: string;
  orderArray: any[] = [
    { key: 'Latest First', value: 'DESC' },
    { key: 'Oldest First', value: 'ASC' },
  ]
  
  constructor(
    private _data: DataService,
    private _rs: RiskScreeningService,
  ) { }

  ngOnInit() {
    this.order = 'DESC';
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  getTableData(page, size, order){
    const payload = {
      "page" : page ,
      "size" : size,
      "entityName" : this.searchKey,
      "order" : order
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getTopSearchList(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      if(res['data']){
        this.tableList = res['data']['content'] || [];
        this.totalItems = res['data']['totalElements'];
        this.initialNumberElement = page * size + 1;
        if (this.initialNumberElement + size > this.totalItems) {
          this.secondElement = this.totalItems
        }
        else {
          this.secondElement = this.initialNumberElement + size - 1;
        }
      }
    })
  }

  pageChange($event) {
    this.page = $event;
    this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
    if (this.page * this.rowPageNo > this.totalItems) {
      this.secondElement = this.totalItems
    }
    else {
      this.secondElement = this.page * this.rowPageNo;
    }
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.page = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  orderFilter(event){
    this.order = event;
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  clearSearch(){
    this.searchKey = null
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  doSearch(){
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  goToAMLReportPage(data){
    if(data?.entityType == 'Individual'){
      window.open("aml-screening/risk-individual-report/"+data?.entityGuid);
    }
    else if(data?.entityType == 'Entity'){
      window.open("aml-screening/risk-entity-report/"+data?.entityGuid);
    }
    else{
      window.open("aml-screening/risk-corporate-report/"+data?.entityGuid);
    }
  }

}

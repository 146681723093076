import { Directive , Input , HostListener , Inject} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {KycModalComponent} from './../../shared/kyc-modal/kyc-modal.component';
@Directive({
  selector: '[appShowImage]'
})

export class ShowImageDirective {

	@Input() imageVal : any ;

	//Modal
	bsModalRef     : BsModalRef;

	constructor(
		private modalService: BsModalService,
		 @Inject('User_Placeholder') public User_Placeholder: any){
	}

	@HostListener('click', ['$event']) public onClick(evt){
		console.log(this.imageVal);
		this.openModal();
	}

	openModal(){
		let imageVal = this.imageVal ? this.imageVal : this.User_Placeholder ;
	    const initialState = {
	      title: null ,
	      type : 'show-image' ,
	      image : imageVal
	    };
	    this.bsModalRef = this.modalService.show(KycModalComponent, {initialState , class : 'modal-lg showImage'});
	    this.bsModalRef.content.closeBtnName = 'Close';
	}

}

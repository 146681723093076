import { Component, OnInit } from '@angular/core';
import { AppService, DataService } from '../../../services';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../../shared/kyc-modal/kyc-modal.component";

@Component({
  selector: 'app-black-list',
  templateUrl: './black-list.component.html',
  styleUrls: ['./black-list.component.scss']
})
export class BlackListComponent implements OnInit {

  public bsModalRef: BsModalRef;
  blacklist=[];
  jump: any;
  currentPage: number = 0;
  itemsPerPage: number = 10;
  totalItems: number;
  maxSize: number;
  pageList:any=[];
  startIndex: number = 0;
  endIndex: number = 10;
  buildQuery: any = {
    'size': this.itemsPerPage,
    'page': this.currentPage,
    'name': null,
};
  constructor(private _app:AppService,private modalService: BsModalService,private _data:DataService) { }

  ngOnInit() {
   this.getPagedData(this.currentPage);
    
  }

  addEntity(){
    const initialState = {
      type               : "select-type-blacklist" ,
      backdrop           : true ,
      ignoreBackdropClick: true ,
  };
  this.bsModalRef = this.modalService.show(KycModalComponent, {
    initialState,
    class: "modal-lg",
  });
  this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(data=> {
      if(data=='success'){
        this._data.toastrShow(
          "Entity added Successfully",
          "info"
        );
        this.getPagedData(this.currentPage);
      }
    });
  }

  getPaginatedText() {
    let a = (this.currentPage * this.itemsPerPage) + 1;
    let b;
    if (this.currentPage < (this.maxSize - 1)) {
        b = (this.currentPage + 1) * this.itemsPerPage;
    }
    else if (this.currentPage == (this.maxSize - 1)) {
        b = this.totalItems;
    }
    return a + "-" + b;
  }

  getPagedData(pageNo){
    this.currentPage = pageNo
    let payload={size:this.itemsPerPage,page:pageNo}
    this._data.changeLoaderVisibility(true)
    this._app.getBlackList(payload).subscribe(res=>{
      this.blacklist=res.content;
      this.totalItems = res.totalElements;
      this.maxSize = res.totalPages;
      this.jump=this.currentPage+1;
      this.pageSize()
      this._data.changeLoaderVisibility(false)
     })
  }

  getData(){
    this._data.changeLoaderVisibility(true)
    this._app.getBlackList(this.buildQuery).subscribe(res=>{
      this.blacklist=res.content;
      this.totalItems = res.totalElements;
      this.maxSize = res.totalPages;
      this.jump=this.currentPage+1;
      this.pageSize()
      this._data.changeLoaderVisibility(false)
     })
  }

  pageSize() {
    this.pageList = []
    let totalPages = this.getPage(this.totalItems)
    this.startIndex = (this.currentPage - 4) < 0 ? 0 : this.currentPage - 4
    this.endIndex = (this.currentPage + 4) > this.getPage(this.totalItems) ? totalPages : this.currentPage + 4
    for (let i = 0; i <= this.currentPage + 4; i++) {
        if (i >= this.currentPage - 4 && i < totalPages) { this.pageList.push(i) }
    }

}
getPage(length) {
  return (Math.round(length / 10));
}

jumpTo() {
  if (this.jump <= this.maxSize) {
  if(Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0)
  this.getPaginatedData(this.jump - 1)
  }
}

getPaginatedData(isNext) {
  this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
  this.buildQuery["page"] = isNext ? this.buildQuery["page"] + 1 : this.buildQuery["page"] - 1;
  if (this.currentPage < this.maxSize) {
      this.getData();
      this.pageSize()
      this.jump=this.currentPage+1;
  }
}

doFilter(value){
  this.currentPage = 0;
  this.buildQuery["page"] = 0;
  if(value==''){
    this.getPagedData(this.currentPage);
  }
  else{
    this.buildQuery['name'] = value;
    this.getData();
  }

}

clearInput() {
  this.jump=1;
  this.currentPage=0
  this.buildQuery = {
      'size': this.itemsPerPage,
      'page': this.currentPage,
      'name': null,
  };
}
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LiveCheckComponent } from "./live-check.component";
import { VerificationResultComponent } from "./verification-result/verification-result.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { ArchwizardModule } from "angular-archwizard";
import { PipesModule } from "./../../pipes/pipes.module";
import { DndModule } from "./../../directives/dnd/dnd.module";
import { ShowImageModule } from "./../../directives/show-image/show-image.module";
import { AmlDetailsModule } from "./../../pages/report-details/aml-details/aml-details.module";
import { AmlSelectedResultModule } from "./../../pages/live-check/aml-selected-result/aml-selected-result.module";
import { LiveCheckRoutingModule } from "./live-check-routing.module";
import { TabsModule } from "ngx-bootstrap/tabs";
import { CustomFormsModule } from "ng2-validation";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SavedCompanyDataModule } from "./saved-company-data/saved-company-data.module";
import { SecondaryIdCheckComponent } from './secondary-id-check/secondary-id-check.component'
import {
  KycCheckComponent,
  IdCheckComponent,
  AddressCheckComponent,
  AmlCheckComponent,
  OcSearchComponent,
  IndividualFormComponent,
  CorpFormComponent,
  CompanyDataComponent,
  OkycChecksComponent,
  PanChecksComponent,
  PassportValidationsComponent,
  OcrCheckComponent
} from './index';
import { NgOtpInputModule } from  'ng-otp-input';
import { RiskScreeningModule } from "../risk-screening/risk-screening.module";

@NgModule({
  declarations: [
    LiveCheckComponent,
    KycCheckComponent,
    IdCheckComponent,
    SecondaryIdCheckComponent,
    AddressCheckComponent,
    AmlCheckComponent,
    OcSearchComponent,
    IndividualFormComponent,
    CorpFormComponent,
    CompanyDataComponent,
    VerificationResultComponent,
    OkycChecksComponent,
    PanChecksComponent,
    PassportValidationsComponent,
    OcrCheckComponent,
  ],
  imports: [
    TabsModule,
    FormsModule,
    SavedCompanyDataModule,
    CustomFormsModule,
    ReactiveFormsModule,
    ArchwizardModule,
    PipesModule,
    DndModule,
    ShowImageModule,
    CommonModule,
    AmlDetailsModule,
    AmlSelectedResultModule,
    LiveCheckRoutingModule,
    BsDatepickerModule.forRoot(),
    NgOtpInputModule,
    RiskScreeningModule
  ],
  exports: [LiveCheckComponent],
  // schemas: [ CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA ],
})
export class LiveCheckModule { }

import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-adverse-articles',
  templateUrl: './adverse-articles.component.html',
  styleUrls: ['./adverse-articles.component.scss', '../../pages/risk-screening/risk-screening.component.scss']
})
export class AdverseArticlesComponent implements OnChanges {
  @Input() item;
  @Input() entityName;
  @Output() falsePositive = new EventEmitter<any>();
  adverseAccordianArr: any[] = [];
  selectedEntites: string = 'adverseEntites';
  activeEntityTabs: any;
  selectedFullScreen: any;
  public bsModalRef: BsModalRef;
  adverseScore = {
    score: 0,
    lable: 'Low'
  }

  constructor(private modalService: BsModalService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.item?.currentValue) {
      this.item = changes?.item?.currentValue;
      if (this.item?.analysis?.entiries?.length) {
        if (changes?.entityName?.currentValue) {
          this.entityName = changes?.entityName?.currentValue;
        }
        let obj = { adverseEntites: [], normalEntities: [] }
        for (let adv of this.item?.analysis?.entiries) {
          if(adv?.entity == this.entityName){
            let score = Number(adv?.adversityScore);
            if(score == -5){
              score = 20
            }
            else if(score == -1){
              score = 40
            }
            else if(score == 0){
              score = 60
            }
            else if(score == 1){
              score = 80
            }
            else if(score == 5){
              score = 100
            }
            else{
              score = 0
            }
            this.adverseScore['score'] = score;
            if(score > 59){
              this.adverseScore['lable'] = 'High';
            }
            else if(score > 39){
              this.adverseScore['lable'] = 'Medium';
            }
            else{
              this.adverseScore['lable'] = 'Low';
            }
          }
          if (adv?.adversityScore > 0) {
            obj['adverseEntites'].push(adv);
          }
          else {
            obj['normalEntities'].push(adv);
          }
        }
        this.item.analysis = { ...this.item.analysis, ...obj }
      }
    }
  }

  markAsFalsePositive(item) {
    this.falsePositive.emit(JSON.stringify(item));
  }

  // For Auto Expand

  getIndexValueForCard(id) {
    if (this.adverseAccordianArr.indexOf(id) > -1) {
      this.adverseAccordianArr.splice(this.adverseAccordianArr.indexOf(id), 1);
      this.activeEntityTabs = null;
    }
    else {
      this.adverseAccordianArr.push(id)
    }
  }

  getEntities(type) {
    this.selectedEntites = type;
    this.activeEntityTabs = null;
  }

  selectedEntityTabs(tab, item){
    this.activeEntityTabs = tab
    this.activeEntityTabs['entitySlug'] = item?.id + tab?.entity + tab?.entityType;
  }

  toggleFullScreen(template, item) {
    this.selectedFullScreen = item;
    this.bsModalRef = this.modalService.show(template, { class: "modal-lg" });
  }

}

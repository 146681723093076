import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { 
  AmlSummaryDetailsComponent,
  DatasourceListingComponent,
  DataUnavailableComponent,
  RiskAmlSummary,
  NewAmlResultsComponent,
  AdverseArticlesComponent,
  HighlightSentencePipe,
  NotificationTosterComponent
} from '../index';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShowImageModule } from "../../directives/show-image/show-image.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

@NgModule({
  declarations: [
    AmlSummaryDetailsComponent,
    RiskAmlSummary,
    DatasourceListingComponent,
    DataUnavailableComponent,
    NewAmlResultsComponent,
    AdverseArticlesComponent,
    HighlightSentencePipe,
    NotificationTosterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ShowImageModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    ImageCropperModule
  ],
  exports: [
    AmlSummaryDetailsComponent,
    RiskAmlSummary,
    DatasourceListingComponent,
    DataUnavailableComponent,
    NewAmlResultsComponent,
    AdverseArticlesComponent,
    NotificationTosterComponent
  ],
})
export class ComponentModuleModule { }

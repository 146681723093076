import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoKycClientRoutingModule } from './video-kyc-client-routing.module';
import { VideoKycClientComponent } from './video-kyc-client.component';
import { 
  CustomerTableComponent,
  CustomerDetailsComponent
 } from './index';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { AddNewCustomerComponent } from './customer-table/add-new-customer/add-new-customer.component';
// import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxChartsModule } from '@swimlane/ngx-charts';


@NgModule({
  declarations: [
    VideoKycClientComponent, 
    CustomerTableComponent, CustomerDetailsComponent, AddNewCustomerComponent
  ],
  imports: [
    CommonModule,
    VideoKycClientRoutingModule,
    NgxPaginationModule,
    FormsModule,
    NgxChartsModule
    // NgCircleProgressModule.forRoot({
    //   backgroundGradient: true,
    //   backgroundColor: '#ffffff',
    //   backgroundGradientStopColor: '#0cf5d8',
    //   backgroundPadding: -10,
    //   radius: 60,
    //   maxPercent: 100,
    //   outerStrokeWidth: 10,
    //   outerStrokeColor: '#13786b',
    //   innerStrokeWidth: 0,
    //   subtitleColor: '#B4B4B4',
    //   titleColor: '#B4B4B4',
    //   showBackground: false,
    // }),
  ],
  exports:[
    CustomerTableComponent, CustomerDetailsComponent,
  ]
})
export class VideoKycClientModule { }

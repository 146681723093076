import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-relation',
  templateUrl: './relation.component.html',
  styleUrls: ['./relation.component.scss']
})
export class RelationComponent implements OnInit {

	@Input() relationShips : any[] = [];

	relationKeys : any[] = [
		{
			"name" : "Family",
			"slug" : "Family",  //equivalent to relationCategory
			"value" : null,
			"relation" : null 
		},
		{
			"name" : "Close Associates",
			"slug" : "associates",
			"value" : null,
			"relation" : null 
		},
		{
			"name" : "Organisations & Corporates",
			"slug" : "org",
			"value" : null,
			"relation" : null 
		},
		{
			"name" : "Person of Significant Control (PSC)",
			"slug" : "psc",
			"value" : null,
			"relation" : null 
		},
		{
			"name" : "Ultimate Beneficial Owner (UBO)",
			"slug" : "ubo",
			"value" : null,
			"relation" : null 
		}
	];

	constructor() { }

	ngOnInit(){
		this.populateRelations();
		console.log(this.relationShips);
	}

	populateRelations(){
		for(var k = 0 ; k < this.relationKeys.length ; k++){
			for(var i = 0 ; i < this.relationShips.length ; i++){
				if(this.relationKeys[k].slug == this.relationShips[i].relationCategory){
					// this.relationKeys[k].value =  this.relationShips[i];
					this.relationKeys[k].value =  this.relationShips[i].childEntity;
					this.relationKeys[k].relation =  this.relationShips[i].childRelation;
				}
			}
		}
		console.log(this.relationKeys);
	}

}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { KycModalComponent } from '../../../shared/kyc-modal/kyc-modal.component';
import { RiskScreeningService } from '../risk-screening.service';

@Component({
  selector: 'app-risk-screening-table',
  templateUrl: './risk-screening-table.component.html',
  styleUrls: ['./risk-screening-table.component.scss', '../risk-screening.component.scss']
})
export class RiskScreeningTableComponent implements OnChanges {
  @Input() data;
  @Input() entityType;
  @Input() subcategory;
  @Input() adverseRiskData?;
  tags = [{ "slug": "pep", "name": "PEP", value: 'No Risk', entity: "Individual" }, { "slug": "crime", "name": "Crime", value: 'No Risk', entity: "Individual,Organisation,Entity" },
  { "slug": "sanction", "name": "Sanction", value: 'No Risk', entity: "Individual,Organisation,Entity" }, { "slug": "watchlist", "name": "Watchlist", value: 'No Risk', entity: "Individual,Organisation,Entity" },
  { "slug": "sip", "name": "SIP", value: 'No Risk', entity: "Individual" }, { "slug": "sie", "name": "SIE", value: 'No Risk', entity: "Organisation,Entity" }, 
  { "slug": "si", "name": "SI", value: 'No Risk', entity: "Individual,Organisation,Entity" }
  ];
  public bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService, public _rs: RiskScreeningService) { }

  ngOnChanges(changes: SimpleChanges): void {
    let categoryList = [];
    if (changes?.['data']?.['currentValue']) {
      for (let item of this.data) {
        categoryList.push(item.slug);
      }
      this.tags = this.tags.map((m) => ({
        ...m,
        value: categoryList.includes(m.slug) ? this._rs.getTagValue(m.slug) : 'No Risk',
        color: categoryList.includes(m.slug) ? this._rs.getTagColor(m.slug) : '-',
      }));
    }
    if(changes?.['adverseRiskData']?.['currentValue']){
      this.adverseRiskData = changes?.['adverseRiskData']?.['currentValue'];
      if(this.adverseRiskData){
        let obj = { slug: 'adverse', name: 'Adverse', entity: "Individual,Organisation,Entity", value:  this.getLable(this.adverseRiskData?.score), isDataFound: this.adverseRiskData?.isAdverseFound, color: this.getColor(this.adverseRiskData?.score) }
        if(!this.tags?.some((f) => f?.slug == 'adverse')) this.tags.push(obj);
      }
    }
  }

  getLable(value){
    if(value){
      const score = Math.round(Number(value) * 20);
      if(score > 59){
        return 'High';
      }
      else if(score > 39){
        return 'Medium';
      }
      else {
        return 'Low';
      }
    }
    else{
      return 'No Risk';
    }
  }

  getColor(value){
    if(value){
      const score = Math.round(Number(value) * 20);
      if(score > 59){
        return 'rgb(225, 40, 40)';
      }
      else if(score > 39){
        return 'rgb(254, 180, 50)';
      }
      else {
        return 'rgb(23, 177, 140)';
      }
    }
  }

  openModal(slug, category){
    const selectedCategory = this.subcategory.filter((f) => f.parentCategory.toLowerCase() == slug);
    const initialState = {
      title: `Subcategories`,
      type: "sub-category-data-list",
      subcategoryData : { 
        category : category, 
        selectedCategoryList: selectedCategory
      }
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
  }

}

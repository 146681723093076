import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appAmlStatusColor]'
})
export class AmlStatusColorDirective {
  @Input() status;
  @Input() cssType;
  constructor(private eleRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['status']['currentValue'] && changes['cssType']['currentValue']){
    this.highlightStatus(changes['status']['currentValue'], changes['cssType']['currentValue']);
    }
  }

  highlightStatus(status, type) {
    switch (status.toLowerCase()) {
      case 'sanction':
        if(type.includes('border')){
          const borderSplit = type.split(' ');
          for(let br of borderSplit){
            if(br != 'border'){
              this.eleRef.nativeElement.style[`${borderSplit[0]}${br}`] = '2px solid #E8C342';
            }
          }
        }
        else{
          this.eleRef.nativeElement.style[type] = '#E8C342';
        }
        break;
      case 'pep':
        if(type.includes('border')){
          const borderSplit = type.split(' ');
          for(let br of borderSplit){
            if(br != 'border'){
              this.eleRef.nativeElement.style[`${borderSplit[0]}${br}`] = '2px solid #8B60D4';
            }
          }
        }
        else{
          this.eleRef.nativeElement.style[type] = '#8B60D4';
        }
        break;
      case 'crime':
        if(type.includes('border')){
          const borderSplit = type.split(' ');
          for(let br of borderSplit){
            if(br != 'border'){
              this.eleRef.nativeElement.style[`${borderSplit[0]}${br}`] = '2px solid #D73838';
            }
          }
        }
        else{
          this.eleRef.nativeElement.style[type] = '#D73838';
        }
        break;
      case 'watchlist':
        if(type.includes('border')){
          const borderSplit = type.split(' ');
          for(let br of borderSplit){
            if(br != 'border'){
              this.eleRef.nativeElement.style[`${borderSplit[0]}${br}`] = '2px solid #D262BA';
            }
          }
        }
        else{
          this.eleRef.nativeElement.style[type] = '#D262BA';
        }
        break;
      case 'sie':
      case 'sip':
        if(type.includes('border')){
          const borderSplit = type.split(' ');
          for(let br of borderSplit){
            if(br != 'border'){
              this.eleRef.nativeElement.style[`${borderSplit[0]}${br}`] = '2px solid #ED7C15';
            }
          }
        }
        else{
          this.eleRef.nativeElement.style[type] = '#ED7C15';
        }
        break;
      case 'blue':
        if(type.includes('border')){
          const borderSplit = type.split(' ');
          for(let br of borderSplit){
            if(br != 'border'){
              this.eleRef.nativeElement.style[`${borderSplit[0]}${br}`] = '2px solid #386E9D';
            }
          }
        }
        else{
          this.eleRef.nativeElement.style[type] = '#386E9D';
        }
        break;
      case 'adverse-media':
        if(type.includes('border')){
          const borderSplit = type.split(' ');
          for(let br of borderSplit){
            if(br != 'border'){
              this.eleRef.nativeElement.style[`${borderSplit[0]}${br}`] = '2px solid #131012';
            }
          }
        }
        else{
          this.eleRef.nativeElement.style[type] = '#131012';
        }
        break;
      default:
        if(type.includes('border')){
          const borderSplit = type.split(' ');
          for(let br of borderSplit){
            if(br != 'border'){
              this.eleRef.nativeElement.style[`${borderSplit[0]}${br}`] = '2px solid #ED7C15';
            }
          }
        }
        else{
          this.eleRef.nativeElement.style[type] = '#ED7C15';
        }
        break;
    }
  }
}

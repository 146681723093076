import { Component, OnInit, Input, Output, TemplateRef, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { DevOpsService } from '../services/dev-ops.service'
import { KycModalComponent } from '../../../shared/kyc-modal/kyc-modal.component';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-video-kyc',
  templateUrl: './video-kyc.component.html',
  styleUrls: ['./video-kyc.component.scss']
})
export class VideoKycComponent implements OnInit {

  list: any;
  total: any;
  page = 0;
  itemsPerPage = 10;
  maxSize = 5;
  jump: any;
  public modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  searchNameKey: any;
  searchIdKey: any;
  filterArray: any[] = [
    { name: 'ACTIVE' },
    { name: 'INACTIVE' },
  ];
  byStatus: any;
  filterSeacrhByName: boolean = false;
  filterSeacrhById: boolean = false;
  filterByStatus: boolean = false;
  isClear: boolean = false;
  constructor(private devOpsService: DevOpsService, private dataService: DataService, private modalService: BsModalService, private _router: Router) { }

  ngOnInit() {
    this.getList(this.page, this.itemsPerPage);
  }

  openDialogue(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' })
  }

  getList(page, itemsPerPage) {
    this.dataService.changeLoaderVisibility(true);
    this.devOpsService.getClientList(page, itemsPerPage).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.list = res.content;
      this.total = res.totalElements;
    })
  }

  searchByName(nameKey) {
    this.searchNameKey = nameKey;
    this.filterSeacrhByName = true;
    const payload = {
      "name": nameKey,
      "page": 0,
      "size": 10
    }
    this.devOpsService.clientFilterByName(payload).subscribe((res) => {
      this.list = res.content;
      this.total = res.totalElements;
    });
  }

  searchByNameChange(page, itemsPerPage) {
    this.filterSeacrhByName = true;
    const payload = {
      "name": this.searchNameKey,
      "page": page,
      "size": itemsPerPage
    }
    this.devOpsService.clientFilterByName(payload).subscribe((res) => {
      this.list = res.content;
      this.total = res.totalElements;
    });
  }

  searchById(idKey) {
    this.searchIdKey = idKey;
    this.filterSeacrhById = true;
    const payload = {
      "page": 0,
      "size": 10
    }
    this.devOpsService.clientFilterById(this.searchIdKey, payload).subscribe((res) => {
      this.list = res.content;
      this.total = res.totalElements;
    });
  }

  searchByIdChange(page, itemsPerPage) {
    const payload = {
      "page": page,
      "size": itemsPerPage
    }
    this.devOpsService.clientFilterById(this.searchIdKey, payload).subscribe((res) => {
      this.list = res.content;
      this.total = res.totalElements;
    });
  }

  getFilter(status) {
    this.byStatus = status;
    this.filterByStatus = true;
    const payload = {
      "page": 0,
      "size": 10,
      "orderBy": "createdDate",
      "order": "DESC"
    }
    this.devOpsService.clientFilterByStatus(status, payload).subscribe((res) => {
      this.list = res.content;
      this.total = res.totalElements;
    });
  }

  filterByStatusChange(page, itemsPerPage) {
    this.filterByStatus = true;
    const payload = {
      "page": page,
      "size": itemsPerPage,
      "orderBy": "createdDate",
      "order": "DESC"
    }
    this.devOpsService.clientFilterByStatus(this.byStatus, payload).subscribe((res) => {
      this.list = res.content;
      this.total = res.totalElements;
    });
  }

  onPageChange({ page, itemsPerPage }: PageChangedEvent) {
    this.itemsPerPage = itemsPerPage;
    this.page = page - 1;
    if (this.filterSeacrhByName) {
      this.searchByNameChange(this.page, this.itemsPerPage)
    }
    else if (this.filterSeacrhById) {
      this.searchByIdChange(this.page, this.itemsPerPage)
    }
    else if (this.filterByStatus) {
      this.filterByStatusChange(this.page, this.itemsPerPage)
    }
    else {
      this.getList(this.page, this.itemsPerPage);
    }
  }

  jumpTo() {
    this.page = this.jump
    if (this.filterSeacrhByName) {
      this.searchByNameChange(this.jump, this.itemsPerPage)
    }
    else if (this.filterSeacrhById) {
      this.searchByIdChange(this.jump, this.itemsPerPage)
    }
    else if (this.filterByStatus) {
      this.filterByStatusChange(this.page, this.itemsPerPage)
    }
    else {
      this.getList(this.jump, this.itemsPerPage);
    }
  }

  clearAll() {
    this.jump = '';
    this.page = 0;
    this.searchIdKey;
    this.searchNameKey = '';
    this.isClear = true;
    this.getList(this.page, this.itemsPerPage);
  }

  openUserArea(client) {
    console.log(client.clientID)
    this._router.navigate([`/dev-ops/user-area/${client.clientID}`])
  }

  // openInvoices(client) {
  //   this.clientId.emit(client.clientID)
  // }

  allconsumptionReport() {
    const initialState = {
      title: `Filter`,
      type: "date-range",
      size: true,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result.endCreatedDate) {
        result['page'] = 0;
        if (result.size == '') {
          result.size = 0;
        }
        this.dataService.changeLoaderVisibility(true);
        this.devOpsService.overallconsumptionReport(result).subscribe(res => {
          const blob = new Blob([res], { type: 'application/pdf' });
          var file = new File([blob], "Reconciliation.pdf", { type: "application/pdf" });
          FileSaver.saveAs(blob, `Reconciliation.pdf`);
          this.dataService.changeLoaderVisibility(false);
        })
      }

    });
  }
  openClientDetailsPage(id){
    this._router.navigate([`/video-kyc/${id}`])
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RiskScreeningService } from '../../../risk-screening.service';
import { Router } from "@angular/router";
import { DataService } from '../../../../../../app/services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-widget-table',
  templateUrl: './widget-table.component.html',
  styleUrls: ['./widget-table.component.scss','../../../risk-screening.component.scss']
})
export class WidgetTableComponent implements OnInit {
  @Output() close = new EventEmitter<any>();
  tableData = {
    totalItems: 0,
    pages: 0,
    currentPage: 1,
    data: [],

  }
  constructor(private _rs: RiskScreeningService, public router: Router,private _data: DataService) { }

  ngOnInit() {
    this.getIntermediateActionReqList()
  }

  async getIntermediateActionReqList() {
    const payload = {
      page: this.tableData.currentPage - 1,
      size: 5,
      "order": "DESC",
      "orderBy": "searchCount"
    }
    const res = await this._rs.getTopSearchCountList(payload).toPromise();
    if(res.data){
      this.tableData.data = res.data.content || [];
      this.close.emit(this.tableData.data);
      this.tableData.totalItems = res.data.totalElements
      this.tableData.pages = res.data.totalPages
    }
  }

  handleIncreasePageSize() {
    if (this.tableData.pages > this.tableData.currentPage) {
      this.tableData.currentPage += 1
      this.getIntermediateActionReqList()
    }

  }
  handleDecreasePageSize() {
    if (this.tableData.currentPage > 1) {
      this.tableData.currentPage -= 1
      this.getIntermediateActionReqList()
    }
  }

  // goToSearchResults(item){
  //   console.log(item);
  //   let data = {
  //     searchKey : item.name,
  //     investigationType: item.entityType || 'Individual',
  //     riskPolicy: null || 'All' ,
  //     country: null,
  //     searchType: item.searchType || 'FUZZY'
  //   }
  //   sessionStorage.setItem('topSearch',JSON.stringify(data))
    // this.router.navigate(["/aml-screening/aml-investigation-results/table"]);
  // }

  callApi(item) {
    // sessionStorage.removeItem('amlSearchQuery');
    // sessionStorage.removeItem('amlSearchResponse');
    // sessionStorage.removeItem('entityAmlData');
    // sessionStorage.removeItem('topSearch')
    // if(this.investigationQuery.riskPolicy){
    //   const chooseData = this.riskPolicyData.filter((f) => f.id == this.investigationQuery.riskPolicy);
    //   if(chooseData && chooseData.length){
    //     this.investigationQuery.country = chooseData[0]['countries'];
    //     this.investigationQuery['categories'] = chooseData[0]['categories'];
    //     this.investigationQuery['dataSources'] = chooseData[0]['dataSources'];
    //   }
    // }
    const payload = { 
      "size": 10, 
      "page": 0, 
      "searchType": item.searchType || 'FUZZY', 
      "name": [ item.name ], 
      "country": [], 
      "source": [], 
      "guid": "", 
      "category": [], 
      "gender": [], 
      "type": [item.entityType]
    }
    this._data.changeLoaderVisibility(true);
    this._rs.doAmlSearch(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      sessionStorage.setItem('amlSearchQuery', JSON.stringify({
        searchKey: item.name,
        investigationType: item.entityType || 'Individual',
        riskPolicy: null,
        country: null,
        searchType: item.searchType || 'FUZZY',
        isRegistryCheck: false,
        companyId: null,
      }));
      this._rs.getAmlInvestigationHistory(payload).subscribe();
      if(res && res.content && res.content.length){
        sessionStorage.setItem('amlSearchResponse', JSON.stringify(res));
      }
      this.router.navigateByUrl('aml-screening/aml-investigation-results');
    })
  }

}
import { Component, OnInit } from "@angular/core";
import { finalize } from "rxjs/operators";
import { AmlSearchService } from "../../aml-search/aml-search.service";
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../../shared/kyc-modal/kyc-modal.component";
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { AmlChecksService } from "../../aml-search/aml-checks.service";
import * as FileSaver from "file-saver";

@Component({
  selector: "app-advance-search",
  templateUrl: "./advance-search.component.html",
  styleUrls: ["./advance-search.component.scss"],
})
export class AdvanceSearchComponent implements OnInit {
  searchQueryType: string = "All";
  searchField: string;
  entityType: string;
  entityData: string[] = ["Name", "Pan", "Passport", "DIN", "CIN"];
  searchValue: string;
  countries: string[] = [];
  advanceLookUp: boolean = false;
  aliasData: string;
  category: string[] = [];
  selectedcategory: string[] = [];
  subCategory: string[] = [];
  selectedsubCategory: string[] = [];
  DOBData: string;
  occupationData: string[] = ["Select Occupation"];
  selectedOccupation: string = "Select Occupation";
  fatherName: string;
  motherName: string;
  spouseName: string;
  genderData: string[] = ["Select Gender", "Male", "Female", "Others"];
  selectedGender: string = "Select Gender";
  adverseMedia: boolean = false;
  additionalTerms: boolean = false;
  filterButton: boolean = true;
  termRadio: string;
  matchType: string[] = ["Near Match", "Broad Match", "Strict Match"];
  matchTypeMain: string[] = ["STRICT", "FUZZY", "MOREFUZZY"];
  selectedMatchType: string = "Near Match";
  amlResultsData: any[] = [];
  jump: any;
  currentPage: number = 0;
  itemsPerPage: number = 10;
  page = this.currentPage;
  totalItems: number;
  maxSize: number;
  pageList: any = [];
  startIndex: number = 0;
  endIndex: number = 10;
  showTabularResult: boolean = false;
  showDetailedResult: boolean = false;
  currentRoute: string;
  param: {};
  selectedAMLEnitity: any;
  bsModalRef: BsModalRef;
  selectedCountry: any = {
    countryCode: "",
    countryName: "Select Country",
    countrySynonyms: "",
    isActive: "ACTIVE",
    jurisdictions: null,
  };
  colors = [
    { key: "sip", color: "#ED7C15" },
    { key: "pep", color: "#8B60D4" },
    { key: "sie", color: "#ED7C15" },
    { key: "crime", color: "#E05B3B" },
    { key: "watchlist", color: "#D262BA" },
    { key: "sanction", color: "#DCAF0E" },
  ];
  searchType: "MOREFUZZY" | "STRICT" | "FUZZY" = "FUZZY";
  possibleSearchTypes: any[] = ["FUZZY", "MOREFUZZY", "STRICT"];

  isAPILoading: boolean = false;
  savedRiskArr: any[] = ["ALL", "SANCTIONS", "PEP", "CRIME"];
  selectedRiskPolicy: string = "ALL";

  dropData: string[] = ["Year", "Month"];
  dropSelected: string = "Year";
  options: Options = {
    floor: 2013,
    ceil: 2023,
    step: 1,
    showTicks: true,
    showTicksValues: true,
  };

  startYear = (this.options.floor + this.options.ceil) / 2 - 1;
  endYear = (this.options.floor + this.options.ceil) / 2 + 1;
  startYearChange: boolean = false;
  endYearChange: boolean = false;

  constructor(
    private _aml: AmlSearchService,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private amlService: AmlChecksService
  ) {
    this.param = {};
    this.entityType = this.entityData[0];
    var currentRoute = this.router.url;
    this.currentRoute =
      currentRoute.indexOf("?") !== -1
        ? currentRoute.substring(0, currentRoute.indexOf("?"))
        : currentRoute;
  }

  ngOnInit(): void {
    this.getAllCountries();
    this.getAllCategory();
    this.getAllOccupation();

    let index = JSON.parse(localStorage.getItem("key"));
    if (index == null) {
      this.getPagedData(this.currentPage);
      this.jump = this.currentPage + 1;
    } else {
      this.getPagedData(index);
      this.jump = index + 1;
    }
    const id = this.route.snapshot.paramMap.get("id");
    if (id !== null || id != undefined) {
      this._data.changeLoaderVisibility(true);
      this._app.doAdvanceSearch(id).subscribe((res) => {
        if (res.content.length != 0 && res.content != null) {
          this.showDetailedResult = true;
          this.showTabularResult = false;
          this.selectedAMLEnitity = res.content[0];

          this._data.changeLoaderVisibility(false);
        } else {
          this._data.toastrShow("No Data Found", "error");
        }
      });
    }
  }
  goBack(type) {
    if (type == "list") {
      this.selectedAMLEnitity = null;
      this.showDetailedResult = false;
      this.showTabularResult = true;
    } else {
      this.showTabularResult = false;
      this.showDetailedResult = false;
    }
  }

  removeDobRange(){
    this.options = {
      floor: 2013,
      ceil: 2023,
      step: 1,
      showTicks: true,
      showTicksValues: true,
    };
  
    this.startYear = (this.options.floor + this.options.ceil) / 2 - 1;
    this.endYear = (this.options.floor + this.options.ceil) / 2 + 1;
    this.startYearChange = false;
    this.endYearChange = false;
  }

  changeBar() {
    const difference = this.startYear - this.options.floor;
    this.startYearChange = true;
    this.endYearChange = true;
    if (difference === 1 || difference === 0 ) {
      if(this.startYear >= 1901){
      const newFloor = this.options.floor - 1;
      this.updateSliderRange(newFloor, this.options.ceil);
    }
    } else if (difference > 1) {
      const newFloor = this.options.floor + 1;
      this.updateSliderRange(newFloor, this.options.ceil);
    }

    const differenceToEnd = this.options.ceil - this.endYear;

    console.log(differenceToEnd,this.startYear, this.endYear, this.options.ceil, this.options.floor)

    if (differenceToEnd === 1 || differenceToEnd === 0) {
      if (this.endYear < 2022) {
        const newCeil = this.options.ceil + 1;
        this.updateSliderRange(this.options.floor, newCeil);
      }
    } else if (differenceToEnd > 1) {
      const newCeil = this.options.ceil - 1;
      this.updateSliderRange(this.options.floor, newCeil);
    }
  }

  updateSliderRange(newFloor: number, newCeil: number) {
    let showTicksValues
    if(newCeil - newFloor >= 26 ){
      showTicksValues = false
    }
    else{
      showTicksValues = true
    }
    this.options = {
      ...this.options,
      floor: newFloor,
      ceil: newCeil,
      showTicksValues: showTicksValues,
    };
  }

  downloadReport() {
    this._data.changeLoaderVisibility(true);
    let payload = {
      guid: this.selectedAMLEnitity.guid,
      name: this.selectedAMLEnitity.masterData.fullName[0],
      countryCode: "",
    };
    this.amlService.downloadReport(payload).subscribe((res) => {
      this._data.changeLoaderVisibility(false);
      if (res.size < 80) {
        this._data.toastrShow(`Insufficient Credits`, "error");
      } else {
        const blob = new Blob([res], { type: "application/pdf" });
        FileSaver.saveAs(
          blob,
          `${this.selectedAMLEnitity.masterData.fullName[0]}-report.pdf`
        );
      }
    });
  }
  bookMark() {
    let data = {
      guid: this.selectedAMLEnitity.guid,
      fullName: this.selectedAMLEnitity.masterData.fullName[0],
      entityType: this.selectedAMLEnitity.masterData.entityType,
    };
    this.amlService.bookMarkEntity(data).subscribe(
      (res) => {
        this._data.toastrShow(`Entity Bookmarked Successfully`, "success");
      },
      (error) => {
        this._data.toastrShow(`Entity already BookMarked`, "error");
      }
    );
  }

  getPaginatedText() {
    let a = this.currentPage * this.itemsPerPage + 1;
    let b;
    if (this.currentPage < this.maxSize - 1) {
      b = (this.currentPage + 1) * this.itemsPerPage;
    } else if (this.currentPage == this.maxSize - 1) {
      b = this.totalItems;
    }
    return a + "-" + b;
  }

  searchQueryChange(event: string) {
    this.searchQueryType = event;
    this.entityType = "Name";
    if (event === "All")
      this.entityData = ["Name", "Pan", "Passport", "DIN", "CIN"];
    else if (event === "Individual")
      this.entityData = ["Name", "Pan", "Passport", "DIN"];
    else if (event === "Organisation") this.entityData = ["Name", "CIN"];
    else this.entityData = ["Name"];
  }

  toggleBodyScrolling(allowScrolling) {
    if (!allowScrolling) {
      document.getElementsByTagName("body")[0].classList.add("noScroll");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("noScroll");
    }
  }


  getFormattedString(search, type) {
    return search;
  }

  setRiskPolicy(item) {
    this.selectedRiskPolicy = item;
  }

  addNewRiskPolicy() {
    this._app.getAllDataSources().subscribe(
      (res) => {
        var i = 0;
        var data = res.data.map((e) => {
          e.id = i;
          i++;
          return e;
        });
        this.openNewPolicyModal(res.data);
        console.log(res);
      },
      (err) => {
        console.log("Add risk policy", err);
      }
    );
  }

  openNewPolicyModal(data) {
    const initialState = {
      title: `Add New Risk Policy`,
      type: "add-new-risk-policy",
      dropdownList: data,
      selectedItems: [],
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe((result) => {
      if (result && Object.keys(result).length) {
        var addPolicy = { name: result[0].riskName, source: [] };
        result[0].selectedItems.forEach((e) => {
          addPolicy.source.push(e.key);
        });

        this._app.addNewRiskPolicy(addPolicy).subscribe(
          (res) => {
            console.log("addNewRiskPolicy response : ", res);
            this.getRiskPolicy();
          },
          (err) => {
            console.log("addNewRiskPolicy error : ", err);
          }
        );
        console.log(addPolicy);
      }
    });
  }

  getRiskPolicy() {
    this._aml.getSavedRiskPolicies().subscribe(
      (res) => {
        res.data.forEach((element) => {
          this.savedRiskArr.push(element.riskPolicyName);
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }


  getAllCountries() {
    this._app
      .getAllCountries()
      .pipe()
      .subscribe(
        (res) => {
          this.countries = res["data"];
          this.countries.unshift(this.selectedCountry);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getAllCategory() {
    this._app
      .getCategories()
      .pipe()
      .subscribe(
        (res) => {
          this.category = res.map((item) => item.categoryName);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getAllOccupation() {
    this._app
      .getOccupation()
      .pipe()
      .subscribe(
        (res) => {
          this.occupationData = res.map((item) => item.key);
          this.occupationData.unshift(this.selectedOccupation);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getSubCategory() {
    if (this.selectedcategory.length > 0) {
      let payload = { category: this.selectedcategory };
      this._app
        .getSubCategories(payload)
        .pipe()
        .subscribe(
          (res) => {
            this.subCategory = res["data"];
            this.subCategory = this.subCategory.map((item) => item["key"]);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  toggleFilterButton() {
    this.filterButton = !this.filterButton;
  }

  clearFilters() {
    this.searchQueryType = "All";
    this.searchField = "";
    this.entityType = "Name";
    this.entityData = ["Name", "Pan", "Passport", "DIN", "CIN"];
    this.searchValue = "";
    this.advanceLookUp = false;
    this.aliasData = "";
    this.selectedcategory = [];
    this.subCategory = [];
    this.selectedsubCategory = [];
    this.DOBData = "";
    this.selectedOccupation = "Select Occupation";
    this.fatherName = "";
    this.motherName = "";
    this.spouseName = "";
    this.selectedGender = "Select Gender";
    this.adverseMedia = false;
    this.additionalTerms = false;
    this.filterButton = true;
    this.termRadio = "";
    this.selectedCountry = {
      countryCode: "",
      countryName: "Select Country",
      countrySynonyms: "",
      isActive: "ACTIVE",
      jurisdictions: null,
    };
    this.getAllCountries();
    this.showTabularResult = false;
    this.options =  {
      floor: 2013,
      ceil: 2023,
      step: 1,
      showTicks: true,
      showTicksValues: true,
    };
    this.startYear = (this.options.floor + this.options.ceil) / 2 - 1;
    this.endYear = (this.options.floor + this.options.ceil) / 2 + 1;
    this.startYearChange = false;
    this.endYearChange = false;
    this.showDetailedResult = false;
    this.currentPage = 0;
    this.itemsPerPage  = 10;
    this.page = this.currentPage;
    this.pageList = [];
    this.jump = this.currentPage + 1
  }

  doSearch() {
    this.currentPage = 0;
    this.itemsPerPage  = 10;
    this.page = this.currentPage;
    this.pageList = [];
    this.jump = this.currentPage + 1
    this.param = {
      size: 10,
      page: 0,
      searchType:
        this.matchTypeMain[this.matchType.indexOf(this.selectedMatchType)] !==
        "All"
          ? this.matchTypeMain[this.matchType.indexOf(this.selectedMatchType)]
          : [],
      name: [],
      type: this.searchQueryType !== "All" ? [this.searchQueryType] : [],
      dob: this.DOBData ? [this.DOBData] : [],
      startDobYear:
        this.startYearChange === true ? this.startYear.toString() : "",
      endDobYear: this.endYearChange === true ? this.endYear.toString() : "",
      country:
        this.selectedCountry.countryName !== "Select Country"
          ? [this.selectedCountry.countryName]
          : [],
      fathersName: this.fatherName ? [this.fatherName] : [],
      mothersName: this.motherName ? [this.motherName] : [],
      spouse: this.spouseName ? [this.spouseName] : [],
      passport: [],
      pan: [],
      din: [],
      cin: [],
      alias: [],
      occupation:
        this.selectedOccupation !== "Select Occupation"
          ? [this.selectedOccupation]
          : [],
      category: this.selectedcategory.length > 0 ? this.selectedcategory : [],
      subCategory:
        this.selectedsubCategory.length > 0 ? this.selectedsubCategory : [],
      gender:
        this.selectedGender !== "Select Gender" ? [this.selectedGender] : [],
    };
    if (this.searchField.length > 0) {
      if (this.entityType === "Name") this.param["name"].push(this.searchField);
      else if (this.entityType === "Passport")
        this.param["passport"].push(this.searchField);
      else if (this.entityType === "Pan")
        this.param["pan"].push(this.searchField);
      else if (this.entityType === "DIN")
        this.param["din"].push(this.searchField);
      else if (this.entityType === "CIN")
        this.param["cin"].push(this.searchField);
    }
    this.getSearchResults();
  }

  pageSize() {
    this.pageList = [];
    let totalPages = this.getPage(this.totalItems);
    this.startIndex = this.currentPage - 4 < 0 ? 0 : this.currentPage - 4;
    this.endIndex =
      this.currentPage + 4 > this.getPage(this.totalItems)
        ? totalPages
        : this.currentPage + 4;
    for (let i = 0; i <= this.currentPage + 4; i++) {
      if (i >= this.currentPage - 4 && i < totalPages) {
        this.pageList.push(i);
      }
    }
  }

  getPage(length) {
    return Math.round(length / 10);
  }

  getColor(category): object {
    for (let i = 0; i < this.colors.length; i++) {
      if (category == this.colors[i].key) {
        return { "background-color": this.colors[i].color };
      }
    }
    return { "background-color": "#ED7C15" };
  }

  getPagedData(index) {
    this.currentPage = index;
    this.param["page"] = index;
    if (this.currentPage < this.maxSize) {
      this.getSearchResults();
      this.pageSize();
      this.jump = this.currentPage + 1;
    }
  }


  getSearchResults() {
    this._data.changeLoaderVisibility(true);
    this.isAPILoading = true;
    this.toggleBodyScrolling(false);
    this._app.doAdvanceSearch(this.param).subscribe(
      (res) => {
        if (res.data && res.data.content) {
          let amlResultsData = res.data.content;
          this.amlResultsData = [];
          for (var i = 0; i < amlResultsData.length; i++) {
            this.amlResultsData.push(amlResultsData[i]);
          }
          this.totalItems = res.data.totalElements;
          this.maxSize = res.data.totalPages;
          this.pageSize();

          if (this.amlResultsData.length > 0) {
            this.showTabularResult = true;
            this.showDetailedResult = false;
          } else {
            this.showTabularResult = true;
            this.showDetailedResult = false;
          }
          this._data.changeLoaderVisibility(false);
          this.isAPILoading = false;
          this.toggleBodyScrolling(true);
        } else {
          this._data.toastrShow(res.data, "warning");
          this._data.changeLoaderVisibility(false);
        }
        const tabularResultSection = document.getElementById('tabularResultSection');
        if (tabularResultSection) {
          tabularResultSection.scrollIntoView({ behavior: 'smooth' });
        }
      },
      (err) => {
        this.isAPILoading = false;
        this.toggleBodyScrolling(true);
      }
    );
  }

  viewRiskInfoText(){
    const initialState = {
      title: 'Info',
      type: "show-aml-risk-text",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
  }
  viewMatchInfoText(){
      const initialState = {
        title: 'Info',
        type: "show-aml-match-text",
      };
      this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        class: "modal-md",
      });
    }
    jumpTo() {
      // let index=parseInt(this.jump)
      if (this.jump <= this.maxSize) {
        if (Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0)
          this.getPagedData(this.jump - 1)
      }
    }
    getPaginatedData(isNext) {
      console.log("getMoreUsersData");
      this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
      this.param["page"] = isNext
        ? this.param["page"] + 1
        : this.param["page"] - 1;
      if (this.currentPage < this.maxSize) {
        this.getSearchResults();
        this.pageSize();
        this.jump = this.currentPage + 1;
        // this.navigationBoy();
      }
    }
    gotoDashboard(){
      this.router.navigate(['/aml-screening/dashboard'])
    }
    scrollToDetailedResult(item) {
      this.selectedAMLEnitity = item;
      this.showDetailedResult = true;
      this.showTabularResult = false;
      const detailedResultDiv = document.getElementById('detailedResultDiv');
      if (detailedResultDiv) {
        detailedResultDiv.scrollIntoView({ behavior: 'smooth' });
      }
    }

    gotoAdvanceFields(){
      const advanceFields = document.getElementById('advanceSearchFields')
      if(advanceFields){
        advanceFields.scrollIntoView({ behavior: 'smooth'})
      }
      this.clearFilters()
    }
}

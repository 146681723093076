import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-kyc-loader',
  templateUrl: './kyc-loader.component.html',
  styleUrls: ['./kyc-loader.component.scss']
})
export class KycLoaderComponent implements OnInit {

	@Input() isUIBlockingLoader : boolean ;
	constructor() { }

	ngOnInit() {
	}

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CorporateCheckComponent } from './corporate-check.component';

const routes: Routes = [{path: "", component: CorporateCheckComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CorporateCheckRoutingModule { }

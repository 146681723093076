import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../../../src/app/services/data.service';
import { VideoKycService } from '../../vide-kyc.service';
import { AppService } from '../../../../../app/services'; 
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnChanges {
  item;
  @Output() close = new EventEmitter<any>();
  public canvasWidth = 300
  public needleValue = 0
  public bottomLabel = '0';
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#14E167', '#FC9031', '#FC3131'],
    arcDelimiters: [30, 50],
    rangeLabel: ['0', '100'],
    needleStartValue: 0,
  }
  ocrData: {};
  frontImage : SafeUrl = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded';
  ScheduledChecksList: any[] = [] ;
  extractionResult: any;
  faceExtractionResults: any;
  emotionalAnalysisArray: { emoji: string; name: string; value: any; }[] = [];
  currentTheme: string;
  imagesArr = []
  selfieUrl: any;
  idProofUrl: any;
  tabs=[
    {
      heading:'Profile Overview',
      slug:'profileOverviewAccordian'
    },
    {
      heading:'Scheduled checks',
      slug:'scheduledChecksAccordian'
    }
  ];
  activeTab: any = this.tabs[0].slug;
  liveLiNess: any;
  docType: any;
  selfieData: any;
  checksDetails = {
    identityCheck: true,
    selfieCheck: true,
    livenessCheck: true,
    aadhaarOtpCheck: false,
    panFetchCheck: false,
  };
  isAadhaarData: boolean = false;
  fetchedTable: any[] = [];
  aadhaarImage: any;
  isPanData: boolean = false;
  fetchedPanTable: any[] = [];
  livelinessVideo: SafeUrl;

  constructor(
    private _data: DataService,
    private _vs: VideoKycService,
    private app:AppService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
    if(history.state.data || JSON.parse(sessionStorage.getItem('Data'))){
      this.tabs = [
        {
          heading:'Profile Overview',
          slug:'profileOverviewAccordian'
        },
        {
          heading:'Scheduled checks',
          slug:'scheduledChecksAccordian'
        }
      ];
      this._data.clientsConfigsV1.subscribe((response) => {
        this.tabs = [
          {
            heading:'Profile Overview',
            slug:'profileOverviewAccordian'
          },
          {
            heading:'Scheduled checks',
            slug:'scheduledChecksAccordian'
          }
        ];
        this.ScheduledChecksList = [];
        if(response?.length &&  response?.some(e => e?.keySlug == 'video-kyc')){
          for(let item of response){
            if(item?.keySlug == 'video-kyc' && item?.keyValue){
              const parseVal = JSON.parse(item?.keyValue);
              this.checksDetails = {
                identityCheck : parseVal?.doc_extraction == "true" ? true : false,
                selfieCheck : parseVal?.selfie_check == "true" ? true : false,
                livenessCheck : parseVal?.liveness_check == "true" ? true : false,
                aadhaarOtpCheck : parseVal?.okyc_check == "true" ? true : false,
                panFetchCheck : parseVal?.pan_fetch == "true" ? true : false,
              }
              if(this.checksDetails?.identityCheck){
                this.tabs?.push({ heading:'Identity Proof', slug:'identityProofAccordian' });
                this.ScheduledChecksList?.push({ key:'idProofUrl', slug:'ID Proof' });
                this.ScheduledChecksList?.push({ key:'uploadedIdDocUrl', slug:'Uploaded ID' });
              }
              if(this.checksDetails?.livenessCheck){
                this.tabs?.push({ heading:'Liveness Proof', slug:'LivelinessChecksAccordian' });
                this.ScheduledChecksList?.push({ key:'videoUrl', slug:'Liveness' });
              }
              if(this.checksDetails?.selfieCheck){
                this.tabs?.push({ heading:'Selfie Check', slug:'SelfieChecksAccordian' });
                this.ScheduledChecksList?.push({ key:'photoUrl', slug:'Selfie' });
              }
              if(this.checksDetails?.aadhaarOtpCheck){
                this.tabs?.push({ heading:'Aadhaar OTP Check', slug:'AadhaarChecksAccordian' });
                this.ScheduledChecksList?.push({ key:'okycCheckData', slug:'Aadhaar OTP Check' });
              }
              if(this.checksDetails?.panFetchCheck){
                this.tabs?.push({ heading:'PAN Fetch Check', slug:'PanChecksAccordian' });
                this.ScheduledChecksList?.push({ key:'panFetchData', slug:'PAN Fetch Check' });
              }
            }
          }
        }
        else{
          this.tabs?.push({ heading:'Identity Proof', slug:'identityProofAccordian' });
          this.ScheduledChecksList?.push({ key:'idProofUrl', slug:'ID Proof' });
          this.ScheduledChecksList?.push({ key:'uploadedIdDocUrl', slug:'Uploaded ID' });
          this.tabs?.push({ heading:'Liveness Proof', slug:'LivelinessChecksAccordian' });
          this.ScheduledChecksList?.push({ key:'videoUrl', slug:'Liveness' });
          this.tabs?.push({ heading:'Selfie Check', slug:'SelfieChecksAccordian' });
          this.ScheduledChecksList?.push({ key:'photoUrl', slug:'Selfie' });
        }
      })
      this.item =history.state.data || JSON.parse(sessionStorage.getItem('Data'))
      if (this.item) {
        if(this.item?.okycCheckData){
          let aadhaarData = JSON.parse(this.item?.okycCheckData);
          aadhaarData = aadhaarData?.data
          if(aadhaarData?.statusCode == 2202){
            this.isAadhaarData = true;
            this.aadhaarImage = aadhaarData?.data?.photo_link;
            let attributes = ['address', 'date_of_birth', 'gender', 'name', 'year_of_birth'];
            for(let [key, value] of Object.entries(aadhaarData?.data)){
              if(attributes.includes(key)){
                const keyData = key as any;
                this.fetchedTable.push({name : keyData.replaceAll('_', ' '), data : value});
              }
            }
          }
          else{
            this.isAadhaarData = false;
          }
        }
        if(this.item?.panFetchData){
          let panOkycData = JSON.parse(this.item?.panFetchData);
          panOkycData = panOkycData?.data
          if(panOkycData?.statusCode == 2009){
            this.isPanData = true;
            let attributes = ['category', 'name', 'panNumber', 'panStatus', 'message']
            for(let [key, value] of Object.entries(panOkycData?.data)){
              if(attributes.includes(key)){
                const keyData = key as any;
                this.fetchedPanTable.push({name : keyData.replaceAll('_', ' '), data : value});
              }
              if(key == 'panStatus' && value == 'INVALID'){
                this.isPanData = false;
              }
            }
          }
          else{
            this.isPanData = false;
          }
        }
        if(this.item?.documentExtractionInfo){ //OCR Data
          this.getOcrData(this.item?.documentExtractionInfo);
        }
        if(this.item?.selfieCheck){ //Selfie Check
          this.getSelfieCheckData(JSON.parse(this.item?.selfieCheck));
        }
        if(this.item?.livelinessCheck){ //Liveliness Check
          this.getLivelinessData(JSON.parse(this.item?.livelinessCheck))
        }
        this.docType = this.item?.docType;
        if(this.docType === 'CAPTURED'){
          this.getIdentityDoc(this.item.id)
        }else if(this.docType === 'UPLOADED'){
          this.getUploadedFiles(this.item.id)
        }else{
          this.frontImage = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded'
        }
        this.getSelfieDoc(this.item.id);
        this.downloadSession(this.item?.id);
        if(this.item?.kycStatus === 'NOT_DONE' || this.item?.kycStatus === 'FAILED'){
          this.needleValue = 0;
        }else if(this.item?.kycStatus === 'ACKNOWLEDGED'){
          this.needleValue = 50
        }else{
          this.needleValue = 100
        }
        this.item['kycStatusKey'] = this.getKycStatusKey(this.item?.kycStatus)
      }
      else {
        if(this.item?.documentExtractionInfo){ //OCR Data
          this.getOcrData(this.item?.documentExtractionInfo);
        }
        if(this.item?.selfieCheck){ //Selfie Check
          this.getSelfieCheckData(JSON.parse(this.item?.selfieCheck));
        }
        if(this.item?.livelinessCheck){ //Liveliness Check
          this.getLivelinessData(JSON.parse(this.item?.livelinessCheck))
        }
        this.docType = this.item?.docType;
        if(this.docType === 'CAPTURED'){
          this.getIdentityDoc(this.item.id)
        }else if(this.docType === 'UPLOADED'){
          this.getUploadedFiles(this.item.id)
        }else{
          this.frontImage = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded'
        }
        this.getSelfieDoc(this.item.id);
        this.downloadSession(this.item?.id);
        if(this.item?.kycStatus === 'NOT_DONE' || this.item?.kycStatus === 'FAILED'){
          this.needleValue = 0;
        }else if(this.item?.kycStatus === 'ACKNOWLEDGED'){
          this.needleValue = 50
        }else{
          this.needleValue = 100
        }
      }

    }
    else{
      this.router.navigate(['/video-kyc-client/dashboard']);
    }
   
   }

  getKycStatusKey(status:string){
    switch (status) {
      case "NOT_DONE":
      return 'Initiated'
        case "ACKNOWLEDGED":
          return 'In Progress'
        
           case "COMPLETED":
            return 'Completed'
        case "FAILED":
          return "Failed"
        
      
      default:
        break;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    
  }

  getUploadedFiles(userID){
    this._vs.fetchUploadedDocument(userID).subscribe(res => {
      if(res){
        let url = URL.createObjectURL(res.body) 
        this.frontImage =  this.sanitizer.bypassSecurityTrustUrl(url);
      }
      }      
    )
  }

  getIdentityDoc(userId) {
    this._vs.fetchIdImage(userId).subscribe((res: any) => {
      let objectURL = URL.createObjectURL(res.body);
      this.frontImage = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      // this.imagesArr.push({ name: 'Identity Image', url: this.sanitizer.bypassSecurityTrustUrl(objectURL) })
    })
  }

  getSelfieDoc(userId) {
    this._vs.fetchImage(userId).subscribe((res: any) => {
      let objectURL = URL.createObjectURL(res.body);
      this.selfieUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      // this.imagesArr.push({ name: 'Selfie Image', url: this.sanitizer.bypassSecurityTrustUrl(objectURL) })
    })
  }

  getLivelinessData(res){
    this.liveLiNess = res?.data?.livelinessRes;
  }

  getOcrData(res) {
    this.ocrData = res;
    if(this.ocrData['postProcessedInfo']){
      this.ocrData['ocrTextData'] = JSON.parse(this.ocrData['postProcessedInfo']);
    }
    if(this.ocrData['docFrontUrl']){
      const base64Object = {
        url : this.ocrData['docFrontUrl']
      }
      this._vs.getBase64Data(base64Object).subscribe((base64: any) =>  {
        // this.frontImage = base64;
      });
    }
  }

  swapImage(img: HTMLImageElement): void {
    img.src = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded';
  }

  closeInfo(event: MouseEvent) {
    sessionStorage.removeItem('Data');
    this.router.navigate(['/video-kyc-client/dashboard']);
  }

  updateStatusAsDone(status){
    let payload = {
      "id": this.item.id,
      "kycStatus": status
    }
    this._vs.agentApprovalStatus(payload).subscribe((res: any) => {
      this._data.toastrShow(`User Status updated to ${this.getKycStatusKey(status)}  successfully`, 'info')
      sessionStorage.setItem('Data', JSON.stringify(res));
      window.location.reload();
    })
  }

  getSelfieCheckData(data){
    let res = data.data
    if(res){
      this.selfieData = res;
      this.extractionResult = res?.photoDetail?.emotions;
      this.faceExtractionResults = res?.photoDetail;
      this.app.currentTheme.subscribe(theme => {
        this.emotionalAnalysisArray = [];
        this.currentTheme = theme;
        for(let i = 0; i < this.extractionResult.length; i++){
          let res = this.extractionResult[i];
          if(res.type === "CALM"){
            this.emotionalAnalysisArray.push({ emoji: '../../../../../assets/images/emotions/Neutral_light_mode.svg', name: 'Neutral', value: (res?.confidence || '0') })
          }else if(res?.type === "SURPRISED"){
            this.emotionalAnalysisArray.push({ emoji: '../../../../../assets/images/emotions/Surprise_light_mode.svg', name: 'Surprise', value: (res?.confidence || '0') })
          }else if(res?.type === "FEAR"){
            this.emotionalAnalysisArray.push({ emoji: '../../../../../assets/images/emotions/Fear_light_mode.svg', name: 'Fear', value: (res?.confidence || '0') })
          }else if(res?.type === "SAD"){
            this.emotionalAnalysisArray.push({ emoji: '../../../../../assets/images/emotions/Sadness_light_mode.svg', name: 'Sadness', value: (res?.confidence || '0') })
          }
          // else if(res?.type === "CONFUSED"){
          //   this.emotionalAnalysisArray.push({ emoji: '../../../../../assets/images/emotions/Contempt_light_mode.svg', name: 'Confused', value: (res?.confidence || '0')})
          // }
          else if(res?.type === "ANGRY"){
            this.emotionalAnalysisArray.push({ emoji: '../../../../../assets/images/emotions/Anger_light_mode.svg', name: 'Anger', value: (res?.confidence || '0') })
          }else if(res?.type === "HAPPY"){
            this.emotionalAnalysisArray.push({ emoji: '../../../../../assets/images/emotions/Happiness_light_mode.svg', name: 'Happiness', value: (res?.confidence || '0') })
          }else if(res?.type === "DISGUSTED"){
            this.emotionalAnalysisArray.push({ emoji: '../../../../../assets/images/emotions/Disgust_light_mode.svg', name: 'Disgust', value: (res?.confidence || '0') })
          }
        }
      });
    }
  }

  downloadSession(userId){
    this._vs.fetchSessionVideo(userId).subscribe(res => {
      const blob = new Blob([res], { type: 'video/mp4' });
      var video = document.querySelector('video');
      if(video){
        video.style.display = "block";
        video["src"] = window.URL.createObjectURL(blob);
      }
    });
  }

  // downloadReport(userId){
  //   this._vs.downloadReport(userId).subscribe(res => console.log(res));
  // }

  setActiveTab(item){
    this.goToTabs(item.slug);
    this.activeTab = item.slug;
  }

  goToTabs(div){
    const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
    const scrollToEl = document.getElementById(div)
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
    if(this.activeTab!=div){
      let scrolly=topOfElement-250;
      window.scroll({ top: scrolly, behavior: "smooth" });
    }
    else{
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }
  handleDownloadReport(id){
    this._vs.downloadReport(id).subscribe(
      data => {
        var fileName = "report.pdf";
      const contentDisposition = data.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
      saveAs(data.body, fileName);
      },
      err => {
        console.log(err)
        let alertObj = {
          message: err?.error?.message,
          status: err?.error?.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        console.log(alertObj)
        this._data.toastrShow('Liveness data not found or still under process.', 'error')
      }
    )
  }
}

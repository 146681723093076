import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationService } from '../../../app/services';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-toster',
  templateUrl: './notification-toster.component.html',
  styleUrls: ['./notification-toster.component.scss'],
})
export class NotificationTosterComponent implements OnInit {

  showPanel: boolean;
  notification;
  notificationSub: Subscription;
  notificationTimeout: any;

  constructor(private notificationService: NotificationService,private router: Router) { }

  ngOnInit(): void {
    this.notificationService.getNotification()
    .subscribe(n => {
      this.notification = n;
      this.showPanel = n !== null;

      // this.notificationTimeout = setTimeout(() => {
      //   this.showPanel = false;
      // },n?.duration || 3000);
    });
  }

  dismissNotification() {
    this.showPanel = false;
  }

  ngOnDestroy() {
    this.notificationSub.unsubscribe();
    clearTimeout(this.notificationTimeout);
  }

  attendCall(){
    this.router.navigate([`/video-kyc-agent/applications/${this.notification.body.connectionId}`])
    this.dismissNotification();
  }
}

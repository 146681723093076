import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-entities-stats',
  templateUrl: './entities-stats.component.html',
  styleUrls: ['./entities-stats.component.scss', '../../../risk-screening.component.scss']
})
export class EntitiesStatsComponent implements OnChanges {
  @Input() dailyUpdates: any;
  items: any = [
    { name: 'Sanction', entities: 50, noOfDs: 2 },
    { name: 'Crime', entities: 500, noOfDs: 12 },
    { name: 'PEP', entities: 200, noOfDs: 4 },
    { name: 'Watchlist', entities: 800, noOfDs: 16 },
    { name: 'SIE', entities: 450, noOfDs: 3 }
  ]
  totalS = {}
  overall = {}
  constructor() {
    this.items = this.items.map(e => ({ ...e, noOfDs: String(e.noOfDs).padStart(2, '0') }))
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['dailyUpdates']['currentValue']){
      let t = changes['dailyUpdates']['currentValue']
      this.totalS = { entities:t.totalEntities,ds:t.totalDs}
      this.items =   [
        { name: 'Sanction', entities: t.totalSanctionEntities, noOfDs: t.totalSanctionDS },
        {  name: 'Crime', entities: t.totalCrimeEntities, noOfDs:  t.totalCrimeDS},
        { name: 'PEP', entities: t.totalPEPEntities, noOfDs: t.totalPEPDS},
        { name: 'Watchlist', entities: t.totalWatchListEntities, noOfDs: t.totalWatchListDS  },
        { name: 'SIE / SIP', entities: t.totalSIPSIESIEntities, noOfDs: t.totalSIPSIESIDS }
      ]
    }
  }

}

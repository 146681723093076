import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { DataService } from '../../services/data.service';
import { PaymentService } from '../../services/payment.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  constructor( private app : AppService , private data : DataService , private _payment : PaymentService , private router : Router) { }
  status : 'warning' | 'success' | 'danger' = 'warning'; 
  statusMsg : string = "";

  ngOnInit() {
  	
  }
  verifyClient(){
  	this.app.verifyClient()
  			.subscribe(
  				res => {
  					this.AfterVerification()
  				},
  				err => {
  					this.statusMsg = err;
  					this.status = 'danger';
  					console.log(err);
  				}
  			)
  }
  AfterVerification(){
	this.app.getClientProfileData()
	    .subscribe(
	        res => {
	            // let con = {
	            //     clientVerification : res.data.clientVerification
	            // };  
	            this.data.setCookieObject('configs' , res.data);
	            this.status = 'success';
  					console.log(res);
	        }
	     );
  }
  getCurrentSubscription(){
    this._payment.getCurrentSubscription().subscribe( res => {
        console.log(' Current SUbscription : ',res);
        if(res.data) this.data.setCookieObject('licence' , true);
        let accessibleStandardServices = (res.data) ? res.data.subscriptionPlan.standardServices : [];
        let accessibleStandardCheck = (res.data) ? res.data.subscriptionPlan.standardCheck : [];
        if (accessibleStandardServices && accessibleStandardServices.length) {
          this.data.changeClientStandardServices(accessibleStandardServices);
          this.data.changeClientStandardChecks(accessibleStandardCheck);
          console.log(
            ":::::::::::::::::::::::::::::::::",
            res.data.subscriptionPlan.planCategory
          );
          console.log(accessibleStandardServices);
        }
    })
  }
  goTo(url){
  	this.router.navigate(['settings']);
  }
  reload(){
    window.location.reload();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../services';
import { from, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Pipe({
  name: 'riskStatusByApi'
})
export class RiskStatusByApiPipe implements PipeTransform {

  constructor(private _app: AppService) {}

  transform(guid: string): Observable<any> {
    if (!guid) {
      return of({ loading: false, data: null });
    }
    return from(this._app.getAdvMediaResults({ guid: guid }).toPromise()).pipe(
      map(response => {
        let crrResponseData = null;
        if (response?.data?.content?.[0]?.riskProfile) {
          crrResponseData = response.data.content[0].riskProfile;
        }
        return { loading: false, data: crrResponseData };
      }),
      startWith({ loading: true, data: null }),
      catchError(() => of({ loading: false, data: null }))
    );
  }
}

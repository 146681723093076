import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-aml-summary-details',
  templateUrl: './aml-summary-details.component.html',
  styleUrls: ['./aml-summary-details.component.scss']
})
export class AmlSummaryDetailsComponent implements OnChanges {
  @Input() amlSummaryDetails;
  @Input() amlType;
  @Input() typeArray? : any;
  @Input() type;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize, first } from "rxjs/operators";
import { AppService, DataService } from '../../../../../services';
import { KycModalComponent } from '../../../../../../app/shared/kyc-modal/kyc-modal.component';
import { RiskScreeningService } from '../../../risk-screening.service';

@Component({
  selector: 'app-new-investigation',
  templateUrl: './new-investigation.component.html',
  styleUrls: ['./new-investigation.component.scss', '../../../risk-screening.component.scss']
})
export class NewInvestigationComponent implements OnInit {
  showTextValidation:boolean = false;
  showIdValidation:boolean = false;
  newInvestigationTypesArr = [
    { key: 'Individual', value: 'Individual' },
    { key: 'Organisation', value: 'Organisation' },
    { key: 'Vessel', value: 'Vessel' },
    { key: 'Aircraft', value: 'Aircraft' },
    { key: 'All', value: '' },
  ];
  showInfo: boolean = false;
  countries: any = [];
  riskPolicyData: any[] = [];
  searchTypeOptions = [
    { key: 'Near Match', value: 'FUZZY' },
    { key: 'Broad Match', value: 'MOREFUZZY' },
    { key: 'Strict Match', value: 'STRICT' },
  ];
  investigationQuery: any = {
    searchKey: null,
    investigationType: 'Individual',
    riskPolicy: null,
    country: null,
    searchType: "FUZZY",
    isRegistryCheck: false,
    companyId: null,
  };

  // same route navigation vars
  navigationSubscription: any;
  clearDisabled: boolean = true;
  public bsModalRef: BsModalRef;

  constructor(
    private _app: AppService,
    public router: Router,
    private _rs: RiskScreeningService,
    private _data: DataService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.getAllCountries();
    this.getAllDataSource();
    if(sessionStorage.getItem('amlSearchQuery')){
      this.investigationQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'));
      if(this.investigationQuery?.isRegistryCheck){
        this.newInvestigationTypesArr = this.newInvestigationTypesArr.filter((f) => f.key == 'Organisation');
        this.investigationQuery.investigationType = 'Organisation';
      }
      this.clearDisabled = false;
    }
  }

  registryCheck(){
    this.investigationQuery = { ...this.investigationQuery, investigationType: 'Individual', companyId: null };
    if(this.investigationQuery?.isRegistryCheck){
      this.newInvestigationTypesArr = this.newInvestigationTypesArr.filter((f) => f.key == 'Organisation');
      this.investigationQuery.investigationType = 'Organisation';
    }
    else{
      this.newInvestigationTypesArr = [
        { key: 'Individual', value: 'Individual' },
        { key: 'Organisation', value: 'Organisation' },
        { key: 'Vessel', value: 'Vessel' },
        { key: 'Aircraft', value: 'Aircraft' },
        { key: 'All', value: '' },
      ];
      this.investigationQuery.investigationType = 'Individual';
    }
  }

  getAllDataSource(){
    this._rs.getRiskPolicyData().subscribe((res) =>{
      this.riskPolicyData = res || [];
    })
  }

  onValueChange(event, type?) {
    if (event) {
      this.clearDisabled = false;
    }
    if(type == 'country'){
      this.investigationQuery.country = [this.investigationQuery.country]
    }
  }

  clearAll() {
    this.clearDisabled = true;
    this.investigationQuery = {
      searchKey: null,
      investigationType: this.investigationQuery?.isRegistryCheck ? 'Organisation' : 'Individual',
      riskPolicy: null,
      country: null,
      searchType: "FUZZY",
      isRegistryCheck: this.investigationQuery?.isRegistryCheck,
      companyId: null,
    };
  }

  getAllCountries() {
    this._app
      .getAllCountries()
      .pipe(first())
      .subscribe(
        res => {
          this.countries = res["data"];
        },
        err => {
          console.log(err);
        }
      );
  }

  doSearch(){
    if(this.investigationQuery?.searchKey){
      this.investigationQuery.searchKey = this.investigationQuery?.searchKey.trim();
      if(this.investigationQuery.searchKey != ''){
        this.showTextValidation = false;
        this.callApi();
      }
      else{
        this.showTextValidation = true;
      }
    }
    else{
      this.showTextValidation = true;
    }
  }

 async callApi() {
    sessionStorage.removeItem('amlSearchQuery');
    sessionStorage.removeItem('amlSearchResponse');
    sessionStorage.removeItem('entityAmlData');
    sessionStorage.removeItem('topSearch')
    if(this.investigationQuery.riskPolicy){
      const chooseData = this.riskPolicyData.filter((f) => f.id == this.investigationQuery.riskPolicy);
      if(chooseData && chooseData.length){
        this.investigationQuery.country = chooseData[0]['countries'];
        this.investigationQuery['categories'] = chooseData[0]['categories'];
        this.investigationQuery['dataSources'] = chooseData[0]['dataSources'];
      }
    }
    const payload = { 
      "size": 10, 
      "page": 0, 
      "searchType": this.investigationQuery.searchType, 
      "name": [this.investigationQuery.searchKey], 
      "country": this.investigationQuery.country ? this.investigationQuery.country : [], 
      "source": this.investigationQuery['dataSources'] ? this.investigationQuery['dataSources'] : [], 
      "guid": "", 
      "category": this.investigationQuery['categories'] ? this.investigationQuery['categories'] : [], 
      "gender": [], 
      "type": this.investigationQuery.investigationType ? [this.investigationQuery.investigationType] : []
    }
    this._data.changeLoaderVisibility(true);
    const res = await this._rs.doAmlSearch(payload).toPromise()
    this._data.changeLoaderVisibility(false)
    sessionStorage.setItem('amlSearchQuery', JSON.stringify(this.investigationQuery));
    const result = await this._rs.getAmlInvestigationHistory(payload).toPromise();
    if(res && res.content && res.content.length){
      sessionStorage.setItem('amlSearchResponse', JSON.stringify(res));
    }
    this.router.navigateByUrl('aml-screening/aml-investigation-results');
  }

  viewInfoText(){
    const initialState = {
      title: 'Info',
      type: "show-aml-info-text",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
  }

}

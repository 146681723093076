import { Component, OnInit , Input} from '@angular/core';
import { AppService } from "../../../services/app.service";
import { first } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import {Verification} from "../../../models/Verification.model";
import { DevOpsService } from '../services/dev-ops.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-selfie-details',
  templateUrl: './selfie-details.component.html',
  styleUrls: ['./selfie-details.component.scss']
})
export class SelfieDetailsComponent implements OnInit {

	@Input() selfieProcessedInfo: any ;
	@Input() biometrics         : any ;
	
	@Input() overriddenBy       : any ;
	@Input() overrideComment    : any ;
	@Input() isDocMatch?: boolean = false;
	
	verification                : Verification = new Verification();
	
	userSelfie                  : any =  null ;
	imgNaturalWidth             : any = null;
	imgNaturalHeight            : any = null;
	olddata: boolean;
	errorMessageForSelfie : any = "";
	errorMessageForDocMatch = "ID Doc Image is not matching with Secondary ID Doc";
	selfieAWSObject : any = {};
	clientId: any;
	constructor(private _app : AppService,private devops:DevOpsService,
		private activatedRoute:ActivatedRoute) { 
		this.activatedRoute.params.subscribe(params => {
			this.clientId = params["id"];
		  });
	}

	ngOnInit(){
		console.log('whole details ',this.selfieProcessedInfo)
		if(this.selfieProcessedInfo){
			if(typeof this.selfieProcessedInfo.livenessVerificationResult == "string"){
				this.selfieProcessedInfo.livenessVerificationResult = JSON.parse(this.selfieProcessedInfo.livenessVerificationResult);
			}
			if(this.selfieProcessedInfo.faceInSelfie !== ""){
				this.olddata=false;
				if(typeof this.selfieProcessedInfo.faceInSelfie  == "string"){
					this.selfieProcessedInfo.faceInSelfie = JSON.parse(this.selfieProcessedInfo.faceInSelfie);
					console.log("seldfiedatea",this.selfieProcessedInfo.faceInSelfie);
				}
			}else this.olddata=true;
			if(typeof this.selfieProcessedInfo.selfiePreProcessedInfo == "string"){
				this.selfieProcessedInfo.selfiePreProcessedInfo = JSON.parse(this.selfieProcessedInfo.selfiePreProcessedInfo);
			}
			if(typeof this.selfieProcessedInfo.selfieVerificationResult == "string"){
				this.selfieProcessedInfo.selfieVerificationResult = JSON.parse(this.selfieProcessedInfo.selfieVerificationResult);
			}
			if(this.selfieProcessedInfo && this.selfieProcessedInfo.selfieVerificationScore){
				this.verification["verificationScore"]  = this.selfieProcessedInfo.selfieVerificationScore.toFixed(2);
			}
			this.verification["verificationResult"] = null
			// this.verification["verificationScore"]  = ( this.selfieProcessedInfo.livenessVerificationScore * 100 ).toFixed(2) ;
			// this.verification["verificationScore"]  = this.selfieProcessedInfo.selfieVerificationScore.toFixed(2) ;

			this.verification["verificationStatus"] = this.selfieProcessedInfo.selfieVerificationStatus;
		
			if(this.selfieProcessedInfo
				&& this.selfieProcessedInfo.endUserCheck
				&& this.selfieProcessedInfo.endUserCheck.endUserCheckID){
				this.getBiometricSelfie(this.selfieProcessedInfo.endUserCheck.endUserCheckID);	
			}

			/// error at 66
			if(this.selfieProcessedInfo.selfieProcessErrorInfo == null){
				this.selfieAWSObject =JSON.parse(this.selfieProcessedInfo.selfiePreProcessedInfoAws);
				console.log(30,this.selfieAWSObject)
				if(this.selfieAWSObject && this.selfieAWSObject.faceMatches.length <= 0){
					this.errorMessageForSelfie = "ID Doc Image  is not matching with Selfie";
				}
				

		}
		if(this.selfieProcessedInfo.selfiePreProcessedInfoAws == "null"){
			this.selfieAWSObject =JSON.parse(this.selfieProcessedInfo.selfieProcessErrorInfo);
			console.log(this.selfieAWSObject.errors[0].errorMessage);
			this.errorMessageForSelfie = this.selfieAWSObject.errors[0].errorMessage;
		}
		}
		// console.log(this.selfieProcessedInfo);
	}

	getBiometricSelfie(checkGuid){
		this.devops.getBiometricSelfie(checkGuid,this.clientId)
		.subscribe(
			(res)=>{
				// console.log(res);
				this.userSelfie = res.data ? `data:image/png;base64,${res.data}` : null ;
				var i = new Image(); 
				var that = this ;
				i.onload = function(){
					that.imgNaturalWidth  = i.width ;
					that.imgNaturalHeight = i.height ;
					setTimeout(()=>{that.drawImageCoordinates()},10);
				};
				i.src = this.userSelfie; 
			}
		);
	}

	drawImageCoordinates(){
		if(this.selfieProcessedInfo 
			&& this.selfieProcessedInfo.selfiePreProcessedInfo
			&& this.selfieProcessedInfo.selfiePreProcessedInfo.selfieRawData){
			var img = <HTMLImageElement>document.getElementById("userImage");
			var c = <HTMLCanvasElement>document.getElementById("imgDrawCanvas");
			c.style.left =  img.offsetLeft + "px";
			c.style.top  = 0 + "px";

			var ctx = (<HTMLCanvasElement>c).getContext("2d");
			var top = this.selfieProcessedInfo.selfiePreProcessedInfo.selfieRawData.faceRectangle.top;
			var left = this.selfieProcessedInfo.selfiePreProcessedInfo.selfieRawData.faceRectangle.left ;
			var width = this.selfieProcessedInfo.selfiePreProcessedInfo.selfieRawData.faceRectangle.width ;
			var height = this.selfieProcessedInfo.selfiePreProcessedInfo.selfieRawData.faceRectangle.height ;
			// console.log(top , left , width , height);

			ctx.beginPath();
			ctx.rect(left , top, width, height);  //( x ,y , wid , height)
			ctx.fillStyle = 'transparent';
			ctx.fill();
			ctx.lineWidth = 2;
			ctx.strokeStyle = '#e1e1e1';
			ctx.stroke();
		}
	}

}

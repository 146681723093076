import { Component, OnInit } from '@angular/core';
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";
import { ActivatedRoute, Router } from '@angular/router';
import { CorporateCheckService } from './corporate-check.service';
import { first,finalize } from "rxjs/operators";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-corporate-check',
  templateUrl: './corporate-check.component.html',
  styleUrls: ['./corporate-check.component.scss']
})
export class CorporateCheckComponent implements OnInit {

  searchField: string;
  isReport=false;
  //Temporary Test Vars
  showTabularResult: boolean = false;
  showDetailedResult: boolean = false;
  amlResultsData: any[] = [];
  amlFilters: any[] = [];
  jurisdiction:any
  jurisdictionData;
  selectedAMLEnitity:any;

  //Pagination
  jump: any=1;
  currentPage: number = 0;
  itemsPerPage: number = 5;
  totalItems: number;
  maxSize: number;


  pageList: any = []
  startIndex: number = 0;
  endIndex: number = 10;
  list=[];
  sourceOfData;
  checkListId;
  savedEntities=[];
  
  countries: any;
  selectedCountry: any = {
    countryCode: "",
    countryName: "Select Country",
    countrySynonyms: "",
    isActive: "ACTIVE",
    jurisdictions: null,
  };
  constructor( private _data: DataService,
    private _app: AppService,
    private _check: CorporateCheckService,
    private route: ActivatedRoute,
    public router: Router) { }

  ngOnInit() {
    this.getAllCountries();
    this.getSavedEntites();
  }

  getAllCountries() {
    this._app
      .getAllCountries()
      .pipe(first())
      .subscribe(
        res => {
          this.countries = res["data"];
          this.countries.unshift(this.selectedCountry);
          console.log(this.countries);
        },
        err => {
          console.log(err);
        }
      );
     
  }

  getSavedEntites(){
   let payload= {
      "page":this.currentPage,
      "size":this.itemsPerPage,
      "saveListStatus":"SAVED"
  }
    this._check.getSavedEntities(payload).subscribe(res=>{
     this.savedEntities=res.content;
     this.totalItems=res.totalElements;
     this.maxSize = res.totalPages;
    })
  }
  doSearch(){
    let payload={ "name":this.searchField ,
    "jurisdictionCode": this.jurisdiction ? this.jurisdiction.code:"",
    "country": this.selectedCountry? this.selectedCountry.countryCode:""}
    this._data.changeLoaderVisibility(true);
    this._check.corporateSearch(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res:any) => {
      if(res !== null){
        if(res.results !== null){
          this.list = res.results.companies;
          this.showTabularResult = true;
          this.showDetailedResult = false;
        }
        this.sourceOfData = res.sourceOfData;
      }
      else{
        this._data.toastrShow(
          `No record found for ${this.searchField}.Please check with other name.`,
          "warning"
        );
      }

    });
   }

   goBack(type){
    if(type=="list" && this.list.length>0){
      this.selectedAMLEnitity = null;
      this.showDetailedResult = false;
      this.showTabularResult = true;
    }else{
      this.showTabularResult = false;
      this.showDetailedResult = false;
      this.isReport=false;
      this.checkListId=null;
    }
  }

  standardCheck(){
      const payload = {
          "checkType": "ALL",
          "name" : this.selectedAMLEnitity.company.name,
          "jurisdiction":this.selectedAMLEnitity.company.jurisdiction_code ,
          "country":this.selectedCountry.countryCode ,
          "companyNumber" : this.selectedAMLEnitity.company.company_number,
          "companyDataFormatted" : this.selectedAMLEnitity.company,
      }
      this._data.changeLoaderVisibility(true);
      this._check.doAllCheck(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) =>{
        this.checkListId = res.checkListId;
        this.isReport=true;
      });
  }

  onCountrySelect(countryCode){
    // this.showRegNo = false

    // if(countryCode === 'CN'){
    //   this.showRegNo = true
    // }
    this._check.getJurisdiction(countryCode).subscribe((res) => {
      this.jurisdictionData = res;
      this.jurisdiction=this.jurisdictionData[0]

    })
  }

  downloadReport(){
    this._data.changeLoaderVisibility(true);
    this._check.downloadReport(this.checkListId).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) =>{
        const blob = new Blob([res], { type: 'application/pdf' });
        var file = new File([blob], 'Documents.pdf', { type: 'application/pdf' });
        FileSaver.saveAs(blob, `${this.checkListId}-Report.pdf`);
    });
  }

  clearFilters(){
    this.selectedCountry = this.countries != undefined && this.countries.length>0?this.countries[0]:'';
    this.searchField = "";
    this.jurisdictionData=[];
  }

  save(){
    this._data.changeLoaderVisibility(true);
    this._check.saveEntity(this.checkListId).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) =>{
      this._data.toastrShow(`Entity Saved Successfully`, 'success');
      this.getSavedEntites()
    }
    ,error=>{
      this._data.toastrShow(`Entity already saved`, 'error');
    })
  }

  getPaginatedText() {
    let a = this.currentPage * this.itemsPerPage + 1;
    let b;
    if (this.currentPage < this.maxSize - 1) {
      b = (this.currentPage + 1) * this.itemsPerPage;
    } else if (this.currentPage == this.maxSize - 1) {
      b = this.totalItems;
    }
    return a + "-" + b;
  }

  pageSize() {
    this.pageList = []
    let totalPages = this.getPage(this.totalItems)
    this.startIndex = (this.currentPage - 4) < 0 ? 0 : this.currentPage - 4
    this.endIndex = (this.currentPage + 4) > this.getPage(this.totalItems) ? totalPages : this.currentPage + 4
    for (let i = 0; i <= this.currentPage + 4; i++) {
        if (i >= this.currentPage - 4 && i < totalPages) { this.pageList.push(i) }
    }
  
  }

  getPage(length) {
    return (Math.round(length / 10));
  }

  viewDetails(item){
   this.checkListId=item.checkListId;
   this.selectedAMLEnitity=item;
   this.selectedAMLEnitity["company"]={name:item.name}
   this.showDetailedResult=true;
   this.isReport=true;

  }

  jumpTo(){
    if (this.jump <= this.maxSize) {
      if(Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0)
          this.currentPage=this.jump - 1;
          if (this.currentPage < this.maxSize) {
            this.getSavedEntites();
            this.pageSize()
          }
      }
  }

  getPaginatedData(isNext){
    this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
    if (this.currentPage < this.maxSize) {
        this.getSavedEntites();
        this.pageSize()
        this.jump=this.currentPage+1;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSentence'
})
export class HighlightSentencePipe implements PipeTransform {

  async transform(item, offsetData, isPopup, iscontributeSentence) {
    let response;
    if(iscontributeSentence){
      if(offsetData?.sentence){
        let articleBody = offsetData?.sentence;
        let result = '';
        let currentIndex = 0;
        const actualSentenceOffset = offsetData?.offset[0]
        let newOffsetList = [];
        if(item?.offset?.length){
          for(let e of item?.offset){
            const newEntityOffset = [(e[0] - actualSentenceOffset[0]), (e[1] - actualSentenceOffset[0])]
            if(newEntityOffset[0] >= 0 && newEntityOffset[1] <= (actualSentenceOffset[1] - actualSentenceOffset[0])){
              newOffsetList.push(newEntityOffset)
            }
          }
          for(let offset of newOffsetList){
            let startIndex = offset[0];
            let endLength = offset[1] + 1;
            result += articleBody.substring(currentIndex, startIndex);
            if (startIndex > -1 && endLength > -1) {
              result += `<span class='kyc-text-warning'><i>${articleBody.substring(startIndex, endLength)}</i></span>`;
            }
            currentIndex = endLength;
          }
        }
        result += articleBody.substring(currentIndex);
        response = await `<div class='text-muted'>${result}</div>`;
      }
      else{
        response = await `<div><p>Article body not found</p></div>`;
      }
    }
    else{
      if(item?.body){
        let articleBody = item?.body;
        let result = '';
        let currentIndex = 0;
        if(offsetData){
          for(let offset of offsetData?.offset){
            let startIndex = offset[0];
            let endLength = offset[1] + 1;
            result += articleBody.substring(currentIndex, startIndex);
            if (startIndex > -1 && endLength > -1) {
                if(offsetData?.adversityScore > 0){
                  result += `<span class='kyc-text-warning'><i>${articleBody.substring(startIndex, endLength)}</i></span>`;
                }
                else{
                  result += `<span class='text-dark'><i>${articleBody.substring(startIndex, endLength)}</i></span>`;
                }
            }
            currentIndex = endLength;
          }
        }
        else{
          if(item?.analysis){
            let offsetData = [];
            let adverseOffset = [];
            let normalOffset = [];
            if(item?.analysis?.adverseEntites?.length){
              item?.analysis?.adverseEntites.forEach(obj => {
                obj?.adverseSentence.forEach(sentence => {
                  adverseOffset.push(...sentence.offset.map(e => ["adverse", ...e]));
                });
              });
            }
            if(item?.analysis?.normalEntities?.length){
              normalOffset = item?.analysis?.normalEntities.reduce((result, obj) => {
                return result.concat(obj.offset.map(e => ["normal", ...e]));
              }, []);
            }
            offsetData = adverseOffset.concat(normalOffset);
            // Sort the data based on the range values
            const sortedData = offsetData.sort((a, b) => {
              // Extract the start and end values from each sub-array
              const startA = a[1];
              const endA = a[2];
              const startB = b[1];
              const endB = b[2];
              // Compare the start values
              if (startA < startB) return -1;
              if (startA > startB) return 1;
              // If the start values are the same, compare the end values
              if (endA < endB) return -1;
              if (endA > endB) return 1;
              // If both start and end values are the same, consider them equal
              return 0;
            });
            for(let offset of sortedData){
              let startIndex = offset[1];
              let endLength = offset[2] + 1;
              result += articleBody.substring(currentIndex, startIndex);
              if (startIndex > -1 && endLength > -1) {
                if(offset[0] == 'adverse'){
                  result += `<span class='kyc-text-warning'><i>${articleBody.substring(startIndex, endLength)}</i></span>`;
                }
                else{
                  result += `<span class='text-dark'><i>${articleBody.substring(startIndex, endLength)}</i></span>`;
                }
              }
              currentIndex = endLength;
            }
          }
        }
        result += articleBody.substring(currentIndex);
        if(result?.length > 1000 && !isPopup){
          response = await `<div class='d-inline'>${result.substring(0, 1000)}</div>`;
        }
        else{
          response = await `<div class='text-muted'>${result}</div>`;
        }
      }
      else{
        response = await `<div><p>Article body not found</p></div>`;
      }
    }
    return response;
  }

}
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DataService } from '../../../../services/data.service';
import * as FileSaver from 'file-saver';
import { finalize } from 'rxjs/operators';
import { AppService } from '../../../../services/app.service';
import { AmlChecksService } from '../../aml-checks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AmlSearchService } from '../../../aml-search/aml-search.service';
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { KycModalComponent } from "../../../../shared/kyc-modal/kyc-modal.component";
import { RelationData } from "../../../../utils/aml-search";


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  @Input() amlDetails: any;
  sticky: boolean = false;
  bsModalRef: BsModalRef;
  menuPosition: any;
  primaryData: any[];
  secondaryData: any[];
  summaryInfo: any;
  faceImageUrl: any;
  docData: any;
  primaryIDData: Object;
  isPrimaryID: boolean = false;
  isSecondaryID: boolean = false;
  secondaryIDData: Object;
  isPrimaryAddress: boolean = false;
  isPrimaryAddressData: any[] = new Array();
  isSecondaryAddress: boolean = false;
  secondaryAddressData: any[] = new Array();
  isSecondary: boolean = false;
  getAllCheckData: any;
  riskScore: number;
  riskStatus: string;
  userNameP: any;
  tagsLength: number = 0;
  currentTheme: any = 'theme-light';
  isHighEntity: boolean = false;
  amlRiskScore: number;
  amlRiskStatus: string = "HIGH";
  isTagFound: boolean = false;
  sancLength: number = 0;
  crimeLength: number = 0;
  pepLength: number = 0;
  primaryUserArray: any = [];
  secondaryUserArray: any = [];
  numberData: number = 0;
  numberDataS: number = 0;
  reportData: any = [];
  reportResponse: any = [];
  baselIndex: any = "The Basel AML Index is an independent annual ranking that assesses risks of money laundering and terrorist financing (ML/TF) around the world.Published by the Basel Institute on Governance since 2012, the Basel AML Index provides risk scores based on data from 17 publicly available sources such as the Financial Action Task Force (FATF), Transparency International, the World Bank and the World Economic Forum.";
  details: any;
  adverseData: any;
  adverseMediaInteliigenceScore: any;
  adverseMedialabel: any;
  adverseMediaArray = [];
  articledatas: any[] = [{}];
  advmedialist: boolean = false;
  newArticleItems: any[] = [];
  designations = []
  RelationData = RelationData;
  traits = [];
  images = [];
  tags = [{ "slug": "pep", "name": "PEP", "value": false, entity: "Individual" }, { "slug": "crime", "name": "Crime", "value": false, entity: "Individual,Organisation" },
  { "slug": "sanction", "name": "Sanction", "value": false, entity: "Individual,Organisation" }, { "slug": "watchlist", "name": "Watchlist", "value": false, entity: "Individual,Organisation" },
  { "slug": "sip", "name": "SIP", "value": false, entity: "Individual" }, { "slug": "sie", "name": "SIE", "value": false, entity: "Organisation" }
  ]
  dob = []
  switchTab = 'summaryInfo'
  possibleMatches = [];
  scoreList = []
  dataSource;
  page = 1;
  sum = 10;
  totalItemz;
  media;
  newnodes;
  relationEntityPage = 1
  public canvasWidth = 300
  public needleValue = 0
  public bottomLabel = '0';
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#14E167', '#FC9031', '#FC3131'],
    arcDelimiters: [30, 50],
    rangeLabel: ['0', '100'],
    needleStartValue: 0,
  }
  dataSourceArray: any[] = [];
  dataSourceName: any[] = [
    {
      slug: 'pep',
      list: [
        { name: "Every politicians" },
        { name: "House of commons" },
        { name: "List of world leaders" },
        { name: "FRANCE SENATE LIST" },
        { name: "United Kingdom Government People" },
        { name: "Nevada Attorney General" },
        { name: "UNOPS leaders" },
        { name: "US SECURITIES AND EXCHANGE COMMISSION COMMISSIONERS" },
        { name: "Presidents of the Senate" },
        { name: "Ministers of South Africa" },
        { name: "Japan Ministers List" },
        { name: "Minister of Law and Justice" },
        { name: "Senate Of US State Wise" },
        { name: "Prime Ministers Office Singapore Cabinet Ministers" },
        { name: "List Of 11th Parliament members of Bangladesh" },
        { name: "Federal Ministers Russian Government" },
        { name: "House of Lords" }
      ]
    },
    {
      slug: 'watchlist',
      list: [
        { name: 'Nyse Disciplinary Actions' },
        { name: '2019 Delinquent Child Support List' },
        { name: 'Occ Enforcement Action' },
        { name: 'Data Mega Store' },
        { name: 'EBRD' },
        { name: 'Canada Alberta Securities Commission Decisions And Orders' },
        { name: 'British Columbia Securities Commission Notices Of Hearing And Temporary Orders' },
        { name: 'Security Commission Malaysia Investor Alert List' },
        { name: 'Osc Tribunal Proceedings' },
        { name: 'Monetory Authority Of Singapore Investor Alert List' },
        { name: 'Statutorily Debarred Parties' }
      ]
    },
    {
      slug: 'sanction',
      list: [
        { name: 'EU Sanctions' },
        { name: 'UK HM treasury' },
        { name: 'US department of Treasury' },
        { name: 'OFAC' },
        { name: 'SECO Sanctions List' },
        { name: 'UK SANCTIONS ON RUSSIAN BANKS AND INDIVIDUALS' },
        { name: "OFAC Lists" },
        { name: "World Bank Ineligible Firms Individuals" },
        { name: "UK Sanctions List Publication" },
        { name: "US Treasury Designated Russian Oligarchs" },
        { name: "EU Sanction Individual 09102021" },
        { name: "UK HM Treasury New" },
        { name: "Ukraine Russia related Sanctions" },
        { name: "Canada Economic Measures Russia-1" },
        { name: "Canada Economic Measures Russia-2" },
        { name: "UK HM Treasury" },
        { name: "EU Sanction" },
        { name: "US Department Of The Treasury SDN" },
        { name: "Interpol UN Notices Entities" },
        { name: "SECO" },
        { name: "UK GOV SANCTIONS" },
        { name: "CIBIL's List of Wilful Defaulters" },
        { name: "FinCEN 311 Special Measures" },
        { name: "Willful Defaulters" },
        { name: "Suspended and Blacklisted NGOs" },
        { name: "Tax Defaulters Individuals" }
      ]
    },
    {
      slug: 'crime',
      list: [
        { name: "Interpol Public Red Notices" },
        { name: "DEA'S MOST WANTED" },
        { name: "FBI Wanted Terrorists" },
        { name: "ICAC Wanted Persons" },
        { name: "US Department of Transportation" },
        { name: "U.S. Department of Justice" },
        { name: "INTERPOL RED NOTICE" },
        { name: "Singapore Terrorism Suppression of Financing Act Order 2015" },
        { name: "ATF Most Wanted" }
      ]
    },
    {
      slug: 'sip',
      list: [
        { name: "List Issued By Fcnb Agent" },
        { name: "Richest Models" },
        { name: "Richest Authors" },
        { name: "Monetory Authority Of Singapore Payments" },
        { name: "Federal Deposit Insurance Corporation Members" },
        { name: "Hudson County Most Wanted" },
        { name: "List Of Corporate Headquarters In Mbabane" },
        { name: "The Most Famous Celebrity Born Every Year Since 1975" },
        { name: "Fcnb Mortage Brokers And Associates" },
        { name: "Swazi Celebrities" }
      ]
    }
  ];
  centralLabel: string = ""
  isRelatedEntitiesLoading: boolean = false;
  relatedEntitiesData: any;
  // nodes graph vars ----->

  displayGraph = false
  chartOptions = {
    chart: {
      type: 'networkgraph',
      backgroundColor: '#ffffff00',

    },

    title: {
      text: 'Relation Graph',
    },
    plotOptions: {
      networkgraph: {
        layoutAlgorithm: {
          enableSimulation: true,
          integration: 'verlet',
          linkLength: 200
        },
      },
    },
    series: [
      {

        link: {
          width: 2,
        },
        cursor: 'pointer',
        point: {
          events: {
            click: function () {
              console.log('even', this);

              window.open("aml-search/report/" + this.series.points.find(e => e.to === this.id).guid);

            }
          },
        },

        marker: {
          radius: 20,

        },

        dataLabels: {
          enabled: true,
          color: '#fff',
          // textPath: {
          //   enabled: true,
          //   attributes: {
          //     dy: 0,

          //   }
          // },
          linkFormat: '{point.parentRelation}'
        },
        data: [],
        nodes: [],
      },
    ],

  }
  detailedGraph: boolean = false


  constructor(
    private dataService: DataService,
    public app: AppService,
    public amlService: AmlChecksService,
    public amlProfile: AmlSearchService,
    private modalService: BsModalService,
    public router: Router) { }

  ngOnInit() {
    this.app.currentTheme.subscribe(theme => {
      this.currentTheme = theme;
    });
    this.reportData = this.amlDetails;
    window.scroll(0, 0)
    if (this.reportData.length != 0) {
      if (this.reportData.masterData.image && this.reportData.masterData.image.length > 0) {
        this.images = this.reportData.masterData.image.filter(element => {
          if (element != null && element.includes('http')) {
            return element;
          }
        });
      }

      this.reportData.masterData.category.forEach(element => {
        this.tags.forEach(tag => {
          if (element.slug == tag.slug) {
            tag.value = true;
          }
        })
        this.reportData.masterData.datasource.forEach(element => {
          for (let type of this.dataSourceName) {
            for (let list of type.list) {
              if (list.name.toUpperCase() == (element.dataAgencyName ? element.dataAgencyName.toUpperCase() : '')) {
                list['found'] = true;
              }
            }
          }
        })
        for (let type of this.dataSourceName) {
          let foundList = [];
          let notfound = [];
          for (let list of type.list) {
            if (list['found']) {
              foundList.push(list)
            }
            else {
              notfound.push(list)
            }
          }
          type.list = [...foundList, ...notfound]
        }

      });
      if (this.reportData.masterData.dob && this.reportData.masterData.dob.length > 0) {
        this.dob = this.reportData.masterData.dob.filter(element => {
          if (element != null && (element.dob || element.dobYear)) {
            return element
          }
        });
      }
      if (this.reportData && this.reportData.possibleMatches && this.reportData.possibleMatches.length > 0) {
        this.reportData.possibleMatches.forEach(match => {
          let matchedEntity = {}
          let matchScore = {}
          if (match.attributes_score.length > 0) {
            match.attributes_score.forEach(element => {
              matchedEntity[element.key] = element.candidate_value;
              matchScore[element.key] = element.score
            });
          }
          matchedEntity["aggrigated_score"] = match.aggrigated_score;
          matchScore["aggrigated_score"] = match.aggrigated_score;
          matchedEntity["candidateGuid"] = match.candidateGuid;
          matchedEntity["scores"] = matchScore;
          this.possibleMatches.push(matchedEntity);
        });
      }
      if (this.reportData.masterData && this.reportData.masterData.careerInfo && this.reportData.masterData.careerInfo.length > 0) {
        this.reportData.masterData.careerInfo.forEach(element => {
          if (element.designations && element.designations.length > 0) {
            element.designations.forEach(des => {
              this.designations.push(des)
            });
          }
        });
      }
      (async () => {
        await this.getAdvMedia(this.reportData.guid);
        // await this.getRelationGraphData();
        await this.getRelatedEntities();
        await this.newsArticles();
      })();
    }
    console.log("report data", this.reportData)

  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 14) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }
  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.amlService.backVal(false)
  }

  // Download Zip File By UserName

  downloadZip() {
    this.dataService.changeLoaderVisibility(true);
    this.amlService.retailDownloadReportZip(this.userNameP).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/zip' });
      var file = new File([blob], 'Documents.zip', { type: 'application/zip' });
      FileSaver.saveAs(blob, `${this.userNameP}-documents.zip`);
      this.dataService.changeLoaderVisibility(false);
    });
  }

  downloadReport() {
    this.dataService.changeLoaderVisibility(true)
    let payload = { "guid": this.reportData.guid, "name": this.reportData.masterData.fullName[0], "countryCode": "" }
    this.amlService.downloadReport(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      if (res.size < 80) {
        this.dataService.toastrShow(`Insufficient Credits`, 'error');
      }
      else {
        const blob = new Blob([res], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `${this.reportData.masterData.fullName[0]}-report.pdf`);
      }
    });
  }


  saveAml(data) {
    this.dataService.country.subscribe(res => {
      this.getGeoLocationScore(res);
    })

    let payload = {
      firstName: data.details[0].firstName,
      lastName: data.details[0].lastName,
      amlGuid: data.guid,
    };
    // this.dataService.changeLoaderVisibility(true);
    this.amlService.saveAmlCheckList(payload).subscribe(
      (res) => {
        console.log("this is the det", res['data']);
        this.reportResponse = res['data'];

        console.log("report response", this.reportResponse)
        // this.getAdvMedia(this.reportResponse.checkListId)
        if (this.reportResponse.amlFormattedData.details.length !== 0) {
          for (var i = 0; i < this.reportResponse.amlFormattedData.details.length; i++) {
            if (this.reportResponse.amlFormattedData.details[i].category.slug === 'pep' || this.reportResponse.amlFormattedData.details[i].category.name === 'Pep') {
              this.pepLength = this.pepLength + 1;
            }
            else if (this.reportResponse.amlFormattedData.details[i].category.slug === 'crime' || this.reportResponse.amlFormattedData.details[i].category.name === 'CRIME') {
              this.crimeLength = this.crimeLength + 1;
            }
            else {
              this.sancLength = this.sancLength + 1;
            }
          }
        }
        this.details = this.reportResponse.amlFormattedData.details;
        this.dataService.changeLoaderVisibility(false);
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.dataService.changeLoaderVisibility(false);
      }
    );
  }
  getGeoLocationScore(cCode) {
    // let cntryCode=cCode!=""cCode:
    let payload = {
      countryCode: cCode,
    };
    this.amlService.getGeoLocationScore(payload)
      .subscribe(
        (res) => {
          this.amlRiskScore = res['data'].geoLocationScore * 10;
          console.log("the score", this.amlRiskScore)
          if (res['data'].geoLocationScore > 4.76 && res['data'].geoLocationScore < 6) {
            this.amlRiskStatus = "MEDIUM";
          } else if (res['data'].geoLocationScore <= 4.5) {
            this.amlRiskStatus = "LOW";
          } else {
            this.amlRiskStatus = "HIGH";
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.dataService.changeLoaderVisibility(false);
        }
      );
  }
  // getAdvMedia(id) {
  //   this.adverseData = [];
  //   let payload={'guid':id}
  //   this.amlService.getAdvMediaResults(payload).subscribe(
  //           (res) => {
  //               console.log(res);
  //                this.adverseData = res['data'];
  //                if(this.adverseData.content.length>0){
  //                  this.adverseMediaInteliigenceScore=this.adverseData.content[0].riskProfile.score;
  //                  this.adverseMedialabel=this.adverseData.content[0].riskProfile.label;
  //                }    
  //           },
  //           (err: HttpErrorResponse) => {
  //               console.log(err);
  //               this.dataService.changeLoaderVisibility(false);
  //           }
  //       );
  //   this.dataService.changeLoaderVisibility(false);
  //   let amlPayload={
  //     'entity':this.reportData.masterData.fullName[0],
  //     'processedStatus' : "entityCategoryScores",
  //     'processStatus' : "LEVEL3"
  //   }
  //   this.amlService.getAMLArticles(amlPayload).subscribe(res=>{
  //     this.adverseMediaArray=res.data.data;
  //   })
  // }
  async getAdvMedia(id) {
    this.adverseData = [];
    let payload = { 'guid': id }
    const res = await this.amlService.getAdvMediaResults(payload).toPromise()

    this.dataService.changeLoaderVisibility(false);
    console.log(res);
    this.adverseData = res['data'];
    if (this.adverseData.content.length > 0) {
      this.adverseMediaInteliigenceScore = Math.round(this.adverseData.content[0].riskProfile.score)
      this.needleValue = this.adverseMediaInteliigenceScore;
      this.bottomLabel = this.adverseMediaInteliigenceScore.toString();
      this.adverseMedialabel = this.adverseData.content[0].riskProfile.label;
    }
    let amlPayload = {
      'entity': this.reportData.masterData.fullName[0],
      'processedStatus': "entityCategoryScores",
      'processStatus': "LEVEL3"
    }
    // this.amlService.getAMLArticles(amlPayload).subscribe(res=>{
    //   this.adverseMediaArray=res.data.data;
    // })
    if (this.reportData.relationshipList != null && this.reportData.relationshipList.length > 0) {
      this.createGraph(this.reportData.relationshipList)
    }
  }
  getArticle(val) {
    console.log("Value: ", val);

    this.articledatas = [];
    for (let article of val) {
      this.amlService.getArticle(article.id).subscribe((res) => {
        if (res.content.length > 0) {
          let articleData = res.content[0];
          this.articledatas.push(articleData);
        }

      });
    }
    this.advmedialist = true;
  }

  expand(id, action) {
    if (action == 'expand') {
      var element = document.getElementById(id);
      element.classList.add('d-none');
      element.classList.remove('d-inline')
      var expelement = document.getElementById(id + '-expand')
      expelement.classList.add('d-inline')
      expelement.classList.remove('d-none')
    }
    else {
      var element = document.getElementById(id);
      element.classList.remove('d-none');
      element.classList.add('d-inline');
      var expelement = document.getElementById(id + '-expand');
      expelement.classList.remove('d-inline')
      expelement.classList.add('d-none')
    }

  }
  async newsArticles() {
    let payload = {
      entityName: this.reportData.masterData.fullName[0]
    }
    const res: any = await this.amlProfile.getRecentArticles(payload).toPromise();
    this.newArticleItems = res.data.source;
  }

  createGraph(list) {
    let levela = [];
    let levelb = [];
    let levelc = [{ "name": this.reportData.masterData.fullName[0], title: "Entity", cssClass: "text-success", guid: "" }];
    let leveld = [];
    for (let i = 0; i < list.length; i++) {
      let obj = { "name": list[i].fullName, title: list[i].parentRelation, cssClass: "text-entity", guid: list[i].childGuid }
      if (this.RelationData[list[i].parentRelation] == 2) {
        levela.push(obj)
      }
      if (this.RelationData[list[i].parentRelation] == 1) {
        levelb.push(obj)
      }
      if (this.RelationData[list[i].parentRelation] == 0) {
        levelc.push(obj)
      }
      if (this.RelationData[list[i].parentRelation] == -1) {
        leveld.push(obj)
      }
    }
    levelc[0]["childs"] = leveld;
    if (levelb.length > 0) {
      levelb[0]["childs"] = levelc
    }
    else {
      levelb[0] = { "name": "", childs: levelc }
    }
    if (levela.length > 0) {
      levela[0]["childs"] = levelb
    }
    else {
      levela[0] = { "name": "", childs: levelb }
    }
    this.newnodes = levela;
  }
  change(div) {
    // var topOfElement = document.getElementById(div)
    // let top=topOfElement.offsetTop
    // window.scroll({ top: topOfElement, behavior: "smooth" });

    const headerHeight = 220; /* PUT HEADER HEIGHT HERE */
    const buffer = 25; /* MAY NOT BE NEEDED */
    const scrollToEl = document.getElementById(div)

    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight - buffer;
    if (this.switchTab != div) {
      let scrolly = topOfElement - 250;
      window.scroll({ top: scrolly, behavior: "smooth" });
    }
    else {
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
    this.switchTab = div;

  }
  scrollTop() {
    window.scroll(0, 0)
  }

  bookMark() {
    let data = { guid: this.reportData.guid, fullName: this.reportData.masterData.fullName[0], entityType: this.reportData.masterData.entityType }
    this.amlService.bookMarkEntity(data).subscribe(res => {
      this.dataService.toastrShow(`Entity Bookmarked Successfully`, 'success');
    }, error => {
      this.dataService.toastrShow(`Entity already BookMarked`, 'error');
    }
    )

  }
  getIndexValueForDataSource(i) {
    if (this.dataSourceArray.indexOf(i) > -1) {
      this.dataSourceArray.splice(this.dataSourceArray.indexOf(i), 1)
    }
    else {
      this.dataSourceArray.push(i)
    }
  }

  openSource(urllin) {
    if(urllin){
      window.open(urllin, "_blank");
    }
  }
  openEntity(guid) {
    window.open("aml-search/report/" + guid);
  }
  openData(data) {
    this.dataSource = data;
  }
  pageChange($event) {
    this.page = $event;
  }

  getAdverseMediaNewImage(data) {
    // if(data.pagemap 
    // 	&& data.pagemap.cse_image 
    // 	&& data.pagemap.cse_image.length
    // 	&& data.pagemap.cse_image[0] && data.pagemap.cse_image[0]){
    // 	return data.pagemap.cse_image[0].src ;
    // }
    // else if(data.pagemap 
    // 	&& data.pagemap.cse_thumbnail 
    // 	&& data.pagemap.cse_thumbnail.length
    // 	&& data.pagemap.cse_thumbnail[0] && data.pagemap.cse_thumbnail[0]){
    // 	return data.pagemap.cse_thumbnail[0].src ;
    // }
    if (data) {
      return data;
    }
    else {
      // return "https://www.kychub.com/dashboard/assets/img/avatar/avatar.png"
      return "assets/images/profile-picture.png";
    }
  }
  getCRRModel() {
    if (this.adverseData.content.length && this.adverseData.content[0].riskProfile.riskFactor) {
      this.adverseData.content[0].riskProfile.riskFactor.forEach((item) => {
        if (item.key.includes('Aml')) {
          item.key = item.key.replace('Aml ', '')
        }
      })
    }
    const initialState = {
      title: 'CRR Details',
      type: "crr-break-points",
      crrGuidDetails: this.adverseData.content[0].riskProfile.riskFactor
    }
    this.bsModalRef = this.modalService.show(KycModalComponent, { initialState, class: 'modal-lg' });
  }
  async getRelatedEntities() {
    if (
      this.amlDetails &&
      this.amlDetails.masterData &&
      this.amlDetails.masterData.fullName &&
      this.amlDetails.masterData.fullName[0]
    ) {
      this.isRelatedEntitiesLoading = true
      const payload = {
        fullName: this.amlDetails.masterData.fullName[0],
        din: this.amlDetails.masterData.din
      }
      const res = await this.amlService.getRelatedEntitiesByName(payload).toPromise();
      this.relatedEntitiesData = res.data
      this.isRelatedEntitiesLoading = false
    }
  }
  async getRelationGraphData() {

    this.displayGraph = false


    const res = await this.amlService.getRelationGraphData(this.reportData.guid).toPromise();

    this.dataService.changeLoaderVisibility(false)

    this.formatRelationGraphData(res.data)
    this.displayGraph = true
  }
  formatRelationGraphData(data) {

    let arr = []

    const rootGuids = this.amlDetails.details.map(e => e.guid)
    data.forEach(e => {
      const index = data.findIndex(p => p.childGuid === e.parentGuid)
      if (rootGuids.includes(e.parentGuid)) {
        arr.push({
          from: this.reportData.masterData.fullName[0],
          to: `${e.fullName}`,
          parentRelation: e.parentRelation,
          childRelation: e.childRelation,
          guid: e.childGuid
        })
      }
      else if (index !== -1 && this.detailedGraph) {
        arr.push({
          from: `${data[index].fullName}`,
          to: e.fullName,
          parentRelation: e.parentRelation,
          childRelation: e.childRelation,
          guid: e.childGuid
        })
      }
    });
    this.chartOptions.series[0].data = arr
    const arrtemp = new Set(data.filter(e => (rootGuids.includes(e.parentGuid) || this.detailedGraph)).map(e => e.fullName))
    let nodesArr = Array.from(arrtemp);
    nodesArr = nodesArr.map(e => ({
      id: e,
      marker: {
        radius: 14,
        fillColor: '#17b18c'
      }
    }))
    this.chartOptions.series[0].nodes = [{
      id: this.reportData.masterData.fullName[0],
      marker: {
        radius: 28,
        fillColor: '#ed7c15'
      },
    }, ...nodesArr]
    this.displayGraph = true
    this.chartOptions.plotOptions.networkgraph.layoutAlgorithm.linkLength = arr.length > 50 ? 100 : 200

    console.log(777, arr)
  }
  handleRelationData(type: 'zoom-in' | 'zoom-out') {
    console.log(type)

    if (this.detailedGraph !== (type === 'zoom-in')) {
      this.detailedGraph = (type === 'zoom-in')
      this.getRelationGraphData()
      console.log(66)
    }

  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RiskScreeningService } from '../../risk-screening.service';
import { DataService } from '../../../../services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-do-corporate-check',
  templateUrl: './do-corporate-check.component.html',
  styleUrls: ['./do-corporate-check.component.scss', '../../risk-screening.component.scss']
})
export class DoCorporateCheckComponent implements OnInit {
  entityMasterData: any;
  countryObject: any;
  investigationQuery: any;
  newPage;
  page: number = 1;
  size: number = 10;
  tableList: any[] = [];
  totalItems: number = 0;
  itemPerPage: any[] = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  totalPage: number = 0;

  constructor(private _rs: RiskScreeningService, private route: Router, private _data: DataService) { }

  ngOnInit(): void {
    const amlData = JSON.parse(sessionStorage.getItem('entityAmlData'));
    if (amlData?.masterData) {
      this.entityMasterData = amlData.masterData;
    }
    if (this.entityMasterData?.nationality) {
      this.getCountryObject(this.entityMasterData.nationality[0]);
    }
    else if (this.entityMasterData?.countryOfCitizenship) {
      this.getCountryObject(this.entityMasterData.countryOfCitizenship[0]);
    }
    if(sessionStorage.getItem('amlSearchQuery')){
      this.investigationQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'));
      this.getTableData(this.page - 1, this.rowPageNo)
      const obj = { fullName: [this.investigationQuery?.searchKey] };
      this.entityMasterData = obj;
    }
    else{
      this.route.navigate(['/aml-screening/aml-investigation-results/aml'], {
        replaceUrl: true,
      });
    }
  }

  getTableData(page, size){
    const payload = {
      "page": page,
      "size": size,
      "searchType" : this.investigationQuery?.searchType,
      "name" : this.investigationQuery?.searchKey,
      "companyId": this.investigationQuery?.companyId,
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getRegistryData(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      if(this.investigationQuery?.companyId && res?.companyData){
        this.tableList.push(res);
        res['totalElements'] = 1;
        res['totalPages'] = 1;
      }
      else{
        this.tableList = res?.['content'] || [];
      }
      this.totalItems = res?.['totalElements'] || 0;
      this.totalPage = res?.['totalPages'] || 0;
      this.initialNumberElement = page * size + 1;
      if (this.initialNumberElement + size > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.initialNumberElement + size - 1;
      }
    });
  }

  pageChange($event) {
    if($event <= 0 || $event > this.totalPage){
      this._data.toastrShow(`${$event <= 0 ? 'Page number cannot be less than 1' : 'You have exceeded the page number'}`, 'warning');
    }
    else{
      this.page = $event;
      this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
      if (this.page * this.rowPageNo > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.page * this.rowPageNo;
      }
      this.getTableData(this.page - 1, this.rowPageNo);
    }
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.page = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getTableData(this.page - 1, this.rowPageNo);
  }

  getCountryObject(country) {
    this._rs.getCountryDetailsByCountry(country).subscribe((res) => this.countryObject = res['data']);
  }

  goToAdversePage() {
    this.route.navigate(['/aml-screening/aml-investigation-results/adverse'], {
      replaceUrl: true,
    });
  }

  goToReport(item?){
    if(item){
      const newSearchQuery = { ...this.investigationQuery, companyId: item?.companyData?.companyNumber };
      sessionStorage.removeItem('amlSearchQuery');
      sessionStorage.setItem('amlSearchQuery', JSON.stringify(newSearchQuery));
    }
    this.route.navigate(['/aml-screening/risk-corporate-report'], {
      replaceUrl: true,
    });
  }

}

import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { AppService } from "../../../services/app.service";
import { BulkUploadService } from "../bulk-upload.service";

@Component({
  selector: "app-edit-single-record",
  templateUrl: "./edit-single-record.component.html",
  styleUrls: ["./edit-single-record.component.scss"],
})
export class EditSingleRecordComponent implements OnInit {

  @Input() record      : any;
  @Output() editRecord = new EventEmitter();
  
  dupeRecord           : any ;
  
  countries            : any;
  selectedCountry      : any;

  constructor(private _app: AppService, private _bulkUp: BulkUploadService) {}

  ngOnInit() {
    this.dupeRecord = Object.assign({}, this.record);
    if(this.dupeRecord && this.dupeRecord["dob"]){
      let dobDate = new Date(this.record.dob) ;
      this.dupeRecord["dob"] = dobDate.toISOString().substring(0,dobDate.toISOString().indexOf("T"));
    }
    this.getAllCountries();
    this.selectedCountry = this.record["kycResidentCountry"];
    // console.log("RECORD: ", this.record);
  }

  //get all countries
  getAllCountries() {
    this._app.getAllCountries().subscribe(res => {
      this.countries = res["data"];
      console.log(res);
    });
  }

  updateRecord() {
    this.dupeRecord["status"] = null;
    this.dupeRecord["validation"] = null;

    console.log(this.dupeRecord);

    this._bulkUp.updateRecord(this.dupeRecord).subscribe(
      res => {
        console.log(res);
        this.editRecord.emit(res.data[0]);
      },
      err => {}
    );
  }
}

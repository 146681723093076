export class AccountRegister {
    client          : Client = new Client();
    clientsUser     : ClientsUser = new ClientsUser();
    sendContactUs   : boolean = false ;
}

export class ClientsUser {
    firstName       : string = "";
    middleName      : string = "";
    lastName        : string = "";
    email           : string = "";
    // password     : string = "" ;
    designation     : string = "";
    contactNumber   : string = "";
}

export class Client {
    clientType      : "ORGANISATION" | "INDIVIDUAL" = null;
    name            : string = "";
    email           : string = "";
    clientAddress   : string = "";
    countryCode     : any = null;
    website         : string = "";
    enquiryComments = "";
    // companyID    : string = "" ;
    contactNumber   : string = "";

    addressLine1    : string = "";
    addressLine2    : string = "";
    city            : string = "";
    state           : string = "";
    postalCode      : string = "";
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RiskScreeningService } from '../../../../src/app/pages/risk-screening/risk-screening.service';

@Component({
  selector: 'app-datasource-listing',
  templateUrl: './datasource-listing.component.html',
  styleUrls: ['./datasource-listing.component.scss', '../../pages/risk-screening/risk-screening.component.scss']
})
export class DatasourceListingComponent implements OnChanges {
  @Input() topData;
  @Input() category;

  constructor(private _rs: RiskScreeningService) {}

  ngOnChanges(changes: SimpleChanges){
  }
}

export const relationKeys: any[] = [
  {
    name: "Family",
    slug: "Family",
    value: null,
    relation: null,
  },
  {
    name: "Close Associates",
    slug: "associates",
    value: null,
    relation: null,
  },
  {
    name: "Organisations & Corporates",
    slug: "org",
    value: null,
    relation: null,
  },
  {
    name: "Person of Significant Control (PSC)",
    slug: "psc",
    value: null,
    relation: null,
  },
  {
    name: "Ultimate Beneficial Owner (UBO)",
    slug: "ubo",
    value: null,
    relation: null,
  },
];

export const graphRelations: any[] = [
  {
    name: "Jurisdiction",
    value: "JURADICTION_OF",
  },
  {
    name: "Details",
    value: "DETAIL_INFO",
  },
  {
    name: "Officer",
    value: "OFFICER_OF",
  },
  {
    name: "PSC",
    value: "PSC_OF",
  },
  {
    name: "Address",
    value: "ADDRESS",
  },
  {
    name: "Category",
    value: "Category",
  },
  {
    name: "SubCategory",
    value: "SubCategory",
  },
  {
    name: "Source",
    value: "From_Source",
  },
  {
    name: "Primary Country",
    value: "Primary_Country_of",
  },
  {
    name: "Allegation Country",
    value: "Allegation_Country_of",
  },
  {
    name: "Son",
    value: "Son",
  },
  {
    name: "Father",
    value: "Father",
  },
  {
    name: "Associate",
    value: "Associate",
  },
];

import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Inject,
} from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { AmlSearchService } from "./../aml-search.service";
import { Output, EventEmitter  } from '@angular/core';
import { forkJoin } from "rxjs";
import { first } from "rxjs/operators";
import { Observable, of } from "rxjs";
// import { TreeModel, NodeEvent } from 'ng2-tree';
//import * as vis from "vis";
import * as jsPDF from "jspdf";
// import html2canvas from "html2canvas";

import * as html2canvas from "html2canvas";
import { async } from "@angular/core/testing";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpErrorResponse } from "@angular/common/http";
// declare var html2canvas: any;

// declare var vis: any;

// import {
//     VisEdges,
//     VisNetworkData,
//     VisNetworkOptions,
//     VisNetworkService,
//     VisNode,
//     VisNodes } from './components/index'

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class DetailsComponent implements OnInit {
  guid: string;
  @Input() amlDetails: any;

  selectedOption: any = "Overview";
  isLvlOneGraphLoaded: boolean = false;
  activeRelations: any[] = [];
  idAMLCheck:any;
  guidAMLSubmit:any;
  leftPanelItems: any[] = [
    {
      name: "Overview",
      icon: "fa-list-alt",
      value: null,
    },
    {
      name: "Summary",
      icon: "fa-info-circle",
      value: null,
    },
    {
      name: "Aliases [Names & DOBs]",
      icon: "fa-user",
      value: null,
    },
    {
      name: "Identifiers",
      icon: "fa-id-card-o",
      value: null,
    },
    {
      name: "Relationships",
      icon: "fa-users",
      // "value" : relationKeys
      value: null,
    },
    {
      name: "Addresses",
      icon: "fa-address-book-o",
      value: null,
    },
    {
      name: "Adverse Media",
      icon: "fa-newspaper-o",
      value: null,
    },
    {
      name: "Data Sources",
      icon: "fa-external-link",
      value: null,
    },
  ];

  loadExternalFile: Promise<any>;

  directLinkAccess: boolean = false;

  //Visjs
  //@ViewChild("vis") element: ElementRef;
  //network: vis.Network;

  nodes: any[] = [];
  edges: any[] = [];
  relations: any[] = [];

  heroNodeId: any;
  subCategory: any;
  asociatedTags: any[] = [
    { "slug":"pep","name":"PEP","value":false, entityType: 'Individual'},
    { "slug":"crime","name":"Crime","value":false, entityType: 'both'},
    { "slug":"sanction","name":"Sanction","value":false, entityType: 'both'},
    { "slug":"watchlist","name":"Watchlist","value":false, entityType: 'both'},
    { "slug":"sip","name":"SIP","value":false, entityType: 'Individual'},
    { "slug":"sie","name":"SIE","value":false, entityType: 'Organisation'},
  ]
  adverseData: any;
  adverseMediaInteliigenceScore: any = 0;
  designations: any[] = [];
  placeOfBirthPlace: any[] = [];

  constructor(
    @Inject("Window") private window: Window,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    private _aml: AmlSearchService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    // this.activatedRoute.params.subscribe(params => {
    //   if (Object.keys(params).length) {
    //     this.guid = params["guid"];
    //     if (!this.amlDetails) {
    //       this.directLinkAccess = true;
    //       this.getDetailedRecord(this.guid);
    //     }
    //   }
    // });
  }

  ngOnInit() {
    this.hideLeftPanel();
    if (!this.directLinkAccess) {
      // this.lvlOneGraphData();
      // this.getGraphFilters();
    }
    // this.amlDetails = AMLResultData.data.content[1] ;
    // console.log(this.amlDetails);
    // console.log("Details",this.amlDetails.subCategory.name);
    // this.subCategory=this.amlDetails.subCategory.name.split("_")
    // if(this.subCategory[1]==undefined){
    //   this.subCategory[1]="";
    // }
    // console.log("ind",this.subCategory)
    // this.loadExternalFile = new Promise((resolve) => {
    //        this.loadFiles();
    //        resolve(true);
    //    });
    if(this.amlDetails.masterData && this.amlDetails.masterData.category.length){
      this.amlDetails.masterData.category.forEach(element => {
        this.asociatedTags.forEach(item => {
          if(element.slug == item.slug){
            item['value'] = true
          }
        })
      })
    }
    if(this.amlDetails.masterData.careerInfo && this.amlDetails.masterData.careerInfo.length>0){
      this.amlDetails.masterData.careerInfo.forEach(element => {
        if(element.designations && element.designations.length>0){
          element.designations.forEach(des => {
            this.designations.push(des)
          });
        }
      });
    }
    if(this.amlDetails.masterData){
      if(this.amlDetails.masterData.placeOfBirth && this.amlDetails.masterData.placeOfBirth.length){
        for(let brthPlace of this.amlDetails.masterData.placeOfBirth){
          this.placeOfBirthPlace.push(brthPlace);
        }
      }
      if(this.amlDetails.masterData.placeOfBirthCity && this.amlDetails.masterData.placeOfBirthCity.length){
        for(let brthCity of this.amlDetails.masterData.placeOfBirthCity){
          this.placeOfBirthPlace.push(brthCity);
        }
      }
      if(this.amlDetails.masterData.placeOfBirthCountry && this.amlDetails.masterData.placeOfBirthCountry.length){
        for(let brthCountry of this.amlDetails.masterData.placeOfBirthCountry){
          this.placeOfBirthPlace.push(brthCountry);
        }
      }
    }
    this.getAdvMedia(this.amlDetails.guid);
  }

  @Output() searchRelation: EventEmitter<string> = new EventEmitter<string>();

  doSearchRelation(name){
    console.log(name);
    this.searchRelation.emit(name);
    }
    getBackground(image) {
      return this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(rgba(29, 29, 29, 0), rgba(16, 16, 23, 0.5)), url(${image})`);
  }
  hideLeftPanel() {
    var leftElem = document.getElementById("wrapper");
    leftElem.classList.add("forced");
    leftElem.classList.add("enlarged");
  }

  // ngAfterViewInit(): void {
  //   const nodes = new vis.DataSet([
  //     { id: 1, label: "Node 1" },
  //     { id: 2, label: "Node 2" },
  //     { id: 3, label: "Node 3" },
  //     { id: 4, label: "Node 4" },
  //     { id: 5, label: "Node 5" },
  //   ]);

  //   const edges = new vis.DataSet([
  //     { from: 1, to: 3 },
  //     { from: 1, to: 2 },
  //     { from: 2, to: 4 },
  //     { from: 2, to: 5 },
  //   ]);

  //   const data = {
  //     nodes: nodes,
  //     edges: edges,
  //   };

  //   const options = {};

  //   this.network = new vis.Network(this.element.nativeElement, data, options);
  // }

  getAdvMedia(id) {
    let payload={'guid':id}
    this._app.getAdvMediaResults(payload).subscribe(
      (res) => {
        if(res && res['data']) {
          this.adverseData = res['data'];
          if(this.adverseData.content.length>0){
            this.adverseMediaInteliigenceScore=this.adverseData.content[0].riskProfile.score;
          }   
        }
      }
    );
  }

  getRelationLvlGraph(rel) {
    console.log(rel);
    let dataQuery = {
      id: this.heroNodeId ? this.heroNodeId : null,
      relation: rel,
    };
    this.nodes = [];
    this.edges = [];
    this._aml.getNodeRelation(dataQuery).subscribe(response => {
      let nodes = response.nodes;
      let edges = response.links;
      if (nodes && nodes.length && edges && edges.length) {
        for (var i = 0; i < nodes.length; i++) {
          nodes[i].shape = "circularImage";
          nodes[i].id = nodes[i].nodeId;
          nodes[i].image =
            "https://www.thetaranights.com/wp-content/uploads/2018/fiverr_reviews/Benf97/cropped(1).png";
          nodes[i].title = nodes[i].description;
          nodes[i].label = ` ${nodes[i].fullName ||
            nodes[i].name ||
            nodes[i].companyName}`;
          this._aml.pushToArray(this.nodes, nodes[i]);
        }
        for (var i = 0; i < edges.length; i++) {
          edges[i].from = edges[i].source;
          edges[i].to = edges[i].target;
        }
        // this.nodes = nodes;
        this.edges = edges;
        console.log(response);
      }
    });
  }

  getGraphFilters() {
    let dataQuery = {
      name: this.amlDetails.fullName,
      guid: this.amlDetails.guid,
    };
    this._aml.getGraphFilters(dataQuery).subscribe(response => {
      this.leftPanelItems.filter(
        obj => obj.name == "Relationships"
      )[0].value = response;
      // this.activeRelations = response;
      console.log(response);
    });
  }

  lvlOneGraphData() {
    let dataQuery = {
      name: this.amlDetails.fullName,
      guid: this.amlDetails.guid,
    };
    this._aml.lvlOneGraphData(dataQuery).subscribe(response => {
      let nodes = response.nodes;
      let edges = response.links;
      if (nodes && nodes.length && edges && edges.length) {
        this.isLvlOneGraphLoaded = true;
        for (var i = 0; i < nodes.length; i++) {
          nodes[i].shape = "circularImage";
          nodes[i].id = nodes[i].nodeId;
          nodes[i].image =
            "https://www.thetaranights.com/wp-content/uploads/2018/fiverr_reviews/Benf97/cropped(1).png";
          nodes[i].title = nodes[i].description;
          nodes[i].label = ` ${nodes[i].fullName ||
            nodes[i].name ||
            nodes[i].companyName}`;
          this._aml.pushToArray(this.nodes, nodes[i]);
        }
        var nodeId = response.nodes.findIndex(e => {
          return e.guid === this.amlDetails.guid;
        });
        console.log('node : ', response.nodes[nodeId]);
        // console.log(this._aml.pushToEdge(this.nodes,response.links, response.nodes[nodeId].nodeId));
        var data : any  = this._aml.pushToEdge(this.nodes,response.links, response.nodes[nodeId].nodeId);
        edges = data.final;
        console.log(data);
        this.relations = data.relations;

        console.log(edges);
        for (var i = 0; i < edges.length; i++) {
          edges[i].from = edges[i].source;
          edges[i].to = edges[i].target;
        }
        console.log('index of node : ',this.nodes)
        this.leftPanelItems.filter(obj => obj.name == "Relationships")[0].value = (this.nodes.length) ? 1 : null;
        // this.nodes = nodes;
        this.edges = edges;
        // this.heroNodeId = edges[0].from;
        console.log(response);
      }
    });
  }

  // loadFiles(){
  // 	var isFound = false;
  //     var scripts = document.getElementsByTagName("script")
  //     for (var i = 0; i < scripts.length; ++i) {
  //         if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("vis")) {
  //             isFound = true;
  //         }
  //     }

  //     if (!isFound) {
  //         var dynamicScripts = ["https://cdnjs.cloudflare.com/ajax/libs/vis/4.21.0/vis.min.js"];
  //         for (var i = 0; i < dynamicScripts .length; i++) {
  //             let node = document.createElement('script');
  //             node.src = dynamicScripts [i];
  //             node.type = 'text/javascript';
  //             node.async = false;
  //             node.charset = 'utf-8';
  //             document.getElementsByTagName('head')[0].appendChild(node);
  //         }
  //     }
  // }

  changeSelectedItem(item) {
    this.selectedOption = item;
    // let block = option == 'profile' ? "start": "end" ;
    var elem = document.getElementById(item);
    elem.scrollIntoView({
      behavior: "smooth",
      block: item == "profile" ? "start" : "end",
    });
  }

  // getDetailedRecord(guid) {
  //   this._data.changeLoaderVisibility(true);
  //   this._aml.getDetailedRecord(guid).subscribe(res => {
  //     this._data.changeLoaderVisibility(false);
  //     this.amlDetails = res.data.content[0];
  //     this.lvlOneGraphData();
  //     this.getGraphFilters();
  //     this.getDocumentImage();
  //    // console.log("AML DETAILS: ", this.amlDetails);
  //     // setTimeout(()=>{
  //     // 	this.doGraphThing()	;
  //     // },2000)
  //   });
  // }

  async getDocumentImage() {
    if (
      this.amlDetails &&
      this.amlDetails.identifiers &&
      this.amlDetails.identifiers.length
    ) {
      for (var i = 0; i < this.amlDetails.identifiers.length; i++) {
        let country = this.amlDetails.identifiers[i].country.name;
        let identifierType = this.amlDetails.identifiers[i].identifierType;
        let documentImage = (await this._app
          .getAllowedDocs(country)
          .pipe(first())
          .toPromise()).data;
        let allowedIdDocs = documentImage.validIdentityDocs;
        let isDocPresent = allowedIdDocs.filter(
          obj => obj.docName.toLowerCase() == identifierType.toLowerCase()
        );
        if (isDocPresent && isDocPresent.length) {
          this.amlDetails.identifiers[i].documentImage =
            isDocPresent[0].docImageUrl;
        } else {
          this.amlDetails.identifiers[i].documentImage = null;
        }
      }
    }
  }

  download() {
    const div = document.getElementById("detailCont");
    const options = {
      height: div.clientHeight,
      width: div.clientWidth,
    };

    console.log(options);

    html2canvas(div, options).then(canvas => {
      console.log("from html ");
      //Initialize JSPDF
      let doc = new jsPDF("p", "mm", "a4");

      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();

      //Converting canvas to Image
      let imgData = canvas.toDataURL("image/PNG");
      //Add image Canvas to PDF
      doc.addImage(imgData, "PNG", 0, 0, width, height);

      let pdfOutput = doc.output();
      // using ArrayBuffer will allow you to put image inside PDF
      let buffer = new ArrayBuffer(pdfOutput.length);
      let array = new Uint8Array(buffer);
      for (let i = 0; i < pdfOutput.length; i++) {
        array[i] = pdfOutput.charCodeAt(i);
      }

      //Name of pdf
      const fileName = `${this.amlDetails.fullName}.pdf`;

      // Make file
      doc.save(fileName);
    });
  }
  removeDot(d){
    if(d){
      if(d.includes('.')){
        return d;
      }else{
        return d + '.';
      }
    }else{
      return d;
    }
  }
  
  saveAMLSearchData(){
    // console.log(this.amlDetails.details[0]);
    let data = this.amlDetails.details[0];
    let dataQuery={};
    if(data.entityType.toUpperCase() === 'INDIVIDUAL'){
    dataQuery={
        firstName: data.firstName,
        lastName:data.lastName,
        identityInfo:"",
        dob:"",
        mobileNumber:"",
        countryCode:"",
        identityInfoType:"NAME",
        remoteChecks:null,
        dashboardChecks:["sanctions-check","crime-check","pep-check"],
        endUserType: data.entityType.toUpperCase()
      };
    }else if(data.entityType.toUpperCase() === 'ORGANISATION'){
      dataQuery={
          companyName: data.fullName,
          identityInfoType:"NAME",
          remoteChecks:null,
          dashboardChecks:["sanctions-check","crime-check"],
          endUserType: "CORPORATE"
        };
      }
    // console.log(dataQuery);
    
    this._aml.saveAMLSearchData(dataQuery).subscribe(
      response =>{
        console.log(response);
        console.log(response.data.checks.endUser.identityInfo);
        this.idAMLCheck = response.data.checks.id;
        this.guidAMLSubmit = response.data.checks.endUser.identityInfo;
        this.submitAMLSearchData(data.guid,this.idAMLCheck)
        // this._data.toastrShow("Aml search result saved successfully",'success')
      },
      err => {
        console.log(err)
      }
    );
    console.log(this.guidAMLSubmit,this.idAMLCheck);
   
    
  }   
  submitAMLSearchData(guidAMLSubmit,idAMLCheck){
    let data = this.amlDetails.details[0];
    let dataSubmitQuery = {}
    if(data.category.name.toUpperCase() === 'PEP'){
      dataSubmitQuery = {
        pepSourceGUIDs:[guidAMLSubmit],
        sanctionSourceGUIDs: [],
        crimeSourceGUIDs: [],
        gotAMLSearchResults: true
      }
    }else if(data.category.name.toUpperCase() === 'SANCTION'){
      dataSubmitQuery = {
        pepSourceGUIDs:[],
        sanctionSourceGUIDs: [guidAMLSubmit],
        crimeSourceGUIDs: [],
        gotAMLSearchResults: true
      }
    }else if(data.category.name.toUpperCase() === 'CRIME'){
      dataSubmitQuery = {
        pepSourceGUIDs:[],
        sanctionSourceGUIDs: [],
        crimeSourceGUIDs: [guidAMLSubmit],
        gotAMLSearchResults: true
      }
    }else{
      dataSubmitQuery = {
          pepSourceGUIDs:[],
          sanctionSourceGUIDs: [],
          crimeSourceGUIDs: [],
          gotAMLSearchResults: false
        }
    }
    
    this._aml.submitAMLSearchData(dataSubmitQuery,idAMLCheck).subscribe(
      response =>{
        console.log(response);
        this._data.toastrShow("Aml search result saved successfully",'success')
      },
      err => {
        console.log(err)
      }
    );
  } 

  // akash : save and exit API
  saveAMLData(){
    let dataQuery={
      amlGUIDs:this.amlDetails.guid,
      identityInfoType: "NAME",
      dashboardChecks: [(this.amlDetails.category.name).toLowerCase()+"-check"],
      endUserType:  (this.amlDetails.entityType =='Organisation') ? "CORPORATE":this.amlDetails.entityType.toUpperCase() 
    };
    this._aml.saveAMLData(dataQuery).subscribe(
      response =>{
        console.log(response);
        this._data.toastrShow("Aml search result saved successfully",'success')
      },
      err => {
        console.log(err)
      }
    );
  }  
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientListComponent } from './client-list/client-list.component';
import { UserAreaComponent } from './user-area/user-area.component';
import { UserReportComponent } from './user-report/user-report.component';
import { VideoKycComponent } from './video-kyc/video-kyc.component';
import { VideoKycClientDetailsComponent } from './video-kyc-client-details/video-kyc-client-details.component';
import { VideoKycApplicationDetailsComponent } from './video-kyc-application-details/video-kyc-application-details.component';

const routes: Routes = [
  { path: 'list', component: ClientListComponent },
  { path: 'user-area/:id', component: UserAreaComponent },
  { path: 'user-report/:name', component: ClientListComponent },
  { path: 'video-kyc', component: VideoKycComponent },
  { path: 'video-kyc/:clientId', component: VideoKycClientDetailsComponent },
  { path: 'video-kyc/:clientId/details', component: VideoKycApplicationDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DevOpsRoutingModule { }

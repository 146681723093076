import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { CorporateCheckRoutingModule } from './corporate-check-routing.module';
import { CorporateCheckComponent } from './corporate-check.component';
import { ReportComponent } from './report/report.component';
// import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChartsModule } from "ng2-charts";
import { GaugeChartModule } from 'angular-gauge-chart';
import { ComponentModuleModule } from '../../components/component-module/component-module.module';
@NgModule({
  declarations: [CorporateCheckComponent, ReportComponent],
  imports: [
    CommonModule,
    CorporateCheckRoutingModule,
    FormsModule,
    ChartsModule,
    GaugeChartModule,
    //   NgCircleProgressModule.forRoot({
    //     backgroundGradient: true,
    //     backgroundColor: '#ffffff',
    //     backgroundGradientStopColor: '#c0c0c0',
    //     backgroundPadding: -10,
    //     radius: 60,
    //     maxPercent: 100,
    //     outerStrokeWidth: 10,
    //     outerStrokeColor: '#61A9DC',
    //     innerStrokeWidth: 0,
    //     subtitleColor: '#444444',
    //     showBackground: false,
    // }),
    ComponentModuleModule
  ],
  exports: [
    ReportComponent
  ]
})
export class CorporateCheckModule { }

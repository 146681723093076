import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AppService, DataService } from '../../../services';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-passport-validations',
  templateUrl: './passport-validations.component.html',
  styleUrls: ['./passport-validations.component.scss']
})
export class PassportValidationsComponent implements OnChanges {
  @Input() traversalState: any;
  @Input() usersCheckConfig: any;
  @Output() checkState = new EventEmitter<any>();
  isPassportValidate: boolean = false;
  passportForm: FormGroup = this.form.group({
    passportNo: ['', Validators.required],
    number: ['', Validators.required],
    dob: ['', Validators.required],
  });
  todayDate: any;
  passportGroupData: any;

  constructor(private form: FormBuilder, private _app: AppService, private _data: DataService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.traversalState?.currentValue){
      this.traversalState = changes?.traversalState?.currentValue;
    }
    const passportData = this.usersCheckConfig.filter(obj => obj.standardCheckGroup == 'nid-passport-validation');
    if(passportData?.length) this.passportGroupData = passportData[0];
  }
  
  get passportValidate(): { [key: string]: AbstractControl } {
    return this.passportForm.controls;
  }

  stepComplete() {
    this.isPassportValidate = true;
    if(this.passportForm.valid){
      const dataReform = new DatePipe('en-US').transform(this.passportForm.value.dob, 'dd/MM/yyyy')
      let payload={
        "type": "passport",
        "number" : this.passportForm.value.number,
        "dob": dataReform,
        "passport_no": this.passportForm.value.passportNo,
      }
      this._data.changeLoaderVisibility(true)
      this._app.nidPassportValidations(payload, this.passportGroupData['endUserCheckID']).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res=>{
        const msg = [4003, 4004].includes(res?.data?.statusCode) ? 'error' : [2031].includes(res?.data?.statusCode) ? 'success' : 'info';
        this._data.toastrShow(res.data.message, msg);
        this.goToStep('next');
      })
    }
  }

  goToStep(stepValue) {
    let state = this.traversalState.filter(obj => obj.standardCheckGroup == 'nid-passport-validation');
    if (state.length) {
      if (!state[0].isFinalStep) {
        let traversalState = {
          "allowedState": state[0],
          "goToStep": stepValue
        }
        this.checkState.emit(traversalState);
      } else {
        {
          let traversalState = {
            "allowedState": state[0],
            "goToStep": "isFinalStep"
          }
          this.checkState.emit(traversalState);
        }
      }
    }
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss','../../../risk-screening.component.scss']
})
export class InfoCardComponent implements OnInit {
  @Input() data;
  @Output() close = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  goToTabs(event: MouseEvent){
    event.preventDefault();
    this.close.emit('fincai-analytics-data');
  }

}
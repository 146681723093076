import { Component, OnInit,Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { DevOpsService } from '../../pages/dev-ops/services/dev-ops.service';

@Component({
  selector: 'app-devops-override-ocr',
  templateUrl: './devops-override-ocr.component.html',
  styleUrls: ['./devops-override-ocr.component.scss']
})
export class DevopsOverrideOcrComponent implements OnInit {
 @Input() clientId;
 @Input() data;
  overrideForm:any ={}
  constructor(public _data: DataService,public _devops:DevOpsService) { }

  ngOnInit(): void {
    console.log('id modal',this.clientId)
  }
  handleOverrideOCR(){
    this._data.changeLoaderVisibility(true)
    try {
      this._devops.overrideVideoKycOCR(this.data.id,this.overrideForm).toPromise()
      this._data.toastrShow("OCR overridden successfully", "sucess");
      this._data.changeLoaderVisibility(false)
    } catch (error) {
      this._data.changeLoaderVisibility(false)
      this._data.toastrShow("Something went wrong!", "error");
    }
  }
}

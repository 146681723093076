export class CurrentState {
	standardCheckGroup: string;
	endUserCheckID: string;
	state: string;
}

export class State {
	standardCheckGroup: any;
	stepNo: any;
	next: any;
	previous: any;
	isFinalStep: any;
}

import { Component, OnInit, Input, ViewChildren, ViewChild, ElementRef, HostListener, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService, AppService, TrackingService } from "../../../services";
import { } from "../../../services/app.service";
import { WizardComponent } from 'angular-archwizard';
import { first } from 'rxjs/operators';
import { forkJoin, Observable, of } from "rxjs";

@Component({
	selector: 'app-kyc-check',
	templateUrl: './kyc-check.component.html',
	styleUrls: ['./kyc-check.component.scss']
})
export class KycCheckComponent implements OnInit {

	@Input() usersCheckConfig: any;
	@Input() checksDataByHashId: any;
	@Input() basicUserData: any;
	@Input() hashId: any;
	@Input() category: any;
	@Input() kycOriginCountry: any;
	@Input() showRemoteLinkModal: any;
	kycChecks: any = {
		"id-checks": [],
		"id-checks-2": [],
		"address-checks": [],
		"ocr-check": [],
		"corporate-checks": [],
		"aml-checks-2": [],
		"nid-okyc-check": [],
		"nid-pan-fetch": [],
		"nid-passport-validation": []
	}

	showWhichCheck: string = null;
	allowedDocs: any;

	// currentState			 : any = {
	// 	"id-checks"     : new State(),
	// 	"address-checks": new State(),
	// 	"aml-checks-2"    : new State()
	// }

	currentState: State[] = [];

	@ViewChildren("traverseBtn")
	traverseBtns: ElementRef;

	@ViewChild(WizardComponent)
	public wizard: WizardComponent;

	//param for checking for final step 
	loadPreviewData: boolean = false;

	//for aml only
	selectAMLResult: any;
	selectAdverseResult: any;
	ip: any;
	deviceInfo: any;
	checkState: any;
	routerUrl: string = '/user-home'
	// shouldCallAdverseAPI: boolean = false ;

	@HostListener('window:beforeunload', ['$event'])
	doSomething($event) {
		console.log($event);
		console.log("END OF ALL IN KYC CHECK");
	}

	constructor(
		private _data: DataService,
		public router: Router,
		private _track: TrackingService,
		private _app: AppService) {
	}

	ngOnInit() {
		if (this.category != 'edit-individual') {
			let userConfigArr = [];
			for(let item of this.usersCheckConfig){
				if(item?.standardCheckGroup == 'aml-checks-2'){
					if(this.checksDataByHashId?.manualAmlCheck){
						userConfigArr?.push(item)
					}
				}
				else{
					if(item?.isRemoteCheck == false){
						userConfigArr?.push(item)
					}
				}
				if(item?.standardCheckGroup == 'video-kyc-check'){
					this.routerUrl = '/video-kyc-client/dashboard'
				}
			}
			this.usersCheckConfig = JSON.parse(JSON.stringify(userConfigArr));
		}

		this.getAllowedDocs();
		for (var key in this.kycChecks) {
			let check = this.usersCheckConfig.filter(obj => obj.standardCheckGroup == key);
			if (check.length) {
				this.kycChecks[key] = check;
			}
		}

		this.setCurrentState();

	}

	setCurrentState() {
		console.log("usersCheckConfig");
		console.log(this.usersCheckConfig);

		console.log("kycChecks");
		console.log(this.kycChecks);

		for (let key in this.kycChecks) {
			let check = this.usersCheckConfig.filter(obj => obj.standardCheckGroup == key);
			console.log("kycChecks", check);
			if (check.length) {
				const checkState: State = {
					"standardCheckGroup": null,
					"stepNo": null,
					"next": null,
					"previous": null,
					"isFinalStep": null
				};
				if (this.currentState.length) {
					this.currentState[this.currentState.length - 1].next = true;
					this.currentState[this.currentState.length - 1].isFinalStep = false;
					checkState.standardCheckGroup = key;
					checkState.stepNo = this.currentState[this.currentState.length - 1].stepNo + 1;
					checkState.next = null;
					checkState.previous = true;
					checkState.isFinalStep = true;
				}
				else {
					checkState.standardCheckGroup = key;
					checkState.stepNo = 1;
					checkState.next = null;
					checkState.previous = null;
					checkState.isFinalStep = true;
				}
				// console.log(JSON.stringify(checkState));
				this.currentState.push(checkState);
			}
			// console.log(JSON.stringify(this.currentState));
		}

		// console.log("currentState");
		console.log(this.currentState);
	}

	getAllowedDocs() {
		// this._data.changeLoaderVisibility(true);
		let kycCountry = this.kycOriginCountry;
		if (kycCountry) {
			this._data.changeLoaderVisibility(true);
			this._app.getAllowedDocs(kycCountry)
				.subscribe(
					(res) => {
						this._data.changeLoaderVisibility(false);
						this.allowedDocs = res.data;
					},
					(err) => {
						console.log("got error , empty country");
						this._data.changeLoaderVisibility(false);
					}
				);
		}
	}

	updateCheckState($event) {
		this.checkState = $event?.allowedState?.standardCheckGroup;
		let standardCheckGroup = $event.allowedState.standardCheckGroup;
		let goToStep = $event.goToStep;
		let elems = this.traverseBtns["_results"];
		for (var i = 0; i < elems.length; i++) {
			let el: HTMLElement = elems[i].nativeElement as HTMLElement;
			if (el.getAttribute('data-check') == standardCheckGroup) {
				if (el.hasAttribute(`data-${goToStep}`) && el.getAttribute(`data-${goToStep}`)) {
					el.click();
					// if(el.getAttribute(`data-${goToStep}`) == $event.allowedState[goToStep]){
					// 	el.click();
					// }
				}
			}
		}
		// if($event && $event.allowedState && $event.allowedState.isFinalStep){
		// 	this.finalSubmit();
		// }
	}

	finalSubmit() {
		if (['nid-okyc-check', 'nid-pan-fetch', 'nid-passport-validation'].includes(this.checkState)) {
			setTimeout(() => {
				this.submitApiCall();
			}, 800);
		}
		else {
			this.submitApiCall();
		}
	}

	submitApiCall() {
		this._data.changeLoaderVisibility(true)
		this._app.finalSubmit(this.hashId)
			.subscribe(
				async (res) => {
					this._data.changeLoaderVisibility(false)
					console.log(res);
					await this._track.activityLog();
					this._data.toastrShow("Your KYC process has been submitted successfully!", "info");
					if (!this.showRemoteLinkModal) {
						if (this._data.corporateId.length > 1 && this._data.corporateId[0] != this._data.corporateId[this._data.corporateId.length - 1]) {
							console.log("route to corporate check", this._data.corporateId[0])
							// this.router.navigate([`/live-check/individual/${this._data.corporateId[0]}`]);
							this._data.clearStoreId();
							this.router.navigate(['/user-home']);
						} else {
							console.log("Before", this._data.corporateId)
							this._data.clearStoreId();
							console.log("After", this._data.corporateId)
							this.router.navigate(['/user-home']);
						}

					}
					else if (this.showRemoteLinkModal) {
						// this.openRemoteLinkModal();
						this.router.navigate(['/user-home']);
					}
				},
				(err) => {
					console.log(err);
					if (err && err.status && err.status == 307) {
						this.router.navigate(['/user-home']);
						this._data.changeLoaderVisibility(false)
					}
				}
			);
	}

	// openRemoteLinkModal(){
	// 	const initialState = {
	//              title   : `Send Remote Request`,
	//              type    : "confirmation-modal" ,
	//              heroText    : `You have selected remote checks for User, Do you want to send request now or later`
	//        };
	//        this.bsModalRef = this.modalService.show(KycModalComponent, {
	//              initialState,
	//              class: "modal-lg remoteCheckConfirmationModal"
	//        });
	//        this.bsModalRef.content.closeBtnName = "Close";
	//        this.bsModalRef.content.onClose.subscribe(sendNow => {
	//              console.log("results", sendNow);
	//              if(Object.keys(sendNow).length && sendNow.sendNow){
	//              	this._app.sendRemoteLink(this.hashId)
	// 			.subscribe(
	// 				(res)=>{
	// 					console.log(res);
	// 					this._data.toastrShow("Remote Kyc link has been sent" , "info");
	// 					this.router.navigate(['/user-home']);
	// 				})
	//              }
	//        });
	// }

	saveAndSubmitAMLResults() {
		console.log(this.selectAMLResult);
		if (!this.selectAMLResult) {
			this.selectAMLResult = {
				"pepSourceGUIDs": [],
				"sanctionSourceGUIDs": [],
				"crimeSourceGUIDs": []
			}
		}

		if (!this.selectAdverseResult) {
			//Means no Search Done , no API call , no Credit Deduction
			this.selectAdverseResult = {
				"checkLinkGUID": this.hashId,
				"gotAdverseSearchResults": false,
				"adverseMediaSource": "GOOGLE",
				"adverseMediaItems": []
			};
		}

		// if(!this.shouldCallAdverseAPI){
		// 	let saveAMLData$ = this._app.saveAndSubmitAMLResults(this.hashId , this.selectAMLResult) ;
		// 	forkJoin([saveAMLData$]).subscribe(
		// 		(res)=>{
		// 			this.finalSubmit();
		// 		}
		// 	);
		// }
		// else{

		if (this.selectAdverseResult && this.selectAdverseResult.gotAdverseSearchResults && !this.selectAdverseResult.adverseMediaItems.length) {
			if (confirm("You have search results for Adverse Media, but you have selected none . Do you want to save & proceed without selecting any result?") == false) {
				return;
			}
		}

		if (this.selectAMLResult
			&& this.selectAMLResult.gotAMLSearchResults
			&& !this.selectAMLResult.pepSourceGUIDs.length
			&& !this.selectAMLResult.sanctionSourceGUIDs.length
			&& !this.selectAMLResult.crimeSourceGUIDs.length
		) {
			if (confirm("You have search results for AML , but you have selected none . Do you want to save & proceed without selecting any result?") == false) {
				return;
			}
		}

		console.log("API CALLED!!");
		console.log(this.selectAdverseResult);
		console.log(this.selectAMLResult);
		let mappedArray = [];
		if(this.selectAMLResult?.pep?.length){
			for(let item of this.selectAMLResult?.pep){
				mappedArray.push(item);
			}
			delete this.selectAMLResult?.pep;
		}
		if(this.selectAMLResult?.sanction?.length){
			for(let item of this.selectAMLResult?.sanction){
				mappedArray.push(item);
			}
			delete this.selectAMLResult?.sanction;
		}
		if(this.selectAMLResult?.crime?.length){
			for(let item of this.selectAMLResult?.crime){
				mappedArray.push(item);
			}
			delete this.selectAMLResult?.crime;
		}
		this.selectAMLResult['amlData'] = mappedArray
		// let saveAMLData$ = this._app.saveAndSubmitAMLResults(this.hashId, this.selectAMLResult);
		// let adverseData$ = this.selectAdverseResult ? this._app.saveAdverseResults(this.selectAdverseResult) : of(null);
		// forkJoin([saveAMLData$, adverseData$]).subscribe(
		// 	(res) => {
		// 		// this.finalSubmit();
		// 	}
		// );

		// }

		this._app.saveAndSubmitAMLResults(this.hashId , this.selectAMLResult)
		.subscribe(
			(res)=>{
				this.finalSubmit();
			}
		);
	}

	onGettingAMLResult(event) {
		console.log("kyc check");
		console.log(event);
		this.selectAMLResult = event;
		for (var key in this.selectAMLResult) {
			if (key !== 'amlComment' && key !== 'gotAMLSearchResults') {
				this.selectAMLResult[key] = Array.from(new Set(this.selectAMLResult[key]));
			}
		}
		this.selectAMLResult.gotAMLSearchResults = event["gotAMLSearchResults"];
		console.log(this.selectAMLResult);
	}

	onGettingAdverseResult(event) {
		if (event && event["hasDoneAdverseSearch"]) {
			var obj = {
				"checkLinkGUID": this.hashId,
				"adverseMediaSource": "GOOGLE",
				"gotAdverseSearchResults": event["gotAdverseSearchResults"],
				"adverseMediaItems": event["adverseMediaItems"]
			}
			this.selectAdverseResult = obj;
			// this.shouldCallAdverseAPI = true;
		}
		console.log(this.selectAdverseResult);
	}

	goToPreview() {
		this.wizard.goToNextStep()
		console.log("Came to Preview");
	}

	traversePreviewStep($event, isEntering: boolean) {
		if (isEntering) {
			this.loadPreviewData = true;
			(<HTMLElement>document.querySelector("aw-wizard#kyc-checks-flow-wizard aw-wizard-navigation-bar")).style.display = "none"
		}
		else {
			this.loadPreviewData = false;
			(<HTMLElement>document.querySelector("aw-wizard#kyc-checks-flow-wizard aw-wizard-navigation-bar")).style.display = "block"
		}
	}

}

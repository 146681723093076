export class Schedule {
  frequency: string;
  startDate: any;
  selectedItems: any;
  constructor() {
    this.frequency = "DAILY";
    this.startDate = null;
    this.selectedItems = [];
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";
import { first } from "rxjs/operators";
@Component({
  selector: "app-alert-schedule",
  templateUrl: "./alert-schedule.component.html",
  styleUrls: ["./alert-schedule.component.scss"],
})
export class AlertScheduleComponent implements OnInit {
  @Input() type: "set-aml-alert" | "schedule-check";
  @Input() userType: any;
  @Input() endUserInfo: any;
  @Output() newSchedule = new EventEmitter();

  frequency: any[] = ["DAILY", "WEEKLY", "MONTHLY", "YEARLY", "CUSTOM_DATE"];

  //vars for schedule-check
  scheduleObj: Schedule = new Schedule();
  standardChecks: any;

  //loading
  isApiLoading: boolean = false;
  isHeroAPILoaded: boolean = false;

  //dropdown vars
  dropdownSettings: any = {};
  checkList: any = [];

  //users previously set alerts/checks
  usersAlerts: any = [];
  lastScheduledDate: any = null;

  //Pagination
  currentPage: number = 0;
  itemsPerPage: number = 10;
  totalItems: number;
  maxSize: number;
  sort: string = null;

  buildQuery: any = {
    size: this.itemsPerPage,
    page: this.currentPage,
  };

  /// credits charged var
  creditsCharged: any = null;

  constructor(
    private _data: DataService,
    private _app: AppService,
    public router: Router
  ) {}

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "slug",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
    this.getUsersScheduledAlerts();
    this.getStandardScheduledAlerts();
    // this.scheduleObj["frequency"] = 'DAILY' ;
    // console.log(this.type);
    // console.log(this.userInfo);
    // console.log(this.userType);
    // console.log(this.endUserInfo);
  }

  getUsersScheduledAlerts() {
    console.log(this.endUserInfo);
    this.isHeroAPILoaded = false;
    this._app
      .getUsersScheduledAlerts(this.endUserInfo["userName"], this.buildQuery)
      .subscribe(res => {
        this.usersAlerts = res.data.content;
        if (this.usersAlerts && this.usersAlerts.length) {
          console.log(this.usersAlerts[0]);
          this.lastScheduledDate = this.usersAlerts[0].lastCheckDate
            ? this.usersAlerts[0].lastCheckDate
            : this.usersAlerts[0].createdDate;
          // this.endUserInfo =  this.usersAlerts[0].endUser ;
          // this.scheduleObj.frequency = this.usersAlerts[0].intervalType;
          // let startDate = new Date(this.usersAlerts[0].startDate) ;
          // this.scheduleObj.startDate =  startDate.toISOString().substring(0,startDate.toISOString().indexOf("T")) ;
        }
        console.log(this.scheduleObj);
        this.isHeroAPILoaded = true;
      });
  }

  getStandardScheduledAlerts() {
    this._app.getStandardScheduledAlerts().subscribe(res => {
      let standardChecks = res.data;
      console.log("checks",standardChecks)
      if (this.userType == "CORPORATE") {
        this.standardChecks = { "aml-alerts": null };
        this.standardChecks["aml-alerts"] = standardChecks["aml-alerts"].filter(
          obj => obj.slug != "pep-alert"
        );
        // this.standardChecks["idv-checks"]=standardChecks["idv-checks"];
        this.standardChecks["corporate-alerts"]=standardChecks["corporate-alerts"];
      } else {
         this.standardChecks = standardChecks;
         this.standardChecks["corporate-alerts"]=[]
      }
      console.log("list",this.standardChecks);
      this.dropDownDataCreator();
    });
  }

  dropDownDataCreator() {
    for (var key in this.standardChecks) {
      for (var i = 0; i < this.standardChecks[key].length; i++) {
        let checkList = {
          slug: null,
          name: null,
        };
        checkList.slug = this.standardChecks[key][i].slug;
        checkList.name = this.standardChecks[key][i].name;
        this.checkList.push(checkList);
      }
    }
  }

  onItemSelect(item: any) {
    console.log("A");
    // console.log(this.scheduleObj.selectedItems);
    // let slugArr = this.scheduleObj.selectedItems.map(obj=>obj.slug);
    // for(var key in this.standardChecks){
    // 	for(var i = 0 ; i < this.standardChecks[key].length ; i++){

    // 	}
    // }
    // .reduce((acc, item) => acc+= item, 0) ;
    // console.log(this.creditsCharged);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  saveScheduleChecks() {
    if (!this.scheduleObj.startDate) {
      this._data.toastrShow("Start Date is Required", "error");
      return;
    } else {
      var modifiedStartDate = this._data.getNormalizedDate(
        this.scheduleObj.startDate
      );
    }

    console.log(modifiedStartDate);

    let scheduleCheckSlugs = this.scheduleObj.selectedItems.map(
      obj => obj.slug
    );

    let data = {
      identifierInfo: this.endUserInfo["identityInfo"],
      scheduleCheckSlugs: scheduleCheckSlugs,
      scheduledCheck: {
        intervalType: this.scheduleObj["frequency"],
        startDate: modifiedStartDate,
      },
    };
    this._app.scheduleChecksForUser(data).subscribe(res => {
      console.log(res);
      this.newSchedule.emit({ isCheckScheduled: true });
      this._data.toastrShow("Checks Scheduled Successfully!", "info");
    });
  }
}

import { Injectable } from '@angular/core';
// import { AngularFireDatabase } from '@angular/fire/database';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import firebase  from 'firebase/compat/app';
import { DataService } from "../services/data.service";
import { NotificationService } from "../services/notification.service";

// importScripts("https://www.gstatic.com/firebasejs/10.4.0/firebase-app.js") 
// importScripts("https://www.gstatic.com/firebasejs/10.4.0/firebase-analytics.js")

// import { mergeMapTo } from 'rxjs/operators';
// import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class MessagingService {
  currentMessage$ = new BehaviorSubject(null);
  messaging =getMessaging();
  // messaging:any;

  constructor(
    private _data: DataService,
    private _noti: NotificationService,
    ) {
  }

  requestPermission(){
    getToken(this.messaging, { vapidKey: 'BCzvICzHJdqNoWrMpmyqgeGcfZvdr1QELQrkX6YBpAnqrFv2r2Wo12YIiqdzwuB4s8LuVaG2cRVY6gsN4A48whc' })
    .then((currentToken) => {
      // this._data.setCookieByName('fb_token', currentToken);
      if (currentToken) {
        console.log("fb token received:",currentToken);
          let firebaseToken = this._data.getCookiesByName('fb_token');
          if(!firebaseToken || firebaseToken!=currentToken){
              this.updateToken(currentToken);
          }
         this.receiveMessage();
      } else {
          console.log('Notification permission granted.');
          // this.requestPermission();
      }
    }).catch((err) => {
      console.log('Unable to get permission to notify.', err);
    });
  }

  

  updateToken(firebaseToken) {
    let data={'token':firebaseToken}
        this._noti.registerDeviceId(data).subscribe((response)=>{
            console.log("register api data",response)
            this._data.setCookieByName('fb_token', firebaseToken);
        }, error=>{
            // console.log(error);
        });
    }

  receiveMessage() {
    // const messaging = getMessaging();
    onMessage(this.messaging, (payload) => {
      console.log("getting here",payload);
      // this._noti.setNotification({
      //   body: payload?.data,
      //   title: payload?.data?.title,
      //   isVisible: true,
      //   position:"bottom-right",
      //   duration:5000
      // })
      // this._data.toastrShow(payload?.data?.body, "info")
      this.currentMessage$.next(payload)
    });
  }

  getCurrentMessage() {
    return this.currentMessage$.asObservable();
  }

  unSubscribeCurrentMessage(){
    this.currentMessage$.unsubscribe();
  }
}
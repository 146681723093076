export interface IRequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  // params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: "arraybuffer" | "blob" | "json" | "text";
  withCredentials?: boolean;
}

import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpHeaders
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { DataService } from "../services/data.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AppService {
  token: any;
  public themeChanged = new BehaviorSubject<string>(localStorage.getItem('currentTheme'));
  currentTheme = this.themeChanged.asObservable();
  constructor(
    private http: HttpClient,
    private _data: DataService,
    @Inject("API_URL") public API_URL: any
  ) {
    this.token = this._data.getCookiesByName("token");
    console.log(this.token);
  }

  setCurrentTheme(theme) {
    this.themeChanged.next(theme)
  }

  //Get i.e get Requests
  getAllCountries() {
    return this.http.get<any>(`${this.API_URL}/country/all `).pipe(
      map(res => {
        return res;
      })
    );
  }
  //indiakyc
  fetchBankacc(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/verify-bank-account`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  fetchPan(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/fetch-pan`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  premiumAadhaarValidation(payload: Object) {
    return this.http.post(`${this.API_URL}/kyc/india/v1/premmium-aadhaar-validation`, payload).pipe(map((res: any) => res));
  }

  fetchVI(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/fetch-vi`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  validatePan(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/document-validation`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  ocrPan(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/id-ocr`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  fetchDL(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/fetch-dl`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  validateDL(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/document-validation`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  ocrDL(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/id-ocr`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  validateBankacc(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/verify-bank-account`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  ocrBankacc(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/verify-bank-account`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  validatePassport(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/document-validation`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  vpaValidation(payload) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/vpa-validate`, payload)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  ocrPassport(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/id-ocr`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  ocrVI(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/document-verification`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  fetchPassport(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/fetch-passport`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  validateAadhar(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/document-validation`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  ocrAadhar(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/id-ocr`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  initiateOtp(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/initiate-aadhaar`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  submitOtp(obj) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/v1/submit-aadhaar`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  reprocessExistingReport(username) {
    return this.http.post<any>(`${this.API_URL}/userchecks/reprocess-check/${username}`, null)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  resendRemoteLink(query) {
    return this.http.post<any>(`${this.API_URL}/userchecks/resend-check`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  sendRemoteLinkForRedo(data) {
    console.log('redo data', data);
    return this.http.post<any>(`${this.API_URL}/userchecks/redo-check`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  getRedoCheckDetails(username) {

    return this.http.get<any>(`${this.API_URL}/userchecks/pre/redo-check/${username}`).pipe(
      map(res => {
        return res;
      })
    );
  }


  getCompanyJurisdictions() {
    return this.http.get<any>(`${this.API_URL}/country/jurisdictions`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getRoles() {
    //Gives type of Roles
    return this.http.get<any>(`${this.API_URL}/roles `).pipe(
      map(res => {
        return res;
      })
    );
  }

  getClientUsers(query) {
    //pageNo , size , sort //i.e Roles
    // return this.http.get<any>(`${this.API_URL}/clientsuser/all?page=${pageNo}&size=${size}&sort=${sort}`)
    return this.http.get<any>(`${this.API_URL}/clientsuser/all?${query}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  toggleAutoRenew() {
    return this.http
      .post<any>(`${this.API_URL}/usersubscription/autorenewal/v2`, null)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getStandardCheckConfig() {
    return this.http.get<any>(`${this.API_URL}/standardchecks/all`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getSelectedChecksByUserName(userName) {
    return this.http.get<any>(`${this.API_URL}/endusers/selected-checks/${userName}`)
      .pipe(map(res => {
        return res['data'];
      }));
  }

  getAllowedDocs(countryName) {
    return this.http.get<any>(`${this.API_URL}/country/${countryName}`).pipe(
      map(res => {
        return res;
      })
    );
  }
  getAllowedDocsForDocExtract(countryName) {
    return this.http.get<any>(`${this.API_URL}/country/extractiondoc/${countryName}`).pipe(
      map(res => {
        return res;
      })
    )
  }

  getProvinceList(countryObj) {
    return this.http.post<any>(`${this.API_URL}/provinces`, countryObj).pipe(
      map(res => {
        return res;
      })
    );
  }

  getChecksConfiguration(hashId) {
    return this.http.get<any>(`${this.API_URL}/userchecks/${hashId}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getUserDocsDetails(checkGuid) {
    return this.http
      .get<any>(`${this.API_URL}/userdocs/${checkGuid}/details`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAMLCheckDetails(hashId) {
    // let sourcetype = [ "pep", "sanctions", "crime" ];
    return this.http.get<any>(`${this.API_URL}/amlchecks/${hashId}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getFromDataSourceId(sourceType, sourceGuid) {
    return this.http
      .get<any>(`${this.API_URL}/amldetails/${sourceType}/${sourceGuid}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getDocFromServer(checkGuid) {
    return this.http.get<any>(`${this.API_URL}/userdocs/${checkGuid}/doc`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getUserNameDetails(userName) {
    return this.http
      .get<any>(`${this.API_URL}/endusers/userdetail/${userName}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getBasicUserInfo(userName) {
    return this.http.get<any>(`${this.API_URL}/endusers/${userName}/info`).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateBasicUserInfo(userName, payload: Object) {
    return this.http.put<any>(`${this.API_URL}/endusers/${userName}/info`, payload).pipe(
      map(res => {
        return res;
      })
    );
  }

  getSaleCoupons() {
    return this.http.get<any>(`${this.API_URL}/usersubscription/sale`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getBiometricSelfie(checkGuid) {
    return this.http
      .get<any>(`${this.API_URL}/userbiometric/doc/selfie/${checkGuid}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getClientProfileData() {
    return this.http.get<any>(`${this.API_URL}/clients/profile`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getGeneratedKeys() {
    return this.http.get<any>(`${this.API_URL}/clients/apikey`).pipe(
      map(res => {
        return res;
      })
    );
  }

  reGenerateKeys(data) {
    return this.http.put<any>(`${this.API_URL}/clients/apikey`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  getCompanySize() {
    return this.http.get<any>(`${this.API_URL}/constants/companysize`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getIndustryType() {
    return this.http.get<any>(`${this.API_URL}/constants/industrytype  `).pipe(
      map(res => {
        return res;
      })
    );
  }

  getCompanyType() {
    return this.http.get<any>(`${this.API_URL}/constants/companytype`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getClientConfig() {
    return this.http.get<any>(`${this.API_URL}/clients/configs`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getClientConfigV1() {
    return this.http.get<any>(`${this.API_URL}/client/config/v1/dashboard-config`).pipe(
      map(res => {
        return res;
      })
    );
  }

  saveClientConfig(data) {
    return this.http.put<any>(`${this.API_URL}/clients/configs`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  getServiceConsumption() {
    return this.http
      .get<any>(`${this.API_URL}/service/consumption/summary`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getDebitHistory(query) {
    return this.http.post<any>(`${this.API_URL}/debit/history`, query).pipe(
      map(res => {
        return res;
      })
    );
  }

  creditConsumptionHistory(query) {
    return this.http.post<any>(`${this.API_URL}/credit/history`, query).pipe(
      map(res => {
        return res;
      })
    );
  }

  overrideChecks(obj) {
    return this.http.post<any>(`${this.API_URL}/userchecks/override`, obj).pipe(
      map(res => {
        return res;
      })
    );
  }
  overrideChecksAll(obj) {
    return this.http.post<any>(`${this.API_URL}/userchecks/v1/override`, obj).pipe(
      map(res => {
        return res;
      })
    );
  }

  setAmlAlerts(obj) {
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/alerts/enduser/alert-bookmark/add-schedule-interval`, obj).pipe(
      map(res => {
        return res;
      })
    );
  }

  getAmlBookMarked(obj, userName) {
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/alerts/enduser/bookmarked/check-scheduled/${userName}`, obj).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateUserEmail(obj, username) {
    return this.http
      .put<any>(`${this.API_URL}/endusers/identity-info/${username}`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // getDebitTransaction(){
  //   return this.http.get<any>(`${this.API_URL}/credit/consumption/history`)
  //     .pipe(map(res => {
  //     return res;
  //   }));
  // }

  getBiometricVideo(checkGuid) {
    // const options = { responseType: 'blob'};
    // let options = { responseType: 'blob'};
    // let options : IRequestOptions = { headers: new HttpHeaders({"Content-Type": "application/octet-stream"}) , responseType: "blob"  } ;
    //   const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/octet-stream'
    //   }),
    //    responseType: 'blob',
    //   observe: 'response' as 'body'
    // };

    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream"
    });

    let authHttpOptions = {
      headers: headers,

      responseType: "blob"
    };
    return this.http
      .get(`${this.API_URL}/userbiometric/doc/video/${checkGuid}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/octet-stream"
        }),
        responseType: "blob"
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  checkPendingChecks(obj) {
    return this.http
      .post<any>(`${this.API_URL}/endusers/pendingchecks`, obj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAllUserReports(query) {
    return this.http
      .post<any>(`${this.API_URL}/userchecks/client/all`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAllInvoice(query) {

    // /usersubscription/invoices

    return this.http
      .post<any>(`${this.API_URL}/payment/v2/invoices`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  generateInvoiceIndia(id) {
    let invoiceObj = { 'invoiceNo': id, typeOfInvoice: 'subscription' }
    return this.http
      .post(`${this.API_URL}/kyc/india/generate-invoice`, invoiceObj, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
        responseType: "blob",
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  generateOnetimeInvoice(id) {
    let invoiceObj = { 'invoiceNo': id, typeOfInvoice: 'initial' }
    return this.http
      .post(`${this.API_URL}/kyc/india/generate-invoice`, invoiceObj, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
        responseType: "blob",
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getActivePages() {
    return this.http.get<any>(`${this.API_URL}/fe/clientsuser/all-pages`).pipe(
      map(res => {
        return res;
      })
    );
  }

  generateInvoicePDF(id) {
    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream",
    });
    let authHttpOptions = {
      headers: headers,
      responseType: "blob",
    };
    // /usersubscription/pdf/invoice/${id}  
    return this.http
      .get(`${this.API_URL}/payment/v2/invoice/pdf/${id}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/octet-stream",
        }),
        responseType: "blob",
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getUserHomeData(query) {
    return this.http.post<any>(`${this.API_URL}/endusers/all`, query).pipe(
      map(res => {
        return res;
      })
    );
  }

  getClientUserHomeData(query, clientId) {
    return this.http.post<any>(`${this.API_URL}/endusers/devops/${clientId}/all`, query).pipe(
      map(res => {
        return res;
      })
    );
  }

  getUsersAllChecks(query) {
    return this.http.post<any>(`${this.API_URL}/userchecks`, query).pipe(
      map(res => {
        return res;
      })
    );
  }

  searchCompanies(query) {
    return this.http.post<any>(`${this.API_URL}/companies/search`, query).pipe(
      map(res => {
        return res;
      })
    );
  }

  searchCorpCompanies(query, checklinkguid) {
    if (query["page"] < 1) {
      query["page"] = query["page"] + 1;
    }

    return this.http
      .post<any>(
        `${this.API_URL}/v1/corporatechecks/${checklinkguid}/search `,
        query
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  submitCorpCheck(data, checklinkguid) {
    return this.http
      .post<any>(
        `${this.API_URL}/v1/corporatechecks/${checklinkguid}/submit `,
        data
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getCorpCheckInfo(checklinkguid) {
    return this.http
      .get<any>(`${this.API_URL}/v1/corporatechecks/${checklinkguid}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  sendRemoteLink(id) {
    let obj = {
      checkLinkGuid: id
    };
    return this.http.post<any>(`${this.API_URL}/faas/sendremotelink`, obj).pipe(
      map(res => {
        return res;
      })
    );
  }
  sendMultipleRemoteLink(payload) {
    return this.http.post<any>(`${this.API_URL}/faas/send-multiple/remotelink`, payload)
  }

  updateProfile(clientData) {
    return this.http
      .put<any>(`${this.API_URL}/clients/profile`, clientData)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getPreviewData(hashId) {
    return this.http
      .get<any>(`${this.API_URL}/userchecks/${hashId}/preview`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getClientSubscriptionInfo() {
    return this.http
      .get<any>(`${this.API_URL}/usersubscription/subscriptioninfo`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAllScheduleChecks(query) {
    return this.http
      .post<any>(`${this.API_URL}/scheduledchecks/all`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAmlAlerts(query) {
    return this.http
      .post<any>(`${this.API_URL}/kys/v2.0/alerts/enduser/bookmarked/get-alerts-data`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getDismissedAmlAlerts(query) {
    return this.http
      .post<any>(`${this.API_URL}/kys/v2.0/alerts/enduser/bookmarked/get-seen-alerts-data`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  doFalsePositiveOfAmlAlerts(query) {
    return this.http
      .post<any>(`${this.API_URL}/kys/v2.0/alerts/enduser/add-false-positive-data`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  dismissedEntityFromAmlAlerts(query, id) {
    return this.http
      .post<any>(`${this.API_URL}/kys/v2.0/alerts/enduser/bookmarked/notification-seen/${id}`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getRiskScoreOfAlerts(query) {
    return this.http
      .post<any>(`${this.API_URL}/kys/v2.0/alerts/crr/get-score/old-new-data`, query)
      .pipe(
        map(res => {
          return res['data'];
        })
      );
  }

  getUsersScheduledAlerts(userName, data) {
    return this.http
      .post<any>(`${this.API_URL}/scheduledchecks/${userName}`, data)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getScheduledAlertDataByID(scheuledcheckid, query) {
    return this.http
      .get<any>(`${this.API_URL}/amlalerts/${scheuledcheckid}${query}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getStandardScheduledAlerts() {
    return this.http.get<any>(`${this.API_URL}/standardschedulechecks`).pipe(
      map(res => {
        return res;
      })
    );
  }

  scheduleChecksForUser(data) {
    return this.http.post<any>(`${this.API_URL}/scheduledchecks`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  getStandardPlans() {
    return this.http
      .get<any>(`${this.API_URL}/usersubscription/standardplan`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getStandardPlansPublic() {
    return this.http
      .get<any>(`${this.API_URL}/usersubscription/standardplan/public`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getStandardServices() {
    return this.http.get<any>(`${this.API_URL}/standardservice/service`).pipe(
      map(res => {
        return res;
      })
    );
  }

  //Create i.e POST Requests

  createNewUser(userObj) {
    return this.http
      .post<any>(`${this.API_URL}/clientsuser/register`, userObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  finalSubmit(parentHashId) {
    return this.http
      .post<any>(`${this.API_URL}/userchecks/${parentHashId}/submit`, null)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // saveAMLChecks(endUserCheckID){
  //   return this.http.post<any>(`${this.API_URL}/amlchecks/${endUserCheckID}` , null)
  //     .pipe(map(res => {
  //         return res;
  //   }));
  // }

  doAMLCheckSearch(checklinkguid, query) {
    return this.http
      .post<any>(`${this.API_URL}/amlchecks/v2/search/${checklinkguid}`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAMLFilters(checklinkguid, searchtype) {
    return this.http
      .get<any>(
        `${this.API_URL
        }/amlchecks/v2/filters/${checklinkguid}?searchtype=${searchtype}`
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getSelectedAMLData(checklinkguid, amlentityguid, query) {
    return this.http
      .post<any>(
        `${this.API_URL}/amlchecks/v2/search/${checklinkguid}/${amlentityguid}`,
        query
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getSavedAMlData(query) {
    return this.http
      .post<any>(`${this.API_URL}/amlchecks/v2/results`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // flagAMLCheck(data){
  //   return this.http.post<any>(`${this.API_URL}/v1/amlchecks` , data)
  //     .pipe(map(res => {
  //         return res;
  //   }));
  // }

  saveAndSubmitAMLResults(checklinkguid, data) {
    return this.http
      .post<any>(
        `${this.API_URL}/amlchecks/v2/results/${checklinkguid}/submit`,
        data
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  saveAdverseResults(data) {
    return this.http
      .post<any>(`${this.API_URL}/adversemedia/results/submit`, data)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // doAdverseMediaSearch(query) {
  //   return this.http.post<any>(`${this.API_URL}/adversemedia`, query).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }
  doAdverseMediaSearch(query, checkguid) {
    return this.http.post<any>(`${this.API_URL}/adversemedia`, query).pipe(
      map(res => {
        return res;
      })
    );
  }

  getSavedAdverseData(query) {
    return this.http
      .post<any>(`${this.API_URL}/adversemedia/results`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  registerEndUser(userObj) {
    if (userObj["officerID"] && userObj["companyID"]) {
      return this.http
        .post<any>(`${this.API_URL}/endusers/register-officer`, userObj)
        .pipe(
          map(res => {
            return res;
          })
        );
    } else {
      return this.http
        .post<any>(`${this.API_URL}/endusers/register`, userObj)
        .pipe(
          map(res => {
            return res;
          })
        );
    }
  }

  saveUserAddress(addressObj, checklinkguid) {
    return this.http
      .post<any>(
        `${this.API_URL}/individualaddress/${checklinkguid}`,
        addressObj
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getUserAddress(checklinkguid) {
    return this.http
      .get<any>(`${this.API_URL}/individualaddress/${checklinkguid}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  saveUserDoc(apiUrl, docObj, checklinkguid) {
    return this.http
      .post<any>(`${this.API_URL}/${apiUrl}/${checklinkguid}`, docObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  downloadUserDocs(username) {
    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream"
    });

    let authHttpOptions = {
      headers: headers,
      responseType: "blob"
    };

    return this.http
      .get(`${this.API_URL}/endusers/download/${username}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/octet-stream"
        }),
        responseType: "blob"
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  downloadSummaryReport(username) {
    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream"
    });

    let authHttpOptions = {
      headers: headers,
      responseType: "blob"
    };

    return this.http
      .get(`${this.API_URL}/endusers/report/summary/${username}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/octet-stream"
        }),
        responseType: "blob"
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  downloadDetailedReport(username) {
    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream"
    });

    let authHttpOptions = {
      headers: headers,
      responseType: "blob"
    };

    return this.http
      .get(`${this.API_URL}/endusers/report/${username}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/octet-stream"
        }),
        responseType: "blob"
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  changeAMLStatus(obj) {
    return this.http.put<any>(`${this.API_URL}/amlchecks`, obj).pipe(
      map(res => {
        return res;
      })
    );
  }

  //Update i.e PUT Requests

  updateUser(userObj) {
    return this.http
      .put<any>(`${this.API_URL}/clientsuser/changerole`, userObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //Open Corps Search
  searchOpenCorps(query) {
    return this.http
      .get<any>(
        `https://api.opencorporates.com/v0.4/companies/search?q=${query}`
      )
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //get activity log
  getActivityLog(userName) {
    return this.http
      .post<any>(`${this.API_URL}/endusers/activity/${userName}`, {
        page: 0,
        size: 10,
        sort: "asc"
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //get activity log
  getActivityLogV1(userName, query) {
    return this.http
      .post<any>(`${this.API_URL}/endusers/activity/v1/${userName}`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  //get misc settings data
  getMiscSettingsData() {
    return this.http.get<any>(`${this.API_URL}/clients/oauth-configs`).pipe(
      map(res => {
        return res;
      })
    );
  }

  //update misc settings data
  updateMiscSettingsData(data) {
    return this.http
      .put<any>(`${this.API_URL}/clients/oauth-configs`, data)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //register webhook
  registerWebHook(apiCallBackUrl) {
    return this.http
      .post<any>(`${this.API_URL}/webhook`, { apiCallBackUrl })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //get webhook
  getWebHook() {
    return this.http.get<any>(`${this.API_URL}/webhook`).pipe(
      map(res => {
        return res;
      })
    );
  }

  //update webhook
  updateWebHook(apiCallBackUrl) {
    return this.http
      .put<any>(`${this.API_URL}/webhook`, { apiCallBackUrl })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //toggle webhook
  toggleWebHook() {
    return this.http.get<any>(`${this.API_URL}/webhook/flip`).pipe(
      map(res => {
        return res;
      })
    );
  }

  //generate secret key for webhook
  generateWebHookToken() {
    return this.http.get<any>(`${this.API_URL}/webhook/token`).pipe(
      map(res => {
        return res;
      })
    );
  }

  saveLogo(formData) {
    return this.http
      .post<any>(`${this.API_URL}/clients/logo`, formData, {
        headers: new HttpHeaders({
          "Content-Type": "multipart/form-data"
        })
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //test webhook
  testWebHoook() {
    return this.http.get<any>(`${this.API_URL}/webhook/test`).pipe(
      map(res => {
        return res;
      })
    );
  }

  //Corporate report download button
  corpDownloadReport(username) {
    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream"
    });

    let authHttpOptions = {
      headers: headers,
      responseType: "blob"
    };

    return this.http
      .get(`${this.API_URL}/endusers/report/corporate/${username}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/octet-stream"
        }),
        responseType: "blob"
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  saveDocExtractedData(data) {
    return this.http.post<any>(`${this.API_URL}/docs/extraction`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateDocExtractedData(data) {
    return this.http.put<any>(`${this.API_URL}/docs/extraction`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  getDocExtractedData(page, size) {
    return this.http
      .get<any>(`${this.API_URL}/docs/extraction?page=${page}&size=${size}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  notifyOnCompletion(userName) {
    return this.http.post<any>(`${this.API_URL}/userchecks/send-completion-email/${userName}`, null).pipe(
      map(res => {
        return res;
      })
    );
  }

  translateOCRData(srcLang, tarLang, text) {
    return this.http
      .post<any>(`${this.API_URL}/demo-data/ocr-translate`, {
        text: text,
        sourceLanguage: srcLang,
        targetLanguage: tarLang
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  //show all risk policy datasources
  getAllDataSources() {
    return this.http.get<any>(`${this.API_URL}/v1/aml/risskpolicy/datasource`).pipe(
      map(res => {
        return res;
      })
    );
  }
  //Add new Risk Policy grouped by datasources  
  addNewRiskPolicy(data) {
    return this.http.post<any>(`${this.API_URL}/riskpolicy`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  //For Verifying Clients  
  verifyClient() {
    return this.http.post<any>(`${this.API_URL}/clients/verify`, null).pipe(
      map(res => {
        return res;
      })
    );
  }
  //Get All Grouped Plans  
  getClientPlans() {
    return this.http.get<any>(`${this.API_URL}/payment/v2/plan`).pipe(
      map(res => {
        return res;
      })
    );
  }
  getClientSubscription() {
    return this.http.get<any>(`${this.API_URL}/kyc/india/get-user-details`).pipe(
      map(res => {
        return res;
      })
    );
  }
  getInvoiceList() {
    return this.http.get<any>(`${this.API_URL}/kyc/india/get-user-all-invoices`).pipe(
      map(res => {
        return res;
      })
    );
  }
  getIntInvoiceList(type, payload) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/international/get-all-invoices/${type}`, payload).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Get all data related to Date Related Deduction Summary for Bar Graph  
  getDeductionSummaryAggr(data) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/deduction/summary/date`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Get all data related to Deduction Summary for Pie Chart  
  getDeductionSummary(data) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/deduction/summary`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Get all Deduction History in Subscription  
  getDeductionHistory(data) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/deduction/history`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Activate Users with Roles  
  activateClientUser(data) {
    return this.http.post<any>(`${this.API_URL}/clientsuser/activate`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Deactivate Users with Roles  
  deactivateClientUser(data) {
    return this.http.post<any>(`${this.API_URL}/clientsuser/deactivate`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Get Subscription Information Version 2  
  getClientSubscriptionInfoV2() {
    return this.http
      .get<any>(`${this.API_URL}/payment/v2/subscriptioninfo`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  // Credit Refund History  
  getCreidtsRefundHistory(data) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/reverse/history`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Cancel Subcription of any plan  
  cancelSubscription() {
    return this.http.post<any>(`${this.API_URL}/payment/v2/subscription/status`, null).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Cancel Autorenewal of any subscription plan  
  changeAutorenewal() {
    return this.http.post<any>(`${this.API_URL}/payment/v2/autorenewal/status`, null).pipe(
      map(res => {
        return res;
      })
    );
  }
  //Enterprise Plan
  enterprisePlan(message) {
    return this.http.post<any>(`${this.API_URL}/contactus/enterpriseplan/getaquote`, message).pipe(
      map(res => {
        return res;
      })
    );
  }
  // Show All Plans History  
  getPlanHistory(data) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/buying/history`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  // Accept Terms And Condition
  acceptTermsAndCondition() {
    return this.http.put<any>(`${this.API_URL}/clients/terms/conditions`, null).pipe(
      map(res => {
        return res;
      })
    );
  }
  //////////////Check Status Report ///////////
  getStatusReport(query) {
    return this.http
      .post<any>(`${this.API_URL}/userchecks/statusReport`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  cancelExpiryLink(id) {
    let obj = {
      checkLinkGuid: id
    };
    return this.http.post<any>(`${this.API_URL}/faas/expiredinvite/cancel`, obj).pipe(
      map(res => {
        return res;
      })
    );
  }


  sendBulkDataRemoteLink() {
    return this.http
      .post<any>(`${this.API_URL}/faas/sendremotelink/bulkdata`, null)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  getOverrideReportReport(query) {
    return this.http
      .post<any>(`${this.API_URL}/userchecks/overrideReport`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getAggregateStatusDashboard() {
    return this.http.get<any>(`${this.API_URL}/endusers/aggregateStatus`).pipe(
      map(res => {
        return res;
      })
    );
  }
  coActivityLog(logData) {
    return this.http
      .post<any>(`${this.API_URL}/clientsuser/device/details`, logData)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  getCoActivityLog(username, query) {
    return this.http
      .post<any>(`${this.API_URL}/clientsuser/activity/${username}`, query)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  getSystemIp() {
    return this.http.get("https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json");
  }

  getLocation(lat, long) {
    return this.http.get<any>(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${long}&localityLanguage=all`).pipe(
      map(res => {
        return res;
      })
    );
  }
  sendPaymentLink(data) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/payment-email-link`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  getRecurringPaymentByToken(paymentToken) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/standard-cost-payment-token?token=` + paymentToken, {}).pipe(
      map(res => {
        return res;
      })
    );
  }

  getRecurringPaymentByTokenStripe(paymentToken, payload) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/stripe/international/standard-cost-payment-token?token=` + paymentToken, payload).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateRecurringPaymentByTokenStripe(paymentObj, paymentToken) {
    return this.http.put<any>(`${this.API_URL}/kyc/india/stripe/international/standard-cost-payment-token?token=` + paymentToken, paymentObj).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateRecurringStripeByToken(paymentObj, paymentToken) {
    return this.http.post<any>(`${this.API_URL}/kyc/india/standard-cost-charge-token?token=` + paymentToken, paymentObj).pipe(
      map(res => {
        return res;
      })
    );
  }
  updateRecurringPaymentByToken(order, paymentToken) {
    let paymentObj = {}
    paymentObj["razorpayOrderId"] = order.razorpay_order_id
    paymentObj["razorpayPaymentId"] = order.razorpay_payment_id
    paymentObj["razorpaySignature"] = order.razorpay_signature
    return this.http.put<any>(`${this.API_URL}/kyc/india/standard-cost-payment-token?token=` + paymentToken, paymentObj).pipe(
      map(res => {
        return res;
      })
    );
  }
  getUserSubscriptionByToken(paymentToken) {
    return this.http.get<any>(`${this.API_URL}/kyc/india/get-user-details-token?token=` + paymentToken).pipe(
      map(res => {
        return res;
      })
    );
  }
  InternationalInvoice(invoiceObj) {
    return this.http
      .post(`${this.API_URL}/kyc/india/generate-international-invoice`, invoiceObj, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
        responseType: "blob",
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  getPageComponent(page) {
    return this.http.get(`${this.API_URL}/fe/clientsuser/page/${page}`).pipe(map((res: any) => res.data));
  }

  getApiResults(url, data) {
    //console.log('data----------------------',data.getAll('multipartFile'),data.getAll('docType'))
    return this.http.post<any>(`${this.API_URL}${url}`, data).pipe(
      map(res => {

        return res;
      })
    )
  }

  getFaceCount(formData) {
    return this.http.post(`${this.API_URL}/face/detect`, formData).pipe(
      map(res => {
        return res;
      })
    )
  }
  getFaceMatch(formData) {
    return this.http.post(`${this.API_URL}/face/match`, formData).pipe(
      map(res => {
        return res;
      })
    )
  }

  getAdvMediaResults(obj) {
    return this.http.post<any>(`${this.API_URL}/v2/crr-data/search/`, obj).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getUserRiskScore(enduserId: string) {
    return this.http.get<any>(`${this.API_URL}/userchecks/risk-score/${enduserId}`).pipe(
      map((res) => {
        return res['data'];
      })
    );
  }

  getConsumptionDetails(obj) {
    return this.http.post<any>(`${this.API_URL}/client/consumption/international/credits/history`, obj).pipe(
      map((res) => {
        return res['data'];
      })
    );
  }

  getNewAdverseArticles(obj) {
    return this.http.post<any>(`${this.API_URL}/adversemedia/get-articles`, obj).pipe(
      map((res) => {
        return res['data'];
      })
    );
  }
  devOpsRestriction() {
    return this.http.get(`${this.API_URL}/clientsuser/profile`).pipe(map((res: any) => res.data));
  }

  getUserMenu() {
    return this.http
      .get(`${this.API_URL}/fe/clientsuser/all-pages`)
      .pipe(map((res: any) => res.data));
  }

  getFalsePositiveData(payload) {
    return this.http
      .post<any>(`${this.API_URL}/kys/v2.0/alerts/devops/get-false-positive-data`, payload)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  markFalsePositive(payload) {
    return this.http
      .post<any>(`${this.API_URL}/kys/v2.0/alerts/devops/update-false-positive-data`, payload)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getBookMarkedFalsePositiveData(payload) {

    return this.http
      .post<any>(`${this.API_URL}/kys/v2.0/alerts/get-false-positive-data`, payload)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getFaqCategoryList() {
    return this.http.get(`${this.API_URL}/end-user/faq/get-all/category`).pipe(map((res: any) => res.data));
  }

  getFaqQuestionList(payload: Object) {
    return this.http.post(`${this.API_URL}/end-user/faq/filter`, payload).pipe(map((res: any) => res.data));
  }

  getFaqQuestionListBtId(id) {
    return this.http.get(`${this.API_URL}/end-user/faq/get-by-id/${id}`).pipe(map((res: any) => res.data));
  }

  updateEmailViaReport(email, payload: Object) {
    return this.http.post(`${this.API_URL}/phone-email/update/email-check/${email}`, payload).pipe(map((res: any) => res.data));
  }

  getBase64(payload) {
    return this.http.post(`${this.API_URL}/end-user/faq/gcs-url-to-base64`, payload);
  }

  getImgPdfBase64(payload) {
    return this.http.post(`${this.API_URL}/faas/questioner/gcs-url-to-base64`, payload).pipe(map((res: any) => res?.data));
  }

  consumptionReport(payload) {
    return this.http.post(`${this.API_URL}/kyc/india/client/report`, payload, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      responseType: "blob",
    }).pipe(
      map(res => {
        return res;
      })
    );
  }

  getBlackList(payload) {
    return this.http.post(`${this.API_URL}/client/black-list/get-entities`, payload).pipe(map((res: any) => res.data));
  }

  saveBlacklist(payload) {
    return this.http.post(`${this.API_URL}/client/black-list/add-entities`, payload).pipe(map((res: any) => res.data));
  }

  uploadBlackListFile(data) {
    return this.http.post<any>(`${this.API_URL}/client/black-list/add-entities/bulk-upload`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  getCSVfile() {
    return this.http.get<any>(`${this.API_URL}/client/black-list/sample-csv/download`, { responseType: 'text' as 'json' }).pipe(
      map(res => {
        return res;
      })
    );
  }

  getEsignDoc(data) {
    return this.http.post<any>(`${this.API_URL}/end-user/faq/gcs-url-to-base64`, data).pipe(
      map(res => {
        return res;
      })
    );
  }


  amlMarkAsFalsePositive(payload) {
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/aml/create/false-positive`, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }
  adverseMediaMarkAsFalsePositive(payload) {
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/ame/create/false-positive`, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }
  nidAadhaarSendOtp(payload, id) {
    return this.http.post<any>(`${this.API_URL}/dashboard/nid/doc/verification/initiate-aadhaar/${id}`, payload)
  }
  nidAadhaarVerifyOtp(payload, id) {
    return this.http.post<any>(`${this.API_URL}/dashboard/nid/doc/verification/submit-aadhaar/${id}`, payload)
  }
  nidPanCheck(payload, id) {
    return this.http.post<any>(`${this.API_URL}/dashboard/nid/doc/verification/pan-fetch/${id}`, payload)
  }
  nidPassportValidations(payload, id) {
    return this.http.post<any>(`${this.API_URL}/dashboard/nid/doc/verification/passport-validation/${id}`, payload)
  }

  iframeEndUserRegister(payload) {
    return this.http.post<any>(`${this.API_URL}/endusers/v1/register`, payload)
  }

  logoutApi() {
    return this.http.post<any>(`${this.API_URL}/clientsuser/logout`, null)
  }

  getCategories() {
    return this.http.get<any>(`${this.API_URL}/aml/categories/get`).pipe(
      map(res => {
        return res['data'];
      })
    );
   }
  getVideoKycAgentList(){
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/all-agents`).pipe(
      map(res => {
        return res['data'];
      })
    );
   }

   getOccupation(){
    return this.http.get<any>(`${this.API_URL}/occupation/get`).pipe(
      map(res => {
        return res['data'];
      })
    );
   }

   getSubCategories( payload ){
    return this.http.post(`${this.API_URL}/category/subcategory-get`,payload).pipe(map((res: any) => res));
   }
   
   doAdvanceSearch(payload){
     return this.http.post(`${this.API_URL}/v2/aml/advance-search`,payload).pipe(map((res: any) => res))
    }
    fetchRegenerateLinkValue(name){
      return this.http.get(`${this.API_URL}/endusers/status/${name}`).pipe(map((res: any) => res))
      
    }

  createNewAmlBatch(payload){
    return this.http.post(`${this.API_URL}/bulk/batch/create`,payload).pipe(map((res: any) => res['data']));
  }

  uploadNewCsvAmlBatch(payload, batchId){
    return this.http.post(`${this.API_URL}/bulk/aml/upload?batchId=${batchId}`,payload, {responseType: 'text' as 'json', observe: 'response'}).pipe(map((res: any) => res));
  }

  appendMoreDataCsvAmlBatch(payload, batchId){
    return this.http.post(`${this.API_URL}/bulk/aml/upload-append?batchId=${batchId}`,payload).pipe(map((res: any) => res['data']));
  }

  getBatchFilterData(payload){
    return this.http.post(`${this.API_URL}/bulk/batches/filter`,payload).pipe(map((res: any) => res['data']));
  }

  fetchDataByBatchId(batchId){
    return this.http.get(`${this.API_URL}/bulk/batch/${batchId}`).pipe(map((res: any) => res['data']));
  }

  getBatchFilterDataByBatchId(payload, batchId){
    return this.http.post(`${this.API_URL}/bulk/records/filter?batchId=${batchId}`,payload).pipe(map((res: any) => res['data']));
  }

  getAmlCSVfile() {
    return this.http.get<any>(`${this.API_URL}/bulk/aml/sample-csv/download`, { responseType: 'text' as 'json' }).pipe(
      map(res => {
        return res;
      })
    );
  }

  getWebhookHistoryData(payload){
    return this.http.post(`${this.API_URL}/webhook/execution/history`,payload).pipe(map((res: any) => res['data']));
  }
  getDocVideoUploaded(checkGuid, type: "front" | "back") {
    return this.http
    .get(`${this.API_URL}/iddcapture/${type}/video/${checkGuid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/octet-stream"
      }),
      responseType: "blob"
    })
    .pipe(
      map(res => {
        return res;
      })
      );
    }

  getAmlTableListData(endUserCheckID){
    return this.http.get(`${this.API_URL}/auto/aml/inter-mediate/match/fetch?check-guid=${endUserCheckID}`).pipe(map((res: any) => res['data']));
  }

  selectAmlTableListData(endUserCheckID, payload){
    return this.http.post(`${this.API_URL}/endusers/save/selected/auto-aml?check-guid=${endUserCheckID}`, payload).pipe(map((res: any) => res['data']));
  }

  downloadDocFromServer(docUrl) {
    return this.http
      .get<any>(`${this.API_URL}/enduser/aadhaar/doc-otp/check/download/base64-url?url=${docUrl}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService, AppService } from "../../../services";
import { finalize, first } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { DevOpsService } from "../services/dev-ops.service";

import {
  MatchAttributeStatus,
  VerificationStates,
} from "../../../utils/status";
import { Verification } from "../../../models/Verification.model";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../../shared/kyc-modal/kyc-modal.component";

@Component({
  selector: "app-check-details-user",
  templateUrl: "./check-details.component.html",
  styleUrls: ["./check-details.component.scss"],
})
export class CheckDetailsComponent implements OnInit,OnChanges{
  @Input() verification: Verification;
  @Input() endUserDocInfo: any;
  @Input() ocrdata: any;
  @Input() faceUrl: any;
  @Input() checkGroup: any; //id-checks , address-checks , aml-checks

  @Input() correspondenceAddress: any;
  @Input() permanentAddress: any;

  @Input() endUserInfo: any;
  @Input() biometrics         : any ;

  @Input() overriddenBy: any;
  @Input() overrideComment: any;

  @Output() refresh = new EventEmitter<String>();

  knOptions = {
    readOnly: true,
    size: 100,
    unit: "%",
    textColor: "#ffffff",
    fontSize: "18",
    fontWeigth: "400",
    fontFamily: "Roboto",
    valueformat: "percent",
    value: 0,
    max: 100,
    trackWidth: 15,
    barWidth: 15,
    trackColor: "#e1e1e1",
    barColor: "#10c469",
  };

  shouldFlip: boolean = false;
  clientId: any;
  public bsModalRef: BsModalRef;
  private checkGuid:any;

  constructor(
    private _data: DataService,
    private _app: AppService,
    public sanitizer: DomSanitizer,
    public router: Router,
    private devops:DevOpsService,
    private activatedRoute:ActivatedRoute,
    private modalService: BsModalService
  ) {

    this.activatedRoute.params.subscribe(params => {
      this.clientId = params["id"];
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('this.endUserInfo', this.verification);
    this.checkGuid = this.endUserDocInfo
    ? this.endUserDocInfo.endUserCheck["endUserCheckID"]
    : null;
    if (this.checkGroup == "id-checks") {
      this.faceUrl = `data:image/png;base64,${this.faceUrl}`;
      this.faceUrl = this.transform(this.faceUrl);
      this.getDocFromServer();
    }

    if (this.checkGroup == "address-checks") {
      this.getDocFromServer();
    }

    if (this.verification.verificationStatus == "FAILED") {
      this.knOptions["barColor"] = "#ff3547";
      this.knOptions["textColor"] = "#ff3547";
      
    }

    if (this.verification.verificationStatus != "FAILED") {
        this.knOptions["textColor"] = "#10c469";
      
    }
    
  }

  ngOnInit() {
   

    // if(this.verification && this.verification.verificationScore){
    // 	if(this.verification.verificationScore < 50){
    // 		this.knOptions.barColor = "#fc4c02";
    // 	}
    // }
    // console.log(this.endUserInfo);
    // console.log(this.endUserDocInfo);
  }

  

  getDocFromServer() {
    // console.log(this.endUserDocInfo);
    // console.log(this.ocrdata);
    let checkGuid = this.endUserDocInfo
      ? this.endUserDocInfo.endUserCheck["endUserCheckID"]
      : null;
    if (checkGuid) {
      this.devops.getDocFromServer(checkGuid,this.clientId).subscribe(res => {
        this.endUserDocInfo["docFrontUrl"] = `data:image/jpeg;base64,${
          res.data["frontDoc"]
        }`;
        this.endUserDocInfo["docRearUrl"] = `data:image/jpeg;base64,${
          res.data["rearDoc"]
        }`;
      });
    }
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }

  getVerificationText(key, value, isIcon?: any) {
    let matched = VerificationStates.filter(obj => {
      if (obj.field == key && obj.status == value) {
        return obj;
      }
    });
    if (matched.length) {
      return isIcon ? matched[0].icon : matched[0].text;
    } else {
      return isIcon
        ? "fa-exclamation-circle text-warning"
        : `${key} : Not Found`;
    }
  }

  flip() {
    this.shouldFlip = !this.shouldFlip;
  }

  openOverrideModel(type: any){
    const devOpsOverride = {
      docType: this.checkGroup,
      overrideType: type,
      clientId: this.clientId,
      checkGuid:this.checkGuid
    }
    const initialState = {
      title: type.replaceAll('-', ' ').toUpperCase(),
      type: 'DevOps-Override',
      devOpsOverrideData: devOpsOverride
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(data=> {
      if(JSON.stringify(data) != '{}'){
        if(type=='override-extraction'){
          let idDocInfo = {
            "name":data.fullName!=''?data.fullName:data.fname+' '+data.lname,
            "issueDate": data.issue!=''?data.issue:null,
            "expiryDate": data.expary!=''?data.expary:null,
            "expiryYear":data.expiryYear!=''?data.expiryYear:null,
            "issueYear":data.issueYear!=''?data.issueYear:null,
            "address": data.address!=''?data.address:null,
            "serialNo": data.sn!=''?data.sn:null,
            "dob": data.dob!=''?data.dob:null,
            "firstName": data.fname!=''?data.fname:null,
            "lastName": data.lname!=''?data.lname:null,
          }
          let payload={
            "faceUrl":null, "idDocInfo":idDocInfo
          }
          this._data.changeLoaderVisibility(true);
          this.devops.overrideDocExtractionUserArea(this.clientId, this.checkGuid, payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(()=>{
            this._data.toastrShow(`Extraction Overrided`, 'success');
          })
        }
        else if(type=='override-score'){
          
          let payload={"verificationScore":data.score!=''? data.score:null,
                       "verificationStatus":data.status!=''?data.status:null,
                      "checkGuid":this.checkGuid}
          this._data.changeLoaderVisibility(true);
          this.devops.overrideScoreStatusUserArea(this.clientId,payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(()=>{
          this._data.toastrShow(`Score Overrided`, 'success');
        })
        }      
        this.refresh.emit("refresh")
      }

  });
  }
}

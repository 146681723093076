import { Component, OnInit, Input } from '@angular/core';
import { RiskScreeningService } from '../../../risk-screening.service';


@Component({
  selector: 'app-entity-cards',
  templateUrl: './entity-cards.component.html',
  styleUrls: ['./entity-cards.component.scss', '../../../risk-screening.component.scss']
})
export class EntityCardsComponent implements OnInit {
  @Input() totalData;
  @Input () data;
  stats: any = {
    total: {
      entity: 20000,
      ds: 20
    },
    sanction: {
      entity: 5000,
      ds: 6
    },
    crime: {
      entity: 6000,
      ds: 6
    },
    watchlist: {
      entity: 7000,
      ds: 7
    },
    pep: {
      entity: 20000,
      ds: 20
    },
    sip: {
      entity: 8000,
      ds: 7
    },
  }
 
  constructor(public _rs:RiskScreeningService) {

  }

  ngOnInit() {
    console.log(1112, Object.keys(this.stats))
    Object.keys(this.stats).forEach((e: any) => {
      this.stats[e].ds = String(this.stats[e].ds).padStart(2, '0');
      // this.stats[e].entity = this.formatToInr(this.stats[e].entity);
    })
    // this.getStatsOverall();
    console.log(111, this.stats)
  }
  async getStatsOverall() {
    const res = await this._rs.getDashboardStatsOverall().toPromise()
    this.data = res.data
    console.log(1233, res)
  }
//   formatToInr(num: number) {
//     return num.toLocaleString('en-IN', {
//         maximumFractionDigits: 2,
//         style: 'currency',
//         currency: 'INR'
//     });
// }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-risk-screening',
  templateUrl: './risk-screening.component.html',
  styleUrls: ['./risk-screening.component.scss']
})
export class RiskScreeningComponent implements OnInit {
  hideElements: boolean = true;
  constructor() { }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  RiskDashboardReportComponent,
  DashboardMainComponent,
  RiskScreeningComponent,
  AmlResultsMainComponent,
  RiskIndividualReportComponent,
  RiskCorporateReportComponent,
  RiskPolicyTableComponent,
  GlobalSanctionNewsComponent,
  ScheduledChecksComponent,
  BlacklistedEntityComponent,
  RecentSearchesComponent,
  RiskEntityReportComponent,
  AdvanceSearchComponent
} from './index';

const routes: Routes = [
  { path: 'risk-dashboard', component: RiskScreeningComponent },
  { path: 'dashboard/advance-search', component: AdvanceSearchComponent },
  { path: 'risk-dashboard/report/:id', component: RiskDashboardReportComponent },
  { path: 'dashboard', component: DashboardMainComponent },
  { path: 'aml-investigation-results', component: AmlResultsMainComponent },
  { path: 'aml-investigation-results/:type', component: AmlResultsMainComponent },
  { path: 'risk-individual-report', component: RiskIndividualReportComponent },
  { path: 'risk-individual-report/:guid', component: RiskIndividualReportComponent },
  { path: 'risk-corporate-report', component: RiskCorporateReportComponent },
  { path: 'risk-corporate-report/:guid', component: RiskCorporateReportComponent },
  { path: 'risk-entity-report', component: RiskEntityReportComponent },
  { path: 'risk-entity-report/:guid', component: RiskEntityReportComponent },
  { path: 'risk-policy', component: RiskPolicyTableComponent },
  { path: 'global-sanction-news', component: GlobalSanctionNewsComponent },
  { path: 'black-listed-entity', component: BlacklistedEntityComponent },
  { path: 'scheduled-checks', component: ScheduledChecksComponent },
  { path: 'recent-searches', component: RecentSearchesComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RiskScreeningRoutingModule { }

export * from './aml-check/aml-check.component';
export * from './address-check/address-check.component';
export * from './company-data/company-data.component'
export * from './corp-form/corp-form.component';
export * from './id-check/id-check.component';
export * from './individual-form/individual-form.component';
export * from './kyc-check/kyc-check.component';
export * from './oc-search/oc-search.component';
export * from './okyc-checks/okyc-checks.component';
export * from './pan-checks/pan-checks.component';
export * from './passport-validations/passport-validations.component';
export * from './ocr-check/ocr-check.component';
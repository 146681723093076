import { Component, ElementRef, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DataSet, Network, Options } from 'vis-network/standalone';


const colors = {
  dark_text: "#98A6AD",
  white_text: "#ffffff"
}

@Component({
  selector: "app-network-graph",
  // template: "<div></div>",  
  templateUrl: './vis-network.component.html',
  styleUrls: ["./vis-network.component.scss"],
})

export class NetworkGraphComponent implements OnInit {
  private network: Network | undefined;
  private nodes = new DataSet([
    {
      id: 1,
      shape: "circularImage",
      image: "assets/images/network-graph/individual-medium.svg",
      size: 50,
      label: "Node 1",
      title: "Sub-label: Node 1 Details\nAdditional info here",
    },
    {
      id: 2,
      shape: "circularImage",
      image: "assets/images/network-graph/corporate-low.svg",
      size: 30,
      label: "Node 2",
      title: "Sub-label: Node 2 Details\nAdditional info here",
    },
    {
      id: 3,
      shape: "circularImage",
      image: "assets/images/network-graph/individual-medium.svg",
      size: 30,
      label: "Node 3",
      title: "Sub-label: Node 3 Details\nAdditional info here",
    },
    {
      id: 4,
      shape: "circularImage",
      image: "assets/images/network-graph/corporate-low.svg",
      size: 30,
      label: "Node 4",
      title: "Sub-label: Node 4 Details\nAdditional info here",
    },
  ]);

  private edges = new DataSet([
    { from: 1, to: 2, id: 1, label: '1231', font: { align: "middle" } },
    { from: 1, to: 3, id: 2, label: '1232', font: { align: "middle" } },
    { from: 1, to: 4, id: 3, label: '1233', font: { align: "middle" } },

  ]);
  private rawApiData = { nodes: [], edges: [] }
  singleClickTimer = null;
  singleClickDelay = 300; // Delay in milliseconds
  selectedNode = null;
  constructor(private el: ElementRef) {

  }
  /// network graph data api fetching, default id for test
  async fetchRadialData(id, type) {
    console.log(id, type)
    if (id && type) {
      const url = `${environment.apiUrl}/corporate/uk/graph/${type}/${id}`
      try {
        let access_token = getCookie("token");
        const res = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`

          }
        });

        const data = await res.json();
        return {
          edges: data.edges?.map((e) => ({
            // ...e,

            from: e?.source,
            to: e?.target,
            id: e?.source + "-" + e?.target,
            label: capitalizeWords(e?.info?.ownerShip, 20),
            // value:e?.info?.ownerShip?.join(" "),
            font: { align: "middle" },
            title:capitalizeWords(e?.info?.ownerShip,null),
          })),
          nodes: data.nodes.map(e => ({
            ...e,
            edgeStatus: data.edges?.find((fn) => fn?.source == e?.id)?.info?.status,
            label: e?.name ? (e?.name?.split(" ").join("\n")) : 'Undisclosed entity',
            // root: e.id === id ,
            shape: "circularImage",
            image: `assets/images/network-graph/${e.type === "Organisation" ? 'corporate' : 'individual'}${e.id === id  ? '-root' : ''}.svg`,
            size: e.id === id ? 50 : 30,
           // title: "Sub-label: Node 4 Details\nAdditional info here",
          }))
        }

      } catch (error) {
        //
        console.log(error)
      }
    }
  }
  hideCollapse(){
    const elem = document.querySelector("#detailsCollapse")
    elem.classList.remove("show")
  }
  async ngOnInit() {

    const amlSearchQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'))
    console.log(12, amlSearchQuery)
    this.rawApiData = await this.fetchRadialData(amlSearchQuery?.companyId, amlSearchQuery?.investigationType)
    this.nodes = new DataSet([...this.rawApiData.nodes])
    this.edges = new DataSet([...this.rawApiData.edges])
    console.log('radial data', this.rawApiData);



    const container = this.el.nativeElement.querySelector("#relationship-graph");
    const res = {
      nodes: this.nodes,
      edges: this.edges
    }


    const data = {
      nodes: this.nodes,
      edges: this.edges
    }
    console.log('99', res, data);
    const options: Options = {
      nodes: {
        shape: 'circle',
        size: 30,
        borderWidth: 0,
        borderWidthSelected: 0,
        chosen: {
          node: function (values, id, selected, hovering) {
            if (selected || hovering) {
              // Custom style for selected nodes
              values.borderColor = "#0075ff"
              values.borderWidth = 2;
              values.shadow = true
              values.shadowColor = "#0075ff"
              values.shadowSize = 8,
                values.shadowX = 0,
                values.shadowY = 0
            } 
            else {
              // Default style for non-selected nodes
              values.borderColor = "#26E78A"
              values.borderWidth = 0;
            }
          },
          label: false
        },
        // scaling: {
        //   max: 50,
        //   min: 30
        // },

        font: {
          size: 14,          // Font size
          background: 'transparent',
          color: colors.dark_text ,// Background color for label text,
          strokeColor:colors.dark_text,
          strokeWidth:1,

        },
        
        shapeProperties: {
          useBorderWithImage: true
        },
      },
      edges: {
        width: 2,
        // hoverWidth: 0,// this is additon to width
        // selectionWidth: 0, // this is additon to width
        color: {
          color: colors.dark_text,
          // highlight: colors.dark_text
           highlight:"#4fa8ed",
           hover:"#4fa8ed"
           
        },
        font: {
          color: colors.dark_text,
          size: 12, // px

          // background: 'red',
          strokeWidth: 0, // px


          // multi: false,
          vadjust: -6,

        },

        length: 330,
        // arrows: {
        //   to: {
        //     enabled: true,
        //     scaleFactor: 0.8
        //   }
        // },
        // smooth: true
      },
      interaction: {
        hover: true,
        tooltipDelay: 200
      },
      layout: {
        improvedLayout: true
      },
      physics: {
        enabled: true, // Enable physics simulation for automatic layout adjustment
        barnesHut: {
          gravitationalConstant: -20000,
          damping: 0.7,
          centralGravity: 0.3,
          springLength: 150,
          springConstant: 0.04,
          avoidOverlap: 1
        },
        repulsion: {
          centralGravity: 0.3,
          springLength: 95,
          springConstant: 0.04,
          nodeDistance: 200
        },
        stabilization: { iterations: 250 } // Stabilize the graph
      },
    };

    this.network = new Network(container, res, options);
    this.network.on('click', (event) => {
      /// handle on node click
      // Clear any existing timeout
      clearTimeout(this.singleClickTimer);

      // Set a timeout to handle single click
      this.singleClickTimer = setTimeout(() => {
        const { nodes: clickedNodes, pointer } = event;
        console.log('clickkednnodes', clickedNodes)
       
        const elem = document.querySelector("#detailsCollapse")
       
        if(clickedNodes.length === 0){
          elem.classList.remove("show")
        }
        else{
          elem.classList.add("show")
          this.selectedNode = this.nodes.get(clickedNodes[0])
        }
      }, this.singleClickDelay);
    });

    this.network.on('doubleClick', async (event) => {
      const { nodes: clickedNodes, pointer } = event;
      console.log('db click event', event);

      if (clickedNodes.length) {
        const nodeId = clickedNodes[0];
        let currentNode:any = this.nodes.get(nodeId)
        console.log('db crnt node ',currentNode);
        //  const node = nodes.get(nodeId);

        // Here you can programmatically add connected nodes
        // For demonstration, let's add a simple connection
        // const newNodeId = this.nodes.length + 1;
        // const newNode: any = {
        //   id: newNodeId,
        //   label: `New Node ${newNodeId}`,
        //   image: "assets/images/network-graph/individual-medium.svg",
        //   shape: "circularImage",
        //   size: 30,
        //   title: "new",
        // };
        // const newEdge: any = { from: nodeId, to: newNodeId, id: newNodeId };
        // console.log('d', newNode, newEdge)
        const res: any = await this.fetchRadialData(nodeId, currentNode?.type);
        let resData = JSON.parse(JSON.stringify(res));

        // if (nodeId && resData?.nodes?.length) {
        //   const nodeObj = resData?.nodes?.find((e) => e?.id === nodeId);
        //   if (nodeObj?.message) {
        //     let message = nodeObj?.message;
        //     let within_parentheses = '';
        //     let outside_parentheses = '';
        //     if (message.includes('(') && message.includes(')')) {
        //       let start_index = message.indexOf('(');
        //       let end_index = message.indexOf(')');
        //       within_parentheses = message.slice(start_index + 1, end_index);
        //       outside_parentheses = message.slice(0, start_index) + message.slice(end_index + 1);
        //     }
        //     else {
        //       outside_parentheses = message;
        //       within_parentheses = message;
        //     }
        //     resData['nodes'].push({ id: within_parentheses?.split(" ").join("\n"), label: within_parentheses?.split(" ").join("\n"), name: within_parentheses, root: false, status: null, type: 'TraceableInfo' });
        //     resData['edges'].push({ info: null, target: nodeObj?.id, source: within_parentheses?.split(" ").join("\n"), value: outside_parentheses });

        //   }
        // }
        console.log('1', resData.nodes.map(e => e.id))
        console.log('2', this.rawApiData.nodes.map(e => e.id))
        console.log('3', resData.edges.map(e => e.id))
        console.log('4', this.rawApiData.edges.map(e => e.id))

        if (resData?.nodes && Array.isArray(resData?.nodes)) {
          const newNodes = resData.nodes.filter(e => !this.nodes.get(e.id)).map(e => ({ ...e, x: currentNode.x, y: currentNode.y }))
          this.nodes.add(newNodes);
        }
        if (resData?.edges && Array.isArray(resData?.edges)) {
          const newEdges = resData.edges.filter(e => !this.edges.get(e.id))
          this.edges.add(newEdges);
        }

        ;
      }
    });

    // Add event listener for node selection
    this.network.on('selectNode', function (params) {
      // Remove existing custom class from all nodes
      const allNodes = document.querySelectorAll('.vis-network .vis-node');
      allNodes.forEach(node => node.classList.remove('selected-node'));

      // Add custom class to the selected node
      const selectedNode = document.querySelector(`.vis-network .vis-node[vis-id="${params.nodes[0]}"]`);
      if (selectedNode) {
        selectedNode.classList.add('selected-node');
      }
    });

    // Add event listener for node deselection
    this.network.on('deselectNode', function () {
      // Remove custom class from all nodes
      const allNodes = document.querySelectorAll('.vis-network .vis-node');
      allNodes.forEach(node => node.classList.remove('selected-node'));
    });
  }
  ngOnDestroy() {
    if (this.network) {
      this.network.destroy();
      clearInterval(this.singleClickTimer)
    }
  }






}
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
function capitalizeWords(input, maxLength) {
  console.log('input',input,maxLength);
  
  if (input?.length) {
    const splitData = input?.length > 1 ? input?.join(',') : input?.[0];
    const string = splitData
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')

    if (maxLength) {
      let subStr =  string.substring(0, maxLength)
      if(string.length > maxLength -3) {
        subStr += '...'
      }
      return subStr
    }
    return string


  }
  else {
    return ''
  }
}

function getUniqueByAttribute(arr, attribute) {
  const uniqueElements = new Map();

  arr.forEach(item => {
    if (item[attribute] !== undefined) {
      uniqueElements.set(item[attribute], item);
    }
  });

  return Array.from(uniqueElements.values());
}
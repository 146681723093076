import { Component, OnInit , Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {KycModalComponent} from '../../../shared/kyc-modal/kyc-modal.component';

@Component({
  selector: 'app-saved-company-data',
  templateUrl: './saved-company-data.component.html',
  styleUrls: ['./saved-company-data.component.scss']
})
export class SavedCompanyDataComponent implements OnInit {

	//storing details
	@Input() companiesData   : any ;
  @Input() canSetAlert     : any ; //a boolean flag to enable/disable Set Alert Button 
  @Input() endUserInfo     : any ; // an object having username , idinfo & idinfotype meant for set alert

	//for selecting company for details
	chosenCompany            : any ;

	bsModalRef               : BsModalRef;

	//Loading
	isApiLoading             : boolean = false;


	constructor(private modalService  : BsModalService) { }

	ngOnInit() {
		console.log("this.companiesData in saved company");
		console.log(this.companiesData);
	}

	doOfficerKYC(officer){
		if(!officer || !officer.name){
			return ;
    }
      var firstName = officer.name.split(' ').slice(0, -1).join(' ');
      var lastName = officer.name.split(' ').slice(-1).join(' ');
		var heroUser = {
			"identityInfo": null ,
      "firstName"   : firstName ,
      "lastName"    :  lastName,
      "dob"         : "",
			"endUserType" :"INDIVIDUAL" ,
			"companyID"   : this.companiesData.companyID ,
			"officerID"   : officer.id
		} ;
		console.log(officer);
		if(heroUser){
            var userInfo = heroUser 
            var userType = "INDIVIDUAL" ;
        }

        const initialState = {
           title              : `Create New User`,
           type               : "do-live-check" ,
           userType           : userType ,
           userInfo           : userInfo
        };
        this.bsModalRef = this.modalService.show(KycModalComponent, {
            initialState,
      			animated           : true,
      			keyboard           : false,
      			backdrop           : true,
      			ignoreBackdropClick: true ,
      			class              : "modal-lg liveCheckModal"
        });
        this.bsModalRef.content.closeBtnName = "Close";
        this.bsModalRef.content.onClose.subscribe(result => {
            if(result && Object.keys(result).length) {
              console.log(result);
            }
        });
	}

	openAMLModal(){
        const initialState = {
            title      : `Set AML ALert`,
            type       : "set-aml-alert" ,
            userType   : "CORPORATE" ,
            endUserInfo: this.endUserInfo 
        };
        this.bsModalRef = this.modalService.show(KycModalComponent, {
            initialState,
            class: "modal-lg scheduleModal"
        });
        this.bsModalRef.content.closeBtnName = "Close";
        console.log(this.bsModalRef.content.onClose);
        this.bsModalRef.content.onClose.subscribe(result => {
            console.log("results", result);
            if (Object.keys(result).length) {
                console.log(result);
                this.setAMLAlert(result);
            }
        });
	}

	setAMLAlert(result){

	}

}

export const AMLSearch = {
    "guid":"5bff5d390d8b42c679f397a66d60d9a71597d91c9168c70ed03ff65884abe6e5",
    "entityType":null,
    "title":null,
    "nameOriginalScript":null,
    "fullName":null,
    "firstName":null,
    "middleName":null,
    "lastName":null,
    "suffix":null,
    "nickNames":[
 
    ],
    "gender":null,
    "riskLevel":null,
    "designation":null,
    "placeOfBirth":null,
    "description":null,
    "primaryCountry":{
       "code":null,
       "name":null,
       "synonyms":null,
       "isActive":null
    },
    "allegationCountry":{
       "code":null,
       "name":null,
       "synonyms":null,
       "isActive":null
    },
    "residentJurisdiction":{
       "code":null,
       "name":null,
       "fullName":null,
       "country":null
    },
    "dataSource":{
       "id":null,
       "name":null,
       "slug":null,
       "url":null,
       "dataAgency":{
          "id":null,
          "name":null,
          "slug":null,
          "url":null,
          "isActive":null
       },
       "isActive":null
    },
    "category":{
       "id":null,
       "name":null,
       "slug":null,
       "isActive":null
    },
    "subCategory":{
       "id":null,
       "name":null,
       "slug":null,
       "amlCategory":null,
       "isActive":null
    },
    "details":[
       {
          "amlDetailID":null,
          "guid":null,
          "entityType":null,
          "title":null,
          "nameOriginalScript":null,
          "fullName":null,
          "firstName":null,
          "middleName":null,
          "lastName":null,
          "suffix":null,
          "nickNames":[
 
          ],
          "gender":null,
          "amlEntity":"5bff5d390d8b42c679f397a66d60d9a71597d91c9168c70ed03ff65884abe6e5",
          "dataSource":{
             "id":null,
             "name":null,
             "slug":null,
             "url":null,
             "dataAgency":{
                "id":null,
                "name":null,
                "slug":null,
                "url":null,
                "isActive":null
             },
             "isActive":null
          },
          "category":{
             "id":null,
             "name":null,
             "slug":null,
             "isActive":null
          },
          "subCategory":{
             "id":null,
             "name":null,
             "slug":null,
             "amlCategory":null,
             "isActive":null
          },
          "riskLevel":null,
          "designation":null,
          "placeOfBirth":null,
          "primaryCountry":null,
          "allegationCountry":{
             "code":null,
             "name":null,
             "synonyms":null,
             "isActive":null
          },
          "residentJurisdiction":{
             "code":null,
             "name":null,
             "fullName":null,
             "country":null
          },
          "description":null,
          "politicalParty":null,
          "exclusionDetails":null,
          "cause":null,
          "listedOn":{
             "date":null,
             "year":null,
             "month":null,
             "day":null,
             "quality":null,
             "dobType":null,
             "fromYear":null,
             "toYear":null
          },
          "deListedOn":{
             "date":null,
             "year":null,
             "month":null,
             "day":null,
             "quality":null,
             "dobType":null,
             "fromYear":null,
             "toYear":null
          },
          "submittedOn":{
             "date":null,
             "year":null,
             "month":null,
             "day":null,
             "quality":null,
             "dobType":null,
             "fromYear":null,
             "toYear":null
          },
          "excludingAgency":null,
          "isActive":null,
          "createdDate":null,
          "lastModifiedDate":null
       }
    ],
    "aliases":[
       {
          "amlAliasID":null,
          "guid":null,
          "amlEntity":null,
          "amlEntityDetail":null,
          "title":null,
          "nameOriginalScript":null,
          "fullName":null,
          "firstName":null,
          "middleName":null,
          "lastName":null,
          "suffix":null,
          "quality":null,
          "createdDate":null,
          "lastModifiedDate":null
       }
    ],
    "dobs":[
       {
          "amlDobID":null,
          "guid":null,
          "amlEntity":null,
          "amlEntityDetail":null,
          "dob":null,
          "dobYear":null,
          "dobMonth":null,
          "dobDay":null,
          "quality":null,
          "dobType":null,
          "fromYear":null,
          "createdDate":null,
          "lastModifiedDate":null,
          "toYear":null
       }
    ],
    "addresses":[
       {
          "id":null,
          "guid":null,
          "amlEntity":null,
          "amlEntityDetail":null,
          "fullAddress":null,
          "addressLine1":null,
          "addressLine2":null,
          "city":null,
          "state":null,
          "country":{
             "code":null,
             "name":null,
             "synonyms":null,
             "isActive":null
          },
          "postalCode":null,
          "createdDate":null,
          "lastModifiedDate":null
       }
    ],
    "relationShips":[
       {
          "id":null,
          "relationCategory":null,
          "parentEntity":{
             "amlEntityID":null,
             "guid":"5bff5d390d8b42c679f397a66d60d9a71597d91c9168c70ed03ff65884abe6e5",
             "entityType":null,
             "title":null,
             "nameOriginalScript":null,
             "fullName":null,
             "firstName":null,
             "middleName":null,
             "lastName":null,
             "suffix":null,
             "nickNames":[
 
             ],
             "gender":null,
             "dataSource":{
                "id":null,
                "name":null,
                "slug":null,
                "url":null,
                "dataAgency":{
                   "id":null,
                   "name":null,
                   "slug":null,
                   "url":null,
                   "isActive":null
                },
                "isActive":null
             },
             "category":{
                "id":null,
                "name":null,
                "slug":null,
                "isActive":null
             },
             "subCategory":{
                "id":null,
                "name":null,
                "slug":null,
                "amlCategory":null,
                "isActive":null
             },
             "riskLevel":null,
             "designation":null,
             "placeOfBirth":null,
             "primaryCountry":{
                "code":null,
                "name":null,
                "synonyms":null,
                "isActive":null
             },
             "allegationCountry":{
                "code":null,
                "name":null,
                "synonyms":null,
                "isActive":null
             },
             "residentJurisdiction":{
                "code":null,
                "name":null,
                "fullName":null,
                "country":null
             },
             "description":null,
             "createdDate":null,
             "lastModifiedDate":null
          },
          "childEntity":{
             "amlEntityID":null,
             "guid":"5bff5d390d8b42c679f397a66d60d9a71597d91c9168c70ed03ff65884abe6e5",
             "entityType":null,
             "title":null,
             "nameOriginalScript":null,
             "fullName":null,
             "firstName":null,
             "middleName":null,
             "lastName":null,
             "suffix":null,
             "nickNames":[
 
             ],
             "gender":null,
             "dataSource":{
                "id":null,
                "name":null,
                "slug":null,
                "url":null,
                "dataAgency":{
                   "id":null,
                   "name":null,
                   "slug":null,
                   "url":null,
                   "isActive":null
                },
                "isActive":null
             },
             "category":{
                "id":null,
                "name":null,
                "slug":null,
                "isActive":null
             },
             "subCategory":{
                "id":null,
                "name":null,
                "slug":null,
                "amlCategory":null,
                "isActive":null
             },
             "riskLevel":null,
             "designation":null,
             "placeOfBirth":null,
             "primaryCountry":{
                "code":null,
                "name":null,
                "synonyms":null,
                "isActive":null
             },
             "allegationCountry":{
                "code":null,
                "name":null,
                "synonyms":null,
                "isActive":null
             },
             "residentJurisdiction":{
                "code":null,
                "name":null,
                "fullName":null,
                "country":null
             },
             "description":null,
             "createdDate":null,
             "lastModifiedDate":null
          },
          "parentRelation":{
             "relationID":null,
             "name":null,
             "slug":null
          },
          "childRelation":{
             "relationID":null,
             "name":null,
             "slug":null
          },
          "parentEntityDetail":null,
          "childEntityDetail":null,
          "isActive":null,
          "createdDate":null,
          "lastModifiedDate":null
       }
    ],
    "adverseMedia":[
       {
          "id":null,
          "amlEntity":null,
          "dataSource":{
             "id":null,
             "name":null,
             "slug":null,
             "url":null,
             "dataAgency":{
                "id":null,
                "name":null,
                "slug":null,
                "url":null,
                "isActive":null
             },
             "isActive":null
          },
          "summary":null,
          "sourcePublishDate":null,
          "createdDate":null,
          "lastModifiedDate":null
       }
    ],
    "identifiers":[
       {
          "id":null,
          "guid":null,
          "amlEntity":null,
          "amlEntityDetail":null,
          "identifierType":null,
          "identifierID":null,
          "description":null,
          "city":null,
          "country":null,
          "issuedAt":null,
          "expiryAt":null,
          "createdDate":null,
          "lastModifiedDate":null
       }
    ],
    "createdDate":null,
    "lastModifiedDate":null
 }
 
 
 export const AMLResultData = {
     "data": {
         "content": [
             {
                 "guid": "5c6eed1d5aa6713dec49e71326db690a8d64c801a43767676e1afeb06225cbaa",
                 "entityType": "Individual",
                 "title": "Dr",
                 "nameOriginalScript": null,
                 "fullName": "Leanna Mallya",
                 "firstName": "Leanna",
                 "middleName": null,
                 "lastName": "Mallya",
                 "suffix": null,
                 "nickNames": null,
                 "gender": "Female",
                 "riskLevel": "National",
                 "designation": null,
                 "placeOfBirth": null,
                 "description": null,
                 "primaryCountry": {
                     "code": "US",
                     "name": "United States",
                     "synonyms": [
                         "US"
                     ],
                     "isActive": "ACTIVE"
                 },
                 "allegationCountry": null,
                 "residentJurisdiction": null,
                 "dataSource": {
                     "id": 2,
                     "name": "Wikipedia",
                     "slug": "wikipedia",
                     "url": "https://starsunfolded.com/leanna-mallya/",
                     "dataAgency": {
                         "id": 2,
                         "name": "Wikipedia",
                         "slug": "wikipedia",
                         "url": "https://en.wikipedia.org",
                         "isActive": "ACTIVE"
                     },
                     "isActive": "ACTIVE"
                 },
                 "category": {
                     "id": 1,
                     "name": "PEP",
                     "slug": "pep",
                     "isActive": "ACTIVE"
                 },
                 "subCategory": {
                     "id": 2,
                     "name": "Family Member",
                     "slug": "family-member",
                     "amlCategory": null,
                     "isActive": "ACTIVE"
                 },
                 "details": [
                     {
                         "amlDetailID": 2,
                         "guid": "3611c9352c80347886a6540d5b0d5ba1bea44bd64ff89af9936ecfbe5f9ddbbe",
                         "entityType": "Individual",
                         "title": "Dr",
                         "nameOriginalScript": null,
                         "fullName": "Leanna Mallya",
                         "firstName": "Leanna",
                         "middleName": null,
                         "lastName": "Mallya",
                         "suffix": null,
                         "nickNames": null,
                         "gender": "Female",
                         "amlEntity": "5c6eed1d5aa6713dec49e71326db690a8d64c801a43767676e1afeb06225cbaa",
                         "dataSource": {
                             "id": 2,
                             "name": "Wikipedia",
                             "slug": "wikipedia",
                             "url": "https://starsunfolded.com/leanna-mallya/",
                             "dataAgency": {
                                 "id": 2,
                                 "name": "Wikipedia",
                                 "slug": "wikipedia",
                                 "url": "https://en.wikipedia.org",
                                 "isActive": "ACTIVE"
                             },
                             "isActive": "ACTIVE"
                         },
                         "category": {
                             "id": 1,
                             "name": "PEP",
                             "slug": "pep",
                             "isActive": "ACTIVE"
                         },
                         "subCategory": {
                             "id": 2,
                             "name": "Family Member",
                             "slug": "family-member",
                             "amlCategory": null,
                             "isActive": "ACTIVE"
                         },
                         "riskLevel": "National",
                         "designation": null,
                         "placeOfBirth": null,
                         "primaryCountry": {
                             "code": "US",
                             "name": "United States",
                             "synonyms": [
                                 "US"
                             ],
                             "isActive": "ACTIVE"
                         },
                         "allegationCountry": null,
                         "residentJurisdiction": null,
                         "description": null,
                         "politicalParty": null,
                         "exclusionDetails": null,
                         "cause": null,
                         "listedOn": null,
                         "deListedOn": null,
                         "submittedOn": {
                             "date": null,
                             "year": 2018,
                             "month": 12,
                             "day": 11,
                             "quality": "STRONG",
                             "dateType": "Exact",
                             "fromYear": null,
                             "toYear": null
                         },
                         "excludingAgency": null,
                         "isActive": "ACTIVE",
                         "createdDate": "2019-07-29T13:00:45.536+0000",
                         "lastModifiedDate": "2019-07-29T13:00:45.536+0000"
                     }
                 ],
                 "aliases": [],
                 "dobs": [],
                 "addresses": [],
                 "relationShips": [],
                 "adverseMedia": [],
                 "identifiers": [],
                 "createdDate": null,
                 "lastModifiedDate": "2019-07-29T13:35:12.917+0000"
             },
             {
                 "guid": "e6f39adaab83bf57bcceb267a187e87648af05b07601a3a2f77ec24998ad9ee9",
                 "entityType": "Individual",
                 "title": "Dr",
                 "nameOriginalScript": null,
                 "fullName": "Vijay Vittal Mallya",
                 "firstName": "Vijay Vittal",
                 "middleName": null,
                 "lastName": "Mallya",
                 "suffix": null,
                 "nickNames": [
                     "King of Good Times"
                 ],
                 "gender": "Male",
                 "riskLevel": "National",
                 "designation": null,
                 "placeOfBirth": "Kolkata, West Bengal, India",
                 "description": null,
                 "primaryCountry": {
                     "code": "IN",
                     "name": "India",
                     "synonyms": [
                         "IND",
                         "IND"
                     ],
                     "isActive": "ACTIVE"
                 },
                 "allegationCountry": {
                     "code": "IN",
                     "name": "India",
                     "synonyms": [
                         "IND",
                         "IND"
                     ],
                     "isActive": "ACTIVE"
                 },
                 "residentJurisdiction": {
                     "code": "in",
                     "name": "India",
                     "fullName": "India",
                     "country": null
                 },
                 "dataSource": {
                     "id": 1,
                     "name": "Indian Central Bureau of Investigation",
                     "slug": "indian-central-bureau-of-investigation",
                     "url": "http://cbi.gov.in/pressreleases/",
                     "dataAgency": {
                         "id": 1,
                         "name": "Indian Central Bureau of Investigation",
                         "slug": "indian-central-bureau-of-investigation",
                         "url": "http://cbi.gov.in",
                         "isActive": "ACTIVE"
                     },
                     "isActive": "ACTIVE"
                 },
                 "category": {
                     "id": 1,
                     "name": "PEP",
                     "slug": "pep",
                     "isActive": "ACTIVE"
                 },
                 "subCategory": {
                     "id": 1,
                     "name": "Former PEP",
                     "slug": "former-pep",
                     "amlCategory": null,
                     "isActive": "ACTIVE"
                 },
                 "details": [
                     {
                         "amlDetailID": 1,
                         "guid": "5ebb80049a2f2dfa490e91b8fe12a492613a0b54f43051c2ed58174d454bcaec",
                         "entityType": "Individual",
                         "title": "Dr",
                         "nameOriginalScript": null,
                         "fullName": "Vijay Vittal Mallya",
                         "firstName": "Vijay Vittal",
                         "middleName": null,
                         "lastName": "Mallya",
                         "suffix": null,
                         "nickNames": [
                             "King of Good Times"
                         ],
                         "gender": "Male",
                         "amlEntity": "e6f39adaab83bf57bcceb267a187e87648af05b07601a3a2f77ec24998ad9ee9",
                         "dataSource": {
                             "id": 1,
                             "name": "Indian Central Bureau of Investigation",
                             "slug": "indian-central-bureau-of-investigation",
                             "url": "http://cbi.gov.in/pressreleases/",
                             "dataAgency": {
                                 "id": 1,
                                 "name": "Indian Central Bureau of Investigation",
                                 "slug": "indian-central-bureau-of-investigation",
                                 "url": "http://cbi.gov.in",
                                 "isActive": "ACTIVE"
                             },
                             "isActive": "ACTIVE"
                         },
                         "category": {
                             "id": 1,
                             "name": "PEP",
                             "slug": "pep",
                             "isActive": "ACTIVE"
                         },
                         "subCategory": {
                             "id": 1,
                             "name": "Former PEP",
                             "slug": "former-pep",
                             "amlCategory": null,
                             "isActive": "ACTIVE"
                         },
                         "riskLevel": "National",
                         "designation": null,
                         "placeOfBirth": "Kolkata, West Bengal, India",
                         "primaryCountry": {
                             "code": "IN",
                             "name": "India",
                             "synonyms": [
                                 "IND",
                                 "IND"
                             ],
                             "isActive": "ACTIVE"
                         },
                         "allegationCountry": {
                             "code": "IN",
                             "name": "India",
                             "synonyms": [
                                 "IND",
                                 "IND"
                             ],
                             "isActive": "ACTIVE"
                         },
                         "residentJurisdiction": {
                             "code": "in",
                             "name": "India",
                             "fullName": "India",
                             "country": null
                         },
                         "description": " Vide Judgment dated December 10, 2018 running into 74 pages consisting of 471 paragraphs has concluded that the allegations in the extradition request of CBI/Govt. of India constitute extraditable offences within the meaning of Extradition Act, 2003 of UK. The Court has observed that the conduct of Vijay Mallya constitute the offences of making false representation to make a gain for himself, conspiracy to defraud and money laundering. The conduct of Vijay Mallya is punishable in India by imprisonment for longer than 12 months. In the light of merits of the case the Extradition Court has passed judgment for Extradition of Vijay Mallya to India",
                         "politicalParty": "Independent",
                         "exclusionDetails": null,
                         "cause": null,
                         "listedOn": null,
                         "deListedOn": null,
                         "submittedOn": {
                             "date": null,
                             "year": 2018,
                             "month": 12,
                             "day": 11,
                             "quality": "STRONG",
                             "dateType": "Exact",
                             "fromYear": null,
                             "toYear": null
                         },
                         "excludingAgency": null,
                         "isActive": "ACTIVE",
                         "createdDate": "2019-07-29T13:00:45.448+0000",
                         "lastModifiedDate": "2019-07-29T13:00:45.540+0000"
                     }
                 ],
                 "aliases": [],
                 "dobs": [
                     {
                         "amlDobID": 1,
                         "guid": "e9144fc61cd13f9237704d47ba5e99bf20336496834675ee092bd4630567661d",
                         "amlEntity": null,
                         "amlEntityDetail": null,
                         "dob": "1955-12-17T18:30:00.000+0000",
                         "dobYear": 1955,
                         "dobMonth": 12,
                         "dobDay": 18,
                         "quality": "STRONG",
                         "dobType": "Exact",
                         "fromYear": null,
                         "createdDate": "2019-07-29T13:00:45.499+0000",
                         "lastModifiedDate": "2019-07-29T13:00:45.499+0000",
                         "toYear": null
                     }
                 ],
                 "addresses": [],
                 "relationShips": [
                     {
                         "id": 1,
                         "relationCategory": "Family",
                         "parentEntity": {
                             "amlEntityID": 1,
                             "guid": "e6f39adaab83bf57bcceb267a187e87648af05b07601a3a2f77ec24998ad9ee9",
                             "entityType": "Individual",
                             "title": "Dr",
                             "nameOriginalScript": null,
                             "fullName": "Vijay Vittal Mallya",
                             "firstName": "Vijay Vittal",
                             "middleName": null,
                             "lastName": "Mallya",
                             "suffix": null,
                             "nickNames": [
                                 "King of Good Times"
                             ],
                             "gender": "Male",
                             "dataSource": {
                                 "id": 1,
                                 "name": "Indian Central Bureau of Investigation",
                                 "slug": "indian-central-bureau-of-investigation",
                                 "url": "http://cbi.gov.in/pressreleases/",
                                 "dataAgency": {
                                     "id": 1,
                                     "name": "Indian Central Bureau of Investigation",
                                     "slug": "indian-central-bureau-of-investigation",
                                     "url": "http://cbi.gov.in",
                                     "isActive": "ACTIVE"
                                 },
                                 "isActive": "ACTIVE"
                             },
                             "category": {
                                 "id": 1,
                                 "name": "PEP",
                                 "slug": "pep",
                                 "isActive": "ACTIVE"
                             },
                             "subCategory": {
                                 "id": 1,
                                 "name": "Former PEP",
                                 "slug": "former-pep",
                                 "amlCategory": null,
                                 "isActive": "ACTIVE"
                             },
                             "riskLevel": "National",
                             "designation": null,
                             "placeOfBirth": "Kolkata, West Bengal, India",
                             "primaryCountry": {
                                 "code": "IN",
                                 "name": "India",
                                 "synonyms": [
                                     "IND",
                                     "IND"
                                 ],
                                 "isActive": "ACTIVE"
                             },
                             "allegationCountry": {
                                 "code": "IN",
                                 "name": "India",
                                 "synonyms": [
                                     "IND",
                                     "IND"
                                 ],
                                 "isActive": "ACTIVE"
                             },
                             "residentJurisdiction": {
                                 "code": "in",
                                 "name": "India",
                                 "fullName": "India",
                                 "country": null
                             },
                             "description": null,
                             "createdDate": "2019-07-29T13:00:45.428+0000",
                             "lastModifiedDate": "2019-07-29T13:00:45.428+0000"
                         },
                         "childEntity": {
                             "amlEntityID": 2,
                             "guid": "5c6eed1d5aa6713dec49e71326db690a8d64c801a43767676e1afeb06225cbaa",
                             "entityType": "Individual",
                             "title": "Dr",
                             "nameOriginalScript": null,
                             "fullName": "Leanna Mallya",
                             "firstName": "Leanna",
                             "middleName": null,
                             "lastName": "Mallya",
                             "suffix": null,
                             "nickNames": null,
                             "gender": "Female",
                             "dataSource": {
                                 "id": 2,
                                 "name": "Wikipedia",
                                 "slug": "wikipedia",
                                 "url": "https://starsunfolded.com/leanna-mallya/",
                                 "dataAgency": {
                                     "id": 2,
                                     "name": "Wikipedia",
                                     "slug": "wikipedia",
                                     "url": "https://en.wikipedia.org",
                                     "isActive": "ACTIVE"
                                 },
                                 "isActive": "ACTIVE"
                             },
                             "category": {
                                 "id": 1,
                                 "name": "PEP",
                                 "slug": "pep",
                                 "isActive": "ACTIVE"
                             },
                             "subCategory": {
                                 "id": 2,
                                 "name": "Family Member",
                                 "slug": "family-member",
                                 "amlCategory": null,
                                 "isActive": "ACTIVE"
                             },
                             "riskLevel": "National",
                             "designation": null,
                             "placeOfBirth": null,
                             "primaryCountry": {
                                 "code": "US",
                                 "name": "United States",
                                 "synonyms": [
                                     "US"
                                 ],
                                 "isActive": "ACTIVE"
                             },
                             "allegationCountry": null,
                             "residentJurisdiction": null,
                             "description": null,
                             "createdDate": "2019-07-29T13:00:45.530+0000",
                             "lastModifiedDate": "2019-07-29T13:00:45.530+0000"
                         },
                         "parentRelation": {
                             "relationID": 1,
                             "name": "Father",
                             "slug": "father"
                         },
                         "childRelation": {
                             "relationID": 2,
                             "name": "Daughter",
                             "slug": "daughter"
                         },
                         "parentEntityDetail": {
                             "amlDetailID": 1,
                             "guid": "5ebb80049a2f2dfa490e91b8fe12a492613a0b54f43051c2ed58174d454bcaec",
                             "entityType": "Individual",
                             "title": "Dr",
                             "nameOriginalScript": null,
                             "fullName": "Vijay Vittal Mallya",
                             "firstName": "Vijay Vittal",
                             "middleName": null,
                             "lastName": "Mallya",
                             "suffix": null,
                             "nickNames": [
                                 "King of Good Times"
                             ],
                             "gender": "Male",
                             "amlEntity": "e6f39adaab83bf57bcceb267a187e87648af05b07601a3a2f77ec24998ad9ee9",
                             "dataSource": {
                                 "id": 1,
                                 "name": "Indian Central Bureau of Investigation",
                                 "slug": "indian-central-bureau-of-investigation",
                                 "url": "http://cbi.gov.in/pressreleases/",
                                 "dataAgency": {
                                     "id": 1,
                                     "name": "Indian Central Bureau of Investigation",
                                     "slug": "indian-central-bureau-of-investigation",
                                     "url": "http://cbi.gov.in",
                                     "isActive": "ACTIVE"
                                 },
                                 "isActive": "ACTIVE"
                             },
                             "category": {
                                 "id": 1,
                                 "name": "PEP",
                                 "slug": "pep",
                                 "isActive": "ACTIVE"
                             },
                             "subCategory": {
                                 "id": 1,
                                 "name": "Former PEP",
                                 "slug": "former-pep",
                                 "amlCategory": null,
                                 "isActive": "ACTIVE"
                             },
                             "riskLevel": "National",
                             "designation": null,
                             "placeOfBirth": "Kolkata, West Bengal, India",
                             "primaryCountry": {
                                 "code": "IN",
                                 "name": "India",
                                 "synonyms": [
                                     "IND",
                                     "IND"
                                 ],
                                 "isActive": "ACTIVE"
                             },
                             "allegationCountry": {
                                 "code": "IN",
                                 "name": "India",
                                 "synonyms": [
                                     "IND",
                                     "IND"
                                 ],
                                 "isActive": "ACTIVE"
                             },
                             "residentJurisdiction": {
                                 "code": "in",
                                 "name": "India",
                                 "fullName": "India",
                                 "country": null
                             },
                             "description": " Vide Judgment dated December 10, 2018 running into 74 pages consisting of 471 paragraphs has concluded that the allegations in the extradition request of CBI/Govt. of India constitute extraditable offences within the meaning of Extradition Act, 2003 of UK. The Court has observed that the conduct of Vijay Mallya constitute the offences of making false representation to make a gain for himself, conspiracy to defraud and money laundering. The conduct of Vijay Mallya is punishable in India by imprisonment for longer than 12 months. In the light of merits of the case the Extradition Court has passed judgment for Extradition of Vijay Mallya to India",
                             "politicalParty": "Independent",
                             "exclusionDetails": null,
                             "cause": null,
                             "listedOn": null,
                             "deListedOn": null,
                             "submittedOn": {
                                 "date": null,
                                 "year": 2018,
                                 "month": 12,
                                 "day": 11,
                                 "quality": "STRONG",
                                 "dateType": "Exact",
                                 "fromYear": null,
                                 "toYear": null
                             },
                             "excludingAgency": null,
                             "isActive": "ACTIVE",
                             "createdDate": "2019-07-29T13:00:45.448+0000",
                             "lastModifiedDate": "2019-07-29T13:00:45.540+0000"
                         },
                         "childEntityDetail": {
                             "amlDetailID": 2,
                             "guid": "3611c9352c80347886a6540d5b0d5ba1bea44bd64ff89af9936ecfbe5f9ddbbe",
                             "entityType": "Individual",
                             "title": "Dr",
                             "nameOriginalScript": null,
                             "fullName": "Leanna Mallya",
                             "firstName": "Leanna",
                             "middleName": null,
                             "lastName": "Mallya",
                             "suffix": null,
                             "nickNames": null,
                             "gender": "Female",
                             "amlEntity": "5c6eed1d5aa6713dec49e71326db690a8d64c801a43767676e1afeb06225cbaa",
                             "dataSource": {
                                 "id": 2,
                                 "name": "Wikipedia",
                                 "slug": "wikipedia",
                                 "url": "https://starsunfolded.com/leanna-mallya/",
                                 "dataAgency": {
                                     "id": 2,
                                     "name": "Wikipedia",
                                     "slug": "wikipedia",
                                     "url": "https://en.wikipedia.org",
                                     "isActive": "ACTIVE"
                                 },
                                 "isActive": "ACTIVE"
                             },
                             "category": {
                                 "id": 1,
                                 "name": "PEP",
                                 "slug": "pep",
                                 "isActive": "ACTIVE"
                             },
                             "subCategory": {
                                 "id": 2,
                                 "name": "Family Member",
                                 "slug": "family-member",
                                 "amlCategory": null,
                                 "isActive": "ACTIVE"
                             },
                             "riskLevel": "National",
                             "designation": null,
                             "placeOfBirth": null,
                             "primaryCountry": {
                                 "code": "US",
                                 "name": "United States",
                                 "synonyms": [
                                     "US"
                                 ],
                                 "isActive": "ACTIVE"
                             },
                             "allegationCountry": null,
                             "residentJurisdiction": null,
                             "description": null,
                             "politicalParty": null,
                             "exclusionDetails": null,
                             "cause": null,
                             "listedOn": null,
                             "deListedOn": null,
                             "submittedOn": {
                                 "date": null,
                                 "year": 2018,
                                 "month": 12,
                                 "day": 11,
                                 "quality": "STRONG",
                                 "dateType": "Exact",
                                 "fromYear": null,
                                 "toYear": null
                             },
                             "excludingAgency": null,
                             "isActive": "ACTIVE",
                             "createdDate": "2019-07-29T13:00:45.536+0000",
                             "lastModifiedDate": "2019-07-29T13:00:45.536+0000"
                         },
                         "isActive": "ACTIVE",
                         "createdDate": "2019-07-29T13:00:45.560+0000",
                         "lastModifiedDate": "2019-07-29T13:00:45.560+0000"
                     }
                 ],
                 "adverseMedia": [],
                 "identifiers": [
                     {
                         "id": 1,
                         "guid": "b9ea2032af8a5cf9111a46a5f3e824a17b3f8e7573a82a80d8bf0b739861be97",
                         "amlEntity": null,
                         "amlEntityDetail": null,
                         "identifierType": "PASSPORT",
                         "identifierID": "D1014381",
                         "description": null,
                         "city": null,
                         "country": {
                             "code": "IN",
                             "name": "India",
                             "synonyms": [
                                 "IND",
                                 "IND"
                             ],
                             "isActive": "ACTIVE"
                         },
                         "issuedAt": null,
                         "expiryAt": null,
                         "createdDate": "2019-07-29T13:00:45.516+0000",
                         "lastModifiedDate": "2019-07-29T13:00:45.516+0000"
                     }
                 ],
                 "createdDate": null,
                 "lastModifiedDate": "2019-07-29T13:34:22.591+0000"
             }
         ],
         "pageable": {
             "sort": {
                 "sorted": false,
                 "unsorted": true
             },
             "pageSize": 5,
             "pageNumber": 0,
             "offset": 0,
             "paged": true,
             "unpaged": false
         },
         "totalElements": 2,
         "last": true,
         "totalPages": 1,
         "first": true,
         "sort": {
             "sorted": false,
             "unsorted": true
         },
         "numberOfElements": 2,
         "size": 5,
         "number": 0
     },
     "message": null,
     "infoType": "INFO",
     "statusCode": 200
 }
 
 
 export const RelationData={"Father":1,
 "Child":-1,
 "Spouse":0,
 "Mother":1,
 "Sibling":0,
 "uncle":1,
 "grandfather":2,
 "nephew":-1,
 "cousin":0,
 "male first cousin":0,
 "brother-in-law":0,
 "niece":-1,
 "great-grandfather":3,
 "aunt":1,
 "grandson":-1,
 "paternal uncle":1,
 "father-in-law":1,
 "paternal grandfather":2,
 "son-in-law":-1,
 "maternal grandfather":2,
 "grandmother":2,
 "stepdaughter":-1,
 "stepson":-1,
 "female first cousin":0,
 "wife's father":1,
 "granddaughter":-2,
 "granduncle":2,
 "sister-in-law":0,
 "maternal uncle":1,
 "stepbrother":0,
 "daughter-in-law":-1,
 "stepsister":0,
 "mother-in-law":1,
 "parent's brother":1,
 "paternal aunt":1,
 "fraternal nephew":-1,
 "sister's husband":0,
 "second cousin":0,
 "maternal grandmother":2,
 "maternal aunt":1,
 "great-grandmother":3,
 "paternal half-brother":0,
 "grandaunt":2,
 "great-great-grandfather":4,
 "husband's father":1,
 "paternal grandmother":2,
 "wife's brother":1,
 "grandnephew":-2,
 "male cousin":0,
 "second uncle":1,
 "great-grandson":-3,
 "stepfather":1,
 "paternal half-sister":0,
 "sororal nephew":-1,
 "brother":0,
 "half-brother":0,
 "son's son":-2,
 "great-great-grandparent":4,
 "paternal great-grandparent":3,
 "daughter's son":-2,
 "great-granduncle":3,
 "adoptive father":1,
 "wife's mother":1,
 "adoptive parent":1,
 "grandniece":-2,
 "great-granddaughter":-3,
 "maternal half-sister":0,
 "stepmother":1,
 "sibling-in-law":0,
 "fraternal niece":-1,
 "maternal half-brother":0,
 "paternal first cousin":0,
 "sororal niece":-1,
 "maternal first cousin":0,
 "child-in-law":-1,
 "paternal cousin":0,
 "father":1,
 "father's father's father":3,
 "second nephew":-1,
 "younger paternal uncle":1,
 "adopted son":-1,
 "paternal great-great-grandfather":4,
 "uncle-in-law":1,
 "husband's brother":0,
 "parent's sister":1,
 "stepchild":-1,
 "daughter's daughter":-2,
 "second aunt":1,
 "third cousin":0,
 "sister":0,
 "brother's wife":0,
 "female cousin":0,
 "half-sister":0,
 "male second cousin":0,
 "second niece":-1,
 "adopted child":-1,
 "elder brother":0,
 "great-great-great-grandparent":5,
 "maternal great-grandparent":3,
 "son's daughter":-2,
 "adoptive mother":1,
 "co-father-in-law":1,
 "husband's mother":1,
 "great-great-grandson":-4,
 "great-great-great-grandfather":5,
 "elder paternal uncle":1,
 "great-great-grandmother":4,
 "husband's sister":0,
 "son":-1,
 "female second cousin":0,
 "parent-in-law":1,
 "younger brother":0,
 "fourth cousin":0,
 "goddaughter":-1,
 "godchild":-1,
 "co-brother-in-law":0,
 "elder sister":0,
 "father's mother's father":3,
 "godson":-1,
 "great-great-grandchild":4,
 "mother":1,
 "twin":0,
 "adopted daughter":-1,
 "first cousin":0,
 "godfather":1,
 "great-great-granddaughter":4,
 "mother's mother's father":3,
 "mother's mother's mother":3,
 "younger sister":0,
 "aunt-in-law":1,
 "father's father's mother":3,
 "grand uncle":2,
 "grandfather-in-law":2,
 "parent":1,
 "Cousin":0,
 "daughter":-1,
 "fiancé":0,
 "first cousin in-law":0,
 "grandchild":-2,
 "grandparent-in-law":2,
 "great-grandaunt":3,
 "great-grandnephew":-3,
 "maternal cousin":0,
 "mother's father's father":3,
 "niece-in-law":-1,
 "wife's sister":0,
 "adoptive brother":0,
 "child":-1,
 "foster brother":0,
 "fraternal twin":0,
 "godmother":1,
 "godparent":1,
 "great-grandniece":-3,
 "great-great-great-grandchild":-5,
 "marriage":0,
 "niece or nephew":-1,
 "spouse":0,
 "stepbrother brother-in-law":0,
 "stepgrandmother":2,
 "stepsister sister-in-law":0,
 "wife of maternal uncle":1,
 "wife of paternal uncle":1,
 "10th great-grandparent":11,
 "11th great-grandparent":12,
 "15th great-grandparent":16,
 "4th great-grandfather":5,
 "6th great-grandfather":7,
 "Cousine":0,
 "Nephew":-1,
 "Spouse's nephew":-1,
 "Uncle":1,
 "benefactor male first cousin":0,
 "blood brother":0,
 "bride":0,
 "cousin second cousin":0,
 "double cousin":0,
 "foster child":-1,
 "foster mother":1,
 "granddaughter-in-law":-2,
 "grandson (English)":-2,
 "grandson-in-law":-2,
 "great-grandfather paternal great-grandparent":3,
 "great-grandmother maternal great-grandparent":3,
 "great-grandmother paternal great-grandparent":3,
 "great-great-great-grandmother":5,
 "great-great-great-great-grandchild":-6,
 "great-great-great-great-grandparent":6,
 "husband":0,
 "husband of maternal aunt":1,
 "husband spouse":0,
 "maternal half-aunt":1,
 "nephew adopted son":-1,
 "nephew godchild":-1,
 "nephew son-in-law":-1,
 "niece goddaughter":-1,
 "parent's brother-in-law":1,
 "paternal cousin female first cousin":0,
 "paternal grandfather adoptive father":2,
 "paternal great-grandfather":3,
 "paternal half-brother younger brother":0,
 "paternal nephew":-1,
 "paternal uncle custodian":1,
 "paternal uncle wife's father":1,
 "second niece goddaughter":-1,
 "sibling":0,
 "son-in-law grandnephew":-2,
 "son-in-law male first cousin":0,
 "stepgrandfather":2,
 "stepgrandson":-2,
 "stepmother-in-law":2,
 "uncle godfather":1,
 "uncle wife's father":1,
 "wife":0,
 "wife of husband's brother":0,
 "wife's father maternal uncle":1,
 "younger paternal uncle adoptive parent":1,
 "paternal cousin male first cousin":0,
 "great-grandfather maternal great-grandparent":3,
 "aunt foster mother":1,
 "ancestor paternal grandfather":2,
 "brother-in-law sister's husband":1,
 "fraternal niece adopted daughter":-1,
 "uncle father-in-law":1,
 "son-in-law nephew":-1,
 "godchild second niece":-1,
 "godfather grandfather":2,
 "godson second nephew":-1,
 "cousin godson":0,
 "adoptive father maternal uncle":1,
 "adoptive parent paternal aunt":1,
 "Chinese adoption of children from near relatives":-1,
 "third-degree relative":-99,
 "kinship":-99,
 "half cousin godchild":-99,
 "child of a sibling, aunt or uncle":-99,
 "cousin adoptive father":-99,
 "adoption":-99,
 "uncle adoptive parent":1,
 "niece godchild":-1,
 "intimate relationship":0,
 "granduncle maternal grandfather":2,
 "distant relative":-99,
 "nephew godson":-1,
 "kin":-99,
 "first-degree relative":-99,
 "progenitor":-99,
 "descendant":-99,
 "lineal descendant":-99,
 "ancestor":-99}
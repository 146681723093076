import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { KycModalComponent } from '../../../shared/kyc-modal/kyc-modal.component';
import { Router,ActivatedRoute } from '@angular/router';
import { DevOpsService } from '../services/dev-ops.service';

@Component({
  selector: 'app-video-kyc-client-details',
  templateUrl: './video-kyc-client-details.component.html',
  styleUrls: ['./video-kyc-client-details.component.scss']
})
export class VideoKycClientDetailsComponent implements OnInit {

  reportDetails: any = null;
  newPage;
  page: number = 1;
  size: number = 10;
  order: string;
  orderArray: any[] = [
    { key: 'Latest First', value: 'DESC' },
    { key: 'Oldest First', value: 'ASC' },
  ];
  statusArray: any[] = [
    { key: 'COMPLETED', value: 'COMPLETED' },
    { key: 'IN PROGRESS', value: 'ACKNOWLEDGED' },
    { key: 'INITIATED', value: 'NOT_DONE' },
  ];
  tableList: any[] = [];
  totalItems: number = 0;
  itemPerPage: any[] = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  resultsCount: number = 0;
  searchKey: any;
  kycStatus: any = null;
  bsModalRef: BsModalRef;
  clientId = null
  constructor(private route: ActivatedRoute,private _data: DataService, private _devops: DevOpsService, private modalService: BsModalService,private router: Router) { }

  ngOnInit() {
    this.order = 'DESC';
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    console.log('clientId',this.clientId)
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.searchKey, this.kycStatus, true);
  }

  refreshTable() {
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.searchKey, this.kycStatus, true);
  }
  getKycStatusKey(status:string){
    switch (status) {
      case "NOT_DONE":
      return 'Initiated'
        case "ACKNOWLEDGED":
          return 'In Progress'
        
           case "COMPLETED":
            return 'Completed'
        case "FAILED":
          return "Failed"
        
      
      default:
        break;
    }
  }
  getTableData(page, size, order, search, status, searchFilterCalled?: boolean) {
    const payload = {
      "size": size,
      "page": page,
      "order": order,
      "orderBy": "createdDate",
      "fullName": search ? search : null,
      "kycStatus": status ? status : null
    };
    this._data.changeLoaderVisibility(true);
    this._devops.getVideoKycClientsDataByClientId(this.clientId,payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.tableList = res['content'] || [];
      this.tableList = [...this.tableList.map(e=>({...e,kycStatusKey:this.getKycStatusKey(e?.kycStatus)}))]
      this.totalItems = res['totalElements'] || 0;
      if (searchFilterCalled) {
        this.resultsCount = res.totalElements;
      }
      this.initialNumberElement = page * size + 1;
      if (this.initialNumberElement + size > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.initialNumberElement + size - 1;
      }
    })
  }

  clearAllFilter() {
    this.page = 1;
    this.rowPageNo = 10;
    this.order = 'DESC';
    this.searchKey = null;
    this.kycStatus = null;
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.searchKey, this.kycStatus, true);
  }

  searcFilter() {
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.searchKey, this.kycStatus, false);
  }

  orderFilter(event) {
    this.order = event;
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.searchKey, this.kycStatus, false);
  }

  statusFilter(event) {
    this.kycStatus = event;
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.searchKey, this.kycStatus, false);
  }

  pageChange($event) {
    this.page = $event;
    this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
    if (this.page * this.rowPageNo > this.totalItems) {
      this.secondElement = this.totalItems
    }
    else {
      this.secondElement = this.page * this.rowPageNo;
    }
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.searchKey, this.kycStatus, false);
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.page = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.searchKey, this.kycStatus, false);
  }
  createNewUser(heroUser?: any, isEdit?: boolean) {
    if (!isEdit) {
      if (heroUser && heroUser.pendingChecks) {
        this._data.toastrShow(`User has already ${heroUser.pendingChecks} checks remaining , Please complete them to proceed`, "warning");
        return;
      }
    }
    else {
      if (heroUser && heroUser.userareaStatus == 'VERIFIED') {
        this._data.toastrShow(`KYC process is completed, no further changes can be made`, "warning");
        return;
      }
    }

    if (heroUser) {
      var userInfo = heroUser.corporateUserInfo ? heroUser.corporateUserInfo : heroUser.individualUserInfo;
      var userType = heroUser.corporateUserInfo ? "CORPORATE" : "INDIVIDUAL";
    }

    if (heroUser && heroUser.individualUserInfo) {
      //if identityInfoType is GUID , make it null
      userInfo.identityInfo = (heroUser.endUser.identityInfoType != "GUID") ? heroUser.endUser.identityInfo : null;
      userInfo.identityInfoType = (heroUser.endUser.identityInfoType == "GUID") ? "GUID" : null;
      userInfo.guid = (heroUser.endUser.identityInfoType == "GUID") ? heroUser.endUser.identityInfo : null;
      userInfo.username = heroUser.endUser.userName;
    }

    if (heroUser && heroUser.corporateUserInfo) {
      //if identityInfoType is GUID , make it null
      userInfo.identityInfo = (heroUser.endUser.identityInfoType != "GUID") ? heroUser.endUser.identityInfo : null;
      userInfo.identityInfoType = (heroUser.endUser.identityInfoType == "GUID") ? "GUID" : null;
      userInfo.guid = (heroUser.endUser.identityInfoType == "GUID") ? heroUser.endUser.identityInfo : null;
      userInfo.username = heroUser.endUser.userName;
    }

    const initialState = {
      title: isEdit ? `Edit User Details` : `Create New User`,
      type: "do-live-check",
      userType: userType,
      userInfo: userInfo,
      editableUserInfo: isEdit
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg liveCheckModal"
      // class: "modal-lg liveCheckModal"tabularDataModal
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      console.log("results", result);
      if (result && Object.keys(result).length) {
        // this.usersData.unshift(result);
        this.refreshTable()
      }
    });
  }
  handleRowClick(data){
    if(this.clientId){
      this.router.navigate([`video-kyc/${this.clientId}/details`], {state: {data},replaceUrl:true});
    }
    
  }

}

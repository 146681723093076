import { Component, OnInit, Input } from '@angular/core';
import { RiskScreeningService } from '../../../risk-screening.service';
import { correctColorOrder, pieChartOptions } from '../../widget.const';

@Component({
  selector: 'app-aml-risk-investigation-widget',
  templateUrl: './aml-risk-investigation-widget.component.html',
  styleUrls: ['./aml-risk-investigation-widget.component.scss', '../../../risk-screening.component.scss']
})
export class AmlRiskInvestigationWidgetComponent implements OnInit {
  pieData;
  legends = []
  pieOptions = pieChartOptions;
  noCharts: boolean = true;
  constructor(public _rs: RiskScreeningService) { }

  ngOnInit() {
    this.getAmlChangesBasedOnCategories()
  }
  async getAmlChangesBasedOnCategories() {
    const res = await this._rs.getAmlChangesBasedOnCategories().toPromise()
    this.pieData = res.data.alertsWidgetList.map(e => ({ name: e.label, value: e.labelCount }))
    this.pieOptions.colorScheme.domain = correctColorOrder(res.data.alertsWidgetList.map(e=>e.label))
    this.legends = res.data.alertsWidgetList.map(e => ({
      ...e, percent: (
        (e.labelCount * 100) /
        res.data.alertsWidgetList.reduce((a, b) => a + b.labelCount, 0)
        ).toFixed(2)
      }))
    if(this.pieData?.length && this.pieData?.some((e) => e?.value > 0)){
      this.noCharts = false;

    }
  }
}

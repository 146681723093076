import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "keyVal"
})
export class KeyValPipe implements PipeTransform {
  transform(object: any, key?: any): any {
    var value = object ? object[key] : null;
    return value;
  }
}

// Usage:
// {{object | keyVal:key}}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { select, geoMercator, geoPath, json, event } from 'd3';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { KycModalComponent } from '../../../../../shared/kyc-modal/kyc-modal.component';
import * as topojson from "topojson-client";

@Component({
  selector: 'app-global-map',
  templateUrl: './global-map.component.html',
  styleUrls: ['./global-map.component.scss']
})
export class GlobalMapComponent implements OnChanges {
  @Input() category: any;
  @Output() close = new EventEmitter<any>();
  chartId: string = 'default';
  public bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['category']['currentValue']){
      this.chartId = changes['category']['currentValue'].label.toUpperCase();
      setTimeout(() => {
        this.loadMap(550, 380, changes['category']['currentValue'], this.chartId);
      }, 100);
    }
    else{
      this.chartId = 'default';
      setTimeout(() => {
        this.loadMap(550, 380, null, this.chartId);
      }, 100)
    }
  }

  loadMap(width, height, category, chartId){
    const svg = select(`#d3-world-map-${chartId}`).append('svg').attr('width', width).attr('height', height);
    const projection = geoMercator().scale(100).translate([width/2, height/2]);
    const graphPath = geoPath(projection);
    const graphG = svg.append('g');
    let modalRef = this.bsModalRef;
    let modalService = this.modalService;
    let modalClose = this.close;

    // Topo JSON for country wise https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json
    // Topo JSON for world https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json

    json('https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json').then((data) => {
      const topoCountries = topojson.feature(data, data.objects.countries);

      // Entire Graph
      let worldGraph = graphG.selectAll('path').data(topoCountries.features).enter().append('path').attr('d', graphPath);
      worldGraph.attr('fill', '#ccc').attr('stroke', '#999').attr("stroke-width", 1);   
      
      // Highlight Country
      if(category){
        let country = category.widgetData.map((e) => e.key.toLowerCase());
        const countryWiseData = topoCountries.features.filter((e) => country.includes(e.properties.name.toLowerCase()));
        let countryPath = graphG.selectAll(category.label).data(countryWiseData).enter().append('path').attr('class', category.label).attr('d', graphPath);
        countryPath.attr('fill', '#b70000').attr('stroke', '#fff').attr("stroke-width", 1);
  
        // Show the data on hover
        countryPath.on("click", function(d) {
          let country, totalValue, listData;
          const type = category.label;
          for(let item of category.widgetData){
            if(item.key.toLowerCase() == d.properties.name.toLowerCase()){
              country = item.key;
              totalValue = item.value;
              listData = item.entityNames
            }
          }
          modalClose.emit(country);
          // Show details in modal
          const initialState = {
            title: `Investigated Risks in ${country}`,
            type: "gloabal-map-aml-listing",
            globalMapAmlData : { country, type, totalValue, listData }
          };
          modalRef = modalService.show(KycModalComponent, {
            initialState,
            class: "modal-md make-modal-center",
          });
        });
      }
    });
  }

}

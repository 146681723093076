// export class bulkSchedule {
//   frequency: string;
//   startDate: any;
//   selectedItems: any;
//   constructor() {
//     this.frequency = "DAILY";
//     this.startDate = null;
//     this.selectedItems = [];
//   }
// }

import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { AppService, TrackingService } from "../../../services";
import { BulkUploadService } from "../bulk-upload.service";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-batch-create-checks",
  templateUrl: "./batch-create-checks.component.html",
  styleUrls: ["./batch-create-checks.component.scss"],
})
export class BatchCreateChecksComponent implements OnInit {
  @Output() newBatch = new EventEmitter();

  //config vars
  checksConfigData: any;
  dropdownSettings = {};
  isBatchCreated: boolean = false;

  //checklists
  checkList = [];
  idvArr = [];
  // bulkScheduleObj: bulkSchedule = new bulkSchedule();
  //selected Items
  selectedItems = [];
  frequency: any[] = ["DAILY", "WEEKLY", "MONTHLY", "YEARLY", "CUSTOM_DATE"];
  selectedFreq: string;
  //batch name
  batchName: any;
  startDate: any;
  endDate: any;

  constructor(
    private _app: AppService,
    private _bulkUp: BulkUploadService,
    public router: Router,
    private _track: TrackingService
  ) { }

  ngOnInit() {
    // get data
    this.getChecksConfig();

    //wrangle data

    //setting
    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  //dropdown selection handlers
  onItemSelect(item: any) {
    console.log(this.selectedItems);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  //get all checks
  getChecksConfig() {
    this._app.getStandardCheckConfig().subscribe(
      res => {
        this.checksConfigData = res.data;
        console.log(res.data);

        this.dropDownDataCreator();
      },
      err => { }
    );
  }

  //wrangle checks data
  dropDownDataCreator() {
    if (this.checksConfigData && this.checksConfigData["aml-checks"]) {
      this.checkList = this.checksConfigData["aml-checks"].map((item, index) => {
        if (item.status == "ACTIVE") {
          return { item_id: index, item_text: item.slug };
        }
      });
    }

    if (this.checksConfigData && this.checksConfigData["id-checks"]) {
      //add idv option
      this.checkList.push({
        item_id: this.checkList.length,
        item_text: "Identity Verification",
      });

      //flatten idv array
      this.idvArr = this.checksConfigData["id-checks"]
        .map(item => {
          if (item.status == "ACTIVE") {
            return item.slug;
          }
        })
        .filter(item => item);
    }

    console.log(this.checkList);
  }

  //create batch
  createBatch(e) {
    //flatten selected items
    var checks = this.selectedItems.map(item => {
      return item.item_text;
    });

    //add id checks array if idv is selected
    if (checks.indexOf("Identity Verification") >= 0) {
      var newChecks = checks.filter(item => item !== "Identity Verification");
      var idvS = ["id-doc-check", "selfie-check", "liveness-check"];
      console.log(newChecks);
      console.log([...idvS, ...newChecks]);
      checks = [...idvS, ...newChecks];
    }

    console.log(checks);

    // call batch creation api
    // let scheduleCheckSlugs = this.bulkScheduleObj.selectedItems.map(
    //   obj => obj.slug
    // );

    // let data = {
    //   identifierInfo: this.endUserInfo["identityInfo"],
    //   scheduleCheckSlugs: scheduleCheckSlugs,
    //   scheduledCheck: {
    //     intervalType: this.scheduleObj["frequency"],
    //     startDate: modifiedStartDate,
    //   },
    // };
    console.log({
      checks,
      batchName: this.batchName,
      schduleFrequency: this.selectedFreq,
      schedulingStartDate: this.startDate,
      schedulingEndDate: this.endDate
    })
    this._bulkUp
      .createBatch({
        checks,
        batchName: this.batchName,
        schduleFrequency: this.selectedFreq,
        schedulingStartDate: this.startDate,
        schedulingEndDate: this.endDate
      })
      .subscribe(
        async res => {
          console.log(res);
          await this._track.activityLog();
          if (e == "continue") {
            // this.isBatchCreated = true;
            this.newBatch.emit(null);
            // this.router.navigate([`/bulk-upload/${res.data.batchId}`]);
            this.router.navigate([`/bulk-upload/${res.data.batchId}`], { queryParams: { status: "INPROGRESS" } });
          } else {
            //kill modal and update table
            this.newBatch.emit(res.data);
            this.router.navigate[`/bulk-upload/${res.data.batchId}`];
          }
        },
        err => { }
      );
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AmlSearchComponent } from './aml-search.component';
import { BlackListComponent } from './black-list/black-list.component';

const routes: Routes = [
	{ path: "", component: AmlSearchComponent },
	{ path: 'report/:id', component: AmlSearchComponent },
  {path:"blacklist",component:BlackListComponent}
];

// const routerOptions: ExtraOptions = {
//   useHash: false,
//   anchorScrolling: 'enabled',
//   // ...any other options you'd like to use
// };

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AmlSearchRoutingModule { }

import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services';
import { KycModalComponent } from '../../../../app/shared/kyc-modal/kyc-modal.component';
import { RiskScreeningService } from '../risk-screening.service';
import { async } from 'q';

@Component({
  selector: 'app-risk-corporate-report',
  templateUrl: './risk-corporate-report.component.html',
  styleUrls: ['./risk-corporate-report.component.scss', '../risk-screening.component.scss']
})
export class RiskCorporateReportComponent implements OnInit {
  entityAmlData: any;
  entityMasterData: any;
  sticky: boolean = false;
  tabs: any[] = [
    {
      heading: 'Summary',
      slug: 'summary'
    },
    {
      heading: 'Investigation summary',
      slug: 'investigation-summary'
    },
    {
      heading: 'Financials',
      slug: 'financials'
    },
    {
      heading: 'Source of risk',
      slug: 'source-of-risk'
    },
    {
      heading: 'Relationship and connection',
      slug: 'relationship-and-connection'
    },
    {
      heading: 'Additional Info',
      slug: 'additional-Info'
    },
    {
      heading: 'Adverse Media Screening',
      slug: 'adverse-Media-Screening'
    },

  ];
  activeTabs: string = 'summary';
  riskProfileData: any;
  amlArray: any[] = [];
  corpAmlUseArray: any[] = [];
  basicDetailsPage: number = 1;
  categoryList = [{ "slug": "pep", "name": "PEP", "value": false, entity: "Individual" }, { "slug": "crime", "name": "Crime", "value": false, entity: "Individual,Organisation,Entity" },
  { "slug": "sanction", "name": "Sanction", "value": false, entity: "Individual,Organisation,Entity" }, { "slug": "watchlist", "name": "Watchlist", "value": false, entity: "Individual,Organisation,Entity" },
  { "slug": "sip", "name": "SIP", "value": false, entity: "Individual" }, { "slug": "sie", "name": "SIE", "value": false, entity: "Organisation,Entity" },
  { "slug": "si", "name": "SI", value: false, entity: "Individual,Organisation,Entity" }
  ];
  public bsModalRef: BsModalRef;
  riskScreeningReport = {
    // flagedArticle: false,
    entitySummary: false,
    entityDetails: false,
    showAdditionalInfo: false,
    amlScreening: false,
    showRiskScreening: false,
    networkAndRelationShip: false,
    incluseAdverseMedia: false,
    // filterOutMarkFalsePositive: false,
    showCrimeRecord: false,
    showSanctionRecord: false,
    showPepRecord: false,
    showWatchListRecord: false,
    showSipRecord: false,
    showSieRecord: false,
    showSources: false
  };
  // relation graph variables ---->
  displayGraph = false
  chartOptions = {
    chart: {
      type: 'networkgraph',
      backgroundColor: '#ffffff00',
    },

    title: {
      text: '',
    },
    plotOptions: {
      networkgraph: {
        layoutAlgorithm: {
          enableSimulation: true,
          integration: 'verlet',
          linkLength: 200
        },
      },
    },
    series: [
      {
        link: {
          width: 2,
        },
        cursor: 'pointer',
        point: {
          events: {
            click: function () {
              // window.open("aml-screening/risk-corporate-report/" + this.series.points.find(e => e.to === this.id).guid);
            }
          },
        },
        marker: {
          radius: 20,
        },
        dataLabels: {
          enabled: true,
          style: {
            textOverflow: 'ellipsis',
            color: '#98a6ad',
            textOutline: '#00000000',
          },
          // textPath: {
          //   enabled: true,
          //   attributes: {
          //     dy: 0,

          //   }
          // },
          linkFormat: '{point.parentRelation}'
        },
        data: [],
        nodes: [],
      },
    ],

  }
  detailedGraph: boolean = false
  relationGraphExists: boolean = true
  topData: any[] = [];
  isAmlDataFound: boolean;
  adverseRiskData: any;
  investigationQuery: any;
  registryData: any;
  countryObject: any;
  amlSummary: any;
  riskData:{
    lable:"test",
    score:50
  }
  healthCheckDetails: any;

  constructor(private _rs: RiskScreeningService, private route: Router, private _data: DataService,
    private _actRoute: ActivatedRoute, private modalService: BsModalService) { }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    windowScroll > 14 ? this.sticky = true : this.sticky = false;
  }

  async ngOnInit() {
    this._actRoute.params.subscribe(async ({ guid }) => {
      let amlData;
      if(sessionStorage.getItem('topData') && sessionStorage.getItem('topData') != 'null'){
        this.topData = JSON.parse(sessionStorage.getItem('topData'));
      }
      if (guid) {
        this._data.changeLoaderVisibility(true);
        const res = await this._rs.getDetailedRecord(guid).toPromise();
        if (res['data'] && res['data']['content']) {
          const amlResponse = res['data']['content'][0];
          this.isAmlDataFound = true;
          amlData = JSON.stringify(amlResponse);
          await this.getData(amlData);
        }
      }
      else{
        if(sessionStorage.getItem('amlSearchQuery')){
          this.investigationQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'));
          if(this.investigationQuery?.isRegistryCheck && this.investigationQuery?.companyId){
            this.registryData = await this.getRegistryData(this.investigationQuery?.companyId);
            if(this.registryData?.pscData?.length){
              let pscArr = [];
              let officerArr = [];
              for(let item of this.registryData?.pscData){
                let obj = { fullName: item?.fullName, dob: null, entityType: item?.entityType, nationality: item?.nationality, address: null, natureOfControl: null, status: null, connectionType: null, identificationNumber: item?.identificationNumber };
                if(item?.dob){
                  let dateOfBirth;
                  if(item?.dob?.dob){
                    dateOfBirth = item?.dob?.dob
                  }
                  else{
                    if(item?.dob?.dobMonth){
                      dateOfBirth = `${item?.dob?.dobMonth}-`
                    }
                    else{
                      dateOfBirth = '';
                    }
                    if(item?.dob?.dobYear){
                      dateOfBirth += `${item?.dob?.dobYear}`
                    }
                  }
                  obj['dob'] = dateOfBirth;
                }
                if(item?.details?.length){
                  const filterObj = item?.details?.filter((f) => f.linkedCompanyNumber == this.investigationQuery?.companyId)?.[0];
                  if(filterObj){
                    obj['address'] = filterObj?.correspondenceAddress;
                    obj['connectionType'] = filterObj?.connectionType;
                    obj['status'] = filterObj?.status;
                    if(filterObj?.natureOfControl?.includes('/')){
                      const splitData = filterObj?.natureOfControl?.split('/');
                      if(splitData?.[0]){
                        obj['natureOfControl'] = splitData?.[0]?.replaceAll('-', ' ');
                        pscArr.push(obj);
                      }
                      if(splitData?.[1]){
                        const tmpObj = {...obj};
                        tmpObj['natureOfControl'] = splitData?.[1]?.replaceAll('-', ' ');
                        officerArr.push(tmpObj);
                      }
                    }
                    else{
                      obj['natureOfControl'] = filterObj?.natureOfControl?.replaceAll('-', ' ');
                      if(obj?.connectionType == 'OFFICER'){
                        officerArr.push(obj);
                      }
                      else{
                        pscArr.push(obj);
                      }
                    }
                  }
                }
              }
              this.registryData['officerDetails'] = officerArr;
              this.registryData['pscDetails'] = pscArr;
            }
            if(this.registryData?.companyData?.countryOfOrigin){
              await this.getCountryObject(this.registryData?.companyData?.countryOfOrigin);
            }
          }
        }
        if (sessionStorage.getItem('entityAmlData')) {
          this.isAmlDataFound = true;
          amlData = sessionStorage.getItem('entityAmlData');
          await this.getData(amlData);
        }
        else {
          if(sessionStorage.getItem('amlSearchQuery')){
            this.isAmlDataFound = false;
            const investigationQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'));
            amlData = { masterData: {fullName: [investigationQuery?.searchKey], entityType: investigationQuery?.investigationType} };
            await this.getData(JSON.stringify(amlData));
          }
          else{
            this.route.navigate(['/aml-screening/dashboard'], {
              replaceUrl: true,
            });
          }
        }
      }
      await this.getReportModificationReport()
    });
  }

  getFinancialsDetails(fullName){
    const payload = {
      name: fullName
    }
    this._rs.fetchEntityHealthCheck(payload).subscribe(res => {
      if(res?.data?.data){
        this.healthCheckDetails = res.data.data;
      }
    })
  }

  async getAMLSummary(){
    if(this.entityAmlData?.guid){
      const response = await this._rs.getAMLSummaryByGuid(this.entityAmlData?.guid).toPromise();
      this._data.changeLoaderVisibility(true);
      if(response?.data?.data){
        this.amlSummary = response?.data?.data;
        this._data.changeLoaderVisibility(false);
      }
    }
  }

  async getRegistryData(companyId){
    this._data.changeLoaderVisibility(true);
    const payload = {
      companyId: companyId
    }
    const res = await this._rs.getRegistryData(payload).toPromise();
    this._data.changeLoaderVisibility(false);
    return res;
  }

  async getTopDataSourcesAml(datasource, category, country) {
    let payload = {
      category: category?.toLowerCase(),
      country: country,
      global: category?.toLowerCase() === "sanction" ? true : false,
    };
    try {
      let response = await this._rs.getTopDataSource(payload).toPromise();
      
      if (response && (response.length === 0 || response === null)) {
        payload.global = true;
        response = await this._rs.getTopDataSource(payload).toPromise();
      }
      
      if (response) {
        const Data = await this.updateFoundStatus(response, datasource);
        this.topData.push(Data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  async updateFoundStatus(Data, dataSource): Promise<void> {
    let response;
    if(dataSource){
      response = JSON.parse(dataSource);
      for (const e of Data) {
        try {
          let foundStatus = response?.map((item) => item.toLowerCase())
            .includes(e.name.toLowerCase());
          e["found"] = foundStatus;
        } catch (error) {
          console.error("Error updating found status:", error);
        }
      }
    }
    return Data
  }

  async getData(amlData) {
    this.entityAmlData = JSON.parse(amlData);
    console.log(this.entityAmlData, "entityAmlData")
    if (this.entityAmlData.masterData) {
      this.entityMasterData = this.entityAmlData.masterData;
      // called at aml-result page instead of here
      // await this.createAmlInvestigation();
      this.getFinancialsDetails(this.entityAmlData?.masterData?.fullName?.[0])
    }
    if (this.entityAmlData.guid) {
      await this.getCrrData();
      await this.getAMLSummary();
      // this.getRelationGraphData()
    }
    
    if(this.entityMasterData?.entityType){
      this.categoryList = this.categoryList.filter((e) => e.entity.includes(this.entityMasterData.entityType));
    }
    // Aml Data
    if (this.entityAmlData?.details && this.entityAmlData?.details.length) {
      this.amlArray = this.categoryList.map((e) => (
        {
          categoryName: e.name, entityType: this.entityMasterData.entityType,
          details: this.entityAmlData.details.filter((f) => f.category.findIndex((i) => i.slug === e.slug) != -1)
            .map((m) => ({ ...m, dateAdded: this.entityAmlData.recordCreatedDate, lastModified: this.entityAmlData.recordLastModifiedDate })),
          dataSource: JSON.stringify(this.entityAmlData.details.filter((f) => f.category.findIndex((i) => i.slug === e.slug) != -1)
            .map((m) => m.datasource && m.datasource.name))
        }
      ));
      for (var j = 0; this.amlArray.length > j; j++) {
        this.getIndexValueForCorpAML(j);
      }
    }
    else if(!this.isAmlDataFound && this.entityMasterData?.entityType){
      this.amlArray = this.categoryList.map((e) => (
        {
          categoryName: e.name, entityType: this.entityMasterData.entityType,
          details: [],
          dataSource: ''
        }
      )); 
      for (var j = 0; this.amlArray.length > j; j++) {
        await this.getIndexValueForCorpAML(j);
      }
    }
    if(!this.topData || this.topData?.length == 0){
      if (this.amlArray.length > 0) {
        let countrytemp = (this.entityMasterData?.countryOfCitizenship?.[0]) || (this.entityMasterData?.nationality?.[0]);
        try {
          for (let i = 0; i < this.amlArray.length; i++) {
            this._data.changeLoaderVisibility(true);
            await this.getTopDataSourcesAml(this.amlArray[i].dataSource, this.amlArray[i].categoryName, countrytemp);
            this._data.changeLoaderVisibility(false);
          }
          sessionStorage.setItem('topData',JSON.stringify(this.topData))
        } catch (error) {
          console.error("Error in sequential API calls:", error);
        }
      }
    }
  }

  async getCountryObject(country) {
    const res = await this._rs.getCountryDetailsByCountry(country).toPromise();
    this.countryObject = res['data'];
  }

  async createAmlInvestigation() {
    const payload = {
      "entityName": this.entityMasterData.fullName[0],
      "countryName": null,
      "entityCategoryList": this.entityMasterData.category.map((e) => e.name),
      "entityGuid": this.entityAmlData.guid,
      "entityType": this.entityMasterData.entityType
    }
    await this._rs.creatAmlInvestigation(payload).toPromise();
  }

  bookmarkedEntity() {
    const payload = {
      "fullName": this.entityMasterData.fullName[0],
      "guid": this.entityAmlData.guid,
      "entityType": this.entityMasterData.entityType
    }
    this._data.changeLoaderVisibility(true);
    this._rs.bookmarkedEntity(payload).pipe(finalize(() => {
      this._data.changeLoaderVisibility(false);
    })).subscribe(() => this._data.toastrShow('Entity Bookmarked', 'success'));
  }

  scheduledChecks() {
    const initialState = {
      title: 'Schedule Check',
      type: "risk-screening-scheduled-checks",
      categoryList: this.entityMasterData.category
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if (Object.keys(result).length) {
        // const startDate = new DatePipe('en-Us').transform(result.startDate, 'yyyy-MM-dd HH:mm:ss');
        const payload = {
          "entityName": this.entityMasterData.fullName[0],
          "entityType": this.entityMasterData.entityType,
          "entityGuid": this.entityAmlData.guid,
          "scheduledIntervalToCheck": result.interval,
          "categoryCheck": result.category,
          "customDate": result.startDate
        }
        this._data.changeLoaderVisibility(true);
        this._rs.scheduleChecks(payload).pipe(finalize(() => {
          this._data.changeLoaderVisibility(false);
        })).subscribe(() => this._data.toastrShow('Checks has been scheduled', 'success'));
      }
    });
  }

  async getCrrData() {
    const payload = {
      guid: null,
      adverseMediaScore: 0,
      entityType: this.entityMasterData?.entityType
    }
    if(this.entityAmlData?.guid){
      payload['guid'] = this.entityAmlData?.guid;
    }
    if(this.adverseRiskData){
      payload['adverseMediaScore'] = this.adverseRiskData?.score;
    }
    this._data.changeLoaderVisibility(true);
    const res = await this._rs.getAdvMediaResults(payload).toPromise();
    this._data.changeLoaderVisibility(false)
    if (res['data'] && res['data']['content'] && res['data']['content'].length) {
      this.riskProfileData = res['data']['content'][0].riskProfile;
    }
  }

  // For Auto Expand

  getIndexValueForCorpAML(i) {
    if (this.corpAmlUseArray.indexOf(i) > -1) {
      this.corpAmlUseArray.splice(this.corpAmlUseArray.indexOf(i), 1)
    }
    else {
      this.corpAmlUseArray.push(i)
    }
  }

  selectedTabs(slug: string) {
    this.goToTabs(slug);
    this.activeTabs = slug;
  }

  goToTabs(div) {
    const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
    const scrollToEl = document.getElementById(div)
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
    if (this.activeTabs != div) {
      let scrolly = topOfElement - 250;
      window.scroll({ top: scrolly, behavior: "smooth" });
    }
    else {
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }

  async getAdverseData(event){
    if(event){
      const adverseData = JSON.parse(event);
      if(!this.entityMasterData?.entityType){
        let entityType = 'Individual';
        const investigationQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'));
        if(adverseData?.content){
          for(let item of adverseData?.content){
            const findData = item?.analysis?.entiries?.find((f) => f?.entity.toLowerCase() == investigationQuery?.searchKey.toLowerCase())?.entityType;
            if(findData){
              entityType = findData
            }
          }
        }
        const amlData = { masterData: {fullName: [investigationQuery?.searchKey], entityType: entityType} };
        await this.getData(JSON.stringify(amlData));
      }
      this.adverseRiskData =  adverseData?.riskData;
      if(adverseData?.content?.length){
        this.adverseRiskData = { ...this.adverseRiskData, isAdverseFound: true }
      }
      this.getCrrData();
    }
  }

  // Read More and Read Less

  expand(id, action) {
    if (action == 'expand') {
      var element = document.getElementById(id);
      element.classList.add('d-none');
      element.classList.remove('d-inline')
      var expelement = document.getElementById(id + '-expand')
      expelement.classList.add('d-inline')
      expelement.classList.remove('d-none')
    }
    else {
      var element = document.getElementById(id);
      element.classList.remove('d-none');
      element.classList.add('d-inline');
      var expelement = document.getElementById(id + '-expand');
      expelement.classList.remove('d-inline')
      expelement.classList.add('d-none')
    }
  }

  goToDashboard() {
    this.route.navigate(['/aml-screening/dashboard'], {
      replaceUrl: true,
    });
    sessionStorage.removeItem('entityAmlData');
    sessionStorage.removeItem('amlSearchResponse')
  }
  // graph fx =======>
  getRelationGraphData() {
    this.displayGraph = false
    this._rs.getRelationGraphData(this.entityAmlData.guid).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res => {
      this.formatRelationGraphData(res.data)
    })
  }

  // Download Report

  async getReportModificationReport() {
    const response = await this._rs.getReportModificationReport().toPromise();
    if (response) this.riskScreeningReport = response
  }

  onClickGenerateReport() {
    const initialState = {
      type: "risk-screening-report",
      title: "Report",
      riskScreeningReport: this.riskScreeningReport
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(data => {
      if (Object.keys(data).length) {
        this.downloadReport(data)
      }
    });
  }

  downloadReport(data) {
    this._data.changeLoaderVisibility(true);
    this._rs.reportModification(data).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this._data.changeLoaderVisibility(true)
      let payload = {
        "guid": this.entityAmlData.guid,
        "name": this.entityMasterData.fullName[0]
      }
      this._rs.downloadIndividualReport(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
        if (res.size < 80) {
          this._data.toastrShow(`Insufficient Credits`, 'error');
        }
        else {
          const blob = new Blob([res], { type: 'application/pdf' });
          FileSaver.saveAs(blob, `${this.entityMasterData.fullName[0]}-report.pdf`);
        }
      })
    })
  }

  formatRelationGraphData(data) {
    if (data.length) {
      let arr = []
      const rootGuids = this.entityAmlData.details.map(e => e.guid)
      data.forEach(e => {
        const index = data.findIndex(p => p.childGuid === e.parentGuid)
        if (rootGuids.includes(e.parentGuid)) {
          arr.push({
            from: this.entityAmlData.masterData.fullName[0],
            to: `${e.fullName}`,
            parentRelation: e.parentRelation,
            childRelation: e.childRelation,
            guid: e.childGuid
          })
        }
        else if (index !== -1 && this.detailedGraph) {
          arr.push({
            from: `${data[index].fullName}`,
            to: e.fullName,
            parentRelation: e.parentRelation,
            childRelation: e.childRelation,
            guid: e.childGuid
          })
        }
      });
      this.chartOptions.series[0].data = arr

      const arrtemp = new Set(data.filter(e => (rootGuids.includes(e.parentGuid) || this.detailedGraph)).map(e => e.fullName))
      let nodesArr = Array.from(arrtemp);
      nodesArr = nodesArr.map(e => ({
        id: e,
        marker: {
          radius: 14,
          fillColor: '#17b18c'
        }
      }))
      this.chartOptions.series[0].nodes = [{
        id: this.entityAmlData.masterData.fullName[0],
        marker: {
          radius: 28,
          fillColor: '#ed7c15'
        },
      }, ...nodesArr]
      this.displayGraph = true
      this.chartOptions.plotOptions.networkgraph.layoutAlgorithm.linkLength = arr.length > 50 ? 100 : 200
    }
    else {
      this.relationGraphExists = false
    }

  }
  handleRelationData(type: 'zoom-in' | 'zoom-out') {
    if (this.detailedGraph !== (type === 'zoom-in')) {
      this.detailedGraph = (type === 'zoom-in')
      this.getRelationGraphData()
    }

  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class FinancialData {
  yearEnded : number;
  totalAssets: string;
  netAssets: string;
  totalLiabilities: string;
}

@Injectable({
  providedIn: 'root'
})
export class CorporateCheckService {

  constructor(private http: HttpClient, @Inject('API_URL') public API_URL: any) { }

  getJurisdiction(countryCode){
    return this.http.get<any>(`${this.API_URL}/country/jurisdiction/${countryCode}`).pipe(map((res) => res["data"]));
  }

  corporateSearch(payload: Object){
    return this.http.post(`${this.API_URL}/kys/v3.0/corporate/search`, payload).pipe(map((res: any) => res["data"]));
  }

  doAllCheck(payload: Object){
    return this.http.post(`${this.API_URL}/kys/v3.0/corporate/start-check/v1`, payload).pipe(map((res: any) => res["data"]));
  }

  companyInfo(id){
    return this.http.get(`${this.API_URL}/kys/v3.0/corporate/company-detail/${id}`).pipe(map((res: any) => res["data"]));
  }

  investigationDetails(id){
      return this.http.get(`${this.API_URL}/kys/v3.0/corporate/investigation-detail/${id}`).pipe(map((res: any) => res["data"]));
  }

  companyOverview(id){
      return this.http.get(`${this.API_URL}/kys/v3.0/corporate/company-overview/${id}`).pipe(map((res: any) => res["data"]));
  }

  nationalityRisk(id){
      return this.http.get(`${this.API_URL}/kys/v3.0/corporate/geolocation-risk/${id}`).pipe(map((res: any) => res["data"]));
  }

  adverseMediaTags(id){
      return this.http.get(`${this.API_URL}/kys/v3.0/corporate/adverse-media/${id}`).pipe(map((res: any) => res["data"]));
  }

  article(id){
      return this.http.get(`${this.API_URL}/kys/v3.0/corporate/article/${id}`).pipe(map((res: any) => res["data"]));
  }

  aML(id){
      return this.http.get(`${this.API_URL}/kys/v3.0/corporate/control-officer/${id}`).pipe(map((res: any) => res["data"]));
  }

  aMLforCompany(id){
      return this.http.get(`${this.API_URL}/kys/v3.0/corporate/aml/${id}`).pipe(map((res: any) => res["data"]));
  }

  saveEntity(id){
    return this.http.post(`${this.API_URL}/kys/v3.0/corporate/save-check/${id}?saveListStatus=SAVED`,{}).pipe(map((res: any) => res["data"]));
  }

  getSavedEntities(payload){
    return this.http.post(`${this.API_URL}/kys/v3.0/corporate/get/save-check`,payload).pipe(map((res: any) => res["data"]));
  }
  downloadReport(username) {
      let headers = new HttpHeaders({
          'Content-Type': 'application/octet-stream',
      });

      let authHttpOptions = {
          headers: headers,
          responseType: 'blob',
      };

      return this.http
          .get(`${this.API_URL}/kys/v3.0/corporate/report/${username}`, {
              headers: new HttpHeaders({
                  'Content-Type': 'application/octet-stream',
              }),
              responseType: 'blob',
          })
          .pipe(
              map((res) => {
                  return res;
              })
          );
  }

  getRiskStatus(id){
      return this.http.get(`${this.API_URL}/kys/v3.0/corporate/risk-details/${id}`).pipe(map((res: any) => res["data"]));
  }

  financialData(checkListId) : Observable<FinancialData[]> {
    return this.http.get<any>(`${this.API_URL}/kys/v3.0/corporate/company-financial-data/${checkListId}`).pipe(map((res) => res["data"]));
  }
}

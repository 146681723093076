import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services';
import { RiskScreeningService } from '../risk-screening.service';
import { correctColorOrder, pieChartOptions } from '../widgets/widget.const';

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.scss', '../risk-screening.component.scss']
})
export class DashboardMainComponent implements OnInit {
  card1Data = {
    isBgDiff: false,
    heading: 'Datasource added Today',
    content: '200 Sources',
    des: '+20 Datasources per Hr on Avergae',
    redirectionBtn: 'Know more',
    redirectionBtnClass: 'text-transparent-button',
    redirectUrl: '',
    title: 'The count of all new Datasources added today',
  }
  card2Data = {
    isBgDiff: false,
    heading: 'Entities added Today',
    content: '20,000 Entities',
    des: '+200 Entities per Hr on Average',
    btnTxt: 'Sanction 30%',
    btnType: 'sanction',
    redirectionBtn: 'Know more',
    redirectionBtnClass: 'text-transparent-button',
    redirectUrl: '',
    title: 'The count of all new Entities added today',
  }
  card3Data = {
    isBgDiff: true,
    heading: 'Our Exclusive AML Solution',
    content: '20,000 Records',
    des: 'From world wide data hubs',
    redirectionBtn: 'Start a new Investigation',
    redirectionBtnClass: 'orange-gradient-btn',
    redirectUrl: ''
  }
  pieData = []
  statsData = []
  pieOptions = pieChartOptions
  legends = []
  totalStats = {}
  todaysTopNews: any;
  dailyUpdates: any;
  categoryList: any[] = [];
  selectedCountryCategory: any;
  amlNewsData: any[] = [];
  globalSanctionNewsFull: boolean = false;
  fincaiAnalyticsFull: boolean = false;
  investigationStatusFull: boolean = false;
  selectedCountry: any;
  subCategoryData: any[] = [];
  selectedSubCategory: any;
  recentTableClass: boolean = false;
  topSearchClass: boolean = false;

  constructor(
    public _rs: RiskScreeningService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    (async () => {
      await this.getPieChartInfo();
      await this.getSubcategoryData();
      await this.getTodayNews();
      await this.getStatsDaily();
      await this.getLocationWiseChart();
    })()
  }
  async getLocationWiseChart() {
    const res = await this._rs.locationWiseChartList().toPromise()
    if (res['investigationWidgetList'] && res['investigationWidgetList'].length) {
      this.categoryList = res['investigationWidgetList'];
      this.selectedCountryCategory = res['investigationWidgetList'][0];
      this.getAmlWiseNews(this.selectedCountryCategory.label, []);
    }
  }
  getBadegeType(cat) {
    this.selectedCountryCategory = cat;
    this.selectedCountry = null;
    this.getAmlWiseNews(this.selectedCountryCategory.label, []);
  }

  expandGraph() {
    // var elem = document.getElementById("full-screen-graph");
    // if (elem.requestFullscreen) {
    //   elem.requestFullscreen();
    // }
  }

  getCountryName(event) {
    if (this.selectedCountry != event)
      this.getAmlWiseNews(this.selectedCountryCategory.label, [event]);
    this.selectedCountry = event;
  }

  async getSubcategoryData() {
    const payload = {
      "size": 10,
      "page": 0,
      "searchType": "FUZZY",
      "name": [],
      "country": [],
      "source": [],
      "guid": "",
      "category": [],
      "gender": [],
      "type": []
    }
    this.dataService.changeLoaderVisibility(true);
    const res = await this._rs.getSubCategoryData(payload).toPromise();
    this.dataService.changeLoaderVisibility(false)
    if (res['data']) {
      this.subCategoryData = res['data']['categoryList'] || [];
      if (this.subCategoryData.length) this.selectedSubCategory = this.subCategoryData[0].category;
    }
  }

  getSelectSubCategory(category) {
    this.selectedSubCategory = category;
  }

  async getStatsDaily() {
    const res = await this._rs.getDashboardStatsDaily().toPromise()
    this.dailyUpdates = res.data;
    this.card1Data['content'] = `${this.dailyUpdates.totalDs} Sources`;
    this.card2Data['content'] = `${this.dailyUpdates.totalEntities} Entities`;
    const percentage = ((this.dailyUpdates.totalSanctionEntities / this.dailyUpdates.totalEntities) * 100).toFixed(2);
    this.card2Data['btnTxt'] = `Sanction ${percentage}%`;
  }
  async getPieChartInfo() {
    const res = await this._rs.getDashboardStatsOverall().toPromise()
    let t = res.data
    this.totalStats = { entities: t.totalEntities, ds: t.totalDs }
    const data: any = [
      { name: 'Sanction', entities: t.totalSanctionEntities, noOfDs: t.totalSanctionDS },
      { name: 'Crime', entities: t.totalCrimeEntities, noOfDs: t.totalCrimeDS },
      { name: 'PEP', entities: t.totalPEPEntities, noOfDs: t.totalPEPDS },
      { name: 'Watchlist', entities: t.totalWatchListEntities, noOfDs: t.totalWatchListDS },
      { name: 'SIE / SIP', entities: t.totalSIPSIESIEntities, noOfDs: t.totalSIPSIESIDS }
    ]
    this.statsData = res.data
    this.pieData = data.map(e => ({ ...e, value: e.entities }))
    this.pieOptions.colorScheme.domain = correctColorOrder(data.map(e => e.name))
    this.pieOptions.explodeSlices = false
    this.legends = data.map(e => ({
      ...e, percent: (
        (e.entities * 100) /
        data.reduce((a, b) => a + b.entities, 0)
      ).toFixed(2)
    }))
  }
  async getTodayNews() {
    const payload = {
      "size": 1,
      "page": 0,
      "sort": 'desc',
      "searchType": "LOOSE",
      "searchBody": null,
      "keyword": ['Sanctions', 'Crime'], // 'AML', 'Money Laundering', 'Financial Crime', 'Bankruptcy', 
      "country": [],
      "publisherUrl": [],
      "startPublishDate": null,
      "endPublishDate": null
    }
    const res = await this._rs.getTrendingNews(payload).toPromise();
    if (res['data'] && res['data']['content'] && res['data']['content'].length) this.todaysTopNews = res['data']['content'][0];

  }

  getAmlWiseNews(category, country) {
    const keywords = [
      {
        slug: 'pep',
        keys: ['Political', 'politics']
      },
      {
        slug: 'sanction',
        keys: ['Sanction', 'Sanctions']
      },
      {
        slug: 'crime',
        keys: ['Crime']
      },
      {
        slug: 'watchlist',
        keys: ['Watchlist']
      },
      {
        slug: 'sie',
        keys: ['Company', 'Corporation', 'Entity']
      },
      {
        slug: 'sip',
        keys: ['Celebrity', 'Athlete']
      },
    ];
    let categoryKey = [];
    categoryKey = keywords.filter((f) => f.slug == category.toLowerCase()).map((e) => e.keys);
    const payload = {
      "size": 10,
      "page": 0,
      "sort": 'desc',
      "searchType": "LOOSE",
      "searchBody": null,
      "keyword": categoryKey[0],
      "country": country,
      "publisherUrl": [],
      "startPublishDate": null,
      "endPublishDate": null
    }
    this.dataService.changeLoaderVisibility(true);
    this._rs.getTrendingNews(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      if (res['data'] && res['data']['content'] && res['data']['content'].length) this.amlNewsData = res['data']['content'];
    });
  }

  // Go To Particula ID

  goToTabs(div) {
    const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
    const scrollToEl = document.getElementById(div)
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
    let scrolly = topOfElement - 120;
    window.scroll({ top: scrolly, behavior: "smooth" });
  }

  // Check the conditions 

  getRecentData(event) {
    if (event.length == 0) this.recentTableClass = true;
  }

  getTopSearchedData(event) {
    if (event.length == 0) this.topSearchClass = true;
  }

}
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services';
import { RiskScreeningService } from '../risk-screening.service';
import { AmlResultsTableComponent } from './aml-results-table/aml-results-table.component';

@Component({
  selector: 'app-aml-results-main',
  templateUrl: './aml-results-main.component.html',
  styleUrls: ['./aml-results-main.component.scss', '../risk-screening.component.scss']
})
export class AmlResultsMainComponent implements OnInit {
  @ViewChild(AmlResultsTableComponent) childComponent : AmlResultsTableComponent | undefined;
  entityArray: any[] = [
    { key: 'Individual', value: 'Individual' },
    { key: 'Organisation', value: 'Organisation' },
    { key: 'Vessel', value: 'Vessel' },
    { key: 'Aircraft', value: 'Aircraft' },
    { key: 'All', value: '' },
  ];
  searchTypeArray: any[] = [
    { key: 'Near Match', value: 'FUZZY' },
    { key: 'Broad Match', value: 'MOREFUZZY' },
    { key: 'Strict Match', value: 'STRICT' },
  ];
  investigationQuery: any = {
    searchKey: null,
    investigationType: 'Individual',
    riskPolicy: 'All',
    country: null,
    searchType: "FUZZY"
  };
  routes = {
    TABLE: 'table',
    AML: 'aml',
    ADVERSE:'adverse',
    CORPORATE: 'corporate-check'
  }
  routerHandle: string;
  amlData: any = JSON.parse(sessionStorage.getItem('amlSearchResponse'));
  constructor(private route: Router, private activeRoute: ActivatedRoute, private _data: DataService, private _rs: RiskScreeningService) { }

  ngOnInit() {
    this.routerHandle = this.routes.TABLE
    this.activeRoute.params.subscribe((params) => {
      if(params['type']){
        this.routerHandle = params['type'];
      }
      else{
        this.routerHandle = this.routes.TABLE
      }      
    })
    if(sessionStorage.getItem('topSearch')){
      this.investigationQuery = JSON.parse(sessionStorage.getItem('topSearch'))
    }
    else if(sessionStorage.getItem('amlSearchQuery')){
      this.investigationQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'));
      if(this.investigationQuery?.isRegistryCheck){
        this.entityArray = this.entityArray.filter((f) => f.key == 'Organisation');
      }
      this.setRoutes();
    }
  }

  setRoutes(){
    const route = ['table', 'aml', 'adverse', 'corporate-check'];
    if(route.includes(this.routerHandle)){
      this.route.navigate([`/aml-screening/aml-investigation-results/${this.routerHandle}`],{
        replaceUrl: true,
      });
    }
  }

  doSearch(){
    console.log(this.investigationQuery,'parent')
    this.localStorageConfigurations()
    if(this.childComponent){
      this.childComponent.customFunctionForSearchFilter(true,this.investigationQuery,this.inFetchLocalConfig)
      this.routeSetConfig()
    }
    else{
      const payload = { 
        "size": 10, 
        "page": 0, 
        "searchType": this.investigationQuery.searchType, 
        "name": [this.investigationQuery.searchKey], 
        "country": this.investigationQuery.country ? this.investigationQuery.country : [], 
        "source": this.investigationQuery['dataSources'] ? this.investigationQuery['dataSources'] : [], 
        "guid": "", 
        "category": this.investigationQuery['categories'] ? this.investigationQuery['categories'] : [], 
        "gender": [], 
        "type": this.investigationQuery.investigationType ? [this.investigationQuery.investigationType] : []
      }
      this._data.changeLoaderVisibility(true);
      this._rs.doAmlSearch(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
        sessionStorage.removeItem('amlSearchQuery');
        sessionStorage.setItem('amlSearchQuery', JSON.stringify(this.investigationQuery));
        if(res){
          sessionStorage.setItem('amlSearchResponse', JSON.stringify(res));
          this.amlData = res;
        }
        this.routerHandle = this.routes.TABLE
        this.setRoutes();
      })
    }
  }

  inFetchLocalConfig(){
    sessionStorage.removeItem('amlSearchQuery');
    sessionStorage.setItem('amlSearchQuery', JSON.stringify(this.investigationQuery));
  }

  routeSetConfig(){
    this.routerHandle = this.routes.TABLE
    this.setRoutes();
  }

  localStorageConfigurations(){
    sessionStorage.removeItem('amlSearchResponse');
    sessionStorage.removeItem('entityAmlData');
    sessionStorage.removeItem('topSearch')
  }

  clearSearch(){
    this.investigationQuery.searchKey = null;
  }

}

import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services';
import { KycModalComponent } from '../../../../app/shared/kyc-modal/kyc-modal.component';
import { RiskScreeningService } from '../risk-screening.service';

@Component({
  selector: 'app-blacklisted-entity',
  templateUrl: './blacklisted-entity.component.html',
  styleUrls: ['./blacklisted-entity.component.scss', '../risk-screening.component.scss']
})
export class BlacklistedEntityComponent implements OnInit {
  page: number = 1;
  size: number = 10;
  tableList: any[] = [];
  totalItems: number = 0;
  itemPerPage: any[] = [
    { value : 10 },
    { value : 25 },
    { value : 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  searchKey: any;
  bySearching: boolean = false;
  public bsModalRef: BsModalRef;
  selectedCountry: any;
  countryList: any[] = [];
  
  constructor(
    private _data: DataService,
    private _rs: RiskScreeningService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this._rs.getAllCountries().subscribe((res) => this.countryList = res);
    this.getTableData(this.page - 1, this.rowPageNo, this.searchKey);
  }

  getTableData(page, size, search, isSearched?: boolean){
    const payload = {
      "page" : page ,
      "size" : size,
      "name" : search,
      "countryCode" : this.selectedCountry
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getBlackListedperson(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.tableList = res['content'] || [];
      this.totalItems = res['totalElements'];
      isSearched ? this.bySearching = true : this.bySearching = false;
      this.initialNumberElement = page * size + 1;
        if (this.initialNumberElement + size > this.totalItems) {
          this.secondElement = this.totalItems
        }
        else {
          this.secondElement = this.initialNumberElement + size - 1;
        }
    })
  }

  pageChange($event) {
    this.page = $event;
    this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
    if (this.page * this.rowPageNo > this.totalItems) {
      this.secondElement = this.totalItems
    }
    else {
      this.secondElement = this.page * this.rowPageNo;
    }
    this.getTableData(this.page - 1, this.rowPageNo, this.searchKey);
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.page = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getTableData(this.page - 1, this.rowPageNo, this.searchKey);
  }

  clearSearch(){
    this.searchKey = null
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.searchKey);
  }

  doSearch(){
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.searchKey, true);
  }

  countryFilter(event){
    this.selectedCountry = event;
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.searchKey, true);
  }

  back(event){
    this.openModal();
  }

  openModal(){
    const initialState = {
      title: 'Add Entities',
      type: "risk-screening-black-list",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
    this.bsModalRef.content.onClose.subscribe(data=> {
      if(data=='success'){
        this.getTableData(this.page - 1, this.rowPageNo, this.searchKey);
      }
    });
  }

}

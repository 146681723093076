import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { VideoKycService } from '../vide-kyc.service';

@Component({
  selector: 'app-video-kyc-client',
  templateUrl: './video-kyc-client.component.html',
  styleUrls: ['./video-kyc-client.component.scss']
})
export class VideoKycClientComponent implements OnInit {

  tableData = {
    totalItems: 0,
    pages: 0,
    currentPage: 1,
    data: [],
  }

  tableData2 = {
    totalItems: 0,
    pages: 0,
    currentPage: 1,
    data: [],
  }
  pieChartData: any;
  activeTab='clientTableSection';
  colorScheme = {
    domain: ['#0075FF', '#0db9a4', '#DCAF0E', '#D84040']
  };

  constructor(private _data: DataService, private _vs: VideoKycService) { }

  ngOnInit() {
    this.getApplicationStatus();
    this.kycLeftAcknowledge();
    this.usersAddedRecently();
    sessionStorage.removeItem('Data');
  }

  getApplicationStatus(){
    this._data.changeLoaderVisibility(true);
    this._vs.ApplicationStatus().pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res => {
      if(res){
        console.log(res);
        this.pieChartData = [
          {
            name:'In Progress',
            value: Math.floor(res?.acknowledgedPercent) || 0
          },
          {
            name:'Completed',
            value: Math.floor(res?.completedPercent) || 0
          },
          {
            name:'Initiated',
            value: Math.floor(res?.notDonePercent) || 0
          },
          {
            name:'Failed',
            value: Math.floor(res?.failedPercent) || 0
          }
        ];
        console.log(this.pieChartData);
      }
    })
  }

  kycLeftAcknowledge(){
    this._data.changeLoaderVisibility(true);
    let payload = {
        "page": this.tableData.currentPage - 1,
        "size": 5
    };
    this._vs.kycLeftAcknowledge(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res => {
      if(res){
        this.tableData.data = res.content || [];
        this.tableData.totalItems = res.totalElements
        this.tableData.pages = res.totalPages
      }
    })
  }

  handleIncreasePageSize() {
    if (this.tableData.pages > this.tableData.currentPage) {
      this.tableData.currentPage += 1
      this.kycLeftAcknowledge()
    }

  }
  handleDecreasePageSize() {
    if (this.tableData.currentPage > 1) {
      this.tableData.currentPage -= 1
      this.kycLeftAcknowledge()
    }
  }

  usersAddedRecently(){
    this._data.changeLoaderVisibility(true);
    let payload = {
        "page": this.tableData2.currentPage - 1,
        "size": 5
    };
    this._vs.usersAddedRecently(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res => {
      if(res){
        this.tableData2.data = res.content || [];
        this.tableData2.totalItems = res.totalElements
        this.tableData2.pages = res.totalPages
      }
    })
  }

  handleIncreasePageSize2() {
    if (this.tableData2.pages > this.tableData2.currentPage) {
      this.tableData2.currentPage += 1
      this.usersAddedRecently()
    }

  }
  handleDecreasePageSize2() {
    if (this.tableData2.currentPage > 1) {
      this.tableData2.currentPage -= 1
      this.usersAddedRecently()
    }
  }

  goToTabs(div){
    const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
    const scrollToEl = document.getElementById(div)
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
    if(this.activeTab!=div){
      let scrolly=topOfElement-250;
      window.scroll({ top: scrolly, behavior: "smooth" });
    }
    else{
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }

}

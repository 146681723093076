import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LiveCheckComponent } from "./live-check.component" ;

const routes: Routes = [
	{path: '', component: LiveCheckComponent},
	{path: ':category/:hashId', component: LiveCheckComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LiveCheckRoutingModule { }

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService, DataService } from '../../../services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-pan-checks',
  templateUrl: './pan-checks.component.html',
  styleUrls: ['./pan-checks.component.scss']
})
export class PanChecksComponent implements OnChanges {
  @Input() traversalState: any;
  @Input() usersCheckConfig: any;
  @Output() checkState = new EventEmitter<any>();
  isPanValidate: boolean = false;
  panForm: FormGroup = this.form.group({
    docNumber: ['', [Validators.required, Validators.pattern("[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}")]]
  });
  panGroupData: any;

  constructor(private form: FormBuilder, private _app: AppService, private _data: DataService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.traversalState?.currentValue){
      this.traversalState = changes?.traversalState?.currentValue;
    }
    if(changes?.usersCheckConfig?.currentValue){
      this.usersCheckConfig = changes?.usersCheckConfig?.currentValue;
    }
    const panData = this.usersCheckConfig.filter(obj => obj.standardCheckGroup == 'nid-pan-fetch');
    if(panData?.length) this.panGroupData = panData[0];
  }

  get panValidate(): { [key: string]: AbstractControl } {
    return this.panForm.controls;
  }

  stepComplete() {
    this.isPanValidate = true;
    if(this.panForm.valid){
      let payload={
        pan : this.panForm.value.docNumber
      }
      this._data.changeLoaderVisibility(true)
      this._app.nidPanCheck(payload, this.panGroupData['endUserCheckID']).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res=>{
        this.goToStep('next');
        if(res.data.statusCode==2009){
          this._data.toastrShow(res.data.data.message, "success");
        }
        else{
          let message = res.data.data.message;
          if(message.includes('pattern')) message = message.replace('pattern', 'format');
          this._data.toastrShow(message, "error");
        }
      })
    }
  }

  goToStep(stepValue) {
    let state = this.traversalState.filter(obj => obj.standardCheckGroup == 'nid-pan-fetch');
    if (state.length) {
      if (!state[0].isFinalStep) {
        let traversalState = {
          "allowedState": state[0],
          "goToStep": stepValue
        }
        this.checkState.emit(traversalState);
      } else {
        {
          let traversalState = {
            "allowedState": state[0],
            "goToStep": "isFinalStep"
          }
          this.checkState.emit(traversalState);
        }
      }
    }
  }

}

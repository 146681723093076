export class EndUser {
  email: string;
  endUserType: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: any;
  gender: string;
  country: string;

  companyName: string;
  companyID: string;
  incorporationDate: string;
  branchCount: string;
  isChildCompany: string;
}

import { Component, OnInit, Input, Output } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";
import { first } from "rxjs/operators";
@Component({
  selector: "app-basic-info",
  templateUrl: "./basic-info.component.html",
  styleUrls: ["./basic-info.component.scss"]
})
export class BasicInfoComponent implements OnInit {
  @Input() category: string;
  standardChecks: any;

  endUser: EndUser;
  selectedChecks: any[] = [];
  countries: any[] = [];
  allowedDocs: any[] = [];

  dropdownSettings: any = {};

  constructor(
    private _data: DataService,
    private _app: AppService,
    public router: Router
  ) {}

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "countryName"
    };
    this.endUser = new EndUser();
    let standardChecks = this._data.getCookieObjectByName("standardChecks");
    if (!standardChecks) {
      this.getStandardCheckConfig();
    } else {
      this.standardChecks = standardChecks;
    }
    console.log(standardChecks);
    this.getAllCountries();
  }

  getAllCountries() {
    this._app
      .getAllCountries()
      .pipe(first())
      .subscribe(
        res => {
          this.countries = res["data"];
        },
        err => {
          console.log(err);
        }
      );
  }

  onCountrySelect(country) {
    console.log(country);
    this._app.getAllowedDocs(country).subscribe(res => {
      (this.allowedDocs["validIdentityDoc"] = res.data["validIdentityDocs"]),
        (this.allowedDocs["validAddressDoc"] = res.data["validAddressDocs"]),
        console.log(this.allowedDocs);
    });
  }

  getStandardCheckConfig() {
    this._app.getStandardCheckConfig().subscribe(res => {
      this.standardChecks = res["data"];
      console.log(this.standardChecks);
    });
  }

  registerEndUser() {
    this._data.changeLoaderVisibility(true);
    let slugArr = this.selectedChecks.map(obj => obj.slug);
    this.endUser["checks"] = slugArr;
    this.endUser["endUserType"] =
      this.category == "individual" ? "INDIVIDUAL" : "CORPORATE";
    this._app.registerEndUser(this.endUser).subscribe(res => {
      console.log(res);
      this._data.changeLoaderVisibility(false);
      this.router.navigate([`/live-check/individual/${res.data["id"]}`]);
    });
  }

  toggleCheck(check) {
    if (!this.isCheckSelected(check)) {
      this.selectedChecks.push(check);
    } else {
      var index = this.selectedChecks.indexOf(check);
      if (index > -1) {
        this.selectedChecks.splice(index, 1);
      }
    }
    console.log(this.selectedChecks);
  }

  isCheckSelected(check) {
    let isSelected = this.selectedChecks.filter(obj => check.slug == obj.slug);
    return isSelected.length;
  }
}

import { Component, Input, OnInit } from "@angular/core";


@Component({
  selector: 'app-recursive-component',
  templateUrl: './recursive-component.component.html',
  // styleUrls: ['./recursive-component.component.scss','../app-family-tree.component.scss' ]
})

export class RecursiveComponent implements OnInit {
  @Input() data
  constructor() {
  }

  ngOnInit(): void {

  }

  openEntity(item) {
    if (item.title !== 'Entity' && item.guid !== "") {
      window.open(`aml-screening/risk-individual-report/${item.guid}`);
    }
  }
}
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";
import { AmlSearchService } from "./aml-search.service";
import * as FileSaver from 'file-saver';
import { finalize, first } from "rxjs/operators";
// import { AMLSearch  , AMLResultData} from '../../utils/aml-search' ;

import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../shared/kyc-modal/kyc-modal.component";
import { interval } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { DomSanitizer } from "@angular/platform-browser";
import { AmlChecksService } from "./aml-checks.service";
import { CorporateCheckService } from "../corporate-check/corporate-check.service";

// import * as StickySidebar from "sticky-sidebar";

// Create an Observable that will publish a value on an interval
const secondsCounter = interval(1000);

@Component({
  selector: "app-aml-search",
  templateUrl: "./aml-search.component.html",
  styleUrls: ["./aml-search.component.scss"],
})
export class AmlSearchComponent implements OnInit {
  searchField: string;
  searchQueryType = 'All';
  //Temporary Test Vars
  showTabularResult: boolean = false;
  showDetailedResult: boolean = false;
  amlResultsData: any[] = [];
  amlFilters: any[] = [];
  savedRiskArr: any[] = ["ALL", "SANCTIONS", "PEP", "CRIME"];
  selectedRiskPolicy: string = "ALL";

  selectedAMLEnitity: any;

  //Pagination
  jump: any;
  currentPage: number = 0;
  itemsPerPage: number = 10;
  totalItems: number;
  maxSize: number;
  searchType: "MOREFUZZY" | "STRICT" | "FUZZY" = "FUZZY";
  possibleSearchTypes: any[] = ["FUZZY", "MOREFUZZY", "STRICT"];
  buildQuery: any = {
    size: this.itemsPerPage,
    page: this.currentPage,
    searchType: this.searchType,
    name: [],
    country: [],
    source: [],
    category: [],
    gender: [],
    type: [],
    riskpolicy: [],
  };
  pageList: any = []
  startIndex: number = 0;
  endIndex: number = 10;

  //routing
  currentRoute: string;

  //API Loading
  isAPILoading: boolean = false;

  bsModalRef: BsModalRef;



  countries: any;
  selectedCountry: any = {
    countryCode: "",
    countryName: "Select Country",
    countrySynonyms: "",
    isActive: "ACTIVE",
    jurisdictions: null,
  };
  guidEntered: string;
  colors = [{ key: 'sip', color: '#ED7C15' }, { key: 'pep', color: '#8B60D4' }, { key: 'sie', color: '#ED7C15' }, { key: 'crime', color: '#E05B3B' }, { key: 'watchlist', color: '#D262BA' },
  { key: 'sanction', color: '#DCAF0E' }]

  // same route navigation vars
  navigationSubscription: any;
  // organization type have amlcheck nd corporate check both
  organizationSearchType: 'aml-check' | 'corporate-check' = 'aml-check';
  corporateCheckList: any = []
  sourceOfData;
  checkListId: any;
  isCorporateReport: boolean = false;
  constructor(
    private _data: DataService,
    private modalService: BsModalService,
    private _app: AppService,
    private _aml: AmlSearchService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    private amlService: AmlChecksService,
    private _check: CorporateCheckService,
  ) {
    console.log("1.constructor");
    var currentRoute = this.router.url;
    this.currentRoute =
      currentRoute.indexOf("?") !== -1
        ? currentRoute.substring(0, currentRoute.indexOf("?"))
        : currentRoute;
    console.log('constructor : ', this.currentRoute);
  }

  ngOnInit() {
    let index = JSON.parse(localStorage.getItem('key'))
    if (index == null) {
      this.getPagedData(this.currentPage)
      this.jump = this.currentPage + 1
    }
    else {
      this.getPagedData(index)
      this.jump = index + 1
    }
    this.hideLeftPanel();
    console.log("3.ngOnInit");
    this.getAllCountries();
    this.getRiskPolicy();
    // console.log(this.amlSearch);
    const id = this.route.snapshot.paramMap.get('id');
    if (id !== null || id != undefined) {
      this._data.changeLoaderVisibility(true);
      this.amlService.getEntityByGuid(id).subscribe(res => {
        if (res.content.length != 0 && res.content != null) {
          this.showDetailedResult = true;
          this.showTabularResult = false;
          this.selectedAMLEnitity = res.content[0]

          this._data.changeLoaderVisibility(false);

        }
        else {
          this._data.toastrShow("No Data Found", "error");
        }

      })
    }
  }

  hideLeftPanel() {
    var leftElem = document.getElementById("wrapper");
    leftElem.classList.add("forced");
    leftElem.classList.add("enlarged");
  }

  ///Test Hooks Starts
  traverse() {
    this.router.navigate([`/aml-search`], {
      queryParams: { country: `India` },
    });
  }
  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  // ngOnChanges(){
  //   console.log("2.ngOnChanges");
  // }

  // ngDoCheck(){
  //   console.log(this.getQueryParams());
  //   console.log("4.ngDoCheck");
  // }

  // ngAfterContentInit(){
  //   console.log("5.ngAfterContentInit");
  // }

  // ngAfterContentChecked(){
  //   console.log("6.ngAfterContentChecked");
  // }

  // ngAfterViewInit(){
  //   console.log("7.ngAfterViewInit");
  // }

  // ngAfterViewChecked(){
  //   console.log("8.ngAfterViewChecked");
  // }

  // ngOnDestroy(){
  //   console.log("9.ngOnDestroy");
  // }
  ///Test Hooks Ends

  getBuildQueryData(params) {
    this.buildQuery = {
      size: this.itemsPerPage,
      page: this.currentPage,
      searchType: this.searchType,
      name: [],
      country: [],
      source: [],
      category: [],
      gender: [],
      type: [],
      riskpolicy: [],
    };
    this.buildQuery["name"] = [];
    this.buildQuery["name"].push(this.searchField);
    for (var key in this.buildQuery) {
      if (key == "page" || key == "size") {
        this.buildQuery[key] = parseInt(params[key]);
      } else if (key == "searchType") {
        this.buildQuery[key] = params[key];
      } else {
        if (params[key] && params[key].indexOf(",") !== -1) {
          this.buildQuery[key] = params[key].split(",");
        } else {
          if (this.buildQuery[key].indexOf(params[key]) === -1) {
            if (params[key]) {
              this.buildQuery[key].push(params[key]);
            }
          }
        }
      }
    }
    console.log(this.buildQuery);
  }

  clearFilters() {
    console.log(this.searchField);
    this.jump = 1;
    this.itemsPerPage = 10;
    this.currentPage = 0;
    this.buildQuery = {
      size: this.itemsPerPage,
      page: this.currentPage,
      searchType: this.possibleSearchTypes[0],
      name: [],
      country: [],
      source: [],
      category: [],
      gender: [],
      type: [],
    };
    this.searchField = "";
    this.guidEntered = "";
    // this.buildQuery["name"] = [];
    // this.buildQuery["name"].push(this.searchField);
    this.showTabularResult = false;
    this.showDetailedResult = false;
    this.amlResultsData = [];
    this.selectedRiskPolicy = "ALL";
    this.amlFilters = [];
    this.selectedCountry = this.countries != undefined && this.countries.length > 0 ? this.countries[0] : '';
    this.searchQueryType = 'Individual';

    // this.getSearchFilters();
    // this.getSearchResults();
  }
  clearAll() {
    this.searchField = "";
    this.searchQueryType = 'Individual';
    this.buildQuery = {
      size: this.itemsPerPage,
      page: this.currentPage,
      searchType: this.possibleSearchTypes[0],
      name: [],
      country: [],
      source: [],
      category: [],
      gender: [],
      type: [],
    };
  }
  clearFiltersInner() {
    this.itemsPerPage = 10;
    this.currentPage = 0;
    this.searchType = 'FUZZY'
    this.searchQueryType = '';
    // this.searchField = "";
    this.guidEntered = "";
    this.buildQuery = {
      size: this.itemsPerPage,
      page: this.currentPage,
      searchType: this.possibleSearchTypes[0],
      name: [],
      country: [],
      source: [],
      category: [],
      gender: [],
      type: [],
    };
    this.buildQuery["name"].push(this.searchField);
    this.showTabularResult = true;
    this.showDetailedResult = false;
    this.amlResultsData = [];
    this.selectedRiskPolicy = "ALL";
    this.amlFilters = [];
    this.selectedCountry = this.countries[0];
    // this.showTabularResult=false;
    // this.showDetailedResult=false;
    this.getSearchFilters();
    this.getSearchResults();
  }

  doSearch(searchTerm) {
    // setTimeout(()=>{this._data.changeLoaderVisibility(false) , this.showTabularResult = true},1000)
    // console.log(searchTerm);
    let countryList = this.buildQuery.country;
    this.currentPage = 0;
    this.jump = 1;
    this.buildQuery = {
      size: this.itemsPerPage,
      page: this.currentPage,
      searchType: this.searchType,
      name: [],
      country: countryList,
      source: [],
      guid: this.guidEntered || "",
      category: [],
      gender: [],
      type: [this.searchQueryType],
    };
    if (this.searchQueryType == 'All') {
      this.buildQuery['type'] = [];
    }
    this.buildQuery["name"] = [];
    this.buildQuery["name"].push(searchTerm);
    this.getSearchResults();
    // this.getSearchFilters();
    // this.getSearchResults();
  }

  getQueryParams(currentPage?) {
    var query = {};
    for (var key in this.buildQuery) {
      if (key == "searchType" || key == "page" || key == "size") {
        query[key] = this.buildQuery[key];
      } else if (
        key != "searchType" &&
        key != "page" &&
        key != "size" &&
        this.buildQuery[key] &&
        this.buildQuery[key].length
      ) {
        query[key] = this.buildQuery[key].toString();
      }
    }
    // query['page'] = currentPage;
    return query;
  }

  doFilter(filterName, filterValue) {
    console.log(filterName, filterValue);
    this.currentPage = 0;
    this.buildQuery["page"] = 0;
    if (filterName == "searchType") {
      this.buildQuery[filterName] = filterValue;
    } else {
      var indexValue = this.buildQuery[filterName].indexOf(filterValue);
      if (indexValue === -1) {
        this.buildQuery[filterName].push(filterValue);
      } else {
        this.buildQuery[filterName].splice(indexValue, 1);
      }
      if (filterName == 'type') {
        this.searchQueryType = "";
      }
    }
    this.getSearchResults();
    console.log(this.buildQuery);
    // this.navigationBoy();
    //this.getSearchResults();
  }

  getRelationSearch(relationName) {
    console.log(relationName);
    this.searchField = relationName;
    this.buildQuery["name"] = [];
    this.buildQuery["name"].push(relationName);
    this.getSearchResults();
  }

  getSearchResults() {
    // this.navigationBoy();
    // this.getSearchFilters(); //Dynamic FIlters
    this._data.changeLoaderVisibility(true);
    this.isAPILoading = true;
    this.toggleBodyScrolling(false);
    this._aml.doSearch(this.buildQuery).subscribe(
      res => {
        if (res.data && res.data.content) {
          let amlResultsData = res.data.content;
          this.amlResultsData = [];
          for (var i = 0; i < amlResultsData.length; i++) {
            this.amlResultsData.push(amlResultsData[i]);
          }
          // if (this.currentPage === 0) {
          this.totalItems = res.data.totalElements;
          this.maxSize = res.data.totalPages;
          this.pageSize();

          // }
          if (this.amlResultsData.length > 0) {
            this.showTabularResult = true;
            this.showDetailedResult = false;
          } else{
            this.showTabularResult = true;
            this.showDetailedResult = false;
          }
            // this._data.toastrShow(
            //   `No record found for ${this.searchQueryType}.Please check with other name.`,
            //   "warning"
            // );

          if (!res.data || !res.data.content) {
            this._data.changeLoaderVisibility(false);
          } else {
            this.getSearchFilters();
          }
          this.isAPILoading = false;
          this.toggleBodyScrolling(true);
        }
        else {
          this._data.toastrShow(
            res.data,
            "warning"
          );
          this._data.changeLoaderVisibility(false);
        }
        // this.sticky();
      },
      err => {
        this.isAPILoading = false;
        this.toggleBodyScrolling(true);
      }
    );
  }

  toggleBodyScrolling(allowScrolling) {
    if (!allowScrolling) {
      document.getElementsByTagName("body")[0].classList.add("noScroll");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("noScroll");
    }
  }

  getSearchFilters() {
    this._aml.getSearchFilters(this.buildQuery).subscribe(res => {
      console.log(res);
      this.amlFilters = res.data;
      this._data.changeLoaderVisibility(false);
    });
  }

  getFormattedString(search, type) {
    return search;
    // .replace(/-/g, " ")
    // .replace(/_/g, " ")
    // .trim();
  }

  getMoreSearchResults() {
    console.log("getMoreUsersData");
    this.currentPage = this.currentPage + 1;
    this.buildQuery["page"] = this.buildQuery["page"] + 1;
    if (this.currentPage < this.maxSize) {
      this.getSearchResults();
      this.jump = this.currentPage + 1;;
      // this.navigationBoy();
    }
  }

  getPaginatedData(isNext) {
    console.log("getMoreUsersData");
    this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
    this.buildQuery["page"] = isNext
      ? this.buildQuery["page"] + 1
      : this.buildQuery["page"] - 1;
    if (this.currentPage < this.maxSize) {
      this.getSearchResults();
      this.pageSize();
      this.jump = this.currentPage + 1;
      // this.navigationBoy();
    }
  }
  jumpTo() {
    // let index=parseInt(this.jump)
    if (this.jump <= this.maxSize) {
      if (Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0)
        this.getPagedData(this.jump - 1)
    }
  }

  getPaginatedText() {
    let a = this.currentPage * this.itemsPerPage + 1;
    let b;
    if (this.currentPage < this.maxSize - 1) {
      b = (this.currentPage + 1) * this.itemsPerPage;
    } else if (this.currentPage == this.maxSize - 1) {
      b = this.totalItems;
    }
    return a + "-" + b;
  }

  getPagedData(index) {
    console.log("getMoreUsersData");
    this.currentPage = index
    this.buildQuery["page"] = index;
    if (this.currentPage < this.maxSize) {
      this.getSearchResults();
      this.pageSize();
      this.jump = this.currentPage + 1;

    }

  }
  getPage(length) {
    console.log("jfhdjhffhh", length)
    // const l=length/10;
    return (Math.round(length / 10));
  }
  pageSize() {
    this.pageList = []
    let totalPages = this.getPage(this.totalItems)
    this.startIndex = (this.currentPage - 4) < 0 ? 0 : this.currentPage - 4
    this.endIndex = (this.currentPage + 4) > this.getPage(this.totalItems) ? totalPages : this.currentPage + 4
    console.log("jgfgjghjghg", this.startIndex, this.endIndex)
    for (let i = 0; i <= this.currentPage + 4; i++) {
      if (i >= this.currentPage - 4 && i < totalPages) { this.pageList.push(i) }
    }

  }

  // sticky() {
  //   console.log("Called Me");

  //   var menu,menuPosition,initialMenuWidth;
  //   var result = document.querySelector(".resultContainer");
  //   var initialResultMargin = window.getComputedStyle(result).marginLeft;

  //   window.scrollTo({ top: 0});
  //   setTimeout(function(){
  //     menu = document.querySelector(".filterContainer");

  //     menuPosition = (menu) ? menu.getBoundingClientRect().top : 0;
  //     initialMenuWidth = (<HTMLElement>menu).offsetWidth;


  //     window.addEventListener("scroll", makeSticky);
  //   },1000)

  //   function makeSticky(){
  //     try{
  //       if (window.pageYOffset >= menuPosition && window.innerWidth > 992) {
  //         var result = document.querySelector(".resultContainer");
  //         var detail_leftPane = document.querySelector("#leftPane");
  //         var detail_leftPane_initialWidth = window.getComputedStyle(
  //           detail_leftPane
  //         ).width;
  //         var detail_rightPane = document.querySelector("#rightPane");
  //         var margin_needed_rightPane = `${(<HTMLElement>(
  //           document.querySelector("#detailCont")
  //         )).offsetWidth -
  //           (<HTMLElement>document.querySelector("#rightPane")).offsetWidth}px`;

  //         (<HTMLElement>menu).style.position = "fixed";
  //         (<HTMLElement>menu).style.top = "90px";
  //         (<HTMLElement>menu).style.width = `${initialMenuWidth}px`;
  //         (<HTMLElement>result).style.marginLeft = `${parseInt(
  //           initialResultMargin.split("px")[0]
  //         ) + initialMenuWidth}px`;

  //         // console.log("CLOG: ", detail_leftPane_width);
  //         (<HTMLElement>detail_leftPane).style.position = "fixed";
  //         (<HTMLElement>detail_leftPane).style.top = "70px";
  //         (<HTMLElement>(
  //           detail_leftPane
  //         )).style.width = detail_leftPane_initialWidth;
  //         (<HTMLElement>(
  //           detail_rightPane
  //         )).style.marginLeft = detail_leftPane_initialWidth;
  //       } else {
  //         var result = document.querySelector(".resultContainer");
  //         var detail_leftPane = document.querySelector("#leftPane");
  //         var detail_rightPane = document.querySelector("#rightPane");

  //         (<HTMLElement>menu).style.position = "static";
  //         (<HTMLElement>menu).style.top = "";
  //         (<HTMLElement>result).style.marginLeft = "";

  //         (<HTMLElement>detail_leftPane).style.position = "";
  //         (<HTMLElement>detail_leftPane).style.top = "";
  //         (<HTMLElement>detail_leftPane).style.width = "";
  //         (<HTMLElement>detail_rightPane).style.marginLeft = "";
  //       }
  //     }catch(e){
  //       return;
  //     }
  //   }
  // }

  openAdvancedSearchModal() {
    const initialState = {
      title: `Advanced AML Search`,
      type: "advanced-aml-search",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result && Object.keys(result).length) {
        console.log(result);
      }
    });
  }

  getCategoryName(aml) {
    if (aml && aml.category && aml.category.name) {
      return aml.category.name == "PEP"
        ? aml.category.name
        : aml.category.name.toLowerCase();
    } else {
      return "-";
    }
  }

  addNewRiskPolicy() {
    this._app.getAllDataSources().subscribe(
      res => {
        var i = 0;
        var data = res.data.map(e => {
          e.id = i;
          i++;
          return e;
        });
        this.openNewPolicyModal(res.data);
        console.log(res);
      },
      err => {
        console.log("Add risk policy", err);
      }
    );
  }

  openNewPolicyModal(data) {
    const initialState = {
      title: `Add New Risk Policy`,
      type: "add-new-risk-policy",
      dropdownList: data,
      selectedItems: [],
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result && Object.keys(result).length) {
        var addPolicy = { name: result[0].riskName, source: [] };
        result[0].selectedItems.forEach(e => {
          addPolicy.source.push(e.key);
        });

        this._app.addNewRiskPolicy(addPolicy).subscribe(
          res => {
            console.log("addNewRiskPolicy response : ", res);
            this.getRiskPolicy();
          },
          err => {
            console.log("addNewRiskPolicy error : ", err);
          }
        );
        console.log(addPolicy);
      }
    });
  }

  getAllCountries() {
    this._app
      .getAllCountries()
      .pipe(first())
      .subscribe(
        res => {
          this.countries = res["data"];
          this.countries.unshift(this.selectedCountry);
          console.log(this.countries);
        },
        err => {
          console.log(err);
        }
      );
  }

  onCountrySelect(country) {
    // console.log(country, this.selectedCountry);
    // this.selectedCountry = country.countryName;

    if (
      this.buildQuery.country &&
      this.buildQuery.country.indexOf(country.countryName) >= 0
    ) {
    } else {
      this.buildQuery.country.push(country.countryName);
    }
    console.log(this.buildQuery);
  }

  onCountrySelectHomepage(country) {
    // console.log(country, this.selectedCountry);
    // this.selectedCountry = country.countryName;

    // if (
    //   this.buildQuery.country &&
    //   this.buildQuery.country.indexOf(country.countryName) >= 0
    // ) {
    // } else {
    this.buildQuery.country = [];
    this.buildQuery.country.push(country.countryName);
    // }
    console.log(this.buildQuery);
  }
  //get saved riskpolicy
  getRiskPolicy() {
    this._aml.getSavedRiskPolicies().subscribe(
      res => {
        console.log("RISKPOLICY", res.data);
        res.data.forEach(element => {
          this.savedRiskArr.push(element.riskPolicyName);
        });
        // this.savedRiskArr.push(res.data.riskPolicyName);
      },
      err => {
        console.log(err);
      }
    );
  }

  //set selected riskpolicy
  setRiskPolicy(item) {
    this.selectedRiskPolicy = item;

    if (this.buildQuery.riskpolicy && this.buildQuery.riskpolicy.length > 0) {
      this.buildQuery.riskpolicy.pop();
      this.buildQuery.riskpolicy.push(this.selectedRiskPolicy);
    } else {
      //   console.log(this.buildQuery);
      this.buildQuery["riskpolicy"] = [this.selectedRiskPolicy];
    }
    console.log(this.buildQuery);
  }

  //set search type
  setSearchType(type) {
    this.buildQuery.searchType = type;
    this.searchType = type;
  }
  goBack(type) {
    if (type == "list") {
      this.selectedAMLEnitity = null;
      this.showDetailedResult = false;
      this.showTabularResult = true;
    } else {
      this.showTabularResult = false;
      this.showDetailedResult = false;
    }
  }
  downloadReport() {
    this._data.changeLoaderVisibility(true)
    let payload = { "guid": this.selectedAMLEnitity.guid, "name": this.selectedAMLEnitity.masterData.fullName[0], "countryCode": "" }
    this.amlService.downloadReport(payload).subscribe((res) => {
      this._data.changeLoaderVisibility(false)
      if (res.size < 80) {
        this._data.toastrShow(`Insufficient Credits`, 'error');
      }
      else {
        const blob = new Blob([res], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `${this.selectedAMLEnitity.masterData.fullName[0]}-report.pdf`);
      }
    });
  }
  bookMark() {
    let data = { guid: this.selectedAMLEnitity.guid, fullName: this.selectedAMLEnitity.masterData.fullName[0], entityType: this.selectedAMLEnitity.masterData.entityType }
    this.amlService.bookMarkEntity(data).subscribe(res => {
      this._data.toastrShow(`Entity Bookmarked Successfully`, 'success');
    }, error => {
      this._data.toastrShow(`Entity already BookMarked`, 'error');
    }
    )

  }

  getColor(category): object {
    for (let i = 0; i < this.colors.length; i++) {
      if (category == this.colors[i].key) {
        return { 'background-color': this.colors[i].color }
      }
    }
    return { 'background-color': '#ED7C15' }
  }

  openDataSources() {
    const initialState = {
      type: "data-source",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
  }
  handleOrganizatonSearch(type: 'aml-check' | 'corporate-check') {
    if (type === 'aml-check') {
      this.organizationSearchType = type
      this.isCorporateReport = false
    } else {
      if (this.organizationSearchType !== type) {

        this.doSearchCorporate();
      }
      this.isCorporateReport = true
      this.organizationSearchType = type
    }
  }
  doSearchCorporate() {
    let payload = {
      "name": this.searchField,
      "jurisdictionCode": "",
      "country": this.selectedCountry ? this.selectedCountry.countryCode : ""
    }
    this._data.changeLoaderVisibility(true);
    this._check.corporateSearch(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res: any) => {
      if (res !== null) {
        if (res.results !== null) {
          this.corporateCheckList = res.results.companies;
          this.showTabularResult = true;
          this.showDetailedResult = false;
        }
        this.sourceOfData = res.sourceOfData;
      }
      else {
        this._data.toastrShow(
          `No record found for ${this.searchField}.Please check with other name.`,
          "warning"
        );
      }

    });
  }
  standardCheck(item) {
    console.log(item)
    this.selectedAMLEnitity = item;
    const payload = {
      "checkType": "ALL",
      "name": this.selectedAMLEnitity.company.name,
      "jurisdiction": this.selectedAMLEnitity.company.jurisdiction_code,
      "country": this.selectedCountry.countryCode,
      "companyNumber": this.selectedAMLEnitity.company.company_number,
      "companyDataFormatted": this.selectedAMLEnitity.company,
    }
    this._data.changeLoaderVisibility(true);
    this._check.doAllCheck(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.checkListId = res.checkListId;
      
      this.isCorporateReport = true;
      this.showDetailedResult = true;
      this.showTabularResult = false;
    });
  }
}
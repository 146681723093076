import { Component, OnInit , Input , Output , EventEmitter} from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { first } from 'rxjs/operators';

import {OCData1 , OCData2} from "../../../utils/oc-search"

@Component({
  selector: 'app-oc-search',
  templateUrl: './oc-search.component.html',
  styleUrls: ['./oc-search.component.scss']
})
export class OcSearchComponent implements OnInit {

    @Input() corpUser          : any ;
    @Input() hashId            : any ;
    @Output() selectedCompany  : EventEmitter<any> = new EventEmitter();
    @Output() gotMultipleResult: EventEmitter<boolean> = new EventEmitter(false);
    @Output() noResultsFound   : EventEmitter<boolean> = new EventEmitter(false);
    
    //for selecting company for details
    chosenCompany            : any ;
    
    //storing arr list & details
    companiesData            : any;
    
    //Pagination
    currentPage              : number = 0;
    itemsPerPage             : number = 10 ;
    totalItems               : number ;
    maxSize                  : number ;

    buildQuery: any = {
        'size'             : this.itemsPerPage,
        'page'             : this.currentPage + 1,
        'name'             : null ,
        "companyID"        : null ,  
        "jurisdictionCodes": null ,
        "country"          : null 
    };

    //loading
    isApiLoading           : boolean = false;

    //show company details UI or search results
    showSearchResultsOnly  : boolean = false ;
    jump: number;
    pageList: any[];
    startIndex: number=0;
    endIndex: any=10;


	constructor(
		private _data: DataService,
		private _app : AppService,
		public router: Router){
	}

	ngOnInit(){
        var ctry : any[] = [];
        var jCode: any[] = [];
        if(this.corpUser["country"]){
            if(this.corpUser["country"].countryName == "all"){
                ctry = null
            }
            else{
                ctry.push(this.corpUser["country"].countryCode.toLowerCase());
            }
             console.log(ctry);
        }
        if(this.corpUser["jurisdictionCodes"]){
            if(this.corpUser["jurisdictionCodes"].name == "all" ){
                jCode = null
            }
            else{
                jCode.push(this.corpUser["jurisdictionCodes"].code);
            }
            console.log(jCode);
        }
        this.buildQuery = {
            'size'             : this.itemsPerPage,
            'page'             : this.currentPage,
            'name'             : this.corpUser["companyName"] ? this.corpUser["companyName"] : null ,
            // "companyID"     : this.corpUser["companyID"] ? this.corpUser["companyID"] : null ,  
            "jurisdictionCodes": jCode ,
            // "country"       : this.corpUser["country"] ? this.corpUser["country"] : null 
            "countryCodes"     : ctry 
        };
		this.searchCompanies(false);
        // this.searchTerm = this.corpUser["companyName"] ? this.corpUser["companyName"] : null ;
        console.log(this.corpUser);
        console.log(this.companiesData);
        this.jump=this.currentPage+1
	}

	searchCompanies(isScroll){
		// this.buildQuery["name"] = this.searchTerm;
        this.isApiLoading = true ;
        this._data.changeLoaderVisibility(true);
        // this._app.searchCompanies(this.buildQuery)
        // this.buildQuery["page"] = this.buildQuery["page"] + 1;
        this._app.searchCorpCompanies(this.buildQuery , this.hashId)
            .pipe(first())
            .subscribe(
            (res)=> { 
                        this._data.changeLoaderVisibility(false);
                        this.isApiLoading = false ;
                        if(res.data.results.companies && !res.data.results.company){
                            this.showSearchResultsOnly = true ;
                            this.gotMultipleResult.emit(this.showSearchResultsOnly);
                            let companiesData = res.data.results.companies ;
                            this.companiesData = [];
                            for(var i = 0 ; i < companiesData.length ; i++){
                                this.companiesData.push(companiesData[i]);
                            }
                            this.companiesData = this.companiesData.map(obj=>obj.company);
                        }
                        else{
                            this.companiesData = res.data.results.company ;
                            // this.companiesData = [];
                            // this.companiesData.push(res.data.results.company);
                            this.showSearchResultsOnly = false ;
                            this.gotMultipleResult.emit(this.showSearchResultsOnly);
                        }
                        if(this.currentPage === 0){
                            this.totalItems = res.data.results.total_count;
                            this.maxSize = res.data.results.total_pages;
                            this.pageSize();
                        }
                        if(!res.data.results.total_count || res.data.results.total_count == 0){
                            //Send to parent , if no results found
                            this.noResultsFound.emit(true);
                        }
                        console.log(this.companiesData);
       	},
        (err) =>{
                console.log("err!");
                // alert("Unable to get company info. Please try later.");
                this._data.toastrShow("Unable to get company info. Please try later." , "error");
                this.isApiLoading = false ;
                this._data.changeLoaderVisibility(false);
            }
        );
	}

    getPaginatedData(isNext){
        console.log("getMoreUsersData");
        this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1 ;
        this.buildQuery["page"] = isNext ? this.buildQuery["page"] + 1 : this.buildQuery["page"] - 1 ; ;
        if(this.currentPage <= this.maxSize){
            this.searchCompanies(true);
            this.jump=this.currentPage+1;
            this.pageSize()
        }
    }

    getPaginatedText(){
        let a = (this.currentPage * this.itemsPerPage) + 1 ;
        let b ;
        if(this.currentPage < (this.maxSize - 1)){
            b = (this.currentPage + 1 ) * this.itemsPerPage;
        }
        else if(this.currentPage == (this.maxSize - 1 )){
            // let bufferCheck = this.itemsPerPage * this.maxSize 
            b = this.totalItems;
        }
        return  a+"-"+b ;
    }
    getPagedData(index) {
        this.currentPage = index
        this.buildQuery["page"] = index;
        if (this.currentPage < this.maxSize) {
            this.searchCompanies(true);
            this.pageSize()
            this.jump=this.currentPage+1;
        }

    }
    pageSize() {
        this.pageList = []
        let totalPages = this.getPage(this.totalItems)
        this.startIndex = (this.currentPage - 4) < 0 ? 0 : this.currentPage - 4
        this.endIndex = (this.currentPage + 4) > this.getPage(this.totalItems) ? totalPages : this.currentPage + 4
        for (let i = 0; i <= this.currentPage + 4; i++) {
            if (i >= this.currentPage - 4 && i < totalPages) { this.pageList.push(i) }
        }

    }
    getPage(length) {
       return (Math.round(length / 10));
    }
    jumpTo() {
        // let index=parseInt(this.jump)
        if (this.jump <= this.maxSize) {
        if(Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0)
        this.getPagedData(this.jump - 1)
        }
    }
    emitCompany(company){
        console.log("selectedCompany");
        console.log(company);
        this.chosenCompany = company.company_number ;
        this.selectedCompany.emit(company);
    }

    onSelectCompany(e){
        console.log("selectedCompany");
        console.log(e);
        this.selectedCompany.emit(e);
    }

}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RiskScreeningService } from '../../../app/pages/risk-screening/risk-screening.service';

@Component({
  selector: 'app-financials-individual-aml-summary',
  templateUrl: './financials-individual-aml-summary.component.html',
  styleUrls: ['./financials-individual-aml-summary.component.scss','../../pages/risk-screening/risk-screening.component.scss']
})
export class FinancialsIndividualAmlSummaryComponent implements OnInit, OnChanges  {
  @Input() guid: string = '';
  carrierTimeLine;

  constructor(private _rs: RiskScreeningService) { }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['guid']?.currentValue) {
      this.guid = changes['guid'].currentValue;
    }
    if(this.guid){
      this.fetchCarrierTimeLine(this.guid);
    }
  }

  ngOnInit(): void {
  }

  async fetchCarrierTimeLine(guid){
    this._rs.fetchCareerChronicle(guid).subscribe(res => {
      if(res?.data?.data){
        this.carrierTimeLine = res?.data?.data;
        console.log(this.carrierTimeLine, "carrierTimeLine")
      }
    })
    
  }

  getDateRange(start_date?: string, end_date?: string): string {
    if (start_date && end_date) {
      return `${start_date} - ${end_date}`;
    } else if (start_date) {
      return start_date;
    } else if (end_date) {
      return end_date;
    } else {
      return '';
    }
  }

  expand(id, action) {
    if (action == 'expand') {
      var element = document.getElementById(id);
      element.classList.add('d-none');
      element.classList.remove('d-inline')
      var expelement = document.getElementById(id + '-expand')
      expelement.classList.add('d-inline')
      expelement.classList.remove('d-none')
    }
    else {
      var element = document.getElementById(id);
      element.classList.remove('d-none');
      element.classList.add('d-inline');
      var expelement = document.getElementById(id + '-expand');
      expelement.classList.remove('d-inline')
      expelement.classList.add('d-none')
    }
  }

}

export const AmlReports = [
	{
		"name"            : "Rajit Gola",
		"image"           : "https://cdn-images-1.medium.com/fit/c/500/500/1*rCAO_Qri-802EfA67QtaUQ.jpeg",
		"createdDate"     : 1545823062306,
		"lastModifiedDate": 1545823062306,
		"info"            : "Rajit has been put on PEP list",
		"details"         : [
			"PEP" , "Crime"
		],
		"alias"           : [
		"Rajit" , "Raajit" , "Raj"
		],
		"country"         : "India" ,
		"alertType"       : "INDIVIDUAL" ,
		"nationality"     : "India",
		"status"          : "Active" ,
		"gender"          : "Male",
		"sources"         : [
		"Aadhar" , "Facebook" , "Open ReCollect"
		],
		"tags"            : [
		"Indian" , "Coder"
		]
	},
	{
		"name"            : "Rakesh Chand",
		"image"           : "https://scontent.fblr2-1.fna.fbcdn.net/v/t1.0-9/18582174_1315008138613879_2116974758715260910_n.jpg?_nc_cat=107&_nc_ht=scontent.fblr2-1.fna&oh=9ec87fceffa73a0fbf84b9d0607ed5e5&oe=5C8F10AF",
		"createdDate"     : 1545823062306,
		"lastModifiedDate": 1545823062306,
		"info"            : "Rakesh has been put into Criminal List",
		"details"         : [
			"PEP" , "Sanctions"
		],
		"alias"           : [
		"Rakesh" , "Raka"
		],
		"country"         : "India" ,
		"alertType"       : "INDIVIDUAL" ,
		"nationality"     : "India",
		"status"          : "Active" ,
		"gender"          : "Male",
		"sources"         : [
		"Aadhar" , "Twitter" , "Open ReCollect" , "GitHub"
		],
		"tags"            : [
		"Indian" , "Coder" , "JavaScript"
		]
	},
	{
		"name"            : "OBSESSORY ONLINE SERVICES PRIVATE LIMITED",
		"image"           : "https://media.licdn.com/dms/image/C4D03AQGH69ugY8ePKQ/profile-displayphoto-shrink_200_200/0?e=1546473600&v=beta&t=rAxrEkFHIPWKm7-KygJ_tgkcdCxMNLcFQJDj8BsYyQg",
		"createdDate"     : 1545823062306,
		"lastModifiedDate": 1545823062306,
		"info"            : "No Info",
		"details"         : [
			"PEP" 
		],
		"alias"           : [
			'Obsessory , GB' , 'Obsessory , India'
		],
		"country"         : "United Kingdom" ,
		"alertType"       : "CORPORATE" ,
		"status"          : "Active" ,
		"nationality"     : "GB",
		"gender"          : null,
		"sources"         : [
			"UK Companies House" , "Facebook" , "OpenCorps" , "The Washington Post"
		],
		"tags"            : [
			"Ecommerce" , "Search Engine" , "Fashion" , "AI"
		]
	},
	{
		"name"            : "Ram Kishor",
		"image"           : "https://scontent.fblr2-1.fna.fbcdn.net/v/t1.0-9/13138982_1127624720643506_805934828192067620_n.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=b9dc72d293f423c512e8fcd99069435c&oe=5CCD5D4A",
		"createdDate"     : 1545823062306,
		"lastModifiedDate": 1545823062306,
		"info"            : "Address has been updated",
		"details"         : [
			"Crime"
		],
		"alias"           : [
		"Ram" , "Rama" , "RK"
		],
		"country"         : "India" ,
		"alertType"       : "INDIVIDUAL" ,
		"status"          : "Active" ,
		"nationality"     : "India",
		"gender"          : "Male",
		"sources"         : [
		"Aadhar" , "Facebook" , "Open ReCollect"
		],
		"tags"            : [
		"Indian" , "Coder" , "Java"
		]
	},
	{
		"name"            : "Jay Rao",
		"image"           : "https://media.licdn.com/dms/image/C4D03AQGH69ugY8ePKQ/profile-displayphoto-shrink_200_200/0?e=1546473600&v=beta&t=rAxrEkFHIPWKm7-KygJ_tgkcdCxMNLcFQJDj8BsYyQg",
		"createdDate"     : 1545823062306,
		"lastModifiedDate": 1545823062306,
		"info"            : "Address has been updated",
		"details"         : [
			 "Crime"
		],
		"alias"           : [
		"Jay" , "Jayant" , "JR"
		],
		"country"         : "United Kingdom" ,
		"alertType"       : "INDIVIDUAL" ,
		"status"          : "Inactive" ,
		"nationality"     : "India",
		"gender"          : "Male",
		"sources"         : [
		"UK Companies House" , "Facebook" , "Florida Land Property Database" , "The Washington Post"
		],
		"tags"            : [
		"Indian" , "Entrepreneur" , "Business" , "StockMarket"
		]
	}
];

export const UserReports = [
	{
		"name": "Rajit Gola",
		"image": "https://cdn-images-1.medium.com/fit/c/500/500/1*rCAO_Qri-802EfA67QtaUQ.jpeg",
		"createdDate" : 1545823062306,
		"completionDate" : 1545823062306,
		"source" : "API",
		"info" : "Quisque nec turpis at urna dictum luctus. Suspendisse convallis dignissim eros at volutpat. In egestas mattis dui.",
		"processingStatus" : "Completed",
		"resultStatus" : "Reject",
		"country" : "India" ,
		"gender" : "Male"
	},
	{
		"name": "Rakesh Chand",
		"image": "https://scontent.fblr2-1.fna.fbcdn.net/v/t1.0-9/18582174_1315008138613879_2116974758715260910_n.jpg?_nc_cat=107&_nc_ht=scontent.fblr2-1.fna&oh=9ec87fceffa73a0fbf84b9d0607ed5e5&oe=5C8F10AF",
		"createdDate" : 1545823062306,
		"completionDate" : 1545823062306,
		"source" : "API",
		"info" : "Quisque nec turpis at urna dictum luctus. Suspendisse convallis dignissim eros at volutpat. In egestas mattis dui.",
		"processingStatus" : "In Process",
		"resultStatus" : "Approved",
		"country" : "India" ,
		"gender" : "Male"
	},
	{
		"name": "Ram Kishor",
		"image": "https://scontent.fblr2-1.fna.fbcdn.net/v/t1.0-9/13138982_1127624720643506_805934828192067620_n.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=b9dc72d293f423c512e8fcd99069435c&oe=5CCD5D4A",
		"createdDate" : 1545823062306,
		"completionDate" : 1545823062306,
		"source" : "API",
		"info" : "Quisque nec turpis at urna dictum luctus. Suspendisse convallis dignissim eros at volutpat. In egestas mattis dui.",
		"processingStatus" : "Completed",
		"resultStatus" : "Warnings",
		"country" : "India" ,
		"gender" : "Male"
	},
	{
		"name": "Jay Rao",
		"image": "https://media.licdn.com/dms/image/C4D03AQGH69ugY8ePKQ/profile-displayphoto-shrink_200_200/0?e=1546473600&v=beta&t=rAxrEkFHIPWKm7-KygJ_tgkcdCxMNLcFQJDj8BsYyQg",
		"createdDate" : 1545823062306,
		"completionDate" : 1545823062306,
		"source" : "API",
		"info" : "Quisque nec turpis at urna dictum luctus. Suspendisse convallis dignissim eros at volutpat. In egestas mattis dui.",
		"processingStatus" : "Failed",
		"resultStatus" : "Warnings",
		"country" : "India" ,
		"gender" : "Male"
	},
];
export * from './dashboard-main/dashboard-main.component'
export * from './widgets/widgets.component';
export * from './risk-screening.component';
export * from './dashboard-main/comps'; // contains all components from index of comps
export * from './risk-screening.component';
export * from './aml-results-main/aml-results-main.component';
export * from './aml-results-main/aml-results-adverse-page/aml-results-adverse-page.component';
export * from './aml-results-main/aml-results-aml-page/aml-results-aml-page.component';
export * from './aml-results-main/aml-results-table/aml-results-table.component';
export * from './aml-results-main/do-corporate-check/do-corporate-check.component';
export * from './widgets/widget-comps'; // contains all comps from index of widgets section
export * from './risk-screening-guage-chart/risk-screening-guage-chart.component';
export * from './risk-screening-table/risk-screening-table.component';
export * from './risk-individual-report/risk-individual-report.component';
export * from './risk-corporate-report/risk-corporate-report.component';
export * from './risk-policy-table/risk-policy-table.component';
export * from './global-sanction-news/global-sanction-news.component';
export * from './scheduled-checks/scheduled-checks.component';
export * from './blacklisted-entity/blacklisted-entity.component';
export * from './recent-searches/recent-searches.component';
export * from './risk-entity-report/risk-entity-report.component';
export * from './advance-search/advance-search.component';
export * from './adeverse-media/adeverse-media.component';
export * from './risk-dashboard-comp/risk-dashboard-comp.component';
export * from './risk-dashboard-comp/risk-short-table/risk-short-table.component';
export * from './risk-dashboard-comp/risk-dashboard-table/risk-dashboard-table.component';
export * from './risk-dashboard-comp/risk-dashboard-report/risk-dashboard-report.component';
export * from './risk-dashboard-comp/compare-aml-report/compare-aml-report.component';
export * from './risk-dashboard-comp/pending-sla-alerts/pending-sla-alerts.component';
export * from './risk-dashboard-comp/open-alerts/open-alerts.component';

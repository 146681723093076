import { Component, OnInit , Input , Output , EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-aml-details',
  templateUrl: './aml-details.component.html',
  styleUrls: ['./aml-details.component.scss']
})
export class AmlDetailsComponent implements OnInit {

	amlCheckData : any = null;

	@Input() data                : any ;
	@Input() canChangeCheckStatus: boolean = true ;

	constructor(
		private _data: DataService,
		private _app : AppService,
		public router: Router){
	}

	ngOnInit(){
		if(this.data){
			this.mapAmlData(this.data);
		}
	}

	async mapAmlData(data){
		this.amlCheckData = {
			"crimeCheck"   : null,
			"pepCheck"     : null,
			"sanctionCheck": null
		};
		for(var key in this.amlCheckData){
			this.amlCheckData[key] = data[key];
		}
		for(var key in this.amlCheckData){
			if(this.amlCheckData[key] && this.amlCheckData[key].matchFound){
				for(var i = 0 ; i < this.amlCheckData[key].results.length ; i++){
					this.amlCheckData[key].results[i].refinedData = (await this.getFromDataSourceId(this.amlCheckData[key].results[i].sourceType , this.amlCheckData[key].results[i].dataSourceId).pipe(first()).toPromise()).data;
				}
			}
			//remove null keys
			if (this.amlCheckData[key] === null || this.amlCheckData[key] === undefined) {
		      delete this.amlCheckData[key];
		    }
		}
		console.log(this.amlCheckData);
	}

	getFromDataSourceId(sourceType , dataSourceId){
		return this._app.getFromDataSourceId(sourceType.toLowerCase() , dataSourceId)
	}

	changeAMLStatus(data , key){
		console.log(data);
		let dataObj  = {
			"amlResultID" : data.id ,
			"amlCheckGuid": data.endUserCheck["endUserCheckID"] ,
			"isActive"    : data.isActive == "ACTIVE" ? "INACTIVE" : "ACTIVE" 
		}
		this._app.changeAMLStatus(dataObj)
		.subscribe(
			(res)=>{
				console.log(res);
				var isPresent = this.amlCheckData[key].results.filter(obj=>obj.id == data.id);
				if(isPresent && isPresent.length){
					this.amlCheckData[key].results.filter(obj=>obj.id == data.id)[0].isActive = res.data.isActive;
				}

				if(res.data.isActive == "ACTIVE"){
					this._data.toastrShow("Added to WatchList!" , "info");
				}
				else{
					this._data.toastrShow("Removed from WatchList!" , "info");
				}
			}
		);
	}

	getTitle(data){
		if(data.isActive == "ACTIVE"){
			return "Remove From WatchList" ;
		}
		else{
			return "Add To Watchlist" ;
		}
	}

}


import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class VideoKycService {
  token: any;
  secret = 'pqZlyax1y344';
  constructor(private http: HttpClient, @Inject("API_URL") public API_URL: any) { }

  getAgentTableList(payload) {
    return this.http.post<any>(`${this.API_URL}/agent-client-kyc/connections/filter`, payload).pipe(map((res) => res['data']));
  }

  getClientTableList(payload) {
    return this.http.post<any>(`${this.API_URL}/agent-client-kyc/filter`, payload).pipe(map((res) => res['data']));
  }

  uploadCapturedPhoto(payload, id) {
    return this.http.post<any>(`${this.API_URL}/agent-client-kyc/upload-photo/${id}`, payload).pipe(map((res) => res['data']));

  }
  uploadCapturedId(payload,id){
    return this.http.post<any>(`${this.API_URL}/agent-client-kyc/upload-id-proof/${id}`, payload).pipe(map((res) => res['data']));
  }

  fetchImage(id): Observable<HttpResponse<Blob>> {
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/download-photo/${id}`, { observe: 'response', responseType: 'blob' as 'json' }).pipe(map((res) => res));
  }

  fetchIdImage(id): Observable<HttpResponse<Blob>> {
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/download-id-proof/${id}`, { observe: 'response', responseType: 'blob' as 'json' }).pipe(map((res) => res));
  }

  getUserDetailsById(id) {
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/get-user/${id}`).pipe(map((res) => res['data']))
  }

  getConnectionDetailsById(id) {
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/connections/get-by-id/${id}`).pipe(map((res) => res['data']))
  }

  getEndUser(id){
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/fetch-end-user/${id}`).pipe(map((res) => res['data']))
  }

  getAllUSerChecks(user){
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/fetch-all-checks/${user}`).pipe(map((res) => res['data']))
  }
  
  stepDoneAPI(payload){
    return this.http.post<any>(`${this.API_URL}/agent-client-kyc/step-done`, payload).pipe(map((res) => res));
  }

  submitAadharDetails(userId,payload){
    return this.http.put<any>(`${this.API_URL}/agent-client-kyc/okyc-check/submit/${userId}`,payload).pipe(map((res) => res['data']))
  }

  submitPanDetails(userId,payload){
    return this.http.put<any>(`${this.API_URL}/agent-client-kyc/pan-fetch/submit/${userId}`,payload).pipe(map((res) => res['data']))
  }

  updateConnectionStatus(payload){
    return this.http.put<any>(`${this.API_URL}/agent-client-kyc/connections/update`,payload).pipe(map((res) => res['data']))
  }

  getOcrData(payload){
    return this.http.post<any>(`${this.API_URL}/agent-client-kyc/perform-ocr`, payload).pipe(map((res) => res));
  }

  fetchSessionVideo(userId){
    let headers = new HttpHeaders({
      "Content-Type": "application/octet-stream"
    });
    return this.http
      .get(`${this.API_URL}/agent-client-kyc/download-conv/${userId}`, {
        headers: new HttpHeaders({
          "Content-Type": "application/octet-stream"
        }),
        responseType: "blob"
      })
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  fetchUploadedDocument(userId){
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/download-uploaded-id-doc/${userId}`,{ observe: 'response', responseType: 'blob' as 'json' }).pipe(map((res) => res));
  }

  performOcrOfUploadedImage(payload){
    return this.http.post(`${this.API_URL}/agent-client-kyc/perform-ocr-uploaded-doc`,payload).pipe(map(res => res));
  }

  performLivelinessCheck(userId){
    return this.http.get(`${this.API_URL}/agent-client-kyc/liveliness/${userId}`).pipe(map(res => res));
  }

  getSelfieCheckData(userId){
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/perform-selfie-check/${userId}`).pipe(map((res) => res));
  }

  getBase64Data(payload){
    return this.http.post<any>(`${this.API_URL}/end-user/faq/local-url-to-base64`, payload).pipe(map((res) => res['data']));
  }

  agentApprovalStatus(payload){
    return this.http.put<any>(`${this.API_URL}/agent-client-kyc/update`,payload).pipe(map(res => res['data']))
  }

  callAttendTrendFetch(payload){
    return this.http.post<any>(`${this.API_URL}/agent-client-kyc/dashboard/agent/call-trend`,payload).pipe(map(res => res['data']));
  }

  getAllPendingCalls(payload){
    return this.http.post<any>(`${this.API_URL}/agent-client-kyc/dashboard/agent/pending-calls`,payload).pipe(map(res => res['data']));
  }

  ApplicationStatus(){
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/dashboard/client/application-status`).pipe(map(res => res['data']));
  }

  usersAddedRecently(payload){
    return this.http.post<any>(`${this.API_URL}/agent-client-kyc/dashboard/client/latest-add`,payload).pipe(map(res => res['data']));
  }

  kycLeftAcknowledge(payload){
    return this.http.post<any>(`${this.API_URL}/agent-client-kyc/dashboard/client/left-acknowledgement`,payload).pipe(map(res => res['data']));
  }

  VKYCAllChecksStatus(userId){
    return this.http.get<any>(`${this.API_URL}/agent-client-kyc/check-data/${userId}`).pipe(map(res => res['data']));
  }

  createSession(sessionId) {
    return this.http.post(
      this.API_URL + '/api/sessions',
      sessionId,
      { headers: { 'Content-Type': 'application/json' }, responseType: 'text' }
    ).toPromise();
  }

  createToken(sessionId) {
    return this.http.post(
      this.API_URL + '/api/sessions/' + sessionId + '/connections',
      {},
      { headers: { 'Content-Type': 'application/json' }, responseType: 'text' }
    ).toPromise();
  }

  uploadRecordedSession(payload,userId){
    console.log("in api",payload,userId)
    return this.http.post(`${this.API_URL}/agent-client-kyc/upload-conv/${userId}`,payload).pipe(map(res => res))
  }

  startRecording(payload){
    let token = 'wss://' + location.host + '?sessionId=' + payload.sessionId + '&secret=' + this.secret + '&recorder=true'
    let header = new HttpHeaders().set(
      "Authorization",
       token
    );
    return this.http.post(`${this.API_URL}/recordings/start`,payload,{headers:header}).pipe(map(res => res))
  }

  stopRecording(payload){
    return this.http.post(`${this.API_URL}/recordings/stop`,payload).pipe(map(res => res))
  }
  downloadReport(id): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${this.API_URL}/agent-client-kyc/report/${id}`, { observe: 'response', responseType: 'blob' as 'json' });
  }
}
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { BulkUploadComponent } from "./bulk-upload.component";
import { BulkUploadRoutingModule } from "./bulk-upload-routing.module";
import { TabsModule } from "ngx-bootstrap/tabs";
import { BatchCreateChecksComponent } from "./batch-create-checks/batch-create-checks.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormsModule } from "@angular/forms";
import { BatchDetailsComponent } from "./batch-details/batch-details.component";
import { EditSingleRecordComponent } from "./edit-single-record/edit-single-record.component";

import { DndModule } from "./../../directives/dnd/dnd.module";
import { NotificationLogComponent } from './notification-log/notification-log.component';

@NgModule({
  declarations: [
    BulkUploadComponent,
    BatchCreateChecksComponent,
    BatchDetailsComponent,
    EditSingleRecordComponent,
    NotificationLogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DndModule,
    BulkUploadRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    TabsModule.forRoot(),
  ],
  exports: [BatchCreateChecksComponent, EditSingleRecordComponent],
})
export class BulkUploadModule {}


export const pieChartOptions = {
  trimLabels: false,
  showLegend: false,
  showLabels: false,
  explodeSlices: false,
  isDoughnut: false,
  legendPosition: 'right',
  legendTitle: 'Categories',
  colorScheme: {
    domain: [ '#6D39FA', '#18C098', '#ED1A66','#E5FE68', '#FFA1A1']
  },
  view: [400, undefined]

}

export const barChartOptions = {
  showXAxis: true,
  showYAxis: true,
  gradient: false,
  showLegend: false,
  showXAxisLabel: false,
  yAxisLabel: 'Category',
  legendPosition: 'right',
  showYAxisLabel: false,
  xAxisLabel: 'Alerts',
  barPadding: 24,
  legendTitle: 'Types',
  colorScheme: {
    domain: [ '#FFA1A1', '#3EFF74', '#00DAF7', '#8DFFFF','#E5FE68']
  },
 
}
export const lineChartOptions = {
  legend: false,
  showLabels: true,
  animations: true,
  xAxis: true,
  yAxis: true,
  showYAxisLabel: true,
  showXAxisLabel: false,
  legendPosition: 'right',
  legendTitle: 'Categories',
  xAxisLabel: 'Year',
  yAxisLabel: 'No. of changes in 100k',
  timeline: false,
  colorScheme: {
    domain: ['#E5FE68', '#FFA1A1', '#3EFF74', '#00DAF7', '#8DFFFF']
    
  },

}
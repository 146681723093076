import { Component, OnInit } from '@angular/core';
import { RiskScreeningService } from '../../risk-screening.service';
import { DataService } from '../../../../services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-open-alerts',
  templateUrl: './open-alerts.component.html',
  styleUrls: ['./open-alerts.component.scss']
})
export class OpenAlertsComponent implements OnInit {
  alertList: any[] = [];
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  start: number = 0;
  total: number = 0;

  constructor(
    private _rs: RiskScreeningService,
    private _data: DataService
  ) { }

  ngOnInit(): void {
    this.getRiskDashboardAmlFilters();
  }

  getRiskDashboardAmlFilters(){
    const payload = {
      page: this.start,
      size: 5,
      alertStatus: "OPEN"
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getRiskDashboardAmlFilters(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.total = res?.totalElements || 0;
      if(res?.content?.length){
        res?.content.forEach((element) => {
          this.alertList.push(element)
        });
      }
    })
  }

  onScrollDown() {
    this.start += 1;
    this.getRiskDashboardAmlFilters();
    this.direction = "down";
  }

}

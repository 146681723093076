import { Component, OnInit } from '@angular/core';
import { RiskScreeningService } from '../../../risk-screening.service';
import { correctColorOrder, lineChartOptions } from '../../widget.const';
import { curveCardinal } from 'd3-shape';

@Component({
  selector: 'app-daywise-risk-widget',
  templateUrl: './daywise-risk-widget.component.html',
  styleUrls: ['./daywise-risk-widget.component.scss','../../../risk-screening.component.scss']
})
export class DaywiseRiskWidgetComponent implements OnInit {
  lineData;
  legends = []
  lineOptions = lineChartOptions;
  linearOption = curveCardinal;
  noCharts: boolean = true;

  constructor(public _rs: RiskScreeningService) { }

  ngOnInit() {
    this.getRiskChangesByDuration()
  }
  async getRiskChangesByDuration() {
    const res = await this._rs.getRiskChangesByDuration().toPromise()

    this.lineData = res.data.alertsWidgetList.map(e => ({
      name: e.label,
      series: e.widgetData.map(p => ({ name: p.key, value: p.value }))
    }))
    this.lineOptions.colorScheme.domain = correctColorOrder(res.data.alertsWidgetList.map(e=>e.label))
    if(this.lineData?.length && this.lineData?.some((e) => e?.series?.length > 0)){
      this.noCharts = false;
    }
  }

}

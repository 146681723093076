import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AppService } from '../../../../../services';
import { RiskScreeningService } from '../../../risk-screening.service';

@Component({
  selector: 'app-news-trending',
  templateUrl: './news-trending.component.html',
  styleUrls: ['./news-trending.component.scss', '../../../risk-screening.component.scss']
})
export class NewsTrendingComponent implements OnInit {
  selectedCountry: string = ''
  countries: null
  trendingNewsArr = []
  trendingNewsByCountryArr = []
  constructor(private _rs: RiskScreeningService, private _app: AppService) { }

  ngOnInit() {
    this.getAllCountries()
    this.getTrendingNews()
  }
  async getTrendingNews() {
    const payload = {
      "size": 2,
      "page": 0,
      "sort": 'desc',
      "searchType": "LOOSE",
      "searchBody": null,
      "keyword": [ "SANCTION", "SANCTIONS" ],
      "country": [],
      "publisherUrl": [],
      "startPublishDate": null,
      "endPublishDate": null
    }
    const res = await this._rs.getTrendingNews(payload).toPromise()
    this.trendingNewsArr = res && res.data && res.data.content || []
  }
  async getTrendingNewsByCountry() {
    const payload = {
      "size": 2,
      "page": 0,
      "sort": 'desc',
      "searchType": "LOOSE",
      "searchBody": null,
      "keyword": [ "SANCTION", "SANCTIONS" ],
      "country": [this.selectedCountry.toUpperCase()],
      "publisherUrl": [],
      "startPublishDate": null,
      "endPublishDate": null
    }
    const res = await this._rs.getTrendingNews(payload).toPromise()
    this.trendingNewsByCountryArr = res && res.data && res.data.content || []
  }
  getAllCountries() {
    this._app
      .getAllCountries()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.countries = res["data"] || [];
          this.selectedCountry = res["data"].length ? res["data"][0].countryName : null
          this.getTrendingNewsByCountry()
        },
        err => {
          console.log(err);
        }
      );
  }
  onCountryChange() {
    this.getTrendingNewsByCountry()
  }

}

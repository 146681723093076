import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../services';
import { RiskScreeningService } from '../risk-screening.service';
import { curveCardinal } from 'd3-shape';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss','../risk-screening.component.scss']
})
export class WidgetsComponent implements OnInit {
  theme: any = 'theme-light'
  loaded: boolean = false
  barData: any
  pieData: any
  lineData: any
  //------------
  multi: any[];
  view: any = undefined

  // options
  barOptions = {
    showXAxis: true,
    showYAxis: true,
    gradient: false,
    showLegend: true,
    showXAxisLabel: false,
    yAxisLabel: 'Country',
    legendPosition: 'right',
    showYAxisLabel: false,
    xAxisLabel: 'Population',
    barPadding: 24,
    legendTitle: 'Countries',
    colorScheme: {
      domain: ['#E8C342', '#E84E7C', '#13CC25', '#0075FF', '#28BBEA']
    }
  }
  pieOptions = {
    trimLabels: false,
    showLegend: true,
    showLabels: false,
    explodeSlices: true,
    isDoughnut: false,
    legendPosition: 'right',
    legendTitle: 'Categories',
    colorScheme: {
      domain: ['#AE5DEE', '#E8C342', '#E84E7C', '#0075FF', '#28BBEA']
    }
  }
  lineOptions = {
    legend: true,
    showLabels: true,
    animations: true,
    xAxis: true,
    yAxis: true,
    showYAxisLabel: true,
    showXAxisLabel: false,
    legendPosition: 'right',
    legendTitle: 'Categories',
    xAxisLabel: 'Year',
    yAxisLabel: 'No. of changes in 100k',
    timeline: false,
    colorScheme: {
      domain: ['#AE5DEE', '#E8C342', '#E84E7C', '#0075FF', '#28BBEA']
    }
  }
  linearOption = curveCardinal;
  tableData = {
    totalItems: 0,
    pages: 0,
    currentPage: 1,
    data: [],
  }

  // Conditions
  noBarCharts: boolean = true;
  noPieCharts: boolean = true;
  noLineCharts: boolean = true;

  constructor(public _rs: RiskScreeningService, public data: DataService) { }

  ngOnInit() {
    this.callApis()
  }
  async callApis() {
    this.loaded = false
    this.data.changeLoaderVisibility(true)
    Promise.all([
      this.getLocationWiseRiskChanges(),
      this.getAmlChangesBasedOnCategories(),
      this.getRiskChangesByDuration(),
      this.getIntermediateActionReqList()
    ]).then(() => {
      this.loaded = true
      this.data.changeLoaderVisibility(false)
    })
  }
  async getLocationWiseRiskChanges() {
    const res = await this._rs.getLocationWiseRiskChanges().toPromise()
    const typeAr = res.data.alertsWidgetList[0].widgetData.map(e => e.key)
    const countryAr = res.data.alertsWidgetList.map(e => e.label)
    const newAr = typeAr.map((e, i) => ({
      name: e,
      series: countryAr.map((p, j) => ({ name: p, value: res.data.alertsWidgetList[j].widgetData[i].value }))
    }))
    this.barData = newAr;
    if(this.barData?.length && this.barData?.some((e) => e?.series?.length && e?.series.some((f) => f?.value > 0))){
      this.noBarCharts = false;
    }
  }
  async getAmlChangesBasedOnCategories() {
    const res = await this._rs.getAmlChangesBasedOnCategories().toPromise()
    this.pieData = res.data.alertsWidgetList.map(e => ({ name: e.label, value: e.labelCount }));
    if(this.pieData?.length && this.pieData?.some((e) => e?.value > 0)){
      this.noPieCharts = false;
    }
  }
  async getRiskChangesByDuration() {
    const res = await this._rs.getRiskChangesByDuration().toPromise()
    this.lineData = res.data.alertsWidgetList.map(e => ({
      name: e.label,
      series: e.widgetData.map(p => ({ name: p.key, value: p.value }))
    }));
    if(this.lineData?.length && this.lineData?.some((e) => e?.series?.length > 0)){
      this.noLineCharts = false;
    }
  }
  async getIntermediateActionReqList() {
    const payload = {
      page: this.tableData.currentPage - 1,
      size: 5,
      sortBy: "createdDate",
      order: "DESC"
    }
    const res = await this._rs.getIntermediateActionReqList(payload).toPromise()
    this.tableData.data = res.data.content
    this.tableData.totalItems = res.data.totalElements
    this.tableData.pages = res.data.totalPages
  }
  handleIncreasePageSize() {
    if (this.tableData.pages > this.tableData.currentPage) {
      this.tableData.currentPage += 1
      this.getIntermediateActionReqList()
    }

  }
  handleDecreasePageSize() {
    if (this.tableData.currentPage > 1) {
      this.tableData.currentPage -= 1
      this.getIntermediateActionReqList()
    }
  }
}

import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { ElementRef, Renderer2 } from "@angular/core";
import { AmlSearchService } from "./../aml-search.service";
import { DataService } from "./../../../services/data.service";
// declare var vis:any;
//import * as vis from "vis";

@Component({
  selector: "app-vis",
  templateUrl: "./vis.component.html",
  styleUrls: ["./vis.component.scss"]
})
export class VisComponent implements OnInit {
  @ViewChild("siteConfigNetwork") networkContainer: ElementRef;

  @Input() nodes: any;
  @Input() edges: any;
  @Input() relations: any;
  
  public network: any;

  showHierarchy: boolean = true;

  constructor(private _aml: AmlSearchService, private _data: DataService) {}

  ngOnInit() {
    console.log(this.nodes);
    console.log(this.edges);
    var treeData = this.getTreeData();
    //this.loadVisTree(treeData); // RENDER STANDARD NODES WITH TEXT LABEL
  }

  loadVisTree(treedata) {
    //  var options = {
    //    interaction: {
    //      hover: true,
    //    },
    //    manipulation: {
    // 	enabled: true
    // }
    //  };
    var options:any = {
      interaction: { hover: true },
      font: {
        size: 25
      },
      nodes: {
        size: 12,
        color: {
          background: "#006400"
        },
        font: { color: "#eeeeee", size: 8 }
      },
      edges: {
        arrows: {
          from: { enabled: true, scaleFactor: 1, type: "arrow" }
        },
        // arrows: 'from',
        // font: '5px arial #ffffff',
        color: {
          color: "#848484",
          highlight: "#848484",
          hover: "#848484",
          inherit: "from",
          opacity: 1
        },
        font: {
          color: "#343434",
          size: 5,
          // "face": "arial",
          // "background": "none",
          // "strokeWidth": 1,
          // "strokeColor": "#ffffff",
          align: "horizontal",
          multi: false,
          vadjust: 0
        },
        scaling: {
          label: true
        },
        shadow: true,
        smooth: true
      }
    };
    var container = this.networkContainer.nativeElement;
   // this.network = new vis.Network(container, treedata, options);

    var that = this;
    this.network.on("hoverNode", function(params) {
      console.log("hoverNode Event:", params);
    });
    this.network.on("blurNode", function(params) {
      console.log("blurNode event:", params);
    });
    this.network.on("click", function(params) {
      console.log("click event:", params);
      let nodeId = params.nodes[0];
      if (nodeId) {
        that.getNodeLvlGraph(nodeId);
      }
    });
  }

  getNodeLvlGraph(nodeId) {
    this._aml.getNodeLvlGraph(nodeId).subscribe(response => {
      console.log(response);
      if (
        response.nodes &&
        response.nodes.length &&
        response.links &&
        response.links.length
      ) {
        // this.appendToExistingGraph(response.nodes, response.links);
      }
    });
  }

  appendToExistingGraph(nodes, edges) {
    if (!this.showHierarchy) {
      this.nodes = [];
      this.edges = [];
    }
    for (var i = 0; i < nodes.length; i++) {
      nodes[i].shape = "circularImage";
      nodes[i].id = nodes[i].nodeId;
      nodes[i].image =
        "https://www.thetaranights.com/wp-content/uploads/2018/fiverr_reviews/Benf97/cropped(1).png";
      nodes[i].title = nodes[i].description;
      nodes[i].label = `${nodes[i].fullName ||
        nodes[i].name ||
        nodes[i].companyName} `;
      this._aml.pushToArray(this.nodes, nodes[i]);
    }

    for (var i = 0; i < edges.length; i++) {
      edges[i].from = edges[i].source;
      edges[i].to = edges[i].target;
      // this._aml.pushToEdge(this.edges , edges[i]);
      this.edges.push(edges[i]);
    }

    var obj = {};
    for (var i = 0; i < this.edges.length; i++) {
      obj[
        `${this.edges[i].from}-${this.edges[i].to}-${this.edges[i].label}`
      ] = this.edges[i];
    }
    console.log(obj);
    this.edges = Object.values(obj);

    // this.edges = this.edges.map((obj:any) => JSON.stringify(obj));
    // var uniqueEdges =  Array.from(new Set(this.edges));
    // console.log(uniqueEdges);
    // this.edges = uniqueEdges.map((obj:any) => JSON.parse(obj));

    console.log(this.nodes);
    console.log(this.edges);
    var treeData = this.getTreeData();
    //this.loadVisTree(treeData);
  }

  getTreeData() {
    var nodes = this.nodes;
    var edges = this.edges;
    var treeData = {
      nodes: nodes,
      edges: edges
    };
    return treeData;
  }
}

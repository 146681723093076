import { Component, Input, OnChanges, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { RiskScreeningService } from '../risk-screening.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from '../../../services';
import { finalize } from 'rxjs/operators';
import { KycModalComponent } from '../../../shared/kyc-modal/kyc-modal.component';
import { Edge, Layout, Node } from '@swimlane/ngx-graph';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-adeverse-media',
  templateUrl: './adeverse-media.component.html',
  styleUrls: ['./adeverse-media.component.scss', '../risk-screening.component.scss']
})
export class AdeverseMediaComponent implements OnChanges {
  @Input() entityMasterData;
  @Input() countryObject;
  @Input() isBgCard?: boolean = true;
  @Output() adverseData? = new EventEmitter<any>();
  isHideFilters: boolean = true;
  adverseList: any[] = [];
  totalItems: number = 0;
  itemPerPage: any[] = [
    { value : 10 },
    { value : 25 },
    { value : 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  newPage;
  page: number = 1;
  size: number = 10;
  totalPage: any;
  public bsModalRef: BsModalRef;
  riskData = {
    score: 0,
    lable: 'Low'
  };
  recentArticles: any[] = [];
  adverseEntities: any[] = [];
  nodes: any[] = [];
  links: any[] = [];
  zoomLevel: number = 0.6;
  layout: String | Layout = 'd3ForceDirected';
  update$: Subject<any> = new Subject();
  showMessage: any;
  
  constructor(private _rs: RiskScreeningService, private _data : DataService, private modalService: BsModalService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.entityMasterData?.currentValue){
      this.entityMasterData = changes?.entityMasterData?.currentValue;
      if(changes?.entityMasterData?.currentValue?.fullName?.[0] != changes?.entityMasterData?.previousValue?.fullName?.[0]){
        if (this.entityMasterData?.fullName?.[0]) {
          this.getArticles(this.page - 1, this.rowPageNo, true);
          // this.drawChart(this.page - 1, this.rowPageNo);
        }
      }
    }
    if(changes?.countryObject?.currentValue){
      this.countryObject = changes?.countryObject?.currentValue;
    }
  }

  getArticles(page, size, isNgOnInit?: boolean){
    const payload = {
      page: page,
      size: size,
      entity: this.entityMasterData?.fullName?.[0],
      aliases: this.entityMasterData?.alias?.length ? this.entityMasterData?.fullName : null
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getRecentArticles(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      if(res?.data?.content){
        this.adverseList = res?.data?.content || [];
        if(isNgOnInit){
          this.getArticleScore(this.page - 1, this.rowPageNo, true);
          this.getData(JSON.stringify(res?.data?.content));
        }
        this.totalItems = res?.data?.totalElements;
        this.totalPage = res?.data?.totalPages;
        this.initialNumberElement = page * size + 1;
        if (this.initialNumberElement + size > this.totalItems) {
          this.secondElement = this.totalItems
        }
        else {
          this.secondElement = this.initialNumberElement + size - 1;
        }
      }
      else{
        if(res?.data?.details){
          this.showMessage = 'Adverse articles not found';
        }
        else{
          this.showMessage = 'Adverse articles are being processed and we are going through 1,23,000 plus articles to check for adversity.';
        }
      }
    });
  }

  getArticleScore(page, size, isNgOnInit?){
    const payload = {
      page: page,
      size: size,
      entity: this.entityMasterData?.fullName?.[0],
      averageType: "NA",
      decay: 0.4
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getArticleScore(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      if(res?.data && isNgOnInit){
        this.getScoreData(JSON.stringify(res?.data));
      }
    });
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.page = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getArticles(this.page - 1, this.rowPageNo);
    this.getArticleScore(this.page - 1, this.rowPageNo);
    this.drawChart(this.page - 1, this.rowPageNo);
  }

  pageChange($event) {
    if($event <= 0 || $event > this.totalPage){
      this._data.toastrShow(`${$event <= 0 ? 'Page number cannot be less than 1' : 'You have exceeded the page number'}`, 'warning');
    }
    else{
      this.page = $event;
      this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
      if (this.page * this.rowPageNo > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.page * this.rowPageNo;
      }
      this.getArticles(this.page - 1, this.rowPageNo);
      this.getArticleScore(this.page - 1, this.rowPageNo);
      this.drawChart(this.page - 1, this.rowPageNo);
    }
  }

  getFalseData(event){
    if(event){
      this.markAsFalsePositive(JSON.parse(event));
    }
  }

  markAsFalsePositive(item){
    const initialState = {
      title: `Mark as false positive`,
      type: "mark-as-false-positive",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if(Object.keys(result).length){
        const payload = {
          "entityName": this.entityMasterData?.fullName?.[0],
          "source": null,
          "comment": result.comment,
          "falsePositiveStatus": "FALSE_POSITIVE",
          "adverseMediaArticleData" : item, 
        }
        this._data.changeLoaderVisibility(true);
        this._rs.adverseMediaMarkAsFalsePositive(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe();
      }
    })
  }

  getData(event){
    if(event){
      const adverseData = JSON.parse(event);
      if(adverseData?.length){
        for(let i = 0; adverseData?.length > i; i++){
          if(i < 4){
            this.recentArticles.push(adverseData[i]?.title)
          }
          if(this.adverseEntities?.length < 5){
            adverseData[i]?.analysis?.entiries?.slice(0, 5 - this.adverseEntities.length)?.map((e) => {
              this.adverseEntities.push(e);
            });
          }
        }
      } 
    }
  }

  getScoreData(event){
    if(event){
      const eventData = JSON.parse(event);
      if(eventData?.score != null && eventData?.score != undefined){
        this.riskData['score'] = Math.round(Number(eventData?.score) * 20);
        if(this.riskData['score'] > 59){
          this.riskData['lable'] = 'High';
        }
        else if(this.riskData['score'] > 39){
          this.riskData['lable'] = 'Medium';
        }
        else {
          this.riskData['lable'] = 'Low';
        }
        const advData = {
          content: this.adverseList,
          riskData: eventData
        };
        this.adverseData.emit(JSON.stringify(advData));
      }
    }
  }

  drawChart(page, size) {
    const payload = {
      page: page,
      size: size,
      entity: this.entityMasterData?.fullName?.[0]
    }
    // this._data.changeLoaderVisibility(true);
    // this._rs.getArticleNetwrokGraph(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
    //   if(res?.data){
    //     const linkList = [...res?.data?.linkList.filter((f) => f?.source == this.entityMasterData?.fullName?.[0] ).map((e) => ({ ...e, id: `link${e?.id}` }))]
    //     this.links = linkList;
    //     const nodeList = [...res?.data?.nodeList.filter((f) => {
    //       if(f.id != this.entityMasterData?.fullName?.[0] && (linkList.findIndex((i) => i.target == f?.id) == -1)) return false;
    //       return true;
    //     }).map((e) => ({ ...e, circleColor: e?.id == this.entityMasterData?.fullName?.[0] ? '#E54D0C' : '#98A6AD' }))];
    //     this.nodes = nodeList;
    //   }
    // });
  }

  updateGraph() {
    this.update$.next(true);
  }

}

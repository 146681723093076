export const termsAndCondition = `<div >
			<h4 class="card-title" 
			style="margin-left: 75px;!important"><b>These terms shall govern all use of KYC Hub, KYC Hub Dashboard and related APIs.</b></h4><br>
			<!-- <p>These terms shall govern all use of KYC Hub, KYC Hub Dashboard and related APIs.</p> -->
<h5><b>Definitions</b></h5>
<p>In these terms of use the words and expressions set out below will have the following meanings:</p>
<p><b>Agreement</b>&nbsp;means the agreement formed between You and Us by Your acceptance of these Terms.</p>
<p><strong>API</strong>&nbsp;means the KYC Hub Application Program Interfaces.</p>
<p><strong>Completed Search</strong>&nbsp;means a search request which has been sent to KYC Hub as a result of a User clicking the ‘SEARCH KYC HUB’ button and which has returned a valid result. A search becomes a “Completed Search” when KYC Hub delivers the results to the User, not when the User completes a report of their search.</p>
<p><strong>Credit</strong>&nbsp;means an electronic unit which can be purchased by Users and exchanged for checks and searches on KYC Hub enhanced due diligence tools.</p>
<p><strong>Terms</strong>&nbsp;means the terms of use contained in this document and all of its schedules, to which You must agree prior to accessing or using the KYC Hub Service.</p>
<p><strong>Third Party Content</strong>&nbsp;means any text, files, images, photos, graphics, video, sounds, musical works, or any other materials published or posted on or through Our services produced by third parties.</p>
<h4><a href="https://www.kychub.com/terms-and-conditions.html" target="_blank">Read more...</a></h4>
		</div>`

export class Theming{
	backgroundPrimary  : any ;
	backgroundSecondary : any ;
	textDark  : any ;
	textMuted : any ;
	headings :  any ;
	formBorder :  any ;

	boxShadow ?:  any ;
}

import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { DataService } from "../services/data.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  token: any;
  public paymentDetails = new BehaviorSubject<any>('');
  updateOrder(val) {
    this.paymentDetails.next(val);
  }
  constructor(
    private http: HttpClient,
    private _data: DataService,
    @Inject("API_URL") public API_URL: any
  ) {
    this.token = this._data.getCookiesByName("token");
    console.log(this.token);
  }

  activateTrial() {
    return this.http
      .post<any>(`${this.API_URL}/usersubscription/subscribe/trial`, null)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getStandardPlans() {
    return this.http
      .get<any>(`${this.API_URL}/usersubscription/standardplan`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getStandardServices() {
    return this.http.get<any>(`${this.API_URL}/standardservice/service`).pipe(
      map(res => {
        return res;
      })
    );
  }

  processPayment(planObj) {
    return this.http
      .post<any>(`${this.API_URL}/usersubscription/subscribe`, planObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  redeemCoupon(redeemObj) {
    return this.http
      .post<any>(`${this.API_URL}/usersubscription/redeem`, redeemObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  redeemCouponV2(redeemObj) {
    return this.http
      .post<any>(`${this.API_URL}/usersubscription/redeem/v2`, redeemObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getInitialCost() {
    return this.http
      .post<any>(`${this.API_URL}/kyc/india/initial-cost-payment/`, {})
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  updateInitialSetup(order) {
    let paymentObj = {}
    paymentObj["razorpayOrderId"] = order.razorpay_order_id
    paymentObj["razorpayPaymentId"] = order.razorpay_payment_id
    paymentObj["razorpaySignature"] = order.razorpay_signature
    return this.http
      .put<any>(`${this.API_URL}/kyc/india/initial-cost-payment/`, paymentObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  getStandardPaymentPlan() {
    return this.http
      .post<any>(`${this.API_URL}/kyc/india/standard-cost-payment/`, {})
      .pipe(
        map(res => {
          return res;
        })
      )
  }
  updateStandardPlan(order) {
    let paymentObj = {}
    paymentObj["razorpayOrderId"] = order.razorpay_order_id
    paymentObj["razorpayPaymentId"] = order.razorpay_payment_id
    paymentObj["razorpaySignature"] = order.razorpay_signature
    return this.http
      .put<any>(`${this.API_URL}/kyc/india/standard-cost-payment/`, paymentObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  processPayAsGoPayment(paymentObj) {
    return this.http
      .post<any>(`${this.API_URL}/usersubscription/payasyougo/v2`, paymentObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  processSubscriptionPayment(paymentObj) {
    return this.http
      .post<any>(`${this.API_URL}/usersubscription/subscribe/v2`, paymentObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  processPayAsGoPaymentV2Add(paymentObj) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/payasyougo/buy`, paymentObj)
      .pipe(map(res => {
        return res;
      }));
  }
  processPayAsGoPaymentV2Update(paymentObj) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/payasyougo/buy`, paymentObj)
      .pipe(map(res => {
        return res;
      }));
  }
  processSubscriptionPaymentV2Add(paymentObj) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/subscribe`, paymentObj)
      .pipe(map(res => {
        return res;
      }));
  }
  processSubscriptionPaymentV2Update(paymentObj) {
    return this.http.put<any>(`${this.API_URL}/payment/v2/subscribe`, paymentObj)
      .pipe(map(res => {
        return res;
      }));
  }
  processTrialSubscriptionPayment(paymentObj) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/subscribe/trail`, paymentObj)
      .pipe(map(res => {
        return res;
      }));
  }
  applyTax(obj) {
    return this.http.post<any>(`${this.API_URL}/tax/calculate `, obj).pipe(
      map(res => {
        return res;
      })
    );
  }

  preVerifyPayment(paymentObj) {
    return this.http
      .post<any>(`${this.API_URL}/usersubscription/preverification`, paymentObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //get payment address
  getPaymentAddress() {
    return this.http
      .get<any>(`${this.API_URL}/usersubscription/paymentaddress/`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //post payment address
  postPaymentAddress(addressObj) {
    return this.http
      .post<any>(`${this.API_URL}/usersubscription/paymentaddress/`, addressObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  preVerifyPaymentV2(paymentObj) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/preverify`, paymentObj)
      .pipe(map(res => {
        return res;
      }));
  }
  getCurrentSubscription() {
    return this.http.get<any>(`${this.API_URL}/payment/v2/subscribe`)
      .pipe(map(res => {
        return res;
      }));
  }
  processInvoicePayment(paymentObj) {
    return this.http.post<any>(`${this.API_URL}/payment/v2/payinvoice`, paymentObj)
      .pipe(map(res => {
        return res;
      }));
  }
  //update payment address
  updatePaymentAddress(addressObj) {
    return this.http
      .put<any>(`${this.API_URL}/usersubscription/paymentaddress/`, addressObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  stripePayment(checkout) {
    return this.http
      .post<any>(`${this.API_URL}/kyc/india/payment`, checkout)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  updateOneTimeStripe(payObj) {
    return this.http
      .post<any>(`${this.API_URL}/kyc/india/initial-cost-charge`, payObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  updateMonthlyStripe(payObj) {
    return this.http
      .post<any>(`${this.API_URL}/kyc/india/standard-cost-charge`, payObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  createPaymentIntent(intentObj) {
    return this.http
      .post<any>(`${this.API_URL}/kyc/india/stripe/standard-cost-payment`, intentObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  updatePaymentIntent(intentObj) {
    return this.http
      .put<any>(`${this.API_URL}/kyc/india/stripe/standard-cost-payment`, intentObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  createInitialPaymentIntent(intentObj) {
    return this.http
      .post<any>(`${this.API_URL}/kyc/india/stripe/initial-cost-payment`, intentObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  updateInitialPaymentIntent(intentObj) {
    return this.http
      .put<any>(`${this.API_URL}/kyc/india/stripe/initial-cost-payment`, intentObj)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  createStripeCustomer(payload) {
    return this.http
      .post<any>(`${this.API_URL}/kyc/india/stripe/create-customer`, payload)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  createAutoSubscription(customerId) {
    return this.http
      .post<any>(`${this.API_URL}/kyc/india/stripe/auto-payment/create-subscription/${customerId}`, null)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getSubscriptionStatus() {
    return this.http
      .get<any>(`${this.API_URL}/kyc/india/stripe/auto-payment/check-subscription`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

}

export class DiscountObj{
	discountPrice  : any = null ;
	originalPrice  : any = null ;
	remainingAmount: any = null ;
	totalCost      : any = null ;
}
	
export class ApplicableTax{
	tax            : any = null ;
	taxType        : any = null ;
	taxRate        : any = null ;
	country        : any = null ;
}

import { Component, OnInit , Input , Output , HostListener , ViewChild , ElementRef , EventEmitter , ChangeDetectorRef} from '@angular/core';
import { PaymentService } from "../../services/payment.service";
import { DataService    } from "../../services/data.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { KycModalComponent } from "../../shared/kyc-modal/kyc-modal.component";

import { FormGroup, FormBuilder, Validators , NgForm } from "@angular/forms";
// import { StripeService, StripeCardComponent, Elements, Element as StripeElement , ElementOptions, ElementsOptions } from "ngx-stripe";

import { StripeKey } from "../../utils/misc" ;

declare var StripeCheckout:any;

declare var elements : any ;
declare var stripe   : any ;
@Component({
  selector: 'app-checkout-new',
  templateUrl: './checkout-new.component.html',
  styleUrls: ['./checkout-new.component.scss']
})
export class CheckoutNewComponent implements OnInit{

	@Input() planData     : any ;
	@Input() planStatus   : any ; 
	@Input() priceCurrency: any ; 
	@Output() paymentComplete = new EventEmitter();

	handler               : any;
	amount                : any = 5000;
	stripeKey             : any = StripeKey ;
	// stripeKey             : any = "pk_test_MzcP5HYKsN62OMepT4ztcw5A00f7fjm8uo" ; // DEV
	// stripeKey             : any = "pk_live_JqFknkpP6c2IKPqdLtVhzFtn00WRITdyg1" ; // PROD
	
	showCheckoutWizard    : boolean = true ;
	isTrxnSuccessful      : boolean = false ;
	
	email                 : any ;
	
	discountObj           : DiscountObj =  new DiscountObj();
	
	couponCode            : any  = null;
	couponId              : any  = null;
	
	couponRedeemSuccess   : boolean = false;
	
	bsModalRef            : BsModalRef;
	
	existingBalance       : any = null ;
	applicableTax         : ApplicableTax = new ApplicableTax() ;
	
	emailPattern          : any = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{0}$";

	selected 			  : any = null;

	clientData 			  : any = null;
	trialPlan  			  : boolean = false;
	// NOTE:
	// Stripe amounts are based equal to 1/100th of the base currency, so an amount of 500 equals $5.00.
	//Custom Design
	// @ViewChild('cardInfo') cardInfo: ElementRef;
	// card                           : any;
	// cardHandler                    = this.onChange.bind(this);
	// error                          : string;
	constructor(
	private _payment     : PaymentService,
	private _data        : DataService,
	private fb           : FormBuilder,
	private cd           : ChangeDetectorRef,
    private modalService : BsModalService,
	// private stripeService: StripeService
	){ }

	ngOnInit(){
		console.log('coming to checkout new',this.planData);
		console.log(this.planStatus);

		if(!this.planData.isInvoice) this.configureStripe();
		this._data.loadClientData.subscribe( data =>{
			this.clientData = data;
		})
		// // if(this.planStatus && this.planStatus == "UPGRADE"){
		// // 	this.redeemDefaultCouponCode(this.planData);
		// // }
		// // else{
		// // 	if(this.planData){
		// // 		let applicableCost = this.planData["subscriptionDuration"] == "ANNUAL" ? this.planData["planCostAnnualy"] : this.planData["planCostMonthly"] ;
		// // 		this.amount = applicableCost ? applicableCost : this.planData["maxCost"] ;
		// // 		this.amount = this.amount * 100 ;
		// // 		this.discountObj.originalPrice = this.amount ;
		// // 	}
		// // 	this.configureStripe();
		// // 	// this.applyTax();
		// // }
		// if(this.planData && this.planData["maxCost"]){
		// 	console.log("PAY AS GO");
		// 	this.amount =  this.planData["maxCost"] ;
		// 	this.amount = this.amount * 100 ;
		// 	this.discountObj.originalPrice = this.amount ;
		// 	this.configureStripe();
		// }
		// else{
		// 	console.log("Subscription Plan");
		// 	this.redeemDefaultCouponCode(this.planData);
		// }
	}
	getSelected(i){
		console.log(i);
		this.selected = i;
	}
	redeemDefaultCouponCode(plan){
		let redeemObj  = {
			"planId"              : plan["id"],
			"subscriptionDuration": plan["subscriptionDuration"]   //ANNUAL,MONTHLY
		};
		this._data.changeLoaderVisibility(true);
		this._payment.redeemCouponV2(redeemObj)
		.subscribe(
			(res)=>{
				this._data.changeLoaderVisibility(false);
				if(res.data && Object.keys(res.data).length){
					this.existingBalance = res.data.remainingAmount * 100 ;
					this.amount = res.data.totalCost * 100;
					this.discountObj.originalPrice = res.data.originalPrice * 100;
					this.configureStripe();
					// this.applyTax();
				}
				else{
					this._data.toastrShow("Coupon not Applicable" , "info");
				}
			}
		);
	}

	applyTax(){
		let obj = {
			"amount" : this.amount / 100 
		}
		this._payment.applyTax(obj)
		.subscribe(
			(res)=>{
				this.applicableTax.tax     = res.data.tax * 100 ;
				this.applicableTax.taxType = res.data.taxType ;
				this.applicableTax.taxRate = res.data.taxRate ;
				this.applicableTax.country = res.data.country ;

				this.amount = res.data.totalAmount * 100;
				this.configureStripe();
			}
		);
	}

	resetCoupon(){
		this._data.changeLoaderVisibility(true);
		this.discountObj = new DiscountObj();
		let applicableCost = this.planData["subscriptionDuration"] == "ANNUAL" ? this.planData["planCostAnnualy"] : this.planData["planCostMonthly"] ;
		this.amount = applicableCost ? applicableCost : this.planData["maxCost"] ;
		this.amount = this.amount * 100 ;
		this.discountObj.originalPrice = this.amount ;

		if(this.existingBalance){
			this.amount = this.amount - this.existingBalance ;
		}

		this.couponRedeemSuccess = false;

		// this.applyTax();
		this._data.changeLoaderVisibility(false);
	}

	configureStripe(){
		this.handler = StripeCheckout.configure({
			key: this.stripeKey,
			image: 'https://www.kychub.com/assets/img/kyc%20hub%201.png',
			locale: 'auto',
			currency : this.priceCurrency.currencyCode ,
			token: token => {
				// this._payment.processPayment(token, this.amount)
				console.log(token);
				this.processPayment(token);
			}
		});
	}

	showAllCoupons(){
		const initialState = {
            title              : `Apply Coupons`,
            type               : "show-coupons"
        };
        this.bsModalRef = this.modalService.show(KycModalComponent, {
            initialState,
            class: "modal-lg"
        });
        this.bsModalRef.content.closeBtnName = "Close";
        console.log(this.bsModalRef.content.onClose);
        this.bsModalRef.content.onClose.subscribe(result => {
            if (Object.keys(result).length) {
                this.redeemCouponCode(result);
            }
        });
	}

	redeemCouponCode(coupon){
		this.couponCode = coupon.code ;
		this.couponId   = coupon.id ;
		let redeemObj  = {
			"subscriptionType"    : this.planData["minCost"] ? "PAYASGO" : "STANDARD_SUBSCRIPTION",
			// "saleId"           :  this.planStatus == 'UPGRADE' ? null : coupon.id ,
			"saleId"              : coupon.id ?  coupon.id : null ,
			"newPlanId"           : this.planData.id  ,
			"planStatus"          : this.planStatus ? this.planStatus : "NEW" ,
			"subscriptionDuration": this.planData["subscriptionDuration"]   //ANNUAL,MONTHLY
		};
		this._data.changeLoaderVisibility(true);
		this._payment.redeemCoupon(redeemObj)
		.subscribe(
			(res)=>{
				console.log(res);
				this._data.changeLoaderVisibility(false);
				if(res.data && Object.keys(res.data).length && res.data["discountPrice"]){
					this.discountObj = res.data ;
					for(var key in this.discountObj){
						this.discountObj[key] = this.discountObj[key] * 100 ;
					}
					this.amount = this.discountObj.totalCost ;
					this.couponRedeemSuccess = true;
					// this.applyTax();
				}
				else{
					this.couponRedeemSuccess = false ;
					this._data.toastrShow("Coupon not Applicable" , "info");
				}
			}
		);
	}

	handlePayment(){
		var amount = (this.planData.totalPayableamount) ? this.planData.totalPayableamount : this.planData.price;
			amount = (amount * this.priceCurrency.conversionRate ) * 100;
		this.handler.open({
			name       : 'KycHub',
			description: 'Know Your Customer - KYC and AML for different industries using machine learning',
			image      : "https://www.kychub.com/assets/img/kyc%20hub%201.png" ,
			excerpt    : 'Deposit Funds to Account',
			amount     : amount.toFixed(2),
			email 	   : this.clientData.email
		});
	}

	handleInvoicePayment(){
		StripeCheckout.configure({
			key: this.stripeKey,
			image: 'https://www.kychub.com/assets/img/kyc%20hub%201.png',
			locale: 'auto',
			currency : this.planData.currencyCode ,
			token: token => {
				// this._payment.processPayment(token, this.amount)
				console.log(token);
				this.processInvoicePayment(token);
			}
		}).open({
			name       : 'KycHub',
			description: 'Know Your Customer - KYC and AML for different industries using machine learning',
			image      : "https://www.kychub.com/assets/img/kyc%20hub%201.png" ,
			excerpt    : 'Deposit Funds to Account',
			amount     : this.planData.totalAmount.toFixed(2) * 100,
			email 	   : this.clientData.email
		});
	}
	processInvoicePayment(tokenObj){
		console.log(this.planData.invoiceId)
		let paymentObj = 
			{
				"invoiceid"     : this.planData.invoiceId,
			    "stripeEmail" 	: tokenObj.email,
				"stripeToken" 	: tokenObj.id
			};
		this._data.changeLoaderVisibility(true);
		this._payment.processInvoicePayment(paymentObj)
		.subscribe(
			(res)=>{
				this.showCheckoutWizard = false ;
				this.isTrxnSuccessful = true ;
				this._data.changeLoaderVisibility(false);
				this._data.toastrShow("Payment Successful" , "info");
			}
		);
	}
	@HostListener('window:popstate')
	onPopstate() {
		this.handler.close()
	}

	processPayment(tokenObj){
		var amount = (this.planData.totalPayableamount) ? this.planData.totalPayableamount : this.planData.price;
		tokenObj.price = (amount * this.priceCurrency.conversionRate );
		tokenObj.price = tokenObj.price.toFixed(2)
		// planStatus ==> NEW, UPDATE, UPGRADE
		// this._data.changeLoaderVisibility(true);
		// let newPlan = {
		// 		"planId"              : this.planData.id ,
		// 		"saleId"              : this.couponRedeemSuccess ? this.couponId : null ,
		// 		// "saleId"           : this.couponId ? this.couponId : null ,
		// 		"subscriptionType"    : this.planData["minCost"] ? "PAYASGO" : "STANDARD_SUBSCRIPTION",
		// 		"planStatus"          : this.planStatus ? this.planStatus : "NEW" ,
		// 		"currencyCode"        : this.priceCurrency,
		// 		// "currency"            :"GBP",
		// 		"amount"              : this.amount/100 ,
		// 		"stripeEmail"         : tokenObj.email ,
		// 		"stripeToken"         : tokenObj.id ,
		// 		"subscriptionDuration": this.planData["subscriptionDuration"]   //ANNUAL,MONTHLY
		// }
		// this._payment.processPayment(newPlan)
		// .subscribe(
		// 	(res)=>{
		// 		console.log(res);
		// 		this.showCheckoutWizard = false ;
		// 		this.isTrxnSuccessful = true ;
		// 		this._data.changeLoaderVisibility(false);
		// 		this._data.toastrShow("Payment Successful" , "info");
		// 	}
		// );
		
		if(this.selected){
			console.log("yo I got a selected one   ",this.selected);
			if(this.selected.includes('monthly')){
				var index = parseInt(this.selected.replace(/.*[a-z-]/,''));
				this.processTrialSubscriptionPayment(tokenObj,this.planData.monthlyLicence[index])
			}else{
				var index = parseInt(this.selected.replace(/.*[a-z-]/,''));
				this.processTrialSubscriptionPayment(tokenObj,this.planData.annualLicence[index])
			}
		}else if(this.planData.type == "STANDARD_SUBSCRIPTION"){
			this.processSubscriptionPayment(tokenObj);
		}else if(this.planData.type == "PAYASGO"){
			this.processPayAsGoPayment(tokenObj);
		}else if(this.planData.licenceType){
			this.processSubscriptionPayment(tokenObj);
		}else{
			console.log("OOPS NOT YET IMPLEMENTED",this.planData);
		}
	}

	// processPayAsGoPayment(tokenObj){
	// 	let paymentObj = {
	// 		"planId"      : this.planData.id ,
	// 		"amount"      : this.amount/100 ,
	// 		"currencyCode": this.priceCurrency ,
	// 		"stripeEmail" : tokenObj.email,
	// 		"stripeToken" : tokenObj.id
	// 	}
	// 	this._data.changeLoaderVisibility(true);
	// 	this._payment.processPayAsGoPayment(paymentObj)
	// 	.subscribe(
	// 		(res)=>{
	// 			this.showCheckoutWizard = false ;
	// 			this.isTrxnSuccessful = true ;
	// 			this._data.changeLoaderVisibility(false);
	// 			this._data.toastrShow("Payment Successful" , "info");
	// 		}
	// 	);
	// }

	processPayAsGoPayment(tokenObj){
		var amount = (this.planData.totalPayableamount * this.priceCurrency.conversionRate) + 0;
		let paymentObj = 
			{
				"payAsYouGoId" 	: this.planData.id,
				"price" 		: (this.planData.totalPayableamount * this.priceCurrency.conversionRate).toFixed(2),
				"currencyCode" 	: this.priceCurrency.currencyCode,
				"stripeEmail" 	: tokenObj.email,
				"stripeToken" 	: tokenObj.id
			};
		this._data.changeLoaderVisibility(true);
		if(this.planData.isNewLicence){
			this._payment.processPayAsGoPaymentV2Update(paymentObj)
			.subscribe(
				(res)=>{
					this.showCheckoutWizard = false ;
					this.isTrxnSuccessful = true ;
					this._data.changeLoaderVisibility(false);
					this._data.toastrShow("Payment Successful" , "info");
				}
			);
		}else{
			this._payment.processPayAsGoPaymentV2Add(paymentObj)
			.subscribe(
				(res)=>{
					this.showCheckoutWizard = false ;
					this.isTrxnSuccessful = true ;
					this._data.changeLoaderVisibility(false);
					this._data.toastrShow("Payment Successful" , "info");
				}
			);
		}
	}

	// processSubscriptionPayment(tokenObj){
	// 	let paymentObj = {
	// 		"planId"              : this.planData.id ,
	// 		"stripeEmail"         : tokenObj.email,
	// 		"stripeToken"         : tokenObj.id,
	// 		"currencyCode"        : this.priceCurrency,
	// 		"subscriptionDuration": this.planData["subscriptionDuration"]   //ANNUAL,MONTHLY
	// 	}
	// 	this._data.changeLoaderVisibility(true);
	// 	this._payment.processSubscriptionPayment(paymentObj)
	// 	.subscribe(
	// 		(res)=>{
	// 			this.showCheckoutWizard = false ;
	// 			this.isTrxnSuccessful = true ;
	// 			this._data.changeLoaderVisibility(false);
	// 			this._data.toastrShow("Payment Successful" , "info");
	// 		}
	// 	);
	// }

	processSubscriptionPayment(tokenObj){
		var price = parseFloat(tokenObj.price);
		let paymentObj = 
			{
				"licenceId" 	: this.planData.id || this.planData.licenceId,
				"price" 		: price.toFixed(2),
				"currencyCode" 	: this.priceCurrency.currencyCode,
				"stripeEmail" 	: tokenObj.email,
				"stripeToken" 	: tokenObj.id
			};
		this._data.changeLoaderVisibility(true);
		if(this.planData.isNewLicence){
			this._payment.processSubscriptionPaymentV2Update(paymentObj)
			.subscribe(
				(res)=>{
					this.showCheckoutWizard = false ;
					this.isTrxnSuccessful = true ;
					this._data.changeLoaderVisibility(false);
					this._data.toastrShow("Payment Successful" , "info");
				}
			);
		}else{
			this._payment.processSubscriptionPaymentV2Add(paymentObj)
			.subscribe(
				(res)=>{
					this.showCheckoutWizard = false ;
					this.isTrxnSuccessful = true ;
					this._data.changeLoaderVisibility(false);
					this._data.toastrShow("Payment Successful" , "info");
				}
			);
		}
	}

	processTrialSubscriptionPayment(tokenObj,nextPlan){
		console.log(tokenObj,nextPlan)
		let paymentObj = 
			{
				"trailPlanId" 	: this.planData.id || this.planData.licenceId,
				"nextPlanId" 	: nextPlan.licenceId,
				"price" 		: 0,
				"currencyCode" 	: this.priceCurrency.currencyCode,
				"stripeEmail" 	: tokenObj.email,
				"stripeToken" 	: tokenObj.id
			};
		this._data.changeLoaderVisibility(true);
		this._payment.processTrialSubscriptionPayment(paymentObj)
		.subscribe(
			(res)=>{
				this.showCheckoutWizard = false ;
				this.isTrxnSuccessful = true ;
				this.trialPlan = nextPlan.licenceName;
				this._data.changeLoaderVisibility(false);
				this._data.toastrShow("Payment Successful" , "info");
			}
		);
	}

	finishProcess(){
		let obj = { "isPaymentSuccessful" :  true }
		this.paymentComplete.emit(obj);
	}

}

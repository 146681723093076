export class Address {
	addressLine1  : string;
	addressLine2 ?: string;
	landmark	 ?: string;
	city          : string;
	state         : string;
	country       : string;
	postalCode    : string;
	
	addressID   ?: any;
	addressType   ?: any;
	createdDate   ?: any;
	lastModifiedDate   ?: any;
}
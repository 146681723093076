export const pieChartOptions = {
    trimLabels: false,
    showLegend: false,
    showLabels: false,
    explodeSlices: true,
    isDoughnut: false,
    legendPosition: 'right',
    legendTitle: 'Categories',
    colorScheme: {
        domain: ['#AE5DEE', '#E8C342', '#E84E7C', '#0075FF', '#28BBEA']
    },
    view: [200, 200]
}

export const barChartOptions = {
    responsive: true,
    aspectRatio: 1.5,
    maintainAspectRatio: true,
    indexAxis: 'y',
    scales: {
        x: {
            stacked: true,
        },
        y: {

        },
        xAxes: [
            {
                display: true,
                barPercentage: 0.9,
                stacked: true,
                ticks: {
                    beginAtZero: true,
                    fontColor: "#85868A",
                    maxTicksLimit: 6,
                },
                gridLines: {
                    display: true,
                    color: "#85868A33",
                },
            }

        ],
        yAxes: [{
            display: true,
            stacked: true,
            ticks: {
                beginAtZero: true,
                fontColor: "#85868A",

            },
            gridLines: {
                display: false,
                color: "#85868A",
            },
        },
        ],
    },
    legend: {
        display: false,
        labels: {
            color: "#ffffff",
            fontSize: 14,
            usePointStyle: true,
        },
    },

    plugins: {
        datalabels: {
            anchor: "end",
            align: "end",
        },
    },
}
export const lineChartOptions = {
    legend: false,
    showLabels: true,
    animations: true,
    xAxis: true,
    yAxis: true,
    showYAxisLabel: true,
    showXAxisLabel: false,
    legendPosition: 'right',
    legendTitle: 'Categories',
    xAxisLabel: 'Year',
    yAxisLabel: 'No. of changes in 100k',
    timeline: false,
    colorScheme: {
        domain: ['#AE5DEE', '#E8C342', '#E84E7C', '#0075FF', '#28BBEA']
    }
}
export const correctColorOrder = (arr) => {
    const staticArr = [
        { name: 'Sanction', color: '#E8C342' },
        { name: 'PEP', color: '#8B60D4' },
        { name: 'Crime', color: '#D73838' },
        { name: 'Watchlist', color: '#D262BA' },
        { name: 'SIP', color: '#ED7C15' },
        { name: 'SIE', color: '#ED7C15' },
        { name: 'SIE / SIP', color: '#ED7C15' }]
    return arr.map(e => staticArr.find(p => e.toLowerCase() === p.name.toLowerCase()).color)
}
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../../../services';
import { RiskScreeningService } from '../../risk-screening.service';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { KycModalComponent } from '../../../../shared/kyc-modal/kyc-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-risk-short-table',
  templateUrl: './risk-short-table.component.html',
  styleUrls: ['./risk-short-table.component.scss', '../../risk-screening.component.scss']
})
export class RiskShortTableComponent implements OnInit {
  @Input() type : 'dashboard' | null = null;
  @Output() close = new EventEmitter<any>();

  orderArray: any[] = [
    { key: 'Latest First', value: 'DESC' },
    { key: 'Oldest First', value: 'ASC' },
  ];
  tableData = {
    totalItems: 0,
    pages: 0,
    currentPage: 1,
    data: [],
  }
  order: string;
  public bsModalRef: BsModalRef;

  constructor(
    private _data: DataService,
    private _rs: RiskScreeningService,
    private modalService: BsModalService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.order = 'DESC';
    this.getTableData();
  }

  getTableData(){
    const payload = {
      "page" : this.tableData.currentPage - 1,
      "size" : 5,
      "orderBy": "createdDate",
      "sort" : this.order
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getRiskDashboardAmlFilters(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.tableData.data = res?.['content'] || [];
      this.updatesSla();
      this.tableData.totalItems = res?.['totalElements'] || 0;
      this.tableData.pages = res?.['totalPages'] || 0;
    })
  }

  updatesSla(){
    if(this.tableData?.data?.length){
      this.tableData?.data.forEach((item) => {
        if(item?.createdDate){
          const sla = item?.sla || 0;
          const slaInMilliseconds = sla * 60 * 60 * 1000;
          const currentEpochTime = moment(item?.createdDate).valueOf();
          const totalEpocTime = currentEpochTime + slaInMilliseconds;
          const systemDate = new Date();
          const systemEpocTime = moment(systemDate).valueOf();
          if(totalEpocTime > systemEpocTime){
            let remainingTime = totalEpocTime - systemEpocTime;
            remainingTime = Math.round(remainingTime/60000);
            item['timeRemaining'] = remainingTime;
            setInterval(() => {
              remainingTime = remainingTime - 1;
              item['timeRemaining'] = remainingTime;
            }, 60000);
          }
          else{
            item['timeRemaining'] = 0;
          }
        }
      });
    }
  }

  handleIncreasePageSize() {
    if (this.tableData.pages > this.tableData.currentPage) {
      this.tableData.currentPage += 1
      this.getTableData()
    }
  }

  handleDecreasePageSize() {
    if (this.tableData.currentPage > 1) {
      this.tableData.currentPage -= 1
      this.getTableData()
    }
  }

  goToReport(id){
    if(this.type != 'dashboard') this.route.navigate([`/aml-screening/risk-dashboard/report/${id}`])
  }

  orderFilter(event){
    this.order = event;
    this.tableData.currentPage = 1;
    this.getTableData();
  }

  viewList(data, title){
    const initialState = {
      title: title,
      type: "data-listing",
      viewListData: data
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
  }

  markAsFalsePositive(item, index, type, title){
    const initialState = {
      title: `Please enter your reason for marking as ${title}`,
      type: "false-positive-alerts",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if(Object.keys(result).length){
        const payload = {
          alertStatusType: type,
          message: result.comment,
        }
        this._rs.markAsFalsePositiveById(payload, item?.alertId).subscribe((res) => {
          this.tableData.data[index] = res;
          this._data.toastrShow(`Alert marked as ${title}`, 'info');
        })            
      }
    })
  }

  goToViewAll(type){
    this.close.emit(type);
  }

}

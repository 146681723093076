import { Component, OnInit , Input , Output , EventEmitter} from '@angular/core';

@Component({
  selector: 'app-client-config',
  templateUrl: './client-config.component.html',
  styleUrls: ['./client-config.component.scss']
})
export class ClientConfigComponent implements OnInit {
	
	@Input() clientConfig : any ;
	@Output() updatedClientConfig = new EventEmitter();

	constructor() { }

	ngOnInit() {
		console.log(this.clientConfig);
	}

	changeCheckbox(){
		// this.amlSources[index].checked = !this.amlSources[index].checked;
		// console.log(this.clientConfig);
		this.updatedClientConfig.emit(this.clientConfig);
	}

}

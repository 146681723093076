import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getParseData"
})
export class GetParseData implements PipeTransform {

  async transform(value) {
    let res = []
    if(value){
        res = await JSON.parse(value);
    }
    return res;
  }
}
import { Component, OnInit } from '@angular/core';
import { AppService, DataService } from '../../services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-webhook-history',
  templateUrl: './webhook-history.component.html',
  styleUrls: ['./webhook-history.component.scss', '../../pages/risk-screening/risk-screening.component.scss']
})
export class WebhookHistoryComponent implements OnInit {
  newPage;
  page: number = 1;
  sortArray: any[] = [
    { key: 'Latest First', value: 'desc' },
    { key: 'Oldest First', value: 'asc' },
  ]
  tableList: any[] = [];
  totalItems: number = 0;
  totalPages: number = 0;
  itemPerPage: any[] = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  order: string = 'desc';

  constructor(
    private _data: DataService,
    private _app: AppService,
  ) { }

  ngOnInit(): void {
    this.getHistoryData(this.page - 1, this.rowPageNo); 
  }

  getHistoryData(page, size){
    this._data.changeLoaderVisibility(true);
    const payload = {
      page: page,
      size: size,
      sort: this.order,
      webhookType: null
    }
    this._app.getWebhookHistoryData(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.tableList = res?.['content'] || [];
      this.totalItems = res?.['totalElements'] || 0;
      this.totalPages = res?.['totalPages'] || 0;
      this.initialNumberElement = page * size + 1;
      if (this.initialNumberElement + size > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.initialNumberElement + size - 1;
      }
    });
  }

  pageChange($event) {
    if($event <= 0 || $event > this.totalPages){
      this._data.toastrShow(`${$event <= 0 ? 'Page number cannot be less than 1' : 'You have exceeded the page number'}`, 'warning');
    }
    else{
      this.page = $event;
      this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
      if (this.page * this.rowPageNo > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.page * this.rowPageNo;
      }
      this.getHistoryData(this.page - 1, this.rowPageNo);
    }
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getHistoryData(this.page - 1, this.rowPageNo);
  }

  getFilterValue($event){
    if($event && $event != 'null'){
      this.order = $event;
      this.getHistoryData(this.page - 1, this.rowPageNo);
    }
  }

  downloadJson(response, type){
    if(response){
      const downloadJson = JSON.parse(response);
      const blob = new Blob([JSON.stringify(downloadJson)], { type: 'application/json' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${type}-body.json`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      this._data.toastrShow(`${type} is donwloaded`, 'info');
    }
    else{
      this._data.toastrShow('Something went wrong, please try again after some time', 'warning');
    }
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RiskScreeningService } from '../../../risk-screening.service';

@Component({
  selector: 'app-recent-searc-table',
  templateUrl: './recent-searc-table.component.html',
  styleUrls: ['./recent-searc-table.component.scss','../../../risk-screening.component.scss']
})
export class RecentSearcTableComponent implements OnInit {
  @Output() close = new EventEmitter<any>();
  tableData = {
    totalItems: 0,
    pages: 0,
    currentPage: 1,
    data: [],

  }
  constructor(private _rs: RiskScreeningService) { }

  ngOnInit() {
    this.getIntermediateActionReqList()
  }

  async getIntermediateActionReqList() {
    const payload = {
      page: this.tableData.currentPage - 1,
      size: 5,
      order: 'DESC'
    }
    const res = await this._rs.getTopSearchList(payload).toPromise();
    if(res.data){
      this.tableData.data = res.data.content || [];
      this.close.emit(this.tableData.data);
      this.tableData.totalItems = res.data.totalElements
      this.tableData.pages = res.data.totalPages
    }
  }

  handleIncreasePageSize() {
    if (this.tableData.pages > this.tableData.currentPage) {
      this.tableData.currentPage += 1
      this.getIntermediateActionReqList()
    }

  }
  handleDecreasePageSize() {
    if (this.tableData.currentPage > 1) {
      this.tableData.currentPage -= 1
      this.getIntermediateActionReqList()
    }
  }

  goToAMLReportPage(data){
    if(data?.entityType == 'Individual'){
      window.open("aml-screening/risk-individual-report/"+data?.entityGuid);
    }
    else if(data?.entityType == 'Entity'){
      window.open("aml-screening/risk-entity-report/"+data?.entityGuid);
    }
    else{
      window.open("aml-screening/risk-corporate-report/"+data?.entityGuid);
    }
  }

}

import { Component, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../shared/kyc-modal/kyc-modal.component";
import { BulkUploadService } from "./bulk-upload.service";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { BatchCreateChecksComponent } from "./batch-create-checks/batch-create-checks.component";

import { DataService } from "../../services/data.service";

@Component({
  selector: "app-bulk-upload",
  templateUrl: "./bulk-upload.component.html",
  styleUrls: ["./bulk-upload.component.scss"]
})
export class BulkUploadComponent implements OnInit {
  bsModalRef: BsModalRef;
  allBatchesResponse: any;
  isApiLoading: boolean = false;

  //Pagination
  currentPage: number = 0;
  itemsPerPage: number =10;
  totalItems: number;
  maxSize: number;
  sort: string = "DESC";

  buildQuery: any = {
    size: this.itemsPerPage,
    page: this.currentPage,
    sort: this.sort
  };
  pageList: any[];
  startIndex: number=0;
  endIndex: number=10;
  jump: any;

  constructor(
    private modalService: BsModalService,
    private _bulkUp: BulkUploadService,
    private _data: DataService,
    public router: Router
  ) {}

  ngOnInit() {
    this.getAllBatches(false);
    this.jump=this.currentPage+1;
  }

  ngOnDestroy() {
    console.log("destroyed!");
    if (this.bsModalRef) {
      this.bsModalRef.content.onClose.subscribe(result => {
        console.log(result);
      });
      this.bsModalRef.hide();
    }
  }

  //open new create batch modal
  createNewBatch() {
    const initialState = {
      title: `Create New Batch`,
      type: "create-new-batch"
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result && Object.keys(result).length) {
        console.log(result);
        this.allBatchesResponse.unshift(result);
      }
    });
  }

  
  // @ViewChild(BatchCreateChecksComponent) BatchCreateChecksComponent:BatchCreateChecksComponent ;
  // uploadNewCsv(){
  //   this.BatchCreateChecksComponent.createBatch('continue');
  // }

  //got to details page
  goToDetails(status, id) {
    // this.router.navigate([`/bulk-upload/${id}`, { status }]);
    this.router.navigate([`/bulk-upload/${id}`], {
      queryParams: { status: `${status}` }
    });
  }

  //get All Batches
  getAllBatches(isScroll) {
    console.log("get all batches");
    this.isApiLoading = true;
    this._data.changeLoaderVisibility(true);
    this._bulkUp.getAllBatches(this.buildQuery).subscribe(
      res => {
        console.log(res);
        let allBatchesResponse = res.data.content;
        this.allBatchesResponse = [];
        for (var i = 0; i < allBatchesResponse.length; i++) {
          this.allBatchesResponse.push(allBatchesResponse[i]);
        }
        if (this.currentPage === 0) {
          this.totalItems = res.data.totalElements;
          this.maxSize = res.data.totalPages;
          this.pageSize();
        } else {
          this.isApiLoading = false;
        }
        this.isApiLoading = false;
        this._data.changeLoaderVisibility(this.isApiLoading);
        console.log(this.allBatchesResponse);
      },
      err => {}
    );
  }

  getPaginatedText() {
    let a = this.currentPage * this.itemsPerPage + 1;
    let b;
    if (this.currentPage < this.maxSize - 1) {
      b = (this.currentPage + 1) * this.itemsPerPage;
    } else if (this.currentPage == this.maxSize - 1) {
      // let bufferCheck = this.itemsPerPage * this.maxSize
      b = this.totalItems;
    }
    return a + "-" + b;
  }

  getPaginatedData(isNext) {
    console.log("getMoreUsersData");
    this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
    this.buildQuery["page"] = isNext
      ? this.buildQuery["page"] + 1
      : this.buildQuery["page"] - 1;
    if (this.currentPage < this.maxSize) {
      this.getAllBatches(true);
      this.pageSize()
      this.jump=this.currentPage+1;
    }
  }
  // Page Bar and jumpTo functionalities
  jumpTo() {
    // let index=parseInt(this.jump)
    if (this.jump <= this.maxSize) {
      if (Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0)
        this.getPagedData(this.jump - 1)
    }
  }
  getPagedData(index) {
    console.log("getMoreUsersData");
    this.currentPage = index
    this.buildQuery["page"] = index;
    if (this.currentPage < this.maxSize) {
      this.getAllBatches(true);
      this.pageSize()
      this.jump = this.currentPage + 1;
    }

  }
  getPage(length) {
    console.log("jfhdjhffhh", length)
    // const l=length/10;
    return (Math.round(length / 10));
  }
  pageSize() {
    this.pageList = []
    let totalPages = this.getPage(this.totalItems)
    this.startIndex = (this.currentPage - 4) < 0 ? 0 : this.currentPage - 4
    this.endIndex = (this.currentPage + 4) > this.getPage(this.totalItems) ? totalPages : this.currentPage + 4
    console.log("jgfgjghjghg", this.startIndex, this.endIndex)
    for (let i = 0; i <= this.currentPage + 4; i++) {
      if (i >= this.currentPage - 4 && i < totalPages) { this.pageList.push(i) }
    }

  }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-compare-aml-report',
  templateUrl: './compare-aml-report.component.html',
  styleUrls: ['./compare-aml-report.component.scss', '../../risk-screening.component.scss']
})
export class CompareAmlReportComponent implements OnChanges {
  @Input() amlData;
  
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.amlData?.currentValue){
      this.amlData = changes?.amlData?.currentValue;
    }
  }

  swapImage(img: HTMLImageElement): void {
    img.src = '/assets/images/profile-picture.png';
  }

}

import { Component, EventEmitter, OnInit, Output,Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/internal/operators/finalize';
import { KycModalComponent } from '../../../../shared/kyc-modal/kyc-modal.component';
import { DataService, AppService } from '../../../../services/index';
import { RiskScreeningService } from '../../risk-screening.service';
import { Router} from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-risk-dashboard-table',
  templateUrl: './risk-dashboard-table.component.html',
  styleUrls: ['./risk-dashboard-table.component.scss', '../../risk-screening.component.scss']
})
export class RiskDashboardTableComponent implements OnInit {
  public bsModalRef: BsModalRef;
  @Output() close = new EventEmitter<any>();
  hide: boolean = true;
  newPage;
  page: number = 1;
  size: number = 5;
  order: string;
  orderArray: any[] = [
    { key: 'Latest First', value: 'DESC' },
    { key: 'Oldest First', value: 'ASC' },
  ];
  dateRangeArray = [
    { key: 'Last Day', value: '1' },
    { key: 'Last Week', value: '7' },
    { key: 'Last 15 Day', value: '15' },
    { key: 'Last 30 Day', value: '30' },
    { key: 'Last Quarter', value: 'quarter' },
    { key: 'Last Year', value: '365' },
  ];
  entityTypeArray = [
    { key: 'Individual', value: 'Individual' },
    { key: 'Organisation', value: 'Organisation' },
    { key: 'Vessel', value: 'Vessel' },
    { key: 'Aircraft', value: 'Aircraft' },
    { key: 'All', value: '' },
  ];
  riskLevelArray = [
    { key: 'L1', value: 'L1' },
    { key: 'L2', value: 'L2' },
    { key: 'L3', value: 'L3' },
  ];
  tableList: any[] = [];
  totalItems: number = 0;
  itemPerPage: any[] = [
    { value : 5 },
    { value : 10 },
    { value : 25 },
    { value : 50 },
  ];
  rowPageNo: number = 5;
  initialNumberElement: number = 1;
  secondElement: number = 5;
  heading: string;
  tabs = [
    { heading: 'Risk level', slug: 'risk-level' },
    { heading: 'Location', slug: 'location' },
    { heading: 'Default View', slug: 'default-view' },
  ];
  activeTabs: string = 'default-view';
  totalCount: number = 0;
  selectedFilter: any = {
    selectedName: null,
    selectedEntityType: null,
    selectedRiskLevel: null,
    selectedNationality: null
  };
  selectedDateRange: any = null;
  filterList: any[] = [];
  startDate: any;
  endDate: any;
  countries: any[] = [];
  totalPage: number = 0;

  constructor(
    private _data: DataService,
    private _rs: RiskScreeningService,
    private modalService: BsModalService,
    public route :Router,
    private _app: AppService
  ) { }

  ngOnInit() {
    this.getAllCountries();
    this.order = 'DESC';
    this.getTableData(this.page - 1, this.rowPageNo, this.order, true);
  }

  selectedTabs(slug){
    this.activeTabs = slug;
  }

  getAllCountries() {
    this._app.getAllCountries().subscribe((res) => this.countries = res?.["data"] || []);
  }

  getTableData(page, size, order, ngOnInit?){
    const payload = {
      "page" : page ,
      "size" : size,
      "orderBy": "createdDate",
      "sort" : order,
      "entityName": this.selectedFilter?.selectedName,
      "entityType": this.selectedFilter?.selectedEntityType,
      "sla": null,
      "riskLevel": this.selectedFilter?.selectedRiskLevel,
      "nationality": this.selectedFilter?.selectedNationality,
      "startDate": this.startDate,
      "endDate": this.endDate
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getRiskDashboardAmlFilters(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.tableList = res['content'] || [];
      this.updatesSla();
      this.totalItems = res?.['totalElements'] || 0;
      this.totalPage = res?.['totalPages'] || 0;
      if(ngOnInit) this.totalCount = res?.['totalElements'] || 0;
      this.initialNumberElement = page * size + 1;
      if (this.initialNumberElement + size > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.initialNumberElement + size - 1;
      }
    });
  }

  updatesSla(){
    if(this.tableList?.length){
      this.tableList.forEach((item) => {
        if(item?.createdDate){
          const sla = item?.sla || 0;
          const slaInMilliseconds = sla * 60 * 60 * 1000;
          const currentEpochTime = moment(item?.createdDate).valueOf();
          const totalEpocTime = currentEpochTime + slaInMilliseconds;
          const systemDate = new Date();
          const systemEpocTime = moment(systemDate).valueOf();
          if(totalEpocTime > systemEpocTime){
            let remainingTime = totalEpocTime - systemEpocTime;
            remainingTime = Math.round(remainingTime/60000);
            item['timeRemaining'] = remainingTime;
            setInterval(() => {
              remainingTime = remainingTime - 1;
              item['timeRemaining'] = remainingTime;
            }, 60000);
          }
          else{
            item['timeRemaining'] = 0;
          }
        }
      });
    }
  }

  orderFilter(event){
    this.order = event;
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  getFilter(type) {
    this.selectedFilter[type] = this.selectedFilter[type];
    const index = this.filterList.findIndex((e) => e.type == type);
    if (index > -1) {
      this.filterList[index].type = type;
      this.filterList[index].name = this.selectedFilter[type];
    }
    else {
      this.filterList.push({ type: type, name: this.selectedFilter[type] });
    }
    this.page = 1;
    this.order = 'DESC';
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  removeFilter(type) {
    const index = this.filterList.findIndex((e) => e.type == type);
    if (index > -1) {
      this.filterList.splice(index, 1)
    }
    if (type == 'date') {
      this.startDate = null;
      this.endDate = null;
      this.selectedDateRange = null;
    }
    else {
      this.selectedFilter[type] = null;
    }
    this.page = 1;
    this.order = 'DESC';
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  getDateFilter() {
    const type = 'date';
    if (this.selectedDateRange != 'quarter') {
      const endDate = new Date();
      this.endDate = new DatePipe('en-US').transform(endDate, 'yyyy-MM-dd');
      var startDate = new Date(new Date().setDate(new Date().getDate() - this.selectedDateRange));
      this.startDate = new DatePipe('en-US').transform(startDate, 'yyyy-MM-dd');
    }
    else {
      const endDate = new Date();
      var year = endDate.getFullYear();
      if (this.getQuarter(new Date()) == 1) {
        this.startDate = `${year - 1}-10-01`
        this.endDate = `${year - 1}-12-31`;
      }
      else if (this.getQuarter(new Date()) == 2) {
        this.startDate = `${year}-01-01`
        this.endDate = `${year}-03-31`;
      }
      else if (this.getQuarter(new Date()) == 3) {
        this.startDate = `${year}-04-01`
        this.endDate = `${year}-06-30`;
      }
      else if (this.getQuarter(new Date()) == 4) {
        this.startDate = `${year}-07-01`
        this.endDate = `${year}-09-30`;
      }
    }
    const getName = this.dateRangeArray.find((e) => e.value == this.selectedDateRange);
    const index = this.filterList.findIndex((e) => e.type == type);
    if (index > -1) {
      this.filterList[index].type = type;
      this.filterList[index].name = getName.key;
    }
    else {
      this.filterList.push({ type: type, name: getName.key });
    }
    this.page = 1;
    this.order = 'DESC';
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  getQuarter(date) {
    return Math.floor(date.getMonth() / 3 + 1);
  }

  pageChange($event) {
    if($event <= 0 || $event > this.totalPage){
      this._data.toastrShow(`${$event <= 0 ? 'Page number cannot be less than 1' : 'You have exceeded the page number'}`, 'warning');
    }
    else{
      this.page = $event;
      this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
      if (this.page * this.rowPageNo > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.page * this.rowPageNo;
      }
      this.getTableData(this.page - 1, this.rowPageNo, this.order);
    }
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.page = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  goToReport(id){
    this.route.navigate([`/aml-screening/risk-dashboard/report/${id}`])
  }

  markAsFalsePositive(item, index, type, title){
    const initialState = {
      title: `Please enter your reason for marking as ${title}`,
      type: "false-positive-alerts",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if(Object.keys(result).length){
        const payload = {
          alertStatusType: type,
          message: result.comment,
        }
        this._rs.markAsFalsePositiveById(payload, item?.alertId).subscribe((res) => {
          this.tableList[index] = res;
          this._data.toastrShow(`Alert marked as ${title}`, 'info');
        })            
      }
    })
  }

  closeInfo(event: MouseEvent){
    event.preventDefault();
    this.close.emit('open-main-comp');
  }

}

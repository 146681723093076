import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HistoryComponent } from "./pages/history/history.component";
import { LoginComponent } from "./pages/auth/login/login.component";
import { RegisterComponent } from "./pages/auth/register/register.component";
import { PasswordComponent } from "./pages/auth/password/password.component";
import { ClientConfigComponent } from "./components/client-config/client-config.component";

import { PaymentComponent } from "./pages/payment/payment.component";

import { NotfoundComponent } from "./shared/notfound/notfound.component";
import { VerifyComponent } from "./shared/verify/verify.component";

//Guards
import { AuthGuard } from "./guards/auth.guard";
import { DeGuard } from "./guards/deactivate.guard";
import { AccessGuard } from "./guards/access.guard";
import { PaymentGuard } from "./guards/payment.guard";
import { NewReportPageComponent } from './pages/reports/new-report-page/new-report-page.component';
import { ProfileSearchComponent } from "./pages/aml-search/profile-search/profile-search.component";
import { ReturnpolicyComponent } from "./pages/returnpolicy/returnpolicy.component";
import { PrivacypolicyComponent } from "./pages/privacypolicy/privacypolicy.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { PageComponentResolver } from "./resolvers/page-component.resolver";
import { AmlFalsePositiveComponent } from "./pages/aml-false-positive/aml-false-positive.component";
import { BillingsComponent } from "./pages/dev-ops/billings/billings.component";
import { WebhookHistoryComponent } from "./components/webhook-history/webhook-history.component";

const routes: Routes = [

  // {
  //   path: "home",
  //   component: DashboardComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "home",
    pathMatch: "full",
    loadChildren: () => import("./pages/dashboard/dashboard.module").then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    resolve: { compList: PageComponentResolver }
  },

  // {
  //   path: "user-reports",
  //   component: ReportsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "user-reports",
    loadChildren: () => import("./pages/reports/reports.module").then(m => m.ReportsModule),
    canActivate: [AuthGuard]
  },

  {
    path: "new-ind-report",
    component: NewReportPageComponent,
    canActivate: [AuthGuard]
  },

  // {
  //   path: "user-reports/:userName",
  //   component: ReportDetailsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "user-reports/:userName",
    loadChildren: () => import("./pages/report-details/report-details.module").then(m => m.ReportDetailsModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: "aml-alerts/:userName",
  //   component: AmlAlertDetailsComponent,
  //   canActivate : [AuthGuard]
  // },
  // {
  //   path: "corp-reports/:userName",
  //   component: CorpReportComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "corp-reports/:userName",
    loadChildren: () => import("./pages/corp-report/corp-report.module").then(m => m.CorpReportModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: "aml-alerts",
  //   component: AmlAlertsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "aml-alerts",
    loadChildren: () => import("./pages/aml-alerts/aml-alerts.module").then(m => m.AmlAlertsModule),
    canActivate: [AuthGuard]
  },
  {
    path: "expired-checks",
    loadChildren: () => import("./pages/check-status/check-status.module").then(m => m.CheckStatusModule),
    canActivate: [AuthGuard]
  },
  {
    path: "override-report",
    loadChildren: () => import("./pages/override-report/override-report.module").then(m => m.OverrideReportModule),
    canActivate: [AuthGuard]
  },

  // {
  //   path: "user-home",
  //   component: UserHomeComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "user-home",
    loadChildren: () => import("./pages/user-home/user-home.module").then(m => m.UserHomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: "aml-bulk-screening",
    loadChildren: () => import("./pages/aml-bulk-screening/aml-bulk-screening.module").then(m => m.AmlBulkScreeningModule),
    canActivate: [AuthGuard]
  },

  {
    path: "notifications",
    loadChildren: () => import("./pages/notification/notification.module").then(m => m.NotificationModule),
    canActivate: [AuthGuard]
  },

  {
    path: "doc-extraction",
    loadChildren: () => import("./pages/doc-extraction/doc-extraction.module").then(m => m.DocExtractionModule),
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: "activity-log",
    loadChildren: () => import("./pages/activity-log/activity-log.module").then(m => m.ActivityLogModule),
    canActivate: [AuthGuard]
  },
  {
    path: "analytics",
    component: HistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "video-kyc-agent",
    loadChildren: () => import("./pages/video-kyc/video-kyc-agent/video-kyc-agent.module").then(m => m.VideoKycAgentModule),
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  {
    path: "video-kyc-client",
    loadChildren: () => import("./pages/video-kyc/video-kyc-client/video-kyc-client.module").then(m => m.VideoKycClientModule),
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  // {
  //   path: "company",
  //   component: CorporateCheckComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "company",
    loadChildren: () => import("./components/corporate-check/corporate-check.module").then(m => m.CorporateCheckModule),
    canActivate: [AuthGuard]
  },

  // {
  //   path: "coupons",
  //   component: CouponsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "coupons",
    loadChildren: () => import("./pages/coupons/coupons.module").then(m => m.CouponsModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: "pricing",
  //   component: PricingStructureComponent,
  //   canActivate : [AuthGuard]
  // },
  //   {
  //     path: "pricing",
  //     loadChildren: () => import(
  //       "./pages/pricing-structure/pricing-structure.module").then(m => m.PricingStructureModule),
  //     canActivate: [AuthGuard]
  //   },
  // {
  //   path: "live-check",
  //   // component: LiveCheckComponent,
  //   canActivate : [AuthGuard],
  //   children: [
  //     // {path: '', redirectTo: 'category' , pathMatch : "full"  },
  //     {path: '', component: LiveCheckComponent  },
  //     // {path: ':category/basic-info', component: LiveCheckComponent},
  //     {path: ':category/:hashId', component: LiveCheckComponent}
  //   ]
  // },
  // {
  //   path: "live-check",
  //   component: LiveCheckComponent,
  //   canActivate : [AuthGuard]
  // },
  // {
  //   path: "live-check/:category/:hashId",
  //   component: LiveCheckComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "one-time-setup",
    loadChildren: () => import("./pages/pricing-new/pricing-new.module").then(m => m.PricingNewModule),
    canActivate: [AuthGuard],
  },

  {
    path: "aml-search",
    loadChildren: () => import("./pages/aml-search/aml-search.module").then(m => m.AmlSearchModule),
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  {
    path: "media-intelligence",
    loadChildren: () => import("./pages/adverse-media/adverse-media.module").then(m => m.AdverseMediaModule),
    canActivate: [AuthGuard],
  },
  {
    path: "consumption-report",
    loadChildren: () => import("./pages/consumtion-report/consumtion-report.module").then(m => m.ConsumtionReportModule),
    canActivate: [AuthGuard],
  },
  {
    path: "corporate-check",
    loadChildren: () => import("./pages/corporate-check/corporate-check.module").then(m => m.CorporateCheckModule),
    canActivate: [AuthGuard],
  },
  {
    path: "bulk-upload-result",
    loadChildren: () => import("./pages/bulk-upload-result/bulk-upload-result.module").then(m => m.BulkUploadResultModule),
    canActivate: [AuthGuard],
  },
  {
    path: "aml-screening",
    loadChildren: () => import("./pages/risk-screening/risk-screening.module").then(m => m.RiskScreeningModule),
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  {
    path: "profile-search",
    component: ProfileSearchComponent,
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  {
    path: "corporate-search",
    loadChildren: () => import("./pages/corporate-search/corporate-search.module").then(m => m.CorporateSearchModule),
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  {
    path: "digital-verification",
    loadChildren: () => import("./pages/digital-verification/digital-verification.module").then(m => m.DigitalVerificationModule),
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  {
    path: "live-check",
    loadChildren: () => import("./pages/live-check/live-check.module").then(m => m.LiveCheckModule),
    canActivate: [AuthGuard]
  },
  {
    path: "iframe-onboarding",
    loadChildren: () => import("./pages/iframe-onboarding/iframe-onboarding.module").then(m => m.IframeOnboardingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dev-ops',
    loadChildren: () => import("./pages/dev-ops/dev-ops.module").then(m => m.DevOpsModule),
    canActivate: [AuthGuard, AccessGuard],

  },
  // {
  //   path: "settings",
  //   component: SettingsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "settings",
    loadChildren: () => import("./pages/settings/settings.module").then(m => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: "invoices",
  //   component: InvoicesComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "invoices",
    loadChildren: () => import("./pages/invoices/invoices.module").then(m => m.InvoicesModule),
    canActivate: [AuthGuard]
  },

  // {
  //   path: "invoices/:id",
  //   component: InvoicesComponent,
  //   canActivate : [AuthGuard]
  // },
  // {
  //   path: "subscriptions",
  //   component: SubscriptionsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "subscriptions",
    loadChildren: () => import("./pages/subscriptions/subscriptions.module").then(m => m.SubscriptionsModule),
    canActivate: [AuthGuard]
  },

  // {
  //   path: "roles",
  //   component: RolesComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "roles",
    loadChildren: () => import("./pages/roles-new/roles.module").then(m => m.RolesModule),
    canActivate: [AuthGuard, AccessGuard]
  },

  {
    path: "access-denied",
    loadChildren: () => import("./pages/access-denied/access-denied.module").then(m => m.AccessDeniedModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: "developer",
  //   component: DeveloperComponent,
  //   canActivate : [AuthGuard]
  // },
  // {
  //   path: "faq",
  //   component: FaqComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "faq",
    loadChildren: () => import("./pages/faq/faq.module").then(m => m.FaqModule),
    canActivate: [AuthGuard]
  },
  {
    path: "bulk-upload",
    loadChildren: () => import("./pages/bulk-upload/bulk-upload.module").then(m => m.BulkUploadModule),
    canActivate: [AuthGuard, AccessGuard]
  },

  {
    // path: "services",
    path: "payment",
    loadChildren: () => import("./pages/payment/payment.module").then(m => m.PaymentModule),
    canActivate: [DeGuard]
  },

  // {
  //   path: "payment",
  //   component: PaymentComponent
  // },

  {
    path: "contact",
    component: ContactComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "tnc",
    component: TermsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "privacy-policy",
    component: PrivacypolicyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "cancel-refund",
    component: ReturnpolicyComponent,
    canActivate: [AuthGuard]
  },

  {
    path: "login",
    component: LoginComponent,
    canActivate: [DeGuard],
    data: {
      title: 'KYC HUB Dashboard - Login.',
      description:'Login to access our robust integrations, simplifying your regulatory adherence.',
      // ogUrl: 'your og url'
    } 
  },
  {
    path: "register",
    component: RegisterComponent,
    canActivate: [DeGuard],
    data: {
      title: 'KYC Dashboard - Register.',
      description:'Register with KYC Hub and effortlessly integrate your enterprise applications with our compliance platform.',
      // ogUrl: 'your og url'
    } 
  },
  // {
  //   path: "test",
  //   component: TestComponent
  // },
  {
    path: "aml-false-positive",
    component: AmlFalsePositiveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "webhook-history",
    component: WebhookHistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "user-invoices",
    component: BillingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "checkout",
    loadChildren: () => import("./components/checkout/checkout.module").then(m => m.CheckoutModule),
  },
  {
    path: "test",
    loadChildren: () => import("./shared/test/test.module").then(m => m.TestModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: "theming",
  //   component: ThemingComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "theming",
    loadChildren: () => import("./pages/theming/theming.module").then(m => m.ThemingModule),
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: "customize",
    loadChildren: () => import("./pages/customize/customize.module").then(m => m.CustomizeModule),
    canActivate: [AuthGuard]
  },
  //Password Related
  {
    path: "reset-password",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },
  {
    path: "setup-account",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },
  {
    path: "forgot-password",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },
  {
    path: "verify-email",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },

  {
    path: "change-password",
    component: PasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "404",
    component: NotfoundComponent
  },
  {
    path: "verify",
    component: VerifyComponent,
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "/404"

    // children: [
    //   {path: '', redirectTo: '404' , pathMatch : "full"  },
    //   {path: '404', component: NotfoundComponent  }    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      useHash: false,
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService, AppService } from '../../../../services/index';
import { RiskScreeningService } from '../../risk-screening.service';
import * as moment from 'moment';
import { KycModalComponent } from '../../../../shared/kyc-modal/kyc-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-risk-dashboard-report',
  templateUrl: './risk-dashboard-report.component.html',
  styleUrls: ['./risk-dashboard-report.component.scss', '../../risk-screening.component.scss']
})
export class RiskDashboardReportComponent implements OnInit {
  responseData: any;
  public bsModalRef: BsModalRef;
  categoryList = [{ "slug": "pep", "name": "PEP", "value": false, entity: "Individual" }, { "slug": "crime", "name": "Crime", "value": false, entity: "Individual,Organisation,Entity" },
  { "slug": "sanction", "name": "Sanction", "value": false, entity: "Individual,Organisation,Entity" }, { "slug": "watchlist", "name": "Watchlist", "value": false, entity: "Individual,Organisation,Entity" },
  { "slug": "sip", "name": "SIP", "value": false, entity: "Individual" }, { "slug": "sie", "name": "SIE", "value": false, entity: "Organisation,Entity" },
  { "slug": "si", "name": "SI", value: false, entity: "Individual,Organisation,Entity" }
  ];
  amlArray: any[] = [];
  corpAmlUseArray: any[] = [];
  isCompareReport: any = null;

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private _data: DataService, 
    private _rs: RiskScreeningService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(({id}) => {
      if(id){
        this.getReportData(id);
      }
    })
  }

  getReportData(id: string){
    this._data.changeLoaderVisibility(true);
    this._rs.getRiskDashboardReportData(id).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.responseData = res;
      if(this.responseData){
        this.updatesSla();
        if(this.responseData?.oldAmlFormattedData?.guid){
        }
        if(this.responseData?.newAmlFormattedData?.guid){
          if (this.responseData?.newAmlFormattedData?.guid) {
            this._data.changeLoaderVisibility(true);
            this._rs.getDetailedRecord(this.responseData?.newAmlFormattedData?.guid).subscribe(async (res) => {
              if (res['data'] && res['data']['content']) {
                const amlResponse = res['data']['content'][0];
                const amlData = JSON.stringify(amlResponse);
                await this.getData(amlData);
              }
              this._data.changeLoaderVisibility(false);
            }, err => this._data.changeLoaderVisibility(false))
          }
        }
      }
    })
  }

  updatesSla(){
    if(this.responseData?.createdDate){
      const sla = this.responseData?.sla || 0;
      const slaInMilliseconds = sla * 60 * 60 * 1000;
      const currentEpochTime = moment(this.responseData?.createdDate).valueOf();
      const totalEpocTime = currentEpochTime + slaInMilliseconds;
      const systemDate = new Date();
      const systemEpocTime = moment(systemDate).valueOf();
      if(totalEpocTime > systemEpocTime){
        let remainingTime = totalEpocTime - systemEpocTime;
        remainingTime = Math.round(remainingTime/60000);
        this.responseData['timeRemaining'] = remainingTime;
        setInterval(() => {
          remainingTime = remainingTime - 1;
          this.responseData['timeRemaining'] = remainingTime;
        }, 60000);
      }
      else{
        this.responseData['timeRemaining'] = 0;
      }
    }
  }

  viewList(data, title){
    const initialState = {
      title: title,
      type: "data-listing",
      viewListData: data
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
  }

  markAsFalsePositive(type, title){
    const initialState = {
      title: `Please enter your reason for marking as ${title}`,
      type: "false-positive-alerts",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if(Object.keys(result).length){
        const payload = {
          alertStatusType: type,
          message: result.comment,
        }
        this._rs.markAsFalsePositiveById(payload, this.responseData?.alertId).subscribe((res) => {
          this._data.toastrShow(`Alert marked as ${title}`, 'info');
        })            
      }
    })
  }

  goToRiskDashboard(){
    this.router.navigateByUrl('aml-screening/risk-dashboard')
  }

  goToCompareReport(){
    this.isCompareReport = this.responseData;
  }

  async getData(amlData) {
    amlData = JSON.parse(amlData);
    if(amlData?.masterData?.entityType){
      this.categoryList = this.categoryList.filter((e) => e.entity.includes(amlData?.masterData?.entityType));
    }
  
    // Aml Data
    if (amlData?.details?.length) {
      this.amlArray = this.categoryList.map((e) => (
        {
          categoryName: e.name, entityType: amlData?.masterData?.entityType,
          details: amlData?.details.filter((f) => f.category.findIndex((i) => i.slug === e.slug) != -1)
            .map((m) => ({ ...m, dateAdded: amlData.recordCreatedDate, lastModified: amlData.recordLastModifiedDate }))
        }
      ));
      for (var j = 0; this.amlArray.length > j; j++) {
        await this.getIndexValueForCorpAML(j);
      }
    }
  }

  // For Auto Expand

  getIndexValueForCorpAML(i) {
    if (this.corpAmlUseArray.indexOf(i) > -1) {
      this.corpAmlUseArray.splice(this.corpAmlUseArray.indexOf(i), 1)
    }
    else {
      this.corpAmlUseArray.push(i)
    }
  }

}

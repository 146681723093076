import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  CustomerDetailsComponent,
  CustomerTableComponent
} from './index';
import { VideoKycClientComponent } from './video-kyc-client.component';

const routes: Routes = [
  { path: 'dashboard', component: VideoKycClientComponent },
  { path: 'customer/details', component: CustomerDetailsComponent }
  // { path: 'customers', component: CustomerTableComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VideoKycClientRoutingModule { }

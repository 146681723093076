import { Component, OnInit } from '@angular/core';
import { RiskScreeningService } from '../risk-screening.service';
import { barChartOptions, lineChartOptions, pieChartOptions } from './dashboard.charts.config';
import { DataService } from '../../../services';
import { finalize } from 'rxjs/operators';

enum WidgetTypes  {
  LINE ="RISK_DASHBOARD_ENTITY_TYPE",
  PIE ="RISK_DASHBOARD_JURISDICTION",
  BAR ="RISK_DASHBOARD_RISK_LEVEL",
}
@Component({
  selector: 'app-risk-dashboard-comp',
  templateUrl: './risk-dashboard-comp.component.html',
  styleUrls: ['./risk-dashboard-comp.component.scss', '../risk-screening.component.scss']
})
export class RiskDashboardCompComponent implements OnInit {
  longTable: boolean = false;
  barOptions = barChartOptions;
  lineOptions = lineChartOptions;
  pieOptions = pieChartOptions;
  barData = null;
  pieData = null
  lineData = null;
  pieLegends = [];
  barLegends = []
  alertCountData: any;
  constructor(
    private _rs: RiskScreeningService,
    private _data: DataService) { }

  ngOnInit(): void {
    this.fetchData()
    this.getRiskDashboardAlertCount();
  }
  
  async fetchData() {
    const res = await this._rs.getDashboardChartsData().toPromise()
    this.barData=res.find(e=>e.widgetType===WidgetTypes.BAR).dataSet.map(e=>({name:e.label,value:e.value1}))
    this.pieData =res.find(e=>e.widgetType===WidgetTypes.PIE).dataSet.map(e=>({name:e.label,value:e.value1}))
    this.pieLegends=this.pieData.map((e,i)=>({label:e.name,color:pieChartOptions.colorScheme.domain[i]}))
    this.barLegends=this.barData.map((e,i)=>({label:e.name,color:barChartOptions.colorScheme.domain[i]}))
    this.lineData =res.find(e=>e.widgetType===WidgetTypes.LINE).dataSet.map(e=>({name:e.label,series:e.data.map(f=>({name:f.date?.substring(0,10),value:f.value}))}))
    console.log(99, this.pieData,this.barData,this.lineData)
  }
  getRiskDashboardAlertCount(){
    this._data.changeLoaderVisibility(true);
    this._rs.getRiskDashboardAlertCount().pipe((finalize(() => this._data.changeLoaderVisibility(false)))).subscribe((res) => {
      this.alertCountData = res;
    })
  }
  getOutputData($event) {
    if ($event == 'View all') {
      this.longTable = true;
    }
    else if ($event == 'open-main-comp') {
      this.longTable = false;
    }
  }

}


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AmlChecksService {

  constructor(private http: HttpClient,
     @Inject('API_URL') public KYS_API_URL: any,
     ) { }

  public back = new BehaviorSubject<boolean>(false);
  backVal(val){
      this.back.next(val);
  }

  searchEntityIndividual(obj) {
    return this.http.post<any>(`${this.KYS_API_URL}/v2/aml/new-search`, obj).pipe(
        map((res) => {
            return res;
        })
    );
  }
  saveAmlCheckList(obj) {
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/amllist/save`, obj).pipe(
        map((res) => {
            return res;
        })
    );
  }
  getGeoLocationScore(obj) {
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/geolocation-score-risk`, obj).pipe(
        map((res) => {
            return res;
        })
    );
  }
  getAdvMediaResults(obj) {
    return this.http.post<any>(`${this.KYS_API_URL}/v2/crr-data/search/`, obj).pipe(
        map((res) => {
            return res;
        })
    );
  }
  getArticle(id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/adverse-media/article/${id}?adverseMediaSourceType=DEVOPS`).pipe(map((res: any) => res.data));
  }

  getAmlSearchList(page: number, size: number){
    return this.http.post(`${this.KYS_API_URL}/kychub/v1/aml-check/get-history`,{
      "page": page,
      "size": size,
      "orderBy": "createdDate",
      "order": "DESC"
    }).pipe(map((res: any) => res.data));
  }

  bookMarkEntity(data){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/alerts/bookmark-entity`,data).pipe(map((res:any)=>res.data))
  }

  getEntityByGuid(guid){
    return this.http.get(`${this.KYS_API_URL}/v2/aml/search/${guid}`).pipe(map((res:any)=>res.data))
  }

  downloadReport(payload){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/aml/report`,payload,{responseType:"blob"}).pipe(map((res: any) => res));
  }

  getDownloadHistory(page,size){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/aml/report/download-history`,{
      "page": page,
      "size": size,
      "orderBy": "createdDate",
      "order": "DESC"
    }).pipe(map((res: any) => res.data));
  }
  downloadReportbyId(id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/aml/report/redownload/${id}`,{responseType:"blob"}).pipe(map((res: any) => res));
  }

  getBookmarkData(){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/alerts/bookmarked/get-bookmarked-data`).pipe(map((res: any) => res.data));
  }
  retailDownloadReportZip(username) {
    let headers = new HttpHeaders({
        'Content-Type': 'application/octet-stream',
    });

    let authHttpOptions = {
        headers: headers,
        responseType: 'blob',
    };

    return this.http
        .get(`${this.KYS_API_URL}/kys/v2.0/download/${username}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream',
            }),
            responseType: 'blob',
        })
        .pipe(
            map((res) => {
                return res;
            })
        );
  }
  getAMLArticles(payload){
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/adverse-media/get-article`,payload)
  } 
  getRelationGraphData(id){
   // return this.http.get(`${this.API_URL_AML}/v1/amldata/rca/${id}`).pipe(map((res: any) => res.data));
   return this.http.get(`${this.KYS_API_URL}/v2/aml/rca/${id}`).pipe(map((res: any) => res.data)); 
  }
  getRelationGraphDataImmediate(id){
    // return this.http.get(`${this.API_URL_AML}/v1/amldata/rca/${id}`).pipe(map((res: any) => res.data));
    return this.http.get(`${this.KYS_API_URL}/v2/aml/immediate/rca/${id}`).pipe(map((res: any) => res.data)); 
   }
  getRelatedEntitiesByName(payload){
    return this.http.post<any>(`${this.KYS_API_URL}/director/entity-info`,payload)
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RiskScreeningService } from '../../../app/pages/risk-screening/risk-screening.service';
import { AppService } from '../../../app/services';

@Component({
  selector: 'app-financials-corporate-aml-summary',
  templateUrl: './financials-corporate-aml-summary.component.html',
  styleUrls: ['./financials-corporate-aml-summary.component.scss','../../pages/risk-screening/risk-screening.component.scss']
})
export class FinancialsCorporateAmlSummaryComponent implements OnInit, OnChanges {
  @Input() healthCheckDetails: any;
  currentTheme: any;
  lineChartData: any={
    monthLineChart:{
      data:null,
      colorSchema:null
    },
    weekLineChart:{
      data:null,
      colorSchema:null
    },
    yearLineChart:{
      data:null,
      colorSchema:null
    },
    
  };
  lineTimeOptions = [
    { key: 'Last Week', value: 'weekLineChart' },
    { key: 'Last Month', value: 'monthLineChart' },
    { key: 'Last Year', value: 'yearLineChart' },
  ];
  lineTimeSelectedOption = this.lineTimeOptions[0].value
  pieChart: any={
    data:null,
    colorSchema:null
  };
  notListedLineChart={
    data:null,
    colorSchema:null
  }

  constructor(private _rs: RiskScreeningService, public app: AppService,) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.healthCheckDetails && changes.healthCheckDetails.currentValue) {
      this.healthCheckDetails = changes.healthCheckDetails.currentValue;
      console.log(this.healthCheckDetails, "healthCheckDetails")
    }

    if(this.healthCheckDetails?.companyInformation?.isListed && this.healthCheckDetails?.stockMarketTrend){
      this.lineChartData.monthLineChart.data = this.formatRawDataToLineChartData(this.healthCheckDetails?.stockMarketTrend?.monthLineChart);
      this.lineChartData.weekLineChart.data = this.formatRawDataToLineChartData(this.healthCheckDetails?.stockMarketTrend?.weekLineChart);
      this.lineChartData.yearLineChart.data = this.formatRawDataToLineChartData(this.healthCheckDetails?.stockMarketTrend?.yearLineChart);
      this.lineChartData.monthLineChart.colorSchema = this.formatRawDataToLineChartColorSchema(this.healthCheckDetails?.stockMarketTrend?.monthLineChart);
      this.lineChartData.weekLineChart.colorSchema = this.formatRawDataToLineChartColorSchema(this.healthCheckDetails?.stockMarketTrend?.weekLineChart);
      this.lineChartData.yearLineChart.colorSchema = this.formatRawDataToLineChartColorSchema(this.healthCheckDetails?.stockMarketTrend?.yearLineChart);
    }else{
      if(this.healthCheckDetails?.mentionOverTimeBySource){
        this.notListedLineChart.data = this.formatRawDataToLineChartData(this.healthCheckDetails?.mentionOverTimeBySource?.lineChart);
        this.notListedLineChart.colorSchema = this.formatRawDataToLineChartColorSchema(this.healthCheckDetails?.mentionOverTimeBySource?.lineChart);
        console.log(this.notListedLineChart, "notListedLineChart")
      }
    }

    if(this.healthCheckDetails?.marketShare?.pieChart){
      this.pieChart.data = this.healthCheckDetails?.marketShare?.pieChart?.chartInsightsData?.map(e => ({
        name:e?.name,
        value: e?.chartIntervalList?.[0]?.value
      }))
      this.pieChart.colorSchema = {
        domain: this.healthCheckDetails?.marketShare?.pieChart?.legendsConfigList?.map(e => e?.color)
      }
    }

  }

  ngOnInit(): void {
    this.app.currentTheme.subscribe(theme => {
      this.currentTheme = theme;
    });
  }

  formatRawDataToLineChartData(rawData){
    const formatData = rawData?.chartInsightsData?.map(e => ({
      name:e?.name,
      series: e?.chartIntervalList?.map(f => ({
        name: f?.secondaryAtt,
        value: f?.value
      }))
    }));
    return formatData;
  }

  formatRawDataToLineChartColorSchema(rawData){
    const domain = rawData?.legendsConfigList?.map(e => e?.color);
    return {domain};
  }


}

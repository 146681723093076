import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { VideoKycService } from '../../video-kyc/vide-kyc.service';
import { AppService } from '../../../../app/services'; 
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { KycModalComponent } from '../../../shared/kyc-modal/kyc-modal.component';

@Component({
  selector: 'app-video-kyc-application-details',
  templateUrl: './video-kyc-application-details.component.html',
  styleUrls: ['./video-kyc-application-details.component.scss']
})
export class VideoKycApplicationDetailsComponent implements OnInit {

  item;
  @Output() close = new EventEmitter<any>();
  public canvasWidth = 300
  public needleValue = 0
  public bottomLabel = '0';
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#14E167', '#FC9031', '#FC3131'],
    arcDelimiters: [30, 50],
    rangeLabel: ['0', '100'],
    needleStartValue: 0,
  }
  ocrData: {};
  frontImage : SafeUrl = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded';
  ScheduledChecksList = [
    {
    key:'photoUrl',
    slug:'Photo',
   },
   {
    key:'idProofUrl',
    slug:'ID Proof',
   },
   {
    key:'videoUrl',
    slug:'Video',
   },
   {
    key:'uploadedIdDocUrl',
    slug:'Uploaded ID Doc',
   }
  ] 
  extractionResult: any;
  faceExtractionResults: any;
  emotionalAnalysisArray: { emoji: string; name: string; value: any; }[];
  currentTheme: string;
  imagesArr = []
  selfieUrl: any;
  idProofUrl: any;
  tabs=[
    {
      heading:'Profile Overview',
      slug:'profileOverviewAccordian'
    },
    {
      heading:'Scheduled checks',
      slug:'scheduledChecksAccordian'
    },
    {
      heading:'Identity Proof',
      slug:'identityProofAccordian'
    },
    {
      heading:'Identity Doc Match',
      slug:'IdDocMatchAccordian'
    },
    {
      heading:'Liveliness Proof',
      slug:'LivelinessChecksAccordian'
    },
    {
      heading:'Selfie Check',
      slug:'SelfieChecksAccordian'
    },
    
  ]
  activeTab: any = this.tabs[0].slug;
  liveLiNess: any;
  docType: any;
  bsModalRef: BsModalRef;
  clientId = null
  constructor(
    private _data: DataService,
    private _vs: VideoKycService,
    private app:AppService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
  ) {
    this.clientId = this.route.snapshot.paramMap.get('clientId')
    console.log('his',history.state.data)
    if(history.state.data){
      this.item =history.state.data
      if (this.item) {
        if(this.item?.documentExtractionInfo){ //OCR Data
          this.getOcrData(this.item?.documentExtractionInfo);
        }
        if(this.item?.selfieCheck){ //Selfie Check
          this.getSelfieCheckData(JSON.parse(this.item?.selfieCheck));
        }
        if(this.item?.livelinessCheck){ //Liveliness Check
          this.getLivelinessData(JSON.parse(this.item?.livelinessCheck))
        }
        this.docType = this.item?.docType;
        console.log(this.docType,'docType');
        if(this.docType === 'CAPTURED'){
          this.getIdentityDoc(this.item.id)
        }else if(this.docType === 'UPLOADED'){
          this.getUploadedFiles(this.item.id)
        }else{
          this.frontImage = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded'
        }
        this.getSelfieDoc(this.item.id);
        if(this.item?.kycStatus === 'NOT_DONE' || this.item?.kycStatus === 'FAILED'){
          this.needleValue = 0;
        }else if(this.item?.kycStatus === 'ACKNOWLEDGED'){
          this.needleValue = 50
        }else{
          this.needleValue = 100
        }
        this.item['kycStatusKey'] = this.getKycStatusKey(this.item?.kycStatus)
        console.log(this.item,'onChange')
      }
      else {
        console.log(this.item,"onChange","no")
        if(this.item?.documentExtractionInfo){ //OCR Data
          this.getOcrData(this.item?.documentExtractionInfo);
        }
        if(this.item?.selfieCheck){ //Selfie Check
          this.getSelfieCheckData(JSON.parse(this.item?.selfieCheck));
        }
        if(this.item?.livelinessCheck){ //Liveliness Check
          this.getLivelinessData(JSON.parse(this.item?.livelinessCheck))
        }
        this.docType = this.item?.docType;
        console.log(this.docType,'docType');
        if(this.docType === 'CAPTURED'){
          this.getIdentityDoc(this.item.id)
        }else if(this.docType === 'UPLOADED'){
          this.getUploadedFiles(this.item.id)
        }else{
          this.frontImage = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded'
        }
        this.getSelfieDoc(this.item.id);
        if(this.item?.kycStatus === 'NOT_DONE' || this.item?.kycStatus === 'FAILED'){
          this.needleValue = 0;
        }else if(this.item?.kycStatus === 'ACKNOWLEDGED'){
          this.needleValue = 50
        }else{
          this.needleValue = 100
        }
      }
    }
    else if(this.clientId){
      this.router.navigate([`/video-kyc/${this.clientId}`]);
    }
    else{
      this.router.navigate(['/video-kyc']);
    }
   
   }
 
  getKycStatusKey(status:string){
    switch (status) {
      case "NOT_DONE":
      return 'Initiated'
        case "ACKNOWLEDGED":
          return 'In Progress'
        
           case "COMPLETED":
            return 'Completed'
        case "FAILED":
          return "Failed"
        
      
      default:
        break;
    }
  }
 ngOnInit(): void {
  this.clientId = this.route.snapshot.paramMap.get('clientId');
  console.log('clientId',this.clientId)
 }

  getUploadedFiles(userID){
    this._vs.fetchUploadedDocument(userID).subscribe(res => {
      if(res){
        let url = URL.createObjectURL(res.body) 
        this.frontImage =  this.sanitizer.bypassSecurityTrustUrl(url);
        console.log(this.frontImage,'docType')
      }
      }      
    )
  }

  getIdentityDoc(userId) {
    this._vs.fetchIdImage(userId).subscribe((res: any) => {
      console.log(99, res.body);
      let objectURL = URL.createObjectURL(res.body);
      this.frontImage = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      console.log(this.frontImage,'docType')
      // this.imagesArr.push({ name: 'Identity Image', url: this.sanitizer.bypassSecurityTrustUrl(objectURL) })
    })
  }

  getSelfieDoc(userId) {
    this._vs.fetchImage(userId).subscribe((res: any) => {
      console.log(99, res.body);
      let objectURL = URL.createObjectURL(res.body);
      this.selfieUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      // this.imagesArr.push({ name: 'Selfie Image', url: this.sanitizer.bypassSecurityTrustUrl(objectURL) })
    })
  }

  getLivelinessData(res){
    console.log(res,"liveliness")
    this.liveLiNess = res?.data?.livelinessRes;
  }

  getOcrData(res) {
    this.ocrData = res;
    if(this.ocrData['postProcessedInfo']){
      this.ocrData['ocrTextData'] = JSON.parse(this.ocrData['postProcessedInfo']);
    }
    if(this.ocrData['docFrontUrl']){
      const base64Object = {
        url : this.ocrData['docFrontUrl']
      }
      this._vs.getBase64Data(base64Object).subscribe((base64: any) =>  {
        // this.frontImage = base64;
      });
    }
  }

  swapImage(img: HTMLImageElement): void {
    img.src = 'https://via.placeholder.com/177x150.png?text=Image%20not%20Uploaded';
  }

  goBack() {
    this.router.navigate([`/video-kyc/${this.clientId}`]);
  }

  updateStatusAsDone(status){
    let payload = {
      "id": this.item.id,
      "kycStatus": status
    }
    this._vs.agentApprovalStatus(payload).subscribe((res: any) => {
      console.log(res)
      this._data.toastrShow(`User Status updated to ${this.getKycStatusKey(status)}  successfully`, 'info')
    })
  }

  getSelfieCheckData(data){
    
    let res = data.data
    if(res){
      this.extractionResult = res?.selfieAtt;
      this.faceExtractionResults = res?.selfieRes;
      this.app.currentTheme.subscribe(theme => {
        this.currentTheme = theme;
        if(true){        
          this.emotionalAnalysisArray = new Array(
            { emoji: '../../../../../assets/images/emotions/Neutral_light_mode.svg', name: 'Neutral', value: this.extractionResult["faceAttributes"]["emotion"]["neutral"] },
            { emoji: '../../../../../assets/images/emotions/Anger_light_mode.svg', name: 'Anger', value: this.extractionResult["faceAttributes"]["emotion"]["anger"] },
            { emoji: '../../../../../assets/images/emotions/Contempt_light_mode.svg', name: 'Contempt', value: this.extractionResult["faceAttributes"]["emotion"]["contempt"] },
            { emoji: '../../../../../assets/images/emotions/Surprise_light_mode.svg', name: 'Surprise', value: this.extractionResult["faceAttributes"]["emotion"]["surprise"] },
            { emoji: '../../../../../assets/images/emotions/Sadness_light_mode.svg', name: 'Sadness', value: this.extractionResult["faceAttributes"]["emotion"]["sadness"] },
            { emoji: '../../../../../assets/images/emotions/Happiness_light_mode.svg', name: 'Happiness', value: this.extractionResult["faceAttributes"]["emotion"]["happiness"] },
            { emoji: '../../../../../assets/images/emotions/Fear_light_mode.svg', name: 'Fear', value: this.extractionResult["faceAttributes"]["emotion"]["fear"] },
            { emoji: '../../../../../assets/images/emotions/Disgust_light_mode.svg', name: 'Disgust', value: this.extractionResult["faceAttributes"]["emotion"]["disgust"] }
          )
        }
        else{
          this.emotionalAnalysisArray = new Array(
            { emoji: '../../../../../assets/images/emotions/Neutral_Dark_Mode.svg', name: 'Neutral', value: this.extractionResult["faceAttributes"]["emotion"]["neutral"] },
            { emoji: '../../../../../assets/images/emotions/Anger_Dark_Mode.svg', name: 'Anger', value: this.extractionResult["faceAttributes"]["emotion"]["anger"] },
            { emoji: '../../../../../assets/images/emotions/Contempt_Dark_Mode.svg', name: 'Contempt', value: this.extractionResult["faceAttributes"]["emotion"]["contempt"] },
            { emoji: '../../../../../assets/images/emotions/Surprise_Dark_Mode.svg', name: 'Surprise', value: this.extractionResult["faceAttributes"]["emotion"]["surprise"] },
            { emoji: '../../../../../assets/images/emotions/Sadness_Dark_Mode.svg', name: 'Sadness', value: this.extractionResult["faceAttributes"]["emotion"]["sadness"] },
            { emoji: '../../../../../assets/images/emotions/Happiness_Dark_Mode.svg', name: 'Happiness', value: this.extractionResult["faceAttributes"]["emotion"]["happiness"] },
            { emoji: '../../../../../assets/images/emotions/Fear_Dark_Mode.svg', name: 'Fear', value: this.extractionResult["faceAttributes"]["emotion"]["fear"] },
            { emoji: '../../../../../assets/images/emotions/Disgust_Dark_Mode.svg', name: 'Disgust', value: this.extractionResult["faceAttributes"]["emotion"]["disgust"] }
          )
        }
      });
    }
  }

  downloadSession(userId){
    this._vs.fetchSessionVideo(userId).subscribe(res => console.log(res));
  }

  downloadReport(userId){
    this._vs.downloadReport(userId).subscribe(res => console.log(res));
  }

  setActiveTab(item){
    this.goToTabs(item.slug);
    this.activeTab = item.slug;
  }

  goToTabs(div){
    const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
    const scrollToEl = document.getElementById(div)
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
    if(this.activeTab!=div){
      let scrolly=topOfElement-250;
      window.scroll({ top: scrolly, behavior: "smooth" });
    }
    else{
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }
  handleOpenOverrideOcrModal(){
  
  
    
  
   
  

  
      const initialState = {
        title:'Override OCR',
        clientId:this.clientId,
        type: "devops-override-ocr",
        data:this.item
   
      };
      this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: "modal-lg liveCheckModal"
        // class: "modal-lg liveCheckModal"tabularDataModal
      });
      this.bsModalRef.content.closeBtnName = "Close";
      console.log(this.bsModalRef.content.onClose);
      this.bsModalRef.content.onClose.subscribe(result => {
        console.log("results", result);
        if (result && Object.keys(result).length) {
          // this.usersData.unshift(result);
          // reload data here
        }
      });
    
  }
}
